<template>
  <v-dialog
    v-model="showDialog"
    max-width="500"
    persistent
  >
    <v-card class="pa-2">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('settingsPage.deleteConfirm', { tokenName: apiKey.name }) }}
          </h2>
          <v-btn icon @click="showDialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>

        <p class="text-body-1 text-left font-weight-light mt-3">
          {{ $t('settingsPage.willLostData') }}
        </p>
      </v-card-text>

      <v-card-actions class="pb-3">
        <v-row>
          <v-col cols="6">
            <v-btn
              color="gray-100"
              width="100%"
              class="text-capitalize"
              elevation="0"
              @click="showDialog = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="error"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              @click="$emit('delete')"
            >
              {{ $t('delete') }}
          </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteConfirmDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    apiKey: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },
}
</script>