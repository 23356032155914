import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import DashboardLayout from '@/Layouts/DashboardLayout.vue';
import ProjectLayout from '@/Layouts/ProjectLayout.vue';
import FullPageLayout from '@/Layouts/FullPageLayout.vue';
import OrgMemberLayout from '@/Layouts/OrgMemberLayout.vue';
// import OrgProfileLayout from '@/Layouts/OrgProfileLayout.vue';
import PersonalProfile from '@/views/PersonalProfile';
import UserProfileLayout from '@/Layouts/UserProfileLayout.vue';
import projectRoutes from '@/router/project';
import adminRoutes from '@/router/admin';
import settingsRoutes from '@/router/settings';
import runRoutes from '@/router/run';
import milestoneRoutes from '@/router/milestone';
import caseRoutes from '@/router/case';
import testPlansRoutes from '@/router/plan';
import projectSettingsRoutes from '@/router/projectSettings';

Vue.use(VueRouter);

export default function makeRouter(store) {
  const routes = [
    {
      path: '/:handle/dashboard',
      component: ProjectLayout,
      meta: {
        requiresAuth: true,
        isDashboard: true,
      },
      children: [
        {
          path: '/',
          name: 'DashboardMain',
          component: () => import('@/views/Dashboard'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    ...settingsRoutes,
    {
      path: '/',
      component: AuthLayout,
      beforeEnter: (to, from, next) =>
      {
        if (store.state?.user?.currentAccount?.handle) {
          next({
            name: 'ProjectsView',
            params: { handle: store.state.user.currentAccount.handle }
          });
        } else {
          if (to.fullPath === '/') { 
              next({ name: 'Login' });
          } else {
                next()
          }
        }
      },
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import('@/views/Auth/LoginPage'),
        },
        {
          path: '/signup',
          name: 'signup',
          component: () => import('@/views/Auth/RegisterPage'),
        },
        {
          path: '/confirmEmail',
          name: 'confirmEmail',
          component: () => import('@/views/Auth/ConfirmEmailPage'),
        },
        {
          path: '/createPassword',
          name: 'createPassword',
          component: () => import('@/views/Auth/CreatePasswordPage'),
        },
        {
          path: '/forgotPassword',
          name: 'forgotPassword',
          component: () => import('@/views/Auth/ForgotPasswordPage'),
        },
        {
          path: '/resetPassword',
          name: 'resetPassword',
          component: () => import('@/views/Auth/ResetPasswordPage'),
        },
        {
          path: '/twoFactor',
          name: 'twoFactor',
          component: () => import('@/views/Auth/TwoFactorPage'),
        },
        // Unused?? vvv
        {
          path: '/orgs/:handle/invite/new',
          props: true,
          name: 'NewInivte',
          component: () => import('@/views/Org/NewInvite.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/orgs/:handle/invite/:token',
          name: 'GetInvite',
          component: () => import('@/views/Org/GetInvite.vue'),
        },
        {
          path: '/orgs/:handle',
          name: 'GetOrg',
          component: () => import('@/views/Org/GetOrg.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/orgs/:handle/users',
          name: 'OrgMembers',
          component: () => import('@/views/Members/Index.vue'),
          meta: {
            requiresAuth: true,
          },
          beforeEnter: (to, from, next) => {
            if (store.getters['user/isAdmin']) next();
            else next('/');
          },
        },
        {
          path: '/orgs/:handle/admin',
          component: () => import('@/views/Org/Admin/AdminLayout'),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (store.getters['user/isAdmin']) next();
            else next('/');
          },
          children: [
            {
              path: 'users',
              name: 'UsersIndex',
              component: () => import('@/views/Org/Admin/UsersIndex'), // CTODO - move under Orgs folder
            },
          ],
        },
        {
          path: '/profile',
          redirect: 'Home',
          component: PersonalProfile,
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: '/',
              name: 'Overview',
              component: () => import('@/views/PersonalProfile/Page1'),
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'repostories',
              name: 'Repostories',
              component: () => import('@/views/PersonalProfile/Page2'),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    ...projectRoutes,
    ...adminRoutes,
    ...caseRoutes,
    ...runRoutes,
    ...milestoneRoutes,
    ...testPlansRoutes,
    ...projectSettingsRoutes,
    {
      path:
        '/setup',
      name: 'Setup',
      component: () => import('@/views/Setup.vue')
    },
    {
      path: '/notFound',
      name: '404',
      component: () => import('@/views/404.vue')
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/views/404.vue')
    },
  ];


  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach((to, from, next) => {
    // Check if the route requires authentication
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // If user is authenticated, continue
      if (store.getters['user/isAuthenticated']) {
        return next();
      } else {

        return next({ name: 'Login' });
      }
    } else if (to.fullPath === '/') {

      if (store.state?.user?.currentAccount?.handle) {
        // If they do, redirect to ProjectsView
        return next({
          name: 'ProjectsView',
          params: { handle: store.state.user.currentAccount.handle }
        });
      } else {
        // If not, allow navigation (which will be handled by the route's beforeEnter)
        return next();
      }
    } else {
      // For all other routes, allow navigation
      return next();
    }
  });

  return router;
}
