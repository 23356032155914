<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
  >
    <template v-slot:[`item.expires_at`]="{ item }">
      <span>{{ formatExpirationDate(item.expires_at) }}</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <div class="d-flex justify-space-between">
        <v-btn
          icon
          color="primary"
          @click="$emit('edit', item)"
        >
          <EditIcon />
        </v-btn>

        <v-btn
          icon
          color="primary"
          @click="$emit('delete', item)"
        >
          <DeleteIcon />
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate } from '@/utils/util';

import EditIcon from '@/assets/svg/edit.svg';
import DeleteIcon from '@/assets/svg/delete.svg';

export default {
  name: 'APIKeyTable',

  props: {
    headers: Array,
    itemKey: String,
    items: Array,
  },

  components: {
    EditIcon,
    DeleteIcon,
  },

  methods: {
    formatExpirationDate(expiresAt) {
      return formatDate(expiresAt, 'MM/dd/yy')
    }
  },
}
</script>
