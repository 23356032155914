<template>
  <v-container fluid class="custom_bg">
    <Appbar></Appbar>
    <router-view />
  </v-container>
</template>

<script>
  import Appbar from "@/components/Layout/Appbar/index.vue"
  export default {
    components: {
      Appbar
    }
  };
</script>

<style>
.custom_bg {
  background-color: #f3f4f7;
  padding: 20px 20px 0;
  margin-bottom: 20px;
  min-height: 100%;
}
</style>
