<template>
  <v-menu offset-y>
    <template #activator="{ on }">
      <v-btn block outlined v-on="on">
        <v-row align="center" justify="space-between">
          <v-col cols="8" class="text-start">
            <v-icon :size="24" :color="selected.color">{{ selected.icon }}</v-icon>
            <span class="mx-2">{{ selected.subTitle }}</span>
          </v-col>
          <v-col cols="4" class="text-end">
            <v-icon size="24">mdi-chevron-down</v-icon>
          </v-col>
        </v-row>
      </v-btn>
    </template>
    <v-list class="text-left" dense>
      <v-list-item-group v-for="(menu, index) in menus" :key="index">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-uppercase">{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(child, index) in menu.children" :key="index" :to="child.link" :active="isActive(child)">
          <v-list-item-icon>
            <v-icon>{{ child.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ child.subTitle }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    /**
     * menu items to be displayed
     */
    menus: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selected: undefined,
    };
  },
  methods: {
    isActive: function (menu) {
      if (this.$route.name === 'new-token') {
        if (menu.link.substring(10) === 'personal-access-token') {
          this.selected = menu;
          return true;
        } else {
          return false;
        }
      }
      if (this.$route.name === menu.link.substring(10)) {
        this.selected = menu;
        return true;
      }
      return false;
    },
  },
  created() {
    this.selected = this.menus[0].children[0];
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .v-menu__content {
    max-height: 50vh !important;
  }
}
</style>
