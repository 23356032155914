
export default function makeAttachmentService(api) {
    return {
        getSignedAttachmentUrl: function (handle,payload) {
            return api.post(`/${handle}/attachments`, payload)
        },
        uploadAttachment: function (payload) {
            return api.post(`attachments`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
        },
        deleteAttachment: function (data) {
            return api.delete(`attachments?key=${data.key}`)
        },
        uploadAttachments: function (payload) {
            return api.post(`attachments/multiple`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        }
    };
}