<template>
  <v-card flat max-width="600" width="100%" class="pb-5">
    <v-row class="text-left">
      <v-col cols="12" class="pb-0">
        <p class="text-body-1 font-weight-bold">
          {{ $t('billing.yourCurrentPlan') }}
        </p>

        <div class="d-flex justify-space-between align-center mt-5">
          <p class="text-body-2 mb-0 text-uppercase">
            {{ planName }}
          </p>
          <v-btn
            color="gray-100"
            class="text-capitalize px-8"
            elevation="0"
            @click="onUpgradePlan"
          >
            {{ $t('billing.upgradePlan') }}
          </v-btn>
        </div>
      </v-col>

      <v-col
        v-if="hasExpirationDate"
        cols="12"
        class="py-0 mt-3"
      >
        <div class="d-flex justify-space-between grey lighten-5 px-3 rounded">
          <p class="text-body-2 py-2 mb-0">
            {{ $t('billing.nextPayment') }}
          </p>
          <p class="text-body-2 py-2 mb-0">
            {{ currentSubscription.expiration_date }}
          </p>
        </div>
      </v-col>

      <v-col cols="12" v-if="showPaymentForm">
        <stripe-card-form :show="showPaymentForm" :clientSecret="paymentRef" @close="closePaymentForm" />
      </v-col>
    </v-row>

    <choose-plan-dialog
      v-if="hasActiveSubscription"
      :currentSubscription="currentSubscription"
      :is-org="isOrg"
      v-model="showChoosePlanDialog"
      @choose-plan="choosePlan"
    />

    <add-payment-method
      v-model="showAddPaymentMethodDialog"
      @add-payment-method="addPaymentMethod"
      @close-dialog="showAddPaymentMethodDialog = false"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import makeUserService from '@/services/api/user';
import makeOrgService from '@/services/api/org';
import { showErrorToast } from '@/utils/toast';

import { userPlans, orgPlans } from '@/constants/plans';

import ChoosePlanDialog from './ChoosePlanDialog.vue';
import AddPaymentMethod from './AddPaymentMethod.vue';

export default {
  name: 'Subscription',

  components: {
    ChoosePlanDialog,
    AddPaymentMethod,
  },

  props: {
    isOrg: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      userService: null,
      orgService: null,
      currentSubscription: null,
      showChoosePlanDialog: false,
      showAddPaymentMethodDialog: false,
      showPaymentForm: false,
      paymentRef: null,
      planList: [],
    }
  },

  computed: {
    ...mapGetters({
      currentAccount: 'user/currentAccount',
    }),

    planName() {
      const plan = this.planList.find(item => item.stripe_id === this.currentSubscription?.stripe_price_id)
      return plan?.name || ''
    },

    hasExpirationDate() {
      return !!this.currentSubscription?.expiration_date
    },

    hasActiveSubscription() {
      return !!this.currentSubscription?.stripe_price_id
    },
  },

  mounted() {
    this.planList = this.isOrg ? orgPlans : userPlans
    this.userService = makeUserService(this.$api);
    this.orgService = makeOrgService(this.$api);
    this.showSuccessMessage();
    this.loadCurrentSubscription()
  },

  methods: {
    ...mapActions({
      setLoading: 'setLoading',
    }),

    async loadCurrentSubscription() {
      this.setLoading({
        loading: true,
        loadingText: this.$t('billing.loadingCurrentSubscription'),
      })

      try {
        const response = this.isOrg
          ? await this.orgService.getCurrentSubscription(this.currentAccount.uid)
          : await this.userService.getCurrentSubscription()

        const { subscription } = response.data;
        const rank = this.planList.find((p) => p.stripe_id === subscription.stripe_price_id)?.rank ?? -1;
        this.currentSubscription = { ...subscription, rank };
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || this.$t('error.internalServerError'))
      } finally {
        this.setLoading({
          loading: false,
        })
      }
    },

    onUpgradePlan() {
      this.showChoosePlanDialog = true
    },

    async choosePlan(plan) {
      this.showChoosePlanDialog = false

      this.setLoading({
        loading: true,
        loadingText: this.$t('billing.updatingPlan')
      })

      try {
        const payload = {
          price_id: plan.stripe_id
        }

        if (this.isOrg) {
          payload.orgId = this.currentAccount.uid
        }

        const response = this.isOrg ? await this.orgService.changeSubscription(payload) : await this.userService.changeSubscription(payload)
        const { client_secret } = response.data;

        if (!client_secret) {
          this.$swal({
            icon: 'success',
            title: this.$t('common.success'),
            text: this.$t('billing.updatedPlan'),
          });

          setTimeout(() => location.reload(), 5000);

          return
        }

        this.paymentRef = client_secret;
        this.showPaymentForm = true;
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || this.$t('error.internalServerError'))
      } finally {
        this.setLoading({
          loading: false,
        })
      }
    },

    closePaymentForm() {
      this.showPaymentForm = false;
    },

    onAddPaymentMethod() {
      this.showAddPaymentMethodDialog = true
    },

    addPaymentMethod(data) {
      this.showAddPaymentMethodDialog = false
    },

    showSuccessMessage() {
      const { query } = this.$route;

      if (query.redirect_status === 'succeeded') {
        this.$swal({
          icon: 'success',
          title: this.$t('common.success'),
          text: this.$t('billing.updatedPlan'),
        });

        this.$router.replace({ query: {} });
      }
    },
  }
}
</script>