<template>
    <v-data-table
      :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
      class="custom-table mt-6"
      :headers="headers"
      :items="items"
      :item-key="itemKey"
      :value="selectedItems"
      @input="onRowClick"
      @click:row="handleRowClick"
      height="100%"
      show-select
      >
      
      <template v-slot:[`item.user`]="{ item }">
      <td class="d-flex align-center">
        <div class="custom-div mr-2"></div>
        <v-avatar class="mr-2" size="40">
          
          <img :src="resolveAvatarUrl(item.avatar_url)" width="100%" alt="logo" />
        </v-avatar>
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">{{ item.first_name }} {{ item.last_name }}</div>
        </div>
      </td>
    </template>

    <template v-slot:[`item.role`]>
      <td class="text-start">
        <v-menu offset-y :position-y="10">
          <template #activator="{ on }">
            <div v-on="on" role="button">
              {{ role.name }}
            </div>
          </template>
        </v-menu>
      </td>
    </template>

    <template v-slot:[`item.tag`]="{ item }">
      <td class="text-start">
        <v-menu offset-y :position-y="10">
          <template #activator="{ on }">
            <div v-on="on" role="button">
              {{ item.tag && item.tag.length ? `${item.tag[0]} +${item.tag.length - 1}` : '' }}
              <v-icon class="ml-3">mdi-chevron-down</v-icon>
            </div>
          </template>
          <v-list class="pt-4">
            <v-list-item v-for="tag in item.tag" :key="tag" class="m-0">
              <div class="d-flex align-start">
                <div class="d-flex align-center mt-2">
                  <span class="mr-2">{{ tag }}</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </td>
    </template>

    <template v-slot:[`item.email`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item.email }}
      </span>
    </template>

    </v-data-table>
  </template>
  
  <script>
  import { createNamespacedHelpers  } from 'vuex';
  
  const { mapState } = createNamespacedHelpers('user');
  
  export default {
    props: {
      headers: Array,
      itemKey: String,
      items: Array,
      clearSelection: Boolean,
      role: Object,
    },
  
    data() {
      return {
        roleMembers: this.items,
        selectedItems: [],
      }
    },
  
    computed: {
      ...mapState(['currentAccount']),
  
      isAbleToManage() {
        return this.currentAccount.role_name === "owner" || this.currentAccount.role_name === "admin"
      }
    },
    watch: {
        clearSelection(newVal) {
        if (newVal) {
            this.selectedItems = [];
            this.$emit('select-item', this.selectedItems);
        }
        },
    },
    methods: {
        onRowClick(item) {
            this.selectedItems = item;
            this.$emit('select-item', this.selectedItems);
        },
        handleRowClick(item) {
            this.$emit('select-row', item);
        },
        resolveAvatarUrl(avatarUrl) {
          return avatarUrl || require('@/assets/png/avatar.png');
        }
    }
  };
  </script>
  