<template>
  <v-container class="elevation-1 pa-1 white card height-parent position-relative">
    <v-row align-content="start">
      <v-col
        cols="12"
        align-self="start"
        class="v-padding-0"
      >
        <v-toolbar
          v-if="isCollapsed"
          flat
          class="toolbar-bg"
        >
          <v-toolbar-title><b>{{ $t('testFolders') }}</b></v-toolbar-title>
          <v-spacer />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-if="hideCreate"
                :disabled="!selectedProject"
                v-bind="attrs"
                @click="addTestFolder"
                v-on="on"
              >
                <v-icon color="#0C2FF3 !important">
                  mdi-folder-plus-outline
                </v-icon>
              </v-btn>
            </template>
            <span v-if="selectedProject">{{ $t('info.addFolder') }}</span>
            <span v-else>{{ $t('info.selectProject') }}</span>
          </v-tooltip>
        </v-toolbar>
        <v-toolbar
          v-else
          flat
          class="toolbar-bg item-center"
        >
          <v-btn
            icon
            :disabled="!selectedProject"
            v-bind="attrs"
            @click="addTestFolder"
            v-on="on"
          >
            <v-icon color="#0C2FF3 !important">
              mdi-folder-plus-outline
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col
        v-if="isCollapsed"
        cols="12"
        align-self="start"
        class="v-padding-0"
      >
        <v-treeview
          v-model="tree"
          :items="editedItems"
          activatable
          item-key="uid"
          hoverable
          transition
          :active.sync="selectedItemsArray"
          :open.sync="open"
          rounded
          return-object
          expand-icon="mdi-chevron-down"
          class="text-left"
          density="compact"
          color="rgb(24,49,230)"
          @update:active="setActive"
          @update:open="handleOpen"
        >
          <template v-slot:prepend="{ item, open }">
            <v-col
              class="d-flex flex-row justify-start align-center no-select pr-0"
              style="border-left: 2px red;"
            >
              <v-icon
                v-if="hasChildren(item)"
                color="yellow-100"
              >
                {{ open ? 'mdi-folder-open-outline' : 'mdi-folder-outline' }}
              </v-icon>
              <v-icon
                v-else
                color="yellow-100"
              >
                mdi-folder-outline
              </v-icon>
            </v-col>
          </template>
          <template v-slot:label="{ item }">
            <v-col
              v-if="item != currentEditableItem || item === editingFolder"
              class="d-flex flex-row justify-start align-center no-select pl-0 w-full"
              @dblclick="makeEditable(item)"
            >
              <div
                v-if="item === editingFolder"
                class="pl-0"
              >
                <v-text-field
                  v-model="editingFolder.name"
                  :value="editingFolder.name"
                  type="text"
                  background-color="transparent"
                  class="new-folder-input mt-0 pt-0 pl-0"
                  hide-details
                  autofocus
                  solo
                  flat
                  dense
                />
              </div>
              <div
                v-else
                class="pl-2"
              >
                <span>{{ item.name }}</span> 
              </div>
              <span
                v-if="hasChildren(item)"
                class="pl-1"
              >({{ item.children.length }})</span>
            </v-col>
            <v-col
              v-else-if="item.editable && item !== editingFolder"
              class="d-flex flex-row justify-start align-center pl-0 pr-0 w-full"
            >
              <v-text-field
                v-model="item.name"
                :value="item.name"
                type="text"
                background-color="transparent"
                class="new-folder-input mt-0 pt-0 pl-2"
                hide-details
                autofocus
                solo
                flat
                dense
                @keyup.enter="updateFolder(item)"
                @blur="updateFolder(item)"
                @click.stop
              />
            </v-col>
          </template>
          <template v-slot:append="{ item }">
            <v-col
              v-if="item.editable && item !== editingFolder"
              class="d-flex align-center"
            >
              <trashIcon
                class="pointer"
                @mousedown="handleOpenDialog(item)"
              />
            </v-col>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
    <FolderDeleteDialog
      v-if="folderDeleteDialog"
      :value="folderDeleteDialog"
      :folder_name="folderDeleteName"
      @handleConfirmClick="deleteFolder"
      @close="handleCloseDialog"
    />
  </v-container>
</template>

<script>
import { uuid } from 'vue-uuid';
import makeFoldersService from '@/services/api/folder';
import { createNamespacedHelpers } from 'vuex';
import trashIcon from '@/assets/svg/trash-outline.svg';
import FolderDeleteDialog from '@/components/TestCases/FolderDeleteDialog.vue';
import { showErrorToast } from '@/utils/toast';
const { mapMutations, mapActions } = createNamespacedHelpers('folder');

export default {
  components: {
    FolderDeleteDialog,
    trashIcon
  },
  props: {
    collapsed: Number,
    items: Array,
    selectedFolderUid: {
      type: [String, Number],
      required: false,
      default: null,
    },
    hideCreate:{
      type: Boolean,
      default: true
    },
    executionFolders:{
      type: Array,
    }
  },
  data() {
    return {
      folderDeleteDialog: false,
      folderDeleteName: '',
      folderDeleteItem: null,
      treeitems: [],
      tree: [],
      open: [],
      editingFolder: null,
      selectedItem: null,
      createdFoldersCache: {},
      currentEditableItem: null,
      treeviewKey: 0,
      selectedItemsArray: [],
      rootFolderUID: "",
    };
  },
  computed: {
    isCollapsed() {
      // TODO - passing this as a number instead of a boolean is a huge hack
      //        to get around:
      // Property or method "on"/"attrs" is not defined on the instance...
      return this.collapsed ? true : false;
    },
    selectedProject() {
      return this.$route.params.key;
    },
    selectedOrganization() {
      return this.$store.state.currentAccount;
    },
    rootFolder() {
      return this.treeitems.find((folder) => folder.parentUid === null);
    },
    editedItems() {
      if(this.executionFolders?.length && this.treeitems.length){
        const filterFolders = (folder, executionFolders) => {
          const processFolder = (folder) => {
            if (folder.children && Array.isArray(folder.children)) 
              folder.children = folder.children.map(child => processFolder(child)).filter(Boolean);

            if (executionFolders.includes(folder.uid) || (folder.children && folder.children.length > 0))
              return { ...folder, children: folder.children };

            return null;
          }; 
          return [processFolder(folder)];
        };
        filterFolders(this.treeitems[0], this.executionFolders);
      }

      return this.treeitems
    }
  },

  watch: {
    editingFolder: {
      immediate: true,
      handler(newVal) {
        this.open.push(this.selectedItem);
      }
    },
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (this.selectedItem.uid) {
            this.$emit('folder-selected', this.selectedItem.uid);
            this.UPDATE_SELECTED(this.selectedItem.uid);
          } else if (this.active && this.active.tempUid) {
            // In the edge case that we deselect and reselect a newly created
            // folder before we've refreshed the folder list from the backend,
            // the real values for that folder live in this cache.
            this.$emit(
              'folder-selected',
              this.createdFoldersCache[this.active.tempUid].uid
            );
            this.UPDATE_SELECTED(
              this.createdFoldersCache[this.active.tempUid].uid
            );
          }
        }
      }
    },
    selectedFolderUid: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.findSelectedItem();
        }
      }
    },
  },
  methods: {
    ...mapMutations(['UPDATE_SELECTED']),
    ...mapActions(['update','delete']),
    makeEditable(selecteditem) {
      if (this.currentEditableItem && this.currentEditableItem !== selecteditem) {
        this.currentEditableItem.editable = false;
      }
      selecteditem.editable = true;
      this.currentEditableItem = selecteditem;
    },
    handleBlur(item) {
      item.editable = false;
      this.currentEditableItem = null;
    },
    handleCloseDialog() {
      this.folderDeleteDialog = false
      this.folderDeleteItem = null
    },
    handleOpenDialog(item) {
      this.folderDeleteName = item.name
      this.folderDeleteDialog = true
      this.folderDeleteItem = item
    },
    setActive(activeItems) {
      if(!activeItems.length)
        this.selectedItem = null

      let currentItem = activeItems.length ? activeItems[0] : null;

      this.selectedItem = currentItem;
    },
    handleOpen(openItems) {
      this.open = openItems;
    },
    addTestFolder() {
      this.editingFolder = {
        tempUid: uuid.v4(),
        name: 'New folder',
        count: 0,
        children: [],
        parent: this.selectedItem || null,
      };
      this.saveNewFolder()
      if (this.selectedItem) {
        const parentItem = this.selectedItem;
        if (parentItem) {
          parentItem.children.push(this.editingFolder);
        } else {
          this.rootFolder.children.push(this.editingFolder);
        }
      } else {
        this.rootFolder.children.push(this.editingFolder);
      }
    },
    async saveNewFolder() {
      const newFolder = {
        parentId: this.editingFolder.parent?.uid || this.rootFolderUID,
        externalId: '',
        customFields: {},
        source: 'testfiesta',
        name: this.editingFolder.name,
      };
      const folderService = makeFoldersService(this.$api);
      try {
        const response = await folderService.createFolder(
          this.$route.params.handle,
          this.$route.params.key,
          newFolder
        );
        this.createdFoldersCache[this.editingFolder.tempUid] = response.data;
        // this.selectedItem = response.data;
        this.editingFolder = null;
        await this.refreshData();
      } catch (error) {
        showErrorToast(this.$swal, this.$t("test_folder.create_failed"));
      }
    },
    async updateFolder(item) {
      let newFolder = {
        ...item,
      };
      if(!newFolder.name){
        return;
      }
      try {
        const response = this.update({
          swal: this.$swal,
          handle: this.$route.params.handle,
          oldFolder: item,
          projectKey: this.$route.params.key,
          payload:newFolder
        });
        this.selectedItem = response.data;
        this.editingFolder = null;
      } catch (error) {
        showErrorToast(this.$swal, this.$t("test_folder.update_failed"));
      }
      item.editable = false;
      this.currentEditableItem = null;
    },

    async deleteFolder() {
      try {
        this.delete({
          swal: this.$swal,
          handle: this.$route.params.handle,
          folderUid: this.folderDeleteItem.uid,
          projectKey: this.$route.params.key
        });
        this.handleCloseDialog()
        await this.refreshData();
        this.$emit('folder-delete')
      } catch (error) {
        showErrorToast(this.$swal, this.$t("test_folder.delete_failed"));
      }
    },
    async refreshData() {
      const folderService = makeFoldersService(this.$api);
      try {
        const {
          data: { folders },
        } = await folderService.getProjectFolders(
          this.$route.params.handle,
          this.$route.params.key
        );

        if (!this.selectedItem) {
          //if no selected folder uid set root
          this.selectedItem = folders.find((folder) => folder.parentUid === null);
          this.rootFolderUID = this.selectedItem.uid;
        }

        this.treeitems = folders;
      } catch (error) {
        showErrorToast(this.$swal, this.$t("test_folder.refresh_failed"));
      }
    },
    hasChildren(item) {
      return item.children && item.children.length > 0;
    },
    findSelectedItem() {
      if (this.selectedFolderUid) {
        this.selectedItemsArray = [];
        this.selectedItemsArray.push({'uid': this.selectedFolderUid});
      }
    }
  },
  async mounted() {
    this.items.map((item) => {
      item.editable = false
    })

    await this.refreshData()
    this.findSelectedItem()
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style>
  .item-center{
    display: flex;
    justify-content: center;
  }
  .toolbar-bg{
    background-color: transparent !important;
  }
  .height-parent{
    height: 100%;
  }
  .float-bottom{
    position: absolute;
    bottom: 0;
  }
  .position-relative{
    position: relative;
  }
  .card{
    border-radius: 5px;
  }
  .v-treeview-node__root {
    position: relative;
  }

  .v-treeview-node__children {
    margin-left: 10px;
  }

  .v-treeview-node__append {
    margin-left: 0px;
    padding-left: 0px;
  }


  .v-treeview-node {
    margin-left: 10px;
  }

  .v-padding-0{
    padding-top: 0;
    padding-bottom: 0;
  }
  .v-treeview-node__children{
    border-left: 1px solid rgb(247,248,249);
  }
  .v-treeview-node__root .v-treeview-node__level{
    width: 5px !important;
    height: 1px;
    background-color: rgb(247, 248, 249);
  }
  .v-treeview-node__root{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }


  .no-select{
    user-select: none;
  }
</style>
