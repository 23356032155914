<template>
  <v-card class="white py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
    <div class="d-flex align-center justify-space-between">
      <h2>{{ $t('users') }}</h2>
      <v-btn color="blue" v-if="inviteVisibility" dark class="text-capitalize" @click="showDialog = true">
        {{ $t('inviteUser') }} <v-icon class="ml-1" size="xs">mdi-plus</v-icon>
      </v-btn>
      <v-dialog v-if="inviteVisibility" v-model="showDialog" fullscreen max-width="400">
        <v-card>
          <v-card-text class="black--text">
            <div class="d-flex align-center justify-space-between pt-6">
              <h2 class="black--text">{{ $t('inviteNewUser') }}</h2>
              <v-btn icon @click="showDialog = false">
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </div>
            <div>
              <div class="mb-1 text-start mt-4 font-weight-bold">{{ $t('email') }}</div>
              <v-chip-group column>
                <v-chip
                  v-for="(email, index) in emails"
                  :key="index"
                  close
                  class="gray-ish--text font-weight-semi-bold"
                  label
                  text-color="black"
                  @click:close="removeEmail(index)"
                >
                  {{ email }}
                </v-chip>
              </v-chip-group>
              <v-form @submit.prevent="addEmail" class="text-right">
                <v-text-field
                  type="text"
                  dense
                  filled
                  v-model="newEmail"
                  clear-icon="body-2"
                  @click:append="addEmail"
                  @keyup.space="addEmail"
                  :placeholder="$t('email')"
                ></v-text-field>
                <v-btn width="10rem" color="blue" dark full-width elevation="0" class="text-capitalize" @click="addEmail" small>Add Email</v-btn>
              </v-form>
            </div>
            <div>
              <div class="mb-1 text-start mt-4 font-weight-bold">{{ $t('role') }}</div>
              <v-select
                v-model="selectedRole"
                :items="roles"
                item-text="name"
                item-value="uid"
                filled
                dense
              ></v-select>
            </div>
            <div>
              <div class="mb-1 text-start mt-4 font-weight-bold">{{ $t('project') }}</div>
              <v-select
                v-model="selectedProject"
                :items="projectOptions"
                item-text="name"
                item-value="key"
                filled
                dense
              ></v-select>
            </div>
            <div>
              <div class="mb-1 text-start mt-4 font-weight-bold">{{ $t('tag') }}</div>
              <vue-tags-input
                v-model="tag"
                :tags="tags"
                @tags-changed="newTags => tags = newTags"
              />
            </div>
            <div class="d-flex justify-space-between mt-5">
              <v-btn width="10rem" @click="cancel" color="gray-100" full-width class="text-capitalize" elevation="0">{{ $t('cancel') }}</v-btn>
              <v-btn width="10rem" color="blue" dark full-width elevation="0" class="text-capitalize" @click="inviteUsers">{{ $t('invite') }}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="mt-4 d-flex">
      <v-chip 
        @click="updateFilter('active')" 
        :class="{ 'blue--text': filter === 'active' }" 
        width="200px"
        :color="filter === 'active' ? '#e6ecff' : '#f9fafb'" 
        label>
        <div class="px-2"
        :class="{ 'font-weight-bold': filter === 'active' }"
        > {{ $t('active') }} <span class="ml-2">{{ activeUserCount }}</span></div>
      </v-chip>
      <div class="ml-2">
        <v-chip 
          @click="updateFilter('pending')" 
          :class="{ 'blue--text': filter === 'pending' }" 
          width="200px"
          :color="filter === 'pending' ? '#e6ecff' : '#f9fafb'" 
          label>
          <div class="px-2"
          :class="{ 'font-weight-bold': filter === 'pending' }" 
          > {{ $t('pending') }} <span class="ml-2">{{ pendingUserCount }}</span></div>
        </v-chip>
      </div>
      <div class="ml-2">
        <v-chip 
          @click="updateFilter('requested')" 
          :class="{ 'blue--text': filter === 'requested' }" 
          width="200px"
          :color="filter === 'requested' ? '#e6ecff' : '#f9fafb'" 
          label>
          <div class="px-2"
          :class="{ 'font-weight-bold': filter === 'requested' }" 
          > {{ $t('requested') }} <span class="ml-2">{{ requestedUserCount }}</span></div>
        </v-chip>
      </div>
    </div>
  </v-card>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import makeOrgService from '@/services/api/org';
import validator from 'validator';
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('project');
const { mapActions } = createNamespacedHelpers('org');

export default {
  props: {
    filter: String,
    activeUserCount: Number,
    pendingUserCount: Number,
    requestedUserCount: Number,
    roles:{
      type: Array,
      default: () =>{
        return []
      }
    }
  },
  components: {
    VueTagsInput,
  },
  data() {
    return {
      showDialog: false,
      showInvites: true,
      newEmail: '',
      emails: [],
      selectedRole: 'member',
      selectedProject: '',
      tag: '',
      tags: [],
      autoCompleteTags: [],
      sendingInvite: false,
    };
  },
  computed: {
    ...mapState(['projects']),  
    // Get username to prevent invites in personal accounts 
    username(){
      return this.$store.getters['user/user'].handle;
    },
    inviteVisibility(){
      return this.$route.params.handle !== this.username ? true : false;

    },
    projectOptions(){
      return this.projects.map(project => ({
      name: project.name,
      key: project.key
    }));
    }
  },
  methods: {
    ...mapActions(['sendInvite']),
    async addEmail() {
      if (this.newEmail && validator.isEmail(this.newEmail.trim())) {
        this.emails.push(this.newEmail.trim());
        this.newEmail = '';
      } else {
        this.$emit('showError', this.$t('validEmail'));
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    updateFilter(filter) {
      this.$emit('update-filter', filter);
    },
    async inviteUsers() {
      if(this.newEmail)
        await this.addEmail()
      
      const orgService = makeOrgService(this.$api);
      if (this.sendingInvite) return;
      let hasError = false;

      if (!this.emails.length) {
        hasError = true;
        this.$emit('showError', this.$t('atLeastOneEmailRequired'));
      }

      if (hasError) return;

      const payloads = this.emails.map(email => ({
        role_uid: this.selectedRole,
        handle: this.$store.state.user.currentAccount.handle,
        user_email: email,
        project_id: this.selectedProject,
        tags: this.tags.map(tag => tag.text),
      }));

      this.sendingInvite = true;
      try {
        await Promise.all(payloads.map(payload => this.sendInvite(payload)));
        this.$emit('completed');

        // reset all states
        this.showDialog = false;
        this.emails = [];
        this.selectedRole = 'member';
        this.selectedProject = '';
        this.tags = [];
      } catch (error) {
        if (!error.response) return this.$emit('showError');

        this.$emit('showError', error.response?.data.message);
      }
      this.sendingInvite = false;
    },
    cancel() {
      this.showDialog = false;
    }
  },
};
</script>
