<template>
  <v-card>
    <v-toolbar class="elevation-0" dense>
      <v-toolbar-title>New Chart</v-toolbar-title>
      <step-progress :length="5" :step="seriesPage"></step-progress>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')" small class="elevation-0 mx-1" outlined>Cancel</v-btn>
      <v-btn
        v-if="graphDataFromProps"
        @click="
          close();
          updateChart();
        "
        color="primary"
        class="elevation-0"
        small
        >Update chart</v-btn
      >
      <v-btn
        v-else
        @click="
          $emit('add-chart', newChart);
          ok();
        "
        class="elevation-0"
        color="primary"
        small
        >Add to chart</v-btn
      >
    </v-toolbar>
    <v-card-text class="my-4">
      <v-row class="bg-default">
        <v-col cols="12" md="6">
          <v-select
            v-model="selectedNewChartType"
            :items="newChartTypeOptions"
            :menu-props="{ bottom: true, offsetY: true }"
            label="Type of chart"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="chartTitle" label="Chart Name" outlined dense></v-text-field>
        </v-col>
        <v-col cols="12">
          <vue-apex-charts
            :type="computedChartType"
            :series="seriesInfo"
            :options="currentOptions"
            width="100%"
            height="306px"
          ></vue-apex-charts>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <v-list dense class="text-left">
            <v-subheader>Data Series</v-subheader>
            <v-list-item outlined v-for="(series, index) in seriesInfo" :key="index" class="data-serious">
              <v-list-item-avatar>
                <v-btn icon @click="deleteSeries(index)" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-icon>
                <div
                  :style="{
                    height: '1rem',
                    width: '1rem',
                    backgroundColor: series.color,
                    borderRadius: '50%',
                    display: 'inline-block',
                    verticalAlign: 'end',
                  }"
                ></div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ series.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-pencil-box-outline</v-icon>
              </v-list-item-action>
              <v-list-item-action>
                <v-icon>mdi-upload</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-btn icon>
                <v-icon>mdi-plus-box-outline</v-icon>
              </v-btn>
              <v-list-item-content>
                <v-list-item-title>Add Data Series</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="seriesPage" background-color="transparent" color="primary" grow>
            <v-tab>Data</v-tab>
            <v-tab :disabled="!chartOptionsAvailable">Basic</v-tab>
            <v-tab :disabled="!chartOptionsAvailable">Advanced</v-tab>
            <v-tab-item>
              <v-form>
                <v-list dense class="text-left">
                  <v-list-item v-if="seriesPage === 1">
                    <v-list-item-content>
                      <v-list-item-title>What data would you like to visualize?</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-for="type in Object.keys(seriesTypes)"
                    :key="type"
                    @click="selectPrimaryDataType(type)"
                    href="#"
                    class="p-8"
                    outlined
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ toTitle(type) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="seriesPage === 1">
                    <v-list-item-content>
                      <v-btn @click="nextSeriesPage" :disabled="!seriesPageComplete(1)" color="primary">Next</v-btn>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="seriesPage === 2">
                    <v-list-item-content>
                      <v-list-item-title>How are we going to want to calculate the data?</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="selectedChartAllowPrimaryTreatment('plot')"
                    :active="selectedPrimaryDataDimensionTreatment == 'plot'"
                    @click="selectPrimaryDataDimensionTreatment('plot')"
                    href="#"
                    class="p-8"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Plot - plot a numerical field that exists in the data.</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="selectedChartAllowPrimaryTreatment('count')"
                    :active="selectedPrimaryDataDimensionTreatment == 'count'"
                    @click="selectPrimaryDataDimensionTreatment('count')"
                    href="#"
                    class="p-8"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Count - count the number of {{ toTitle(selectedPrimaryDataType) }} in the
                        data.</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="selectedChartAllowPrimaryTreatment('sum')"
                    :active="selectedPrimaryDataDimensionTreatment == 'sum'"
                    @click="selectPrimaryDataDimensionTreatment('sum')"
                    href="#"
                    class="p-8"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Sum - sum a numerical field that exists in the data.</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="seriesPage === 2">
                    <v-list-item-content>
                      <v-btn @click="backSeriesPage" href="#" variant="outline-primary">Back</v-btn>
                      <v-btn @click="nextSeriesPage" href="#" :disabled="!seriesPageComplete(2)" color="primary"
                        >Next</v-btn
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="seriesPage === 3">
                    <v-list-item-content>
                      <v-list-item-title>Do we want to include related data sets?</v-list-item-title>
                      <v-select
                        id="secondary-data-types-ddl"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="selectedSecondaryDataTypes"
                        @change="selectSecondaryDataType()"
                        :items="secondaryDataTypeOptions"
                        multiple
                        :select-size="5"
                      ></v-select>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="seriesPage === 3">
                    <v-list-item-content>
                      <v-btn @click="backSeriesPage" href="#" variant="outline-primary">Back</v-btn>
                      <v-btn @click="nextSeriesPage" href="#" :disabled="!seriesPageComplete(3)" color="primary"
                        >Next</v-btn
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="seriesPage === 4">
                    <v-list-item-content>
                      <v-list-item-title
                        >How are we going to want to graph the (' + toTitle(selectedPrimaryDataDimension) + ')
                        data?</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="selectedChartAllowSecondaryTreatment('time')"
                    :active="selectedSecondaryDataDimensionTreatment == 'time'"
                    @click="selectSecondaryDataDimensionTreatment('time')"
                    href="#"
                    class="p-8"
                  >
                    <v-list-item-content>
                      <v-list-item-title>Over time - plot the primary data over time.</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="selectedChartAllowSecondaryTreatment('number')"
                    :active="selectedSecondaryDataDimensionTreatment == 'number'"
                    @click="selectSecondaryDataDimensionTreatment('number')"
                    href="#"
                    class="p-8"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Over numbers - plot the primary data over a range of numbers.</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="selectedChartAllowSecondaryTreatment('category')"
                    :active="selectedSecondaryDataDimensionTreatment == 'category'"
                    @click="selectSecondaryDataDimensionTreatment('category')"
                    href="#"
                    class="p-8"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >Over categories - plot the primary data over groups/categories.</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="seriesPage === 4">
                    <v-list-item-content>
                      <v-btn @click="backSeriesPage" href="#" variant="outline-primary">Back</v-btn>
                      <v-btn @click="nextSeriesPage" href="#" :disabled="!seriesPageComplete(4)" color="primary"
                        >Next</v-btn
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="seriesPage === 5">
                    <v-list-item-content>
                      <v-list-item-title>How do we want to narrow down our data?</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="(filter, index) in dataFilters" :key="index" href="#" class="p-8">
                    <v-select
                      v-model="filter.dimension"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :items="allDataDimensionOptions"
                      dense
                      outlined
                    ></v-select>
                    <v-select
                      v-if="filter.dimension"
                      v-model="filter.operator"
                      :items="filterOptions(filter.dimension)"
                      :menu-props="{ bottom: true, offsetY: true }"
                      dense
                      outlined
                    ></v-select>
                    <v-text-field
                      v-if="filter.dimension"
                      v-model="filter.input"
                      :type="filterInputType(filter.dimension)"
                    ></v-text-field>
                    <v-btn icon @click="addNewDataFilter">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field v-model="selectedSeriesName" label="Name this data series"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-btn @click="backSeriesPage" href="#" variant="outline-primary">Back</v-btn>
                      <v-btn @click="addSeriesData" href="#" :disabled="!seriesPageComplete(5)" color="primary"
                        >Add data</v-btn
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <v-form>
                <v-card-text>
                  <v-checkbox
                    v-model="chartDataLabels"
                    v-if="this.chartTypeConfig.options.dataLabels"
                    label="Data labels on chart elements?"
                  ></v-checkbox>
                </v-card-text>
              </v-form>
            </v-tab-item>
            <v-tab-item></v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="backSeriesPage()" :disabled="seriesPage <= 1" class="graph-btn-back">Back</v-btn>
      <v-btn class="graph-btn-next" @click="nextSeriesPage()" :disabled="seriesPage >= 5">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import _ from 'lodash';
import { ChartTypes } from '@/constants/chartTypes';
import DashboardService from '@/services/api/dashboard';
import dayjs from 'dayjs';
import validator from 'validator';
import VueApexCharts from 'vue-apexcharts';
import stepProgress from './stepProgessSlider';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('user');

export default {
  props: {
    chartData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      seriesPage: 1,
      seriesInfo: [],
      currentOptions: {},
      dashboardService: null,
      seriesTypes: {},
      seriesTypeData: {},
      selectedNewChartType: 'Line',
      selectedPrimaryDataType: null,
      selectedSecondaryDataTypes: [],
      selectedPrimaryDataDimension: null,
      selectedSecondaryDataDimension: null,
      selectedPrimaryDataDimensionTreatment: '',
      selectedSecondaryDataDimensionTreatment: '',
      selectedSeriesName: null,
      primaryDataDimensionOptions: {},
      secondaryDataDimensionOptions: {},
      allDataDimensionOptions: {},
      numericalDataDimensionOptions: {},
      numAndBoolDataDimensionOptions: {},
      dateDataDimensionOptions: {},
      dataDimensionsInfo: {},
      dataFilters: [],
      chartTitle: '',
      chartDataLabels: false,
      newChartTypeOptions: [
        'Area',
        'Bar',
        'Box Plot',
        'Bubble',
        'Candlestick',
        'Donut',
        'Heatmap',
        'Histogram',
        'Line',
        'Pie',
        'Polar Area',
        'Radar',
        'Radial Bar',
        'Range Bar',
        'Scatter',
        'Treemap',
      ],
      graphDataFromProps: null,
    };
  },
  components: { VueApexCharts, stepProgress },
  computed: {
    ...mapState(['currentAccount']),
    newChart() {
      let chartInfo = {
        options: this.currentOptions,
        series: this.seriesInfo,
      };
      chartInfo.options.type = this.computedChartType;
      return chartInfo;
    },
    newChartReady() {
      return this.seriesInfo.length > 0 && Object.keys(this.seriesInfo[0].ref).length > 0 ? true : false;
    },
    chartOptionsAvailable() {
      return this.seriesInfo.length > 0 && Object.keys(this.seriesInfo[0].ref).length > 0 ? true : false;
    },
    selectedPrimaryDataDimensionTreatmentExists() {
      return Object.keys(this.primaryDataDimensionOptions).length > 0;
    },
    chartTypeConfig() {
      const camelType = _.camelCase(this.selectedNewChartType);
      return ChartTypes[camelType];
    },
    computedChartType() {
      const camelType = _.camelCase(this.selectedNewChartType);
      let aliasedType;
      if (this.chartTypeConfig.aliasedType) {
        aliasedType = this.chartTypeConfig.aliasedType;
      }
      if (aliasedType !== undefined) {
        return aliasedType;
      } else {
        return camelType;
      }
    },
    allowAdditionalDataDimensions() {
      return this.chartTypeConfig.fields.secondary;
    },
    secondaryDataTypeOptions() {
      return Object.values(this.seriesTypes[this.selectedPrimaryDataType])
        .flatMap((key) => {
          return Object.values(key).flatMap((relation) => {
            return relation;
          });
        })
        .map((relation) => ({
          value: relation.relationship,
          text: this.toTitle(relation.relationship),
        }));
    },
    seriesInfoColors() {
      return this.seriesInfo.flatMap((series) => {
        return series.color;
      });
    },
  },
  watch: {
    chartData(newValue) {
      this.graphDataFromProps = newValue;
    },
    selectedNewChartType() {
      if (this.seriesInfo.length < 1 || Object.keys(this.seriesInfo[0].ref).length === 0) {
        this.seriesInfo = this.chartTypeConfig.defaultData.series;
      }
      this.currentOptions = this.chartTypeConfig.defaultData.options;
      this.currentOptions.colors = this.seriesInfoColors;
      if (this.chartTitle === '' || this.chartTitle.indexOf('New ') === 0) {
        this.chartTitle = this.chartTypeConfig.options.title.default;
      }
      this.chartDataLabels = this.chartTypeConfig.options.dataLabels.default;
    },
    chartTitle(newValue) {
      this.updateProperty(this.chartTypeConfig.options.title, newValue);
    },
    chartDataLabels(newValue) {
      this.updateProperty(this.chartTypeConfig.options.dataLabels, newValue);
    },
    selectedSecondaryDataTypes() {
      this.updateDataDimensionOptions();
    },
    selectedPrimaryDataType() {
      // CTODO - reset EVERYTHING after. Do we need a tree so this is easy?
      this.selectedPrimaryDataDimensionTreatment = '';
    },
    selectedPrimaryDataDimensionTreatment(newValue) {
      switch (newValue) {
        case 'plot':
          this.primaryDataDimensionOptions = this.numAndBoolDataDimensionOptions;
          break;
        case 'sum':
          this.primaryDataDimensionOptions = this.numericalDataDimensionOptions;
          break;
        case 'count':
        default:
          this.primaryDataDimensionOptions = {};
          this.selectedPrimaryDataDimension = this.selectedPrimaryDataType;
          break;
      }
    },
    selectedSecondaryDataDimensionTreatment(newValue) {
      switch (newValue) {
        case 'time':
          this.secondaryDataDimensionOptions = this.dateDataDimensionOptions;
          break;
        case 'number':
          this.secondaryDataDimensionOptions = this.numericalDataDimensionOptions;
          break;
        case 'category':
        default:
          this.secondaryDataDimensionOptions = this.allDataDimensionOptions;
          break;
      }
    },
  },
  created() {
    this.dashboardService = DashboardService(this.$api);
    this.dashboardService
      .getSeriesTypes(this.currentAccount.uid)
      .then((response) => {
        this.seriesTypes = response.data;
      })
      .catch((error) => {
        this.$swal({
          icon: 'error',
          title: this.$t('error'),
          text: error.response.data.error,
        });
      });
  },
  mounted() {
    this.resetData();
  },
  methods: {
    async updateChart() {
      const { value } = await this.$swal({
        title: 'New Dashboard',
        input: 'text',
        inputLabel: `Name for a new Dashboard:`,
        inputValue: '',
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: 'Save',
        inputValidator: (value) => {
          if (!value) return 'You need to write something!';
        },
      });
    },
    async addSeriesData() {
      let newSeries = {
        name: this.selectedSeriesName,
        color: '#8841eb',
        ref: {
          axises: {
            primary: {
              dimension: this.selectedPrimaryDataDimension,
              treatment: this.selectedPrimaryDataDimensionTreatment,
            },
            secondary: {
              dimension: this.selectedSecondaryDataDimension,
              treatment: this.selectedSecondaryDataDimensionTreatment,
            },
          }, // CTODO - tertiary
          filters: this.dataFilters,
        },
      };
      newSeries.data = await this.dashboardService.pullSeriesData(this.$parent.db, newSeries);

      if (this.seriesInfo.length === 1 && Object.keys(this.seriesInfo[0].ref).length === 0) {
        this.seriesInfo = [];
      }
      this.seriesInfo.push(newSeries);
    },
    seriesPageComplete(number) {
      switch (number) {
        case 1:
          return this.selectedPrimaryDataType !== null;
        case 2:
          return this.selectedPrimaryDataDimensionTreatment && this.selectedPrimaryDataDimension;
        case 3:
          return true;
        case 4:
          return this.selectedSecondaryDataDimensionTreatment && this.selectedSecondaryDataDimension;
        case 5:
          return this.selectedSeriesName;
      }
    },
    nextSeriesPage() {
      if (this.seriesPage === 3) {
        if (this.allowAdditionalDataDimensions) {
          this.seriesPage = 4;
        } else {
          this.seriesPage = 5;
        }
      } else {
        this.seriesPage = this.seriesPage + 1;
      }
    },
    backSeriesPage() {
      if (this.seriesPage === 5) {
        if (this.allowAdditionalDataDimensions) {
          this.seriesPage = 4;
        } else {
          this.seriesPage = 3;
        }
      } else {
        this.seriesPage = this.seriesPage - 1;
      }
    },
    graphBackClick() {},
    graphNextClick() {},
    deleteSeries(index) {
      this.seriesInfo.splice(index, 1);
    },
    determineType(value) {
      if (value === undefined || value === null) {
        return 'Unknown';
      } else if (
        typeof value === 'boolean' ||
        (typeof value === 'string' && validator.isBoolean(value, { loose: true }))
      ) {
        return 'Boolean';
      } else if (!isNaN(value) || validator.isNumeric(value)) {
        return 'Number';
      } else if (validator.isURL(value)) {
        return 'URL';
      } else if (dayjs(value).isValid()) {
        return 'Date';
      } else {
        return 'String';
      }
    },
    filterInputType(data) {
      if (data) {
        const splitData = data.split('.');
        if (splitData.length === 2) {
          const dataType = splitData[0];
          const dataDimension = splitData[1];

          switch (this.dataDimensionType(dataType, dataDimension).name) {
            case 'Number':
              return 'number';
            case 'Date':
              return 'date';
          }
        }
      }
      return 'text';
    },
    filterOptions(data) {
      if (data) {
        const splitData = data.split('.');
        if (splitData.length === 2) {
          const dataType = splitData[0];
          const dataDimension = splitData[1];

          switch (this.dataDimensionType(dataType, dataDimension).name) {
            case 'Number':
            case 'Boolean':
            case 'Date':
              return ['>', '<', '=', '>=', '<='];
            case 'String':
            default:
              return ['=', '~'];
          }
        }
      }
      return [];
    },
    addNewDataFilter() {
      this.dataFilters.push({});
    },
    selectedChartAllowPrimaryTreatment(treatmentType) {
      // Chart type allows it
      return this.chartTypeConfig.fields.primary.typeOptions.includes(treatmentType);
    },
    selectedChartAllowSecondaryTreatment(treatmentType) {
      // Chart type allows it
      if (this.chartTypeConfig.fields.secondary && this.chartTypeConfig.fields.secondary.typeOptions) {
        return this.chartTypeConfig.fields.secondary.typeOptions.includes(treatmentType);
      } else {
        return false;
      }
    },
    selectPrimaryDataDimensionTreatment(typeSelection) {
      this.selectedPrimaryDataDimensionTreatment = typeSelection;
    },
    selectSecondaryDataDimensionTreatment(typeSelection) {
      this.selectedSecondaryDataDimensionTreatment = typeSelection;
    },
    async selectPrimaryDataType(typeSelection) {
      this.selectedPrimaryDataType = typeSelection;
      if (typeSelection !== null && !this.seriesTypeData[typeSelection]) {
        // CTODO - Loader while waiting on async to fill selection box?
        let res = await this.dashboardService
          .getTypeData(this.$parent.db, this.currentAccount.uid, [typeSelection], 700)
          .catch((error) => {
            this.$swal({
              icon: 'error',
              title: this.$t('error'),
              text: error,
            });
          });
        // CTODO - back to 7 for events and infinity for non event-type
        this.$parent.db = await res.db;
        let response = res.response;
        for (const [key, value] of Object.entries(response.data)) {
          this.seriesTypeData[key] = value;
        }
        this.updateDataDimensionOptions();
      }
    },
    async selectSecondaryDataType() {
      let desiredTypes = [];
      for (const typeSelection of this.selectedSecondaryDataTypes) {
        if (!this.seriesTypeData[typeSelection]) {
          desiredTypes.push(typeSelection);
        }
      }

      if (desiredTypes.length > 0) {
        // CTODO - Loader while waiting on async to fill selection box?
        let res = await this.dashboardService
          .getTypeData(this.$parent.db, this.currentAccount.uid, desiredTypes, 700)
          .catch((error) => {
            this.$swal({
              icon: 'error',
              title: this.$t('error'),
              text: error,
            });
          });
        // CTODO - back to 7 for events and infinity for non event-type
        this.$parent.db = await res.db;
        let response = res.response;

        for (const [key, value] of Object.entries(response.data)) {
          this.seriesTypeData[key] = value;
          //this.dashboardService.indexData(key, value, this.seriesTypes[key]);
        }
        await this.updateDataDimensionOptions();
      }
    },
    toTitle(snakeStr) {
      return _.startCase(_.camelCase(snakeStr));
    },
    dataDimensionType(dataType, dataDimension) {
      let returnType = {
        name: 'Unknown',
        count: 0,
        total: 0,
      };
      for (const [countedType, count] of Object.entries(this.dataDimensionsInfo[dataType][dataDimension].dataType)) {
        if (count > returnType.count) {
          returnType.name = countedType;
          returnType.count = count;
        }
        returnType.total += count;
      }
      return returnType;
    },
    async updateDataDimensionOptions() {
      this.allDataDimensionOptions = {};
      this.numericalDataDimensionOptions = {};
      this.numAndBoolDataDimensionOptions = {};
      this.dateDataDimensionOptions = {};

      if (this.selectedPrimaryDataType) {
        if (!this.allDataDimensionOptions[this.selectedPrimaryDataType]) {
          this.allDataDimensionOptions[this.selectedPrimaryDataType] = [];
        }
      }

      if (this.selectedSecondaryDataTypes.length > 0) {
        for (const secondDataType of this.selectedSecondaryDataTypes) {
          if (!this.allDataDimensionOptions[secondDataType]) {
            this.allDataDimensionOptions[secondDataType] = [];
          }
        }
      }

      // Remove types that are no longer selected
      const validTypes = [this.selectedPrimaryDataType, ...this.selectedSecondaryDataTypes];
      for (const oldKey of Object.keys(this.allDataDimensionOptions)) {
        if (!validTypes.includes(oldKey)) {
          delete this.allDataDimensionOptions[oldKey];
        }
      }

      const skipKeys = ['created_at', 'deleted_at', 'updated_at'];
      const defaultDataType = {
        Unknown: 0,
        Boolean: 0,
        Number: 0,
        URL: 0,
        Date: 0,
        String: 0,
      };
      for (const dataType of Object.keys(this.allDataDimensionOptions)) {
        const data = await this.dashboardService.getAllData(this.$parent.db, dataType);
        const sampleEnd = data.length > 500 ? data.length - 500 : -1;
        for (let i = data.length - 1; i > sampleEnd; i--) {
          // TODO - is a sampple of 500 from the end enough?
          for (const key of Object.keys(data[i])) {
            if (key !== 'custom_fields') {
              if (!skipKeys.includes(key)) {
                if (!this.allDataDimensionOptions[dataType].includes(key)) {
                  this.allDataDimensionOptions[dataType].push(key);
                }

                if (!this.dataDimensionsInfo[dataType]) {
                  this.dataDimensionsInfo[dataType] = {};
                }
                if (!this.dataDimensionsInfo[dataType][key]) {
                  this.dataDimensionsInfo[dataType][key] = {
                    dataType: Object.assign({}, defaultDataType),
                  };
                }
                // If there is already a value there, we don't want to overwrite it.  If it doesn't match what
                //    we find, then we should change to unknown.
                switch (this.determineType(data[i][key])) {
                  case 'Unknown':
                    this.dataDimensionsInfo[dataType][key].dataType.Unknown++;
                    break;
                  case 'Number':
                    this.dataDimensionsInfo[dataType][key].dataType.Number++;
                    break;
                  case 'Boolean':
                    this.dataDimensionsInfo[dataType][key].dataType.Boolean++;
                    break;
                  case 'URL':
                    this.dataDimensionsInfo[dataType][key].dataType.URL++;
                    break;
                  case 'Date':
                    this.dataDimensionsInfo[dataType][key].dataType.Date++;
                    break;
                  case 'String':
                    this.dataDimensionsInfo[dataType][key].dataType.String++;
                    break;
                }
              }
            }
          }

          // Flatten custom keys
          for (const customKey of Object.keys(data[i].custom_fields)) {
            let newData = data[i].custom_fields;
            if (!this.allDataDimensionOptions[dataType].includes(customKey)) {
              this.allDataDimensionOptions[dataType].push(customKey);
            }
            if (!this.dataDimensionsInfo[dataType]) {
              this.dataDimensionsInfo[dataType] = {};
            }
            if (!this.dataDimensionsInfo[dataType][customKey]) {
              this.dataDimensionsInfo[dataType][customKey] = {
                dataType: Object.assign({}, defaultDataType),
              };
            }
            // If there is already a value there, we don't want to overwrite it.  If it doesn't match what
            //    we find, then we should change to unknown.
            switch (this.determineType(newData[customKey])) {
              case 'Unknown':
                this.dataDimensionsInfo[dataType][customKey].dataType.Unknown++;
                break;
              case 'Number':
                this.dataDimensionsInfo[dataType][customKey].dataType.Number++;
                break;
              case 'Boolean':
                this.dataDimensionsInfo[dataType][customKey].dataType.Boolean++;
                break;
              case 'URL':
                this.dataDimensionsInfo[dataType][customKey].dataType.URL++;
                break;
              case 'Date':
                this.dataDimensionsInfo[dataType][customKey].dataType.Date++;
                break;
              case 'String':
                this.dataDimensionsInfo[dataType][customKey].dataType.String++;
                break;
            }
          }
        }
      }

      // Break down specific dimension options.
      for (const [keyDataType, keyData] of Object.entries(this.allDataDimensionOptions)) {
        for (const newKey of keyData) {
          if (!this.numericalDataDimensionOptions[keyDataType]) {
            this.numericalDataDimensionOptions[keyDataType] = [];
            this.numAndBoolDataDimensionOptions[keyDataType] = [];
            this.dateDataDimensionOptions[keyDataType] = [];
          }
          let DDT = this.dataDimensionType(keyDataType, newKey);
          if (!this.numericalDataDimensionOptions[keyDataType].includes(newKey) && DDT.name === 'Number') {
            this.numericalDataDimensionOptions[keyDataType].push(newKey);
            this.numAndBoolDataDimensionOptions[keyDataType].push(newKey);
          } else if (!this.numericalDataDimensionOptions[keyDataType].includes(newKey) && DDT.name === 'Boolean') {
            this.numAndBoolDataDimensionOptions[keyDataType].push(newKey);
          } else if (!this.dateDataDimensionOptions[keyDataType].includes(newKey) && DDT.name === 'Date') {
            this.dateDataDimensionOptions[keyDataType].push(newKey);
          }
        }
      }
    },
    resetData() {
      if (this.seriesInfo.length < 1 || Object.keys(this.seriesInfo[0].ref).length === 0) {
        this.seriesInfo = this.chartTypeConfig.defaultData.series;
      }
      this.currentOptions = this.chartTypeConfig.defaultData.options;
      this.currentOptions.colors = this.seriesInfoColors;
      if (this.chartTitle === '' || this.chartTitle.indexOf('New ') === 0) {
        this.chartTitle = this.chartTypeConfig.options.title.default;
      }
      this.chartDataLabels = this.chartTypeConfig.options.dataLabels.default;
      // CTODO - reset the rest as well
    },
    updateProperty(property, newValue) {
      const keys = property.location.split('.');
      let value;
      for (let i = 0; i <= keys.length - 2; i++) {
        if (this.currentOptions[keys[i]] === undefined) {
          this.$set(this.currentOptions, keys[i], {});
        }
        value = this.currentOptions[keys[i]];
      }
      value[keys[keys.length - 1]] = newValue; // CTODO - this doesn't work with reactivity
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/dashboard-grid/theme-colors';

.add-to-chart {
  width: 94px;
  height: 30px;
  background: #8b5cf6 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border-color: #8b5cf6 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  padding: 0px !important;
  margin-left: 8px;
  line-height: 30px !important;
}

.add-to-chart-cancel {
  width: 62px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #d1d5db !important;
  background: white !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  padding: 0px !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  line-height: 30px !important;
}

.new-chart-type-ddl {
  width: 253px;
}

.modal-dialog {
  max-width: 970px !important;
  display: flex !important;
  height: 100% !important;
  margin: auto !important;
  align-content: flex-start;
  align-items: center;
}

.graph-wrap {
  height: 474px;
  background: #f3f4f6;
  padding: 16px !important;
  border-radius: 0px 0px 0px 8px;

  .row {
    width: 534px !important;
  }
}

.data-wrap {
  padding: 16px !important;
  width: 400px !important;
}

.modal-body {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.desp {
  margin-bottom: 4px !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}

.data-serious {
  margin-bottom: 1rem !important;
  display: flex !important;
  padding: 0px !important;
  border: none !important;
}

.data-serious-item {
  box-sizing: border-box;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px 9px 3px !important;
  gap: 8px;
  width: 284px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-left: 12px;
}

.icon-btn {
  cursor: pointer;
}

.nav-pills .nav-link.active {
  color: #1f2937 !important;
  background-color: #fff !important;
  border-radius: 6px !important;
}

.card-header ul {
  padding: 2px;
  height: 32px;
  background: #f3f4f6;
  border-radius: 8px;
}

.card-header a {
  height: 28px !important;
  padding: 2px !important;
}

.data-wrap .card {
  background: #f9fafb !important;
  border-radius: 10px !important;
  border: none !important;

  .tab-pane {
    box-shadow: none !important;
  }

  .card-header {
    border: none !important;
    background-color: #f9fafb !important;
  }
}

.modal-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 32px !important;
}

.btn-wrap {
  position: absolute;
  bottom: 0px;
  display: flex;
  padding: 0px 30px 16px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;
  right: 0px;
  left: 0px;

  .graph-btn-back,
  .graph-btn-next {
    padding: 7px 11px;
    width: 51px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #d1d5db !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #374151;
  }
}
</style>
