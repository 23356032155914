<template>
    <div>
      <v-sheet
        @click="showDialog = true"
        color="gray-100"
        class="d-flex align-center justify-center"
        height="40px"
        rounded="lg"
      >
        <span class="px-4">
          {{ $t('filters') }}
          <v-icon>mdi-filter-variant</v-icon>
        </span>
      </v-sheet>
  
      <v-dialog v-model="showDialog" fullscreen max-width="400">
        <v-card>
          <v-card-text class="black--text">
            <div class="d-flex align-center justify-space-between pt-6">
              <h2 class="black--text">{{ $t('filters') }}</h2>
              <v-btn icon @click="showDialog = false">
                <v-icon color="black">mdi-close</v-icon>
              </v-btn>
            </div>
            <div>
              <div class="mb-1 text-start mt-4">
                {{ $t('numberOfTestRuns') }}
              </div>
              <v-range-slider
                style="height: 50px;"
                color="blue"
                v-model="testrun"
              ></v-range-slider>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="testrun[0]"
                  color="blue"
                  class="mr-0 custom_input"
                ></v-text-field>
                <div class="mx-4 font-weight-bold text-h6">-</div>
                <v-text-field
                  v-model="testrun[1]"
                  color="blue"
                  class="mr-0 custom_input"
                ></v-text-field>
              </div>
            </div>
  
            <div>
              <div class="mb-1 text-start mt-4">
                {{ $t('numberOfTestCases') }}
              </div>
              <v-range-slider
                style="height: 50px;"
                color="blue"
                v-model="testcases"
              ></v-range-slider>
              <div class="d-flex align-center">
                <v-text-field
                  v-model="testcases[0]"
                  color="blue"
                  class="mr-0 custom_input"
                ></v-text-field>
                <div class="mx-4 font-weight-bold text-h6">-</div>
                <v-text-field
                  v-model="testcases[1]"
                  color="blue"
                  class="mr-0 custom_input"
                ></v-text-field>
              </div>
            </div>
  
            <div class="text-start pt-6">
              <div class="mt-4">{{ $t('Closed Date') }}</div>
              <div class="d-flex align-center">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  full-width
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      color="blue"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      class="mr-0 custom_input"
                      :value="startDate"
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @change="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <div class="mx-4 font-weight-bold text-h6">-</div>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  full-width
                  max-width="290"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      color="blue"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      class="custom_input"
                      :value="endDate"
                      clearable
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @change="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
  
              <div>
                <div class="mb-1 text-start mt-4">{{ $t('Progress') }}</div>
                <v-range-slider
                  style="height: 50px;"
                  color="blue"
                  v-model="users"
                ></v-range-slider>
                <div class="d-flex align-center">
                  <v-text-field
                    v-model="users[0]"
                    color="blue"
                    class="mr-0 custom_input"
                  ></v-text-field>
                  <div class="mx-4 font-weight-bold text-h6">-</div>
                  <v-text-field
                    v-model="users[1]"
                    color="blue"
                    class="mr-0 custom_input"
                  ></v-text-field>
                </div>
              </div>
  
              <div class="d-flex justify-end mt-5">
                <v-btn
                  width="10rem"
                  @click="clearAll"
                  color="gray-100"
                  full-width
                  class="mr-4 text-capitalize"
                  elevation="0"
                >
                  {{ $t('clearAll') }}
                </v-btn>
                <v-btn
                  width="10rem"
                  color="blue"
                  dark
                  full-width
                  elevation="0"
                  class="text-capitalize"
                  @click="apply"
                >
                  {{ $t('apply') }}
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: "MilestoneFilter",
    data() {
      return {
        showDialog: false,
        testrun: [0, 100],
        testcases: [0, 100],
        users: [0, 100],
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        menu1: false,
        menu2: false,
      };
    },
    methods: {
      apply() {
            this.$emit("applyFilters", {
              testRuns: this.testrun,
              testCases: this.testcases,
              users: this.users,
              dateRange: {
                start: this.startDate,
                end: this.endDate,
              },
      });
        console.log("Selected Roles:", this.selectedRoles);
        this.showDialog = false;
      },
      clearAll() {
        this.testrun = [0, 100];
        this.testcases = [0, 100];
        this.users = [0, 100];
        this.startDate = new Date().toISOString().substr(0, 10);
        this.endDate = new Date().toISOString().substr(0, 10);
      },
    },
  };
  </script>
  
  <style>
  .v-dialog--fullscreen {
    max-height: 100vh !important;
    width: 400px !important;
    right: 0 !important;
    left: auto !important;
  }
  
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
  </style>
  