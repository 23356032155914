<template>
  <v-dialog
    v-model="showDialog"
    max-width="800"
    persistent
  >
    <v-card class="pa-2">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('billing.availablePlans') }}
          </h2>
          <v-btn icon @click="showDialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="d-flex justify-space-between mt-3">
          <plan-card-item
            v-for="(plan, index) in plans"
            :key="index"
            :plan="plan"
            :currentSubscription="currentSubscription"
            :class="{'mr-4': index === 0}"
            @select="$emit('choose-plan', plan)"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PlanCardItem from './PlanCardItem.vue';

import { userPlans, orgPlans } from '@/constants/plans';

export default {
  components: {
    PlanCardItem,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    currentSubscription: {
      type: Object,
      required: true,
    },

    isOrg: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      plans: [],
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  mounted() {
    this.plans = this.isOrg ? orgPlans : userPlans
  }
}
</script>