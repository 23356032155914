<template>
  <v-text-field 
    v-model="internalSearch" 
    color="blue" 
    prepend-inner-icon="mdi-magnify"
    class="shrink mr-4 custom_input" 
    :placeholder="placeholder"
    @input="$emit('update:search', internalSearch)">
  </v-text-field>
</template>

<script>
export default {
  name: 'SearchComponent',
  props: {
    search: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search'
    }
  },
  data() {
    return {
      internalSearch: this.search
    };
  },
  watch: {
    search(newValue) {
      this.internalSearch = newValue;
    }
  }
};
</script>

<style scoped>
.custom_input {
  height: 50px;
  border-radius: 10px;
  width: 400px;
  padding-left: 10px;
  background-color: #f9f9fb;
}

.custom_input .v-input__slot::before {
  border-style: none !important;
}
</style>
