<template>
  <v-row>
    <v-col>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eu pulvinar lectus. Quisque vel nisi ligula.
        Pellentesque at dictum sapien. Nulla varius lectus vulputate turpis aliquet consequat. Proin turpis leo,
        ultrices non neque id, rhoncus tristique mi. Nulla ac urna sit amet risus laoreet suscipit commodo ut diam.
        Aenean congue ac justo et tincidunt. Fusce volutpat et turpis at iaculis. Donec auctor tortor lacus, vitae
        sodales lacus viverra sit amet. Orci varius natoque penatibus et magnis dis parturient montes, nascetur
        ridiculus mus.
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>
