<template>
  <div class="text-left">
    <p class="font-weight-bold text-body-1 mb-6">
      {{ $t("authentication.step", { currentStep, totalSteps }) }}
    </p>

    <p class="font-weight-bold text-body-1 mb-1">
      {{ $t("authentication.recoveryCodes") }}
    </p>

    <p class="mb-6 text-body-1">
      {{ $t("authentication.pleaseKeepTheseCodes") }}
      {{ $t("authentication.codesAreUsedToRestore") }}
      {{ $t("authentication.onceAuthenticationIsComplete") }}
    </p>

    <div
      v-for="(recoveryCode, index) in recoveryCodes"
      :key="index"
      class="d-flex text-body-1 justify-space-between align-center grey lighten-5 mb-2 pa-2 rounded-lg"
    >
      <span>{{ recoveryCode }}</span>
      <v-btn
        icon
        @click="copyRecoveryCode(recoveryCode)"
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { showSuccessToast, showErrorToast } from '@/utils/toast';

export default {
  props: {
    totalSteps: {
      type: Number,
      default: 1,
    },

    currentStep: {
      type: Number,
      default: 1,
    },

    recoveryCodes: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    copyRecoveryCode(code) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          showSuccessToast(this.$swal, this.$t("authentication.savedRecoveryCode"));
        }, (err) => {
          showErrorToast(this.$swal, err);
        })
    }
  }
}
</script>