<template>
  <v-card class="mt-3" rounded="lg" elevation="0" width="100%">
    <div
      v-if="!isSelf"
      class="text-left mb-1"
    >
      <v-btn
        color="blue"
        dark
        class="text-capitalize"
        text
        @click="$emit('go-back')"
      >
        <v-icon class="mr-1" size="xs">mdi-chevron-left</v-icon>
        {{ $t('organization.backToOrganizations') }}
      </v-btn>
    </div>

    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-row>
        <v-col cols="12" sm="8" md="6" offset-sm="2" offset-md="3">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-badge overlap color="white">
                <template v-slot:badge>
                  <upload-avatar @uploaded="updatedAvatar" is-org />
                </template>
                <v-avatar class="mb-4" size="9rem" color="secondary">
                  <img :src="org.avatar_url" v-if="org.avatar_url" alt="avatar" />
                  <v-icon v-else size="6rem" color="white">mdi-account</v-icon>
                </v-avatar>
              </v-badge>
            </v-col>

            <v-col cols="12" class="pb-0">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('orgName') }}
              </p>
              <v-text-field v-model="org.name" type="text" dense filled :placeholder="$t('enterOrgname')"
                :rules="orgNameValidation" />
            </v-col>

            <v-col v-if="!isEditMode" cols="12" class="pb-0">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('orgAccountName') }}
              </p>

              <v-text-field v-model="org.handle" type="text" dense filled :placeholder="$t('enterOrgHandle')"
                :loading="handleRequestState.isLoading" :rules="accountNameValidation" :hint="orgHandleHint"
                persistent-hint />

            </v-col>

            <v-col cols="12">
              <v-btn color="blue" width="300px" elevation="0" class="white--text text-capitalize" :disabled="!validForm"
                @click="onUpdateOrganization()">
                {{ isEditMode ? $t('organization.updateOrganization') : $t('organization.createOrganization') }}
              </v-btn>
            </v-col>

            <template v-if="isEditMode">
              <v-col cols="12" class="pb-4 mt-8 text-left">
                <p class="font-weight-bold text-h6">
                  {{ $t('organization.deleteOrganization') }}
                </p>
                <p class="font-weight-medium body-2">
                  {{ $t('organization.deleteOrganizationNotice') }}
                </p>
                <v-btn color="error" width="200px" elevation="0" class="white--text text-capitalize mt-2"
                  @click="onDeleteOrganization()">
                  {{ $t('organization.deleteOrganization') }}
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <DeleteOrganizationConfirmDialog v-model="showDeleteConfirmDialog" @delete="deleteOrganization" />
  </v-card>
</template>

<script>
import { formatDate } from '@/utils/util';

import UploadAvatar from '../../Profile/UploadAvatar.vue';
import DeleteOrganizationConfirmDialog from './DeleteOrganizationConfirmDialog.vue';
import { handleDuplicateMixin } from '@/mixins/handleDuplicate';

export default {
  name: 'CreateUpdateOrganization',

  components: {
    UploadAvatar,
    DeleteOrganizationConfirmDialog,
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    isSelf: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [handleDuplicateMixin],
  watch: {
    'org.handle': {
      handler: 'usernameInUse',
      immediate: true,
    },
  },
  data() {
    return {
      orgNameValidation: [
        value => !!value || this.$t('error.requiredField'),
        value => (value.length >= 2 && value.length <= 50) || this.$t('min2max50Chars')
      ],
      validForm: false,
      org: {
        uid: '',
        avatarUrl: '',
        name: '',
        handle: '',
        createdBy: '',
        createdAt: null,
      },
      showDeleteConfirmDialog: false,
    }
  },

  computed: {
    isEditMode() {
      return !!this.org.uid
    },
    orgHandleHint() {
      if (this.org.handle === '') {
        return this.$t('orgAccountNameLabel')
      }
      if (!this.handleError && this.handleAvailable) {
        return "handle is available"
      }
      return ''
    },
    accountNameValidation() {
      const defaultRules = [
        value => !!value || this.$t('error.requiredField'),
        value => /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/.test(value) || this.$t('invalidUsername'),

      ]
      if (this.handleError) {
        return [
          ...defaultRules,
          value => this.handleError,
        ]
      }
      return defaultRules
    }
  },

  mounted() {
    this.org = {
      uid: this.data.uid || '',
      avatarUrl: this.data.avatar_url || '',
      name: this.data.name || '',
      handle: this.data.handle || '',
      createdBy: this.data.created_by || '',
      createdAt: this.data.created_at ? formatDate(this.data.created_at, 'MM/dd/yy') : null,
    }
  },

  methods: {
    updatedAvatar(avatarURL) {
      this.org.avatarUrl = avatarURL;
    },

    onUpdateOrganization() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit(this.isEditMode ? 'update' : 'create', this.org)
    },

    onDeleteOrganization() {
      this.showDeleteConfirmDialog = true
    },

    deleteOrganization(password) {
      this.showDeleteConfirmDialog = false

      this.$emit('delete', password)
    }
  }
}
</script>
