<template>
  <v-row class="justify-center">
    <template v-if="!isReady">
      <v-col cols="12" md="4" v-for="(plan, i) in plans" :key="i">
        <v-skeleton-loader height="2000px" class="mx-auto" type="card"></v-skeleton-loader>
      </v-col>
    </template>
    <v-col v-else cols="12" md="4" v-for="(plan, i) in plans" :key="i">
      <billing-plan
        :plan="plan"
        :currentSubscription="currentSubscription"
        @select="showPrompt"
        :isLoading="isLoading"
        :amount="amount"
      />
    </v-col>

    <v-col cols="12" v-if="showPaymentForm">
      <stripe-card-form :show="showPaymentForm" :clientSecret="paymentRef" @close="closePaymentForm" />
    </v-col>
  </v-row>
</template>

<script>
import { userPlans } from '@/constants/plans';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('user');
import makeUserService from '@/services/api/user';

let service;
export default {
  data() {
    return {
      plans: userPlans.map((u) => ({ ...u, isProcessing: false })),
      currentSubscription: {
        // the rank property is used to determine if a plan switch is an upgrade or downgrade
        rank: -1,
      },
      /**
       * indicates if the user's subscription has been loaded
       */
      isReady: false,
      /**
       * indicates if a subscription update is currently ongoing
       */
      isLoading: false,
      /**
       * determines if the payment component should show up
       */
      showPaymentForm: false,
      /**
       * stripe client_secret to be used to identify the payment
       */
      paymentRef: null,
    };
  },
  computed: {
    ...mapState(['user', 'currentAccount']),
  },
  /**
   * initiate the user service on creating component
   */
  created() {
    service = makeUserService(this.$api);
  },
  /**
   * on component load, get the current subscription
   */
  mounted() {
    this.showSuccessMessage();
    this.initSettings();
    this.loadCurrentSubscription();
  },
  methods: {
    ...mapActions(['initSettings']),
    showPrompt(plan) {
      const action = this.currentSubscription > plan.rank ? 'downgrade' : 'upgrade';
      const confirmation = confirm(`Are you sure you want to ${action} to the ${plan.title} plan?`);
      if (!confirmation) return;

      this.plans.forEach((p) => {
        p.isProcessing = false;
      });
      this.switchPlan(plan);
    },

    async switchPlan(plan) {
      this.isLoading = true;
      plan.isProcessing = true;

      service
        .changeSubscription({ price_id: plan.stripe_id })
        .then((res) => {
          if (res.data.client_secret == null) {
            this.$swal({
              icon: 'success',
              title: this.$t('success'),
              text: this.$t('subscriptionUpdateSuccess'),
            });
            setTimeout(() => location.reload(), 5000);
          } else {
            this.paymentRef = res.data.client_secret;
            this.showPaymentForm = true;
          }
        })
        .catch((err) => {
          this.$swal({
            icon: 'error',
            title: this.$t('error'),
            text: err.response.data.error,
          });
        });
    },

    async loadCurrentSubscription() {
      service
        .getCurrentSubscription()
        .then((res) => {
          const { subscription } = res.data;
          const rank = userPlans.find((p) => p.stripe_id === subscription.stripe_price_id)?.rank ?? -1;
          this.currentSubscription = { ...subscription, rank };
          this.isReady = true;
        })
        .catch((err) => {
          this.$swal({
            icon: 'error',
            title: this.$t('error'),
            text: err.response.data.error,
          });
          this.closePaymentForm();
        });
    },

    closePaymentForm() {
      this.isLoading = false;
      this.plans.forEach((p) => {
        p.isProcessing = false;
      });
      this.showPaymentForm = false;
    },

    showSuccessMessage() {
      const { query } = this.$route;
      if (query.redirect_status === 'succeeded') {
        this.$swal({
          icon: 'success',
          title: this.$t('success'),
          text: this.$t('subscriptionUpdateSuccess'),
        });
        this.$router.replace({ query: {} });
      }
    },
  },
};
</script>