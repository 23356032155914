<template>
  <div class="text-body-1 text-left">
    <p class="font-weight-bold text-body-1 mb-1">
      {{ $t("authentication.chooseAuthenticationMethod") }}
    </p>

    <p class="text-body-1 mb-6">
      {{ $t("authentication.howWouldYouReceiveAuthenticationCode") }}
    </p>

    <v-radio-group v-model="authenticationMethod">
      <div>
        <div class="d-flex align-start justify-start mb-4">
          <v-radio value="sms" class="ml-2 ml-md-2 mr-4 mr-md-2" />
          <div>
            <div class="text-body-1 font-weight-bold pb-0">
              {{ $t("common.phoneNumber") }}
            </div>
            <div class="text-body-1 font-weight-regular pt-0">
              {{ $t("authentication.willSendAuthenticationToMobile") }}
            </div>
          </div>
        </div>
        <div class="d-flex align-start justify-start">
          <v-radio value="otp" class="ml-2 ml-md-2 mr-4 mr-md-2" />
          <div>
            <div class="text-body-1 font-weight-bold pb-0">
              {{ $t("authentication.authenticationApp") }}
            </div>
            <div class="text-body-1 font-weight-regular pt-0">
              {{ $t("authentication.useAuthenticationAppToGenerateCode") }}
            </div>
          </div>
        </div>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'ChooseAuthenticationMethod',

  props: {
    value: {
      type: String,
      default: 'sms',
    },
  },

  computed: {
    authenticationMethod: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },
}
</script>