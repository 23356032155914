<template>
  <!-- header section -->
   <v-col cols="12" sm="12" class="fill-height card bg-white">
     <v-col cols="12" sm="12" class="d-flex justify-space-between align-center pl-1">
       <div>
         <v-btn class="text-capitalize bg-white f-color-blue px-0 toggle-btn" depressed>
           <v-icon size="16">mdi-square-edit-outline</v-icon>
           {{ $t('edit') }}
         </v-btn>
       </div>
       <div>
         <span><v-icon>mdi-arrow-left-thin</v-icon></span>
         <span class="mx-2"><v-icon>mdi-arrow-right-thin</v-icon></span>
         <span class="close-btn" @click="$emit('closeDetail')"></span>
       </div>
     </v-col>
     <h2 class="pl-3">{{SelectedItem.name}}</h2>
     <v-btn
      label="Piñata"
     >
     </v-btn>
     <v-col cols="12" sm="12">

      <h4>{{ $t('defect.overview') }}</h4>
      <v-list>
        <v-list-item class="px-0">
          <template>
            <div class="d-flex flex-row justify-space-between w-full">
              <v-menu offset-y v-model="menuStateOpen">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" width="49%" height="55" class="d-flex justify-space-between rounded-lg w-full color-f9fafb">
                    <div class="block round-8 px-3 py-2 w-50 mr-1">
                      <h6 class="align-left">Status</h6>
                      <div class="align-left contents" :class=getColor(SelectedItem.status)>{{ SelectedItem.status }}</div>
                    </div>
                    <div>
                      <v-icon>{{ menuStateOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </div>
                  </v-btn>
                </template>
                <v-list dense class="text-left" v-model="SelectedItem.status">
                  <v-list-item v-for="(status,index) in states" :key="index" @click="SetStatus(SelectedItem, status.value)">
                    <v-list-item-title :class=getColor(status.value)>{{ status.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-model="menuPriorityOpen">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" width="49%" height="55" class="d-flex justify-space-between rounded-lg w-full color-f9fafb">
                    <div class="block round-8 px-3 py-2 w-50 mr-1">
                      <h6 class="align-left">Priority</h6>
                      <div class="align-left contents" :class=getColor(SelectedItem.priority)>{{ SelectedItem.priority }}</div>
                    </div>
                    <div>
                      <v-icon>{{ menuPriorityOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </div>
                  </v-btn>
                </template>
                <v-list dense class="text-left" v-model="SelectedItem.priority">
                  <v-list-item v-for="(priority,index) in priorities" :key="index" @click="SetPriority(SelectedItem, priority.value)">
                    <v-list-item-title :class=getColor(priority.value)>{{ priority.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-list-item>
        <v-list-item class="px-0 mt-2" v-if="!overviewCollapsed">
          <template>
            <div class="flex justify-between">
              <div class="block round-8 px-3 py-2 w-50 mr-1" style="background-color: #F9FAFB">
                <h5 class="align-left">Creator</h5>
                <div class="align-left contents">Maria Watson</div>
              </div>
              <div class="block round-8 px-3 py-2 w-50 ml-1" style="background-color: #F9FAFB">
                <h5 class="align-left">ID</h5>
                <div class="align-left contents">{{ SelectedItem.external_id }}</div>
              </div>
            </div>
          </template>
        </v-list-item>
        <v-list-item class="px-0 mt-2" v-if="!overviewCollapsed">
          <template>
            <div class="flex justify-between">
              <div class="block round-8 px-3 py-2 w-50 mr-1" style="background-color: #F9FAFB">
                <h5 class="align-left">Last update</h5>
                <div class="align-left contents">{{ SelectedItem.last_date }}</div>
              </div>
              <div class="block round-8 px-3 py-2 w-50 ml-1" style="background-color: #F9FAFB">
                <h5 class="align-left">Folder</h5>
                <div class="align-left contents">{{ SelectedItem.folder }}</div>
              </div>
            </div>
          </template>
        </v-list-item>
        <v-list-item class="px-0 mt-2" v-if="!overviewCollapsed">
          <template>
            <div class="flex justify-between">
              <div class="block round-8 px-3 py-2 w-50 mr-1" style="background-color: #F9FAFB">
                <h5 class="align-left">Tags</h5>
                <div class="align-left contents">{{ SelectedItem.tag }}</div>
              </div>
              <div class="block round-8 px-3 py-2 w-50 ml-1" style="background-color: #F9FAFB">
                <h5 class="align-left">Assigned</h5>
                <div class="align-left contents">{{ SelectedItem.assign }}</div>
              </div>
            </div>
          </template>
        </v-list-item>
      </v-list>
      <h4 @click="overviewCollapsed = !overviewCollapsed" class="f-color-blue toggle-btn" v-if="overviewCollapsed"> {{ $t('defect.showMore') }} </h4>
      <h4 @click="overviewCollapsed = !overviewCollapsed" class="f-color-blue toggle-btn" v-else> {{ $t('defect.showLess') }} </h4>

      <v-expansion-panels flat v-model="DescriptionPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 panel-title">
            {{ $t('description') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- description contents -->
             <v-timeline dense>
              <v-timeline-item v-for="(step,index) in SelectedItem.steps" :key="index" right>
                <step-item :title="step.title" :description="step.description" :expectedResult="step.result"></step-item>
              </v-timeline-item>
             </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels flat v-model="CommentsPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 panel-title">
            {{ $t('comments') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
                    <!-- to do comments -->
                      <v-tiptap
                        v-model="newComment"
                        outlined
                        overflow
                        :toolbar="['bold', 'italic', 'strike', '|', 'bulletList', 'orderedList']"
                        :dense="false"
                        :placeholder="$t('addComment')">
                        <template #bottom>
                          <v-card>
                            <v-col cols="12" class="text-right pa-1">
                              <v-btn color="primary" icon @click="addNewComment(newComment)" :disabled="!newComment">
                                <v-icon>mdi-send</v-icon>
                              </v-btn>
                            </v-col>
                          </v-card>
                        </template>
                      </v-tiptap>

                      <v-list three-line class="mt-4 py-0 text-left" v-if="FilteredComments">
                        <v-list-item-group>
                          <v-list-item
                            v-for="(item, index) in FilteredComments"
                            :key="index"
                            class="mb-2 px-0"
                          >
                            <v-list-item-content class="grey lighten-4 rounded-lg pa-4 custom-wrap">
                              <v-list-item-title class="subtitle-1 d-flex align-center flex-row justify-space-between">
                                <div class="subtitle-1 d-flex align-center">
                                  <v-icon  size="40">mdi-account-circle</v-icon>
                                  <div class="ml-2">
                                    <p class="mb-1">{{ item.first_name}} {{item.last_name}}</p>
                                    <p class="text-caption grey--text mb-0">{{ item.entity_type }}</p>
                                  </div>
                                </div>
                                <p class="text-caption grey--text mb-0">{{ item.created_at }}</p>
                              </v-list-item-title>
                              <p class="body-1 mt-2 mb-0 items-wrapper" v-html="item.body" />
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    <!-- <comment-view /> -->
                   </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels flat v-model="DefectsPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 panel-title">
            {{ $t('defects') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github" justify-start/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
     </v-col>
   </v-col>

</template>
<script>
import StepItem from '@/components/TestRuns/CaseStepItem.vue';
import DefectItem from '@/views/Tests/Case/Components/DefectItem.vue';
import { mapGetters, mapState ,createNamespacedHelpers} from 'vuex';
const { mapActions } = createNamespacedHelpers('comment');

export default {
  name: 'CustomDetailView',
  props: {
    SelectedItem: JSON,
  },
  components: {
    StepItem,
    DefectItem,
  },
  data() {
    return {
      overviewCollapsed: true,
      menuStateOpen: false,
      menuPriorityOpen: false,
      DescriptionPanel: false,
      CommentsPanel: false,
      DefectsPanel: false,
      newComment: '',

      states: [
        { value: 'Active', text: 'Active' },
        { value: 'Incomplete', text: 'Incomplete' },
        { value: 'Passed', text: 'Passed' },
        { value: 'Failed', text: 'Failed' },
      ],
      priorities: [
        { value: 'High', text: 'High' },
        { value: 'Medium', text: 'Medium' },
        { value: 'Low', text: 'Low' },
      ],
    }
  },
  computed: {
    ...mapState('comment', ['comments']),
    ...mapState('user',['currentAccount','user']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),

    FilteredComments() {
      const newItemComments = this.comments
      return newItemComments
    },
  },
  created() {
    this.getCurrentComment();
  },
  watch: {
    SelectedItem(newVal, oldVal) {
      this.getCurrentComment();
    }
  },
  methods: {
    ...mapActions(['addComment','fetchComments']),
    getColor(priority) {
      if (priority == "High" || priority == "Failed") {
        return "text-red"
      } else if (priority == "Medium" || priority == "Incomplete") {
        return "text-yellow"
      } else if (priority == "Low" || priority == "Passed"){
        return "text-green"
      }
      else
        return "blue--text lighten-1"
    },

    addNewComment(comment) {
      let originComments = this.SelectedItem.comments;
      let newComment = {
          author: this.currentAccount.handle,
          created_at: '13/1/2024',
          content: comment
      }
      let N_Comments = [
        ...originComments,
        newComment
      ]

      this.addComment({
        swal: this.$swal,
        user: this.currentAccount.handle,
        payload: {
          entity_type: "case",
          entity_uid:this.SelectedItem.uid,
          created_by: this.user.uid,
          body: String(comment)
        }
      });
      this.getCurrentComment();
    },

    getCurrentComment(){
      this.fetchComments({
        handle: this.currentAccount.handle,
        entityType: "case",
        entityUid:this.SelectedItem.uid,
      });
      console.log(this.comments)
      this.SelectedItem.comments = this.comments
    },
    SetPriority(item, priority){
      item.priority = priority
    },
    SetStatus(item, selectedStatus){
      item.status = selectedStatus
    }

  }
}
</script>
<style>
h2, h3, h4{
  text-align: left;
}
h5{
  color:#667085;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.collapse-btn{
  color:#0C2FF3;
}
p{
  text-align: left;
}
.flex{
  display: flex;
}
.justify-between{
  justify-content: space-between;
}
.justify-start{
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.bg-white{
  background-color: white !important;
}
.close-btn:hover{
  cursor: pointer;
}
.f-color-blue{
  color: #0C2FF3 !important;
}
.f-color-red{
  color: #EF5350;
}
.w-50{
  width: 50%;
}
.align-left{
  text-align: left;
}
.align-left .contents{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0C111D;
}
.toggle-btn{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}

.panel-title{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.color-f9fafb{
  background-color: #F9FAFB
}

.custom-wrap{
  overflow-wrap: anywhere;
}
</style>
