<template>
  <div class="search-field d-flex align-center">
    <iconSearch class="icon-search"></iconSearch>
    <v-text-field class="pl-9 pr-3" dense height="40" hide-details="auto" v-model="value" :placeholder="placeholder" />
  </div>
</template>

<script>
import iconSearch from '@/assets/svg/search-outline-16x16-gray.svg';

export default {
  name: 'search-field',

  props: ['value', 'placeholder'],
  components: {
    iconSearch,
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  position: relative;
  background: #f9f9fb;
  border-radius: 8px;
}

.search-field .icon-search {
  flex: none;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.search-field .v-text-field {
  margin-top: 0;
  flex: auto;
}
</style>