<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="12" class="mt-10">
          <v-icon color="purple" size="60">mdi-account-edit</v-icon>
        </v-col>
        <v-col cols="12">
          <h4>{{ $t('confirmUpdateSeatsTitle') }}</h4>
        </v-col>
        <v-col cols="12">{{ $t('confirmUpdateSeats', { action_phrase: `add ${n} seats to ${orgName}` }) }}</v-col>
        <v-col cols="12" class="mb-8">
          <v-btn color="primary darken-1" outlined @click.stop="accept">
            {{ $t('agree') }}
            <v-progress-circular v-if="isLoading" indeterminate model-value="10" :size="20"></v-progress-circular>
          </v-btn>
          <v-btn color="red darken-1" class="ml-3" text @click="close"> {{ $t('cancel') }} </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: { n: String, orgName: String },

  data() {
    return { isLoading: false };
  },

  methods: {
    accept() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.$emit('accept');
    },
    close() {
      if (this.isLoading) return;
      this.$emit('close');
    },
  },
};
</script>

<style>
</style>