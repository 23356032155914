<template>
  <v-container class="pa-6 elevation-1 white fill-height align-start card" fluid>
    <v-row justify="start" align="start" v-if="!isBlankTable">
      <v-col cols="7" sm="7" class="search-bar-style">
        <v-text-field v-model="searchTerm" class="text-field search-field mr-3 round-8 " placeholder="Search by name, id, tag, etc." @input="searchItems" height="40" dense filled single-line >
          <template v-slot:prepend-inner>
            <iconSearch></iconSearch>
          </template>
        </v-text-field>
        <TestCasesFilter></TestCasesFilter>
      </v-col>
      <v-col cols="5" sm="5" class="search-bar-style setting-btn-style">
        <div class="btn-selector">
          <template>
            <div class="text-center">
              <v-menu :close-on-content-click="false" :nudge-width="200" offset-y >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="setting-btn h-40 round-8" depressed v-bind="attrs" v-on="on">
                    <v-icon size="18px">
                      mdi-cog-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-card >
                  <template>
                    <v-container class="pb-0 pt-4">
                      <div class="mb-4 pl-1 menu-header">{{ $t('columns') }}</div>
                      <v-checkbox v-for="(item, index) in filteredMenuHeaders" v-model="item.isSelected" :label="item.text" :value="item.isSelected" :key="index"
                      class="mt-0" @click="handleClickColumnFilter"></v-checkbox>
                    </v-container>
                  </template>
                  <v-card-actions class="pt-0 pb-4">
                    <div class="btn-restore pl-2" v-if="!enableRestoreDefault">{{ $t('restoreDefault') }}</div>
                    <div class="btn-restore pl-2 f-color-blue" @click="restoreDefault" v-else>{{ $t('restoreDefault') }}</div>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </template>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="breadcrumb-container mt-4">
        <v-breadcrumbs :items="bread_items">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item v-if="isLastItem(item)"><b>{{ item.text }}</b></v-breadcrumbs-item>
            <v-breadcrumbs-item v-else><span style="color: #667085">{{ item.text }}</span></v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="12" sm="12">
        <v-data-table :headers="filteredHeaders" :items="isSelectedData ? selected_data : table_data" disable-pagination item-value="id"
          :hide-default-footer="true" show-select class="data-table-style" v-model="selectedRows" @input="handleSelectTestCases"
          :header-props="{'sort-icon': 'mdi-chevron-down'}" @click:row="handleClick">
          <template v-slot:[`header.actions`]="{header}" >
            <div class="d-none">{{ header.text }}</div>
          </template>
          <template v-slot:[`item.id`]="{item}" >
            <span class="custom-attribute font-weight-bold">{{ item.id }}</span>
          </template>
          <template v-slot:[`item.assigned`]="{item}" >
            <span class="custom-attribute font-weight-bold">{{ item.assign }}</span>
          </template>
          <template v-slot:[`item.name`]="{item}">
            <span class="custom-attribute font-weight-bold">{{ item.name }}</span>
          </template>
          <template v-slot:[`item.priority`]="{item}">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" width="80%" class="rounded-lg">
                  <div class="d-flex flex-row align-center justify-space-between w-full px-3">
                    <span :class=getColor(item.priority)>{{ item.priority }}</span>
                    <div>
                      <v-icon>mdi-chevron-down</v-icon>
                    </div>
                  </div>
                </v-btn>
              </template>
              <v-list dense class="text-left" v-model="item.priority">
                <v-list-item v-for="(priority,index) in priorities" :key="index" @click="SetPriority(item, priority.value)">
                  <v-list-item-title :class=getColor(priority.value)>{{ priority.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.status`]="{item}">
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" width="80%" class="rounded-lg">
                    <div class="d-flex flex-row align-center justify-space-between w-full px-3">
                      <span :class=getColor(item.status)>{{ item.status }}</span>
                      <div>
                        <v-icon>mdi-chevron-down</v-icon>
                      </div>
                    </div>
                  </v-btn>
                </template>
                <v-list dense class="text-left" v-model="item.status">
                  <v-list-item v-for="(status,index) in states" :key="index" @click="SetStatus(item, status.value)">
                    <v-list-item-title :class=getColor(status.value)>{{ status.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
          </template>

          <template v-slot:[`item.tags`]="{item}">
            <span class="custom-attribute font-weight-bold">{{ item.tags }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row justify-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="text-left">
                  <v-list-item @click="deleteTestCase(item.uid)">
                    <v-list-item-icon>
                      <v-icon color="danger">mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleRowEdit(item.uid)">
                    <v-list-item-icon>
                      <v-icon color="info">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" sm="12" class="create-btn" v-if="!isRepository">
        <div v-if="testName == '' || testTemplate == ''">
          <v-btn class="text-capitalize mt-0" depressed disabled height="40">
            {{ $t('create') }}
          </v-btn>
        </div>
        <div v-else>
          <v-btn color="blue" class="text-capitalize mt-0" @click="quickCreate" depressed height="40">
            {{ $t('create') }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="breadcrumb-container mt-4" v-if="!isRepository">
      </v-col>
      <v-col cols="12" sm="12" class="round-8 px-6 py-4 absolute bottom-right" v-if="!isRepository">
        <v-menu offset-y v-if="actionBtnShow" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#0C2FF3" class="text-capitalize f-color-white px-6 py-2" v-bind="attrs" v-on="on" >
              {{ $t('actions') }}
              <v-icon size="16px">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card rounded="8">
            <v-list>
              <v-list-item class="action-btn" :key="1" @click="hanldeEditClick">
                <v-list-item-title>
                  <v-icon size="16px" class="mr-2">mdi-square-edit-outline</v-icon>
                  {{ $t('edit') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="action-btn" :key="2" @click="handleExportClick">
                <v-list-item-title>
                  <v-icon size="16px" class="mr-2">mdi-tray-arrow-up</v-icon>
                  {{ $t('export') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="action-btn" :key="3" @click="handleBulkRemove">
                <v-list-item-title class="color-red">
                  <v-icon size="16px" class="mr-2 f-color-red">mdi-trash-can-outline</v-icon>
                  {{ $t('remove') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" class="round-8 px-6 py-4 absolute bottom-right" v-else>
        <v-btn color="#0C2FF3" class="text-capitalize f-color-white px-6 py-2" v-if="actionBtnShow" @click="addSelectedCases">
          {{ isAddedTable ? $t('remove') : $t('add') }} {{ selectedRows.length }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="h-100" justify="start" align="start" v-else>
      <v-col cols="12" sm="12" class="h-100 flex">
        <div class="block">
          <img :src="require('@/assets/png/blank-case-background.png')" style="margin-left: auto; margin-right: auto; display: block;"/>
          <h2 class="blank-title">{{ $t('manageTestCaseEasily') }}</h2>
          <p class="blank-description">{{ $t('startCreateTestCase') }}</p>
          <div class="w-100 flex">
            <v-btn color="#0C2FF3" dark class="text-capitalize px-6" depressed to="/tests/cases/create">
              {{ $t('createTestCase') }} 
              <v-icon class="ml-1" size="xs">mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <EditDialog :isOpen="isOpenEditDialog" :priorities="priorities" :tags="tags"
      @closeDialog="handleCloseEditDialog"
      @clickSave="updateCases"/>
    <ExportDialog :isOpen="isOpenExportDialog" @closeDialog="handleCloseExportDialog"/>
    <ConfirmDialog :isOpen="isOpenConfirmDialog" @closeDialog="handleCloseConfirmDialog" @confirm="handleConfirm"/>
  </v-container>
</template>

<script>
import { caseInRunTableHeader  } from '@/constants/grid';
import TestCasesFilter from '@/views/Tests/Case/Components/Filter.vue';
import EditDialog from '@/views/Tests/Case/Components/EditDialog.vue'
import ExportDialog from '@/views/Tests/Case/Components/ExportDialog.vue';
import ConfirmDialog from '@/views/Tests/Case/Components/ConfirmDialog.vue';
import iconSearch from '@/assets/svg/search-outline-16x16-gray.svg';
import makeCasesService from '@/services/api/case';
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('user');
let caseService;

export default {
  components: {
    TestCasesFilter,
    EditDialog,
    ExportDialog,
    ConfirmDialog,
    iconSearch,
  },
  props: {
    isRepository: Boolean,
    isExpand: Boolean,
    CasesInRun : Array,
  },
  data() {
    return {
      // test state for blank page
      isBlankTable: false,
      // test state for blank page
      isExpandDetail: false,
      isOpenEditDialog: false,
      isOpenExportDialog: false,
      isOpenConfirmDialog: false,
      isSelectedData: false,
      isAddedTable: false,
      openFilterDrawer: true,
      toggleSelect: false,
      testName: "",
      testTemplate: "",
      selectedRows: [],
      headers: caseInRunTableHeader,
      searchTerm: '',
      enableRestoreDefault: false,
      actionBtnShow: false,
      selected_data: [],
      states: [
        { value: 'Active', text: 'Active' },
        { value: 'Incomplete', text: 'Incomplete' },
        { value: 'Passed', text: 'Passed' },
        { value: 'Failed', text: 'Failed' },
      ],
      priorities: [
        { value: 'High', text: 'High' },
        { value: 'Medium', text: 'Medium' },
        { value: 'Low', text: 'Low' },
      ],
      tags: [
        { value: "test", text: '#test'},
        { value: "billing", text: '#billing'},
        { value: "performance", text: '#performance'},
        { value: "navigation", text: '#navigation'},
      ],
      table_data: [],
      items: [],
      bread_items: [
        { text: "General", },
        { text: "Android", },
        { text: "App", }
      ],
      select_items: [
        { text: "App", value: 1, externalId: "HM-1", source: "Test create data for App"},
        { text: "Web", value: 2, externalId: "HM-2", source: "Test create data for Web"},
      ]
    }
  },
  created() {
    caseService = makeCasesService(this.$api);
  },
  computed: {
    ...mapState(['currentAccount']),
    selectedItemsTitles() {
      return this.selectedItems.map(i => i.text).join(', ');
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
    filteredHeaders() {
      let filtered = this.headers.filter((header) => header.isSelected);
      if(this.isExpand)
        filtered = filtered.filter((header) => (header.text !='Priority' && header.text !='Status'))

      if (filtered.length < 9)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true;
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false;
      return filtered
    },

  },
  methods: {
    handleCloseConfirmDialog() {
      this.isOpenConfirmDialog = false;
    },
    handleConfirm() {
      this.isOpenConfirmDialog = false;
    },
    hanldeEditClick() {
      this.isOpenEditDialog = true;
    },
    handleCloseEditDialog() {
      this.isOpenEditDialog = false;
    },
    handleExportClick() {
      this.isOpenExportDialog = true;
    },
    handleCloseExportDialog() {
      this.isOpenExportDialog = false;
    },
    isLastItem(item) {
      return item.text == this.bread_items[this.bread_items.length - 1].text;
    },
    getColor(priority) {
      if (priority == "High" || priority == "Failed") {
        return "text-red"
      } else if (priority == "Medium" || priority == "Incomplete") {
        return "text-yellow"
      } else if (priority == "Low" || priority == "Passed"){
        return "text-green"
      }
      else
        return "blue--text lighten-1"
    },
    searchItems() {
      const search = this.searchTerm.toLowerCase();
      this.selectedItems = this.stepItems.filter(option =>
        option.title.toLowerCase().includes(search)
      );
    },
    handleSelectTestCases() {
      if (this.selectedRows.length > 0) {
        this.actionBtnShow = true;
      } else {
        this.actionBtnShow = false;
      }
    },
    handleClick(row) {
      this.$emit('expandDetail', row)
    },
    restoreDefault() {
      this.enableRestoreDefault = false;
      this.handleColumnReset();
    },
    handleColumnReset() {
      this.headers.map((header) => {header.isSelected = true})
    },
    handleClickColumnFilter() {
      const filters = this.filteredMenuHeaders;
      for (let item in filters) {
        if (filters[item].isSelected !== true) {
          this.enableRestoreDefault = true;
          return
        }
      }
      this.enableRestoreDefault = false;
    },

    addSelectedCases() {
      this.selected_data = this.selectedRows;
      this.isSelectedData = true;
      this.isAddedTable = true;
    },
    removeSelectedCases() {
      this.isAddedTable = false
    },
    // quick create test case function
    async quickCreate() {
      let handle = this.currentAccount.handle;
      const item = this.select_items[parseInt(this.testTemplate) - 1];
      let customField = {
        "tags": this.tags[0].value,
        "priority": this.priorities[this.priorities.length - 1].value,
      };
      const payload = {
        'name': this.testName,
        'externalId': item.externalId,
        'source': item.source,
        'customFields': customField
      };
      try {
        const res = await caseService.createTestCase(handle, this.$route.params.key, payload)
        if (res.status == 200) {
          let caseItem = {
              "id": this.table_data.length + 1,
              "name": res.data.name,
              "priority": res.data.custom_fields.priority,
              "tags": res.data.custom_fields.tags,
              "externalId": res.data.externalId,
              "source": res.data.source,
              "uid": res.data.uid,
            };
            this.table_data.push(caseItem);
        } else {
          console.log("Error occured!");
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    },
    // get Test case history function
    async getCaseHistoryData() {
      console.log(this.CaseInRun)
      this.table_data = this.CasesInRun
    },
    // delete Test case from uid
    async deleteTestCase(uid) {
      let handle = this.currentAccount.handle;
      try {
        const response = await caseService.deleteCase(
          handle,
          this.$route.params.key,
          uid
        );
        if (response.status == 200) {
          this.getCaseHistoryData();
        } else {
          console.log("Error occured in delete item");
        }
      } catch (err) {
        console.log("Error: ", err);
      }
    },
    async handleBulkRemove() {
      const handle = this.currentAccount.handle;
      const itemUIDs = [];
      for (let item in this.selectedRows) {
        itemUIDs.push(this.selectedRows[item].uid);
      }
      const payload = {
        ids: itemUIDs
      };
      try {
        const response = await caseService.deleteCases(
          handle,
          this.$route.params.key,
          payload
        );
        if (response.status == 200) {
          this.getCaseHistoryData();
        } else {
          console.log("Error occured!");
        }
      } catch (err) {
        console.error("Error: ", err);
      }
    },
    async updateCases(selectedPriority, selectedTag) {
      const handle = this.currentAccount.handle;
      try {
        const payload = {
          "name": this.selectedRows[0].name,
          "externalId": this.selectedRows[0].externalId,
          "source": this.selectedRows[0].source,
          "custom_fields": {
            "tags": selectedTag,
            "priority": selectedPriority
          }
        };
        const response = await caseService.updateTestCase(
          handle,
          this.$route.params.key,
          this.selectedRows[0].uid,
          payload
        );
        if (response.status == 200) {
          console.log("Case item updated successfully!");
        } else {
          console.error("Error occured in backend!");
        }
      } catch (err) {
        console.log("Error: ", err);
      }
    },
    handleRowEdit(uid) {
      let caseItem;
      for (let item in this.table_data) {
        if (this.table_data[item].uid == uid) {
          caseItem = this.table_data[item]
        }
      }
      this.$router.push({name: 'CreateTestCases', params: { item: caseItem }}) // TODO don't pass this via the router
    },
    SetPriority(item, priority){
      item.priority = priority
    },
    SetStatus(item, selectedStatus){
      item.status = selectedStatus
    }
  },
  mounted() {
    this.getCaseHistoryData()
  },
};
</script>
<style>
  .color-red{
    color: #F2284E !important;
  }
  .f-color-red {
    color:#F2284E !important;
  }
  .round-8{
    border-radius: 8px;
  }
  .round-6{
    border-radius: 6px;
  }
  .h-40{
    height: 40px !important;
  }
  .v-dialog__content{
    justify-content: flex-end;
  }
  .v-dialog{
    margin: 0;
    border-radius: 15px;
  }
  .btn-selector{
    position: relative;
  }
  .modal-main-area{
    height: 100%;
    padding: 32px 32px 32px 32px;
  }
  .dialog-title{
    font-weight: 900 !important;
  }
  .filter-dialog{
    padding-top: 15px;
  }

  .dialog-action{
    width: 90%;
    display: flex;
    position: absolute;
    bottom: 25px;
  }
  .btn-selector .selector-wrapper {
    position: relative;
  }
  .selector-style{
    position: absolute;
    right: 0;
    left: unset;
    top: 30px;
    min-width: 240px;
  }
  .modal-btn{
    width: 45%;
  }
  .f-color-white{
    color: white !important;
  }

  .text-red{
    color: #EF5350;
  }
  .text-green{
    color: #66BB6A;
  }
  .text-yellow{
    color: #FFA726;
  }

  .align-start{
    align-items: baseline !important;
    font-family: Inter !important;
  }
  .search-box-style{
    padding-top: 0;
    border-radius: 8px;
  }
  .search-bar-style{
    display: flex;
    padding-bottom: 0;
    justify-content: space-between;
  }
  .setting-btn-style{
    display: flex;
    justify-content: flex-end;
  }
  .setting-btn{
    position: absolute;
    right: 10px;
    width: 40px !important;
    min-width: 40px !important;
  }
  .breadcrumb-container{
    padding: 0;
  }
  .breadcrumb-container ul {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 15px;
  }
  .create-btn{
    display: flex;
    justify-content:space-between;
    padding-top: 0;
    padding-bottom: 0;
  }
  .create-btn button {
    color: white !important;
    margin-top: 10px;
  }
  .bottom-input-style{
    margin-top: 0;
    border-radius: 5px;
    margin-right: 10px;
  }
  .bottom-input-style .v-text-field__slot{
    padding-left: 10px;
  }
  .bottom-input-style .v-select__selections{
    padding-left: 10px;
  }
  .data-table-style{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
  .data-table-style tbody tr{
    height: 50px !important;
    line-height: 0% !important;
    min-height: 50px;
    cursor: pointer;
  }
  .data-table-style tbody tr:hover,
  .data-table-style tbody tr:hover:not(.v-data-table__expanded__content){
    background-color: #D0D5DD !important;
  }
  .v-input__prepend-inner{
    padding-left: 10px;
  }
  .v-list-item__content{
    text-align: start;
  }
  .v-breadcrumbs__item .normal-font-color{
    color: rgb(93, 101, 121) !important;
    color: red;
  }
  .search-field .v-input__slot {
    display: flex;
    align-items: center !important;
  }
  .search-field .v-input__prepend-inner {
    align-self: center;
    margin-top: 0 !important;
    padding-left: 0px;
    padding-right: 8px !important;
  }

  .text-field .v-input__slot {
    background-color: #F9F9FB !important;
  }
  .btn-restore{
    width: 100%;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    cursor: pointer;
  }
  .menu-header{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .absolute{
    position:absolute;
  }
  .bottom-right{
    display: flex;
    justify-content: right;
    right: 24px;
    bottom: 16px;
  }
  .f-color-blue {
    color: #0C2FF3;
  }
  .action-btn .v-list-item__title {
    display: flex;
    justify-content: flex-start;
  }
  .h-100{
    height: 100%;
  }
  .flex{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .block{
    display: block;
  }
  h2.blank-title{
    text-align: center;
  }
  p.blank-description{
    max-width: 500px;
    text-align: center;
  }
  .none{
    display: none;
  }
</style>
