<template>
  <div class="text-center pa-4">
    <v-dialog
      v-model="isOpen"
      max-width="400"
    >
      <v-card
        prepend-icon="mdi-map-marker"
        text="Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running."
        title="Use Google's location service?"
      >
        <template v-slot:actions>
          <v-spacer></v-spacer>

          <v-btn @click="$emit('closeDialog')">
            {{ $t('cancel') }}
          </v-btn>

          <v-btn @click="$emit('confirm')">
            {{ $('remove') }}
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    isOpen: Boolean
  },
}
</script>
