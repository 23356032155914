<template>
  <v-container fluid style="padding: 0">
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
      />
      <p class="text-body-1 mt-3">{{ loadingText }}...</p>
    </v-overlay>

    <v-row>
      <v-col :cols="isMenuCollapsed ? 1 : 2">
        <left-menu :menuItems="menuItems" />
      </v-col>

      <v-col :cols="isMenuCollapsed ? 11 : 10" style="padding-left: 0 !important">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers  } from 'vuex';
import { mapGetters } from 'vuex';
const { mapState } = createNamespacedHelpers('user');

import LeftMenu from '@/components/Admin/LeftMenu';

export default {
  name: 'UserSettingsLayout',

  components: {
    LeftMenu,
  },

  computed: {
    ...mapState(['currentAccount']),
    ...mapGetters(['isMenuCollapsed', 'isLoading', 'loadingText']),

    menuItems() {
      return [
        { title: 'Info',  icon: 'mdi-domain', to: { name: 'UserOrganizations', params: { handle: this.$route.params.handle } }, isActive: false },
        { title: 'Notifications', icon: 'mdi-bell-outline', to: { name: 'UserNotifications', params: { handle: this.$route.params.handle } }, isActive: false },
      ]
    }
  },
}
</script>
