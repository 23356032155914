<template>
  <div>
    <v-sheet @click="showDialog = true" color="gray-100" class="d-flex align-center justify-center" height="50px" rounded="lg">
      <span class="px-4"> {{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog v-model="showDialog" fullscreen max-width="400">
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">{{ $t('filters') }}</h2>
            <v-btn icon @click="showDialog = false">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>

          <v-expansion-panels flat v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('priority') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox v-model="selectedPriorities" value="High" color="blue" dense label="High"></v-checkbox>
                <v-checkbox v-model="selectedPriorities" value="Medium" color="blue" dense label="Medium"></v-checkbox>
                <v-checkbox v-model="selectedPriorities" value="Low" color="blue" dense label="Low"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('tag') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field color="blue" dense class="mt-2" placeholder="Search"></v-text-field>
                <v-checkbox v-model="selectedTags" value="#test" color="blue" dense label="#test"></v-checkbox>
                <v-checkbox v-model="selectedTags" value="#performance" color="blue" dense label="#performance"></v-checkbox>
                <v-checkbox v-model="selectedTags" value="#billing" color="blue" dense label="#billing"></v-checkbox>
                <v-checkbox v-model="selectedTags" value="#navigation" color="blue" dense label="#navigation"></v-checkbox>
                <v-checkbox v-model="selectedTags" value="#remotetest" color="blue" dense label="#remotetest"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div class="d-flex justify-end mt-5">
            <v-btn width="10rem" @click="cancel" color="gray-100" full-width class="mr-4 text-capitalize" elevation="0">{{ $t('clearAll') }}</v-btn>
            <v-btn width="10rem" color="blue" dark full-width elevation="0" class="text-capitalize" @click="apply">{{ $t('apply') }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  name: "FilterComponent",
  data() {
    return {
      selectedPriorities: [],
      selectedTags: [],
      showDialog: false,
      panel: [0,1],
    }
  },
  methods: {
    apply() {
      this.$emit('update:filters', { priorities: this.selectedPriorities, tags: this.selectedTags });
      this.showDialog = false;
    },
    cancel() {
      this.selectedPriorities = [];
      this.selectedTags = [];
    }
  }
}
</script>

