<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    fullscreen
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">{{ isEditMode ? $t('customFieldPage.editCustomField') : $t('customFieldPage.createCustomField') }}</h2>
          <v-btn icon @click="showDialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-10"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('name') }}*
              </p>
              <v-text-field
                v-model="customField.name"
                type="text"
                dense
                filled
                :placeholder="$t('name')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col cols="12" class="pb-0">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('customFieldPage.dataType') }}
              </p>
              <v-select
                v-model="customField.type"
                :items="dataTypes"
                dense
                filled
                item-text="name"
                item-value="type"
                :placeholder="$t('customFieldPage.chooseDataType')"
                :rules="requiredRules"
              />
            </v-col>

            <template v-if="isAbleToAddItem">
              <OptionItems
                :type="customField.type"
                :items="customField.options"
                @remove-item="removeOptionItem"
              />

              <v-col
                v-if="isAbleToAddItem"
                cols="12"
                class="text-right pt-0"
              >
                <v-btn
                  color="blue"
                  class="white--text text-capitalize"
                  elevation="0"
                  @click="onAddItem()"
                >
                  {{ $t('add') }} +
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn
              color="gray-100"
              width="100%"
              class="text-capitalize"
              elevation="0"
              @click="onCancel()"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="blue"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              :disabled="!validForm"
              @click="onCreate()"
            >
              {{ isEditMode ? $t('save') : $t('create') }}
          </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dataTypes } from '@/constants/customField.js';

import OptionItems from './OptionItems.vue';

export default {
  name: 'CreateUpdateCustomField',

  components: {
    OptionItems,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    isEditMode() {
      return !!this.customField.uid
    },

    isAbleToAddItem() {
      if (!this.customField.type) {
        return false
      }

      const dataType = dataTypes.find(item => item.type === this.customField.type)

      return dataType?.isOptionsRequired
    }
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.customField = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        type: this.data.type || '',
        source: this.data.source || '',
        options: this.data.options || [],
      }
    },

    'customField.type': {
      handler: function () {
        if (this.isEditMode && this.customField.type === this.data.type) {
          this.customField.options = this.data.options
        } else {
          this.customField.options = []
        }
      }
    }
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: false,
      dataTypes: dataTypes,
      customField: {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      },
    }
  },

  methods: {
    onAddItem() {
      this.customField.options.push('')
    },

    removeOptionItem(index) {
      this.customField.options.splice(index, 1)
    },

    onCreate() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit(this.isEditMode ? 'update-custom-field' : 'create-custom-field', this.customField)
    },

    onCancel() {
      this.$emit('close-dialog')
    }
  }
}
</script>