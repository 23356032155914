<template>
  <v-container fluid style="padding: 0">
    <v-row>
      <v-col :cols="isMenuCollapsed ? 1 : 2">
        <left-menu :menuItems="menuItems" />
      </v-col>

      <v-col :cols="isMenuCollapsed ? 11 : 10" style="padding-left: 0 !important">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { createNamespacedHelpers } from 'vuex';

import LeftMenu from '@/components/Admin/LeftMenu';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'AdminLayout',

  components: {
    LeftMenu,
  },

  computed: {
    ...mapGetters(['isMenuCollapsed']),

    ...mapState(['currentAccount']),

    menuItems()
    {
      const handle = this.currentAccount.handle

      return [
        { title: 'Users', icon: 'mdi-account-multiple-outline', to: `/${handle}/admin/users`, isActive: true },
        { title: 'Roles', icon: 'mdi-account-multiple-check-outline', to: `/${handle}/admin/roles`, isActive: false },
        { title: 'Tags', icon: 'mdi-tag-multiple-outline', to: `/${handle}/admin/tags`, isActive: false },

      ]
    }
  },
}
</script>
