<template>
  <v-row id="main-row">
    <v-col cols="12" align="center" v-if="requestState.done" id="alert-col">
      <v-alert v-if="!requestState.hasError" dismissible id="success-alert">{{ requestState.message }}</v-alert>
      <v-alert v-if="requestState.hasError" variant="error" dismissible id="error-alert">{{
        requestState.message
        }}</v-alert>
    </v-col>
    <v-col cols="12" align="right" class="my-0 pb-0" id="invite-button-col">
      <v-btn color="secondary" @click="inviteMember = !inviteMember" id="invite-member-dialog-activate">{{
        $t('inviteMember')
        }}</v-btn>
    </v-col>
    <v-col id="remove-member-dialog-col" v-if="removeMemberDialog">
      <v-dialog :title="$t('areYouSure')" id="remove-member-dialog" v-model="removeMemberDialog"
        @ok="removeUser(selectedMember)" :ok-title="$t('yes')" centered>
        <v-card v-if="selectedMember" id="remove-member-dialog-card">
          <v-card-text class="px-12 py-12" id="remove-member-dialog-text">
            {{
              $t('removeMemberConfirm', {
                username: selectedMember.username,
                orgname: current_org.name,
              })
            }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col id="members-invitation-modal-col" v-if="inviteMember">
      <v-dialog id="members-invitation-modal" v-model="inviteMember" width="40%">
        <v-card id="members-invitation-modal-card">
          <invite-member-modal :members="members" v-if="inviteMember" @completed="invitedUser"
            :current_org="current_org" :roles="roles" id="invite-member-modal"></invite-member-modal>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col cols="12" id="members-list-card-col">
      <v-card id="members-list-card">
        <v-card-title class="subtitle-1 text-center" color="default">
          <v-row align="center" justify="space-between">
            <v-col cols="auto">
              <v-checkbox v-model="selectAllMembers" :value="selectAllMembers" :label="$t('members')"
                id="select-all-members" hide-details></v-checkbox>
            </v-col>
            <v-col cols="auto" v-if="totalMembers > 1" id="members-list-total-members">{{ totalMembers }} {{
              $t('membersLeft') }}</v-col>
            <v-col cols="auto" id="members-list-total-members-1" v-else>{{ totalMembers }} {{ $t('memberLeft')
              }}</v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="px-0 py-0" v-if="totalMembers">
          <member-item v-for="member in members" :key="member.uid" :isSelected="selectAllMembers" :userId="member.uid"
            :isAdmin="isAdmin" id="members-list" :username="member.first_name + ' ' + member.last_name"
            :role="member.role" :permissions="roles" @change-permission="changeMemberPermission"
            @remove-member="enableMemberRemovalDialog" @member-selected="memberSelected" />
        </v-card-text>
        <v-card-text v-else class="text-center">
          <v-row aligh-h="center">
            <v-col cols="12" align="center">
              <v-icon font-size="2em">mdi-information-outline</v-icon>
            </v-col>
            <v-col class="my-10 py-10">
              <h6>{{ $t('noMembersInvited') }}</h6>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-pagination v-if="members.length > 30" class="py-12 my-12" v-model="currentPage" :total-visible="5"
            :total-items="totalRows" :items-per-page="perPage" align="center" aria-controls="members-list"
            @input="$emit('loadNext', { currentPage: currentPage, perPage: perPage })" prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"></v-pagination>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MemberItem from '@/components/Members/MemberItem.vue';
import InviteMemberModal from '@/components/Members/InviteMemberModal.vue';
import makeOrgService from '@/services/api/org';

export default {
  props: {
    /**
     * selected organization
     */
    current_org: {
      type: Object,
      required: true,
    },
    /**
     * current organization members
     */
    members: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    /**
     * is current
     */
    isAdmin: {
      type: Boolean,
      default: false,
    },
    /**
     * roles that are allowed by the service
     */
    roles: {
      type: Array,
      required: true,
    },
    /**
     * total number of available members
     */
    totalMembers: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selectAllMembers: false,
      currentPage: 1,
      totalRows: this.totalMembers / 30,
      perPage: 30,
      inviteMember: false,
      removeMemberDialog: false,
      selectedMember: undefined,
      requestState: {
        isLoading: false,
        hasError: false,
        data: undefined,
        message: '',
        done: false,
      },
    };
  },

  methods: {
    /**
     * enable member removal confirmtion
     */
    enableMemberRemovalDialog(data) {
      this.selectedMember = data;
      this.removeMemberDialog = !this.removeMemberDialog;
    },
    /**
     * remove user from organization
     * @param {Object} data
     */
    async removeUser(data) {
      try {
        const orgService = makeOrgService(this.$api);
        this.requestState.isLoading = true;
        await orgService.removeUser({
          handle: this.current_org.handle,
          ...data,
        });
        this.removeMemberDialog = false;
        this.requestState.isLoading = false;
        this.requestState.message = this.$t('memberDeleted', {
          username: data.username,
          orgname: this.current_org.name,
        });
        this.$emit('removedUser', data);
        this.requestState.done = true;
      } catch (error) {
        this.requestState.message = this.$t('memberNotDeleted', {
          username: data.username,
          orgname: this.current_org.name,
        });
        this.removeMemberDialog = false;
        this.requestState.isLoading = false;
        this.requestState.hasError = true;
        this.requestState.done = true;
      }
    },
    /**
     * inviatation was sent successfully
     */
    invitedUser() {
      this.$swal({
        title: this.$t('invitationSent'),
        icon: 'success',
        showConfirmButton: false,
        position: 'top-center',
        timer: 2000,
        toast: true,
      });
      this.inviteMember = !this.inviteMember;
    },
    /**
     * changes role of user
     * @param {Object} data
     */
    async changeMemberPermission(data) {
      const orgService = makeOrgService(this.$api);
      this.requestState.isLoading = true;
      try {
        await orgService.updateRoleOfMember({
          org_id: this.current_org.handle,
          ...data,
        });
        this.requestState.data = data;
        this.requestState.message = this.$t('roleUpdateSuccess', { username: data.username });
        this.$emit('updateMember', data);
        this.requestState.isLoading = false;
        this.requestState.done = true;
      } catch (error) {
        this.requestState.data = data;
        this.requestState.message = this.$t('roleUpdateNotSuccessful', { username: data.username });
        this.requestState.isLoading = false;
        this.requestState.hasError = true;
        this.requestState.done = true;
      }
    },
    memberSelected(isSelected, memberId) {
      // TODO update list ids
      console.log(isSelected, memberId);
    },
  },
  components: {
    MemberItem,
    InviteMemberModal,
  },
};
</script>
