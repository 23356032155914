<template>
  <div>
    <v-dialog
      class="test-cases-filter-drawer"
      v-model="isOpen"
      attach
      fullscreen
      max-width="400"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6 mb-4">
            <h2 class="black--text">{{ $t('edit') }}</h2>
            <v-btn
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
          <div>
            <h3 class="select-title mt-4">{{ $t('testSuite') }}</h3>
            <v-select placeholder="Choose test suite" filled class="round-8" dense height="38"></v-select>
            <h3 class="select-title mt-4">{{ $t('priority') }}</h3>
            <v-select placeholder="Choose priority" v-model="selectedPriority" :items="priorities" item-text="text" item-value="value" filled class="round-8" dense height="38"></v-select>
            <h3 class="select-title mt-4">{{ $t('tag') }}</h3>
            <v-select placeholder="Choose tag" v-model="selectedTag" :items="tags" item-text="text" item-value="value" filled class="round-8" dense height="38"></v-select>
          </div>
          <div class="actions-container d-flex justify-end mt-5">
            <v-btn width="10rem" color="gray-100" full-width class="mr-4 text-capitalize" @click="$emit('closeDialog')">{{ $t('cancel') }}</v-btn>
            <v-btn width="10rem" color="blue" dark full-width class="text-capitalize" @click="$emit('clickSave', selectedPriority, selectedTag)">{{ $t('save') }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'EditDialog',
  props: {
    isOpen: Boolean,
    tags: Array,
    priorities: Array,
    items: Array,
  },
  data() {
    return {
      selectedTag: "",
      selectedPriority: "",
    };
  },

};
</script>
<style>
.select-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
</style>