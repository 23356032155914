import { showConfirmationDialog, showErrorToast, showSuccessToast } from '@/utils/toast';

import i18n from '../../i18n';
import makeBranchService from '@/services/api/branch';
import makeCaseService from '@/services/api/case';
import makeRepoService from '@/services/api/repo';

const state = {
  items: [],
  branches: {
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    branches: [],
  },
  repo: {
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    repos: [],
  },
};
const mutations = {
  SET_ITEMS: (state, items) => (state.items = items),
  SET_BRANCHES: (state, branches) => (state.branches.branches = branches),
  SET_REPOS: (state, repos) => (state.repo.repos = repos),
  ADD_ITEM: (state, item) => state.items.unshift(item),
  DELETE_ITEM: (state, itemUid) => (state.items = state.items.filter((item) => item.uid !== itemUid)),
  UPDATE_ITEM: (state, updatedItem) => {
    const index = state.items.findIndex((item) => item.uid === updatedItem.uid);
    if (index !== -1) {
      state.items.splice(index, 1, updatedItem);
    }
  },
};

const makeActions = (api) => {
  const casesService = makeCaseService(api);
  const branchService = makeBranchService(api);
  const repoService = makeRepoService(api);
  return {
    /*
    fetchItems: async function ({ commit, rootState }, { handle, projectKey, perPage, currentPage }) {
      try {
        const dt = await casesService.getCases(handle, projectKey, perPage, currentPage);
        commit('SET_ITEMS', dt.data.cases);
      } catch (error) {
        console.error('Error fetching items: ', error);
      }
    },
    fetchBranches: async function ({ commit }, { handle, repoUid }) {
      try {
        const response = await branchService.getRepoBranches(handle, repoUid);
        commit('SET_BRANCHES', response.data.branches);
      } catch (error) {
        console.error('Error fetching branches: ', error);
      }
    },
    fetchRepos: async function ({ commit, rootState }, { handle, projectKey, perPage, currentPage }) {
      try {
        const response = await repoService.getOrgRepos(handle, projectKey, perPage, currentPage);
        commit('SET_REPOS', response.data.repos);
      } catch (error) {
        console.error('Error fetching repos: ', error);
      }
    },
    */
    deleteTestCase: async function ({ dispatch }, { swal, handle, perPage, currentPage }) {
      const result = await showConfirmationDialog(
        swal,
        i18n.t('deleteTestCase'),
        i18n.t('confirmDeleteTestCase'),
        'warning',
        i18n.t('delete'),
        i18n.t('cancel')
      );
      if (result.isConfirmed) {
        try {
          const dt = await casesService.deleteCase(handle);
          if (dt) {
            dispatch('fetchItems', { handle, perPage, currentPage });
            showSuccessToast(swal, i18n.t('caseDeleted'));
          }
        } catch (error) {
          showErrorToast(swal, i18n.t('caseDeleteFailed'));
        }
      }
    },
    updateTestCase: async function ({ dispatch, rootState }, { swal, uid, handle, projectKey, perPage, currentPage, payload }) {
      try {
        const dt = await casesService.updateTestCase(handle, projectKey, uid, {
          ...payload,
          externalId: 'pinata',
          source: 'pinata',
        });
        if (dt) {
          dispatch('fetchItems', { handle, perPage, currentPage });
          showSuccessToast(swal, i18n.t('testCaseUpdated'));
        }
        return true;
      } catch (error) {
        showErrorToast(swal, i18n.t('caseUpdateFailed'));
        return false;
      }
    },
    createTestCase: async function ({ commit }, { swal, handle, projectKey, payload }) {
      try {
        const dt = await casesService.createTestCase(handle, projectKey, {
          ...payload,
          externalId: 'testfiesta',
          source: 'testfiesta',
        });
        if (dt) {
          commit('ADD_ITEM', dt.data);
          showSuccessToast(swal, i18n.t('caseCreated'));
        }
      } catch (error) {
        showErrorToast(swal, i18n.t('caseCreateFailed'));
      }
    },
    searchTestCase: async function ({ commit, rootState }, { handle, projectKey, text, perPage, currentPage }) {
      try {
        const dt = await casesService.searchCases(text, handle, projectKey, perPage, currentPage);
        commit('SET_ITEMS', dt.data.cases);
      } catch (error) {
        console.error('Error searching items: ', error);
      }
    },
    getCaseTestExecutions: async function ({ commit }, { uid, perPage, handle, currentPage }) {
      try {
        const dt = await casesService.getCaseExecutions(handle, uid, perPage, currentPage);
        return dt.data;
      } catch (error) {
        console.error('Error fetching items: ', error);
        throw error;
      }
    },
    getProjectCases: async function ({ commit }, { handle, projectKey, page, limit }) {
      try {
        const dt = await casesService.getProjectCases(handle, projectKey, page, limit);
        commit('SET_ITEMS', dt.data.cases);
      } catch (error) {
        console.error('Error fetching items: ', error);
        throw error;
      }
    },
    getFolderCases: async function ({ commit }, { handle, projectKey, folderId, page, limit }) {
      try {
        const dt = await casesService.getFolderCases(handle, projectKey, folderId, page, limit);
        commit('SET_ITEMS', dt.data.cases);
      } catch (error) {
        console.error('Error fetching items: ', error);
        throw error;
      }
    },
  };
};

export default function makeTestCase(api) {
  return {
    namespaced: true,
    state,
    mutations,
    actions: makeActions(api),
  };
}
