<template>
  <div>
    <v-sheet
      @click="showDialog = true"
      color="gray-100"
      class="d-flex align-center justify-center pointer"
      height="3.125rem"
      rounded="lg"
    >
      <span class="px-4">{{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      fullscreen
      max-width="400"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">{{ $t('filters') }}</h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-expansion-panels flat v-model="rolesPanel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('role') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(role, index) in roles" :key="index">
                  <v-checkbox
                    v-model="role.selected"
                    :value="role.selected"
                    color="blue"
                    dense
                    :label="role.name"
                  ></v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels flat v-model="projectsPanel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('project') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(project, index) in projects" :key="index">
                  <v-checkbox
                    v-model="project.selected"
                    :value="project.selected"
                    color="blue"
                    dense
                    :label="project.name"
                  ></v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-start">
            <v-expansion-panels flat v-model="tagsPanel">
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">{{ $t('tags') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(tag, index) in tags" :key="index">
                    <v-checkbox
                      v-model="tag.selected"
                      :value="tag.selected"
                      color="blue"
                      dense
                      :label="tag.title"
                    ></v-checkbox>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="d-flex justify-end mt-5">
              <v-btn
                width="10rem"
                @click="clearAll"
                color="gray-100"
                full-width
                class="mr-4 text-capitalize"
                elevation="0"
              >{{ $t('clearAll') }}</v-btn>
              <v-btn
                width="10rem"
                color="blue"
                dark
                full-width
                elevation="0"
                class="text-capitalize"
                @click="apply"
              >{{ $t('apply') }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { roles, projects, tags } from '@/constants/data.js';
export default {
  name: 'ProjectUserFilter',
  data() {
    return {
      rolesPanel: 0,
      projectsPanel: 0,
      tagsPanel: 0,
      rolesMenu: false,
      projectsMenu: false,
      tagsMenu: false,
      showDialog: false,
      roles: roles,
      projects: projects,
      tags: tags
    };
  },
  mounted() {
    this.clearAll();
  },
  methods: {
    apply() {
      const selectedRoles = this.roles.filter(role => role.selected);
      const selectedProjects = this.projects.filter(project => project.selected);
      const selectedTags = this.tags.filter(tag => tag.selected);
      const filters = {
        roles: selectedRoles,
        projects: selectedProjects,
        tags: selectedTags
      };
      this.$emit('filters', filters);
      this.showDialog = false;
    },
    clearAll() {
      this.roles.forEach(role => {
        this.$set(role, 'selected', false);
      });
      this.projects.forEach(project => {
        this.$set(project, 'selected', false);
      });
      this.tags.forEach(tag => {
        this.$set(tag, 'selected', false);
      });
    }
  }
};
</script>
