export const userPlans = [
  {
    name: 'Basic',
    title: 'Free',
    subtitle: 'Unlock a World of Premium Features at Zero Cost - Your Journey Starts Now with Our Free Basic Plan!',
    amount: 0,
    stripe_id: process.env.VUE_APP_STRIPE_FREE_USER_PRICE_ID,
    rank: 0,
    benefits: [
      { title: 'Limited users (5)', icon: 'mdi-check-circle-outline' },
      { title: '1000 testcases', icon: 'mdi-check-circle-outline' },
      { title: '1 GB storage', icon: 'mdi-check-circle-outline' },
    ],
    type: 'user',
  },
  {
    name: 'Advanced',
    title: '$4',
    subtitle: 'An opportunity to elevate your Journey with Ad-Free, Priority Support, and Exclusive Features!',
    amount: 4,
    stripe_id: process.env.VUE_APP_STRIPE_PAID_USER_PRICE_ID,
    rank: 1,
    benefits: [
      { title: 'Unlimited users', icon: 'mdi-check-circle-outline' },
      { title: 'Unlimited testcases', icon: 'mdi-check-circle-outline' },
      { title: '500 GB storage', icon: 'mdi-check-circle-outline' },
    ],
    type: 'user',
  },
];

export const orgPlans = [
  {
    name: 'Basic',
    title: 'Free',
    subtitle: 'Pricing Perfection: Improve Your Organization Without Breaking the Bank with Our Free offering',
    price: '$0',
    stripe_id: process.env.VUE_APP_STRIPE_FREE_ORG_PRICE_ID,
    rank: 0,
    benefits: [
      { title: 'Limited users (5)', icon: 'mdi-check-circle-outline' },
      { title: '1000 testcases', icon: 'mdi-check-circle-outline' },
      { title: '1 GB storage', icon: 'mdi-check-circle-outline' },
    ],
    type: 'org',
  },

  {
    name: 'Advanced',
    title: '$25',
    subtitle: 'Unleash the Possibilities: Propel Your Organization to New Heights with Limitless Potential',
    price: '$3.80',
    stripe_id: process.env.VUE_APP_STRIPE_PAID_ORG_PRICE_ID,
    rank: 1,
    benefits: [
      { title: 'Unlimited users', icon: 'mdi-check-circle-outline' },
      { title: 'Unlimited testcases', icon: 'mdi-check-circle-outline' },
      { title: '500 GB storage', icon: 'mdi-check-circle-outline' },
    ],
    type: 'org',
  },
];
