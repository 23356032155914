<template>
  <div>
    <v-sheet @click="showDialog = true" color="gray-100" class="d-flex align-center justify-center" height="50px" rounded="lg">
      <span class="px-4"> {{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog v-model="showDialog" fullscreen max-width="400">
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">{{ $t('filters') }}</h2>
            <v-btn icon @click="showDialog = false">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-expansion-panels flat v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('role') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox v-model="selectedRoles" value="Account admin" color="blue" dense label="Account admin"></v-checkbox>
                <v-checkbox v-model="selectedRoles" value="Project Admin" color="blue" dense label="Project Admin"></v-checkbox>
                <v-checkbox v-model="selectedRoles" value="QA Lead" color="blue" dense label="QA Lead"></v-checkbox>
                <v-checkbox v-model="selectedRoles" value="Tester" color="blue" dense label="Tester"></v-checkbox>
                <v-checkbox v-model="selectedRoles" value="Client" color="blue" dense label="Client"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-start pt-6">
            <div>Last activity</div>
            <div class="d-flex align-center">
              <v-menu v-model="menu1" :close-on-content-click="false" full-width max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field color="blue" prepend-inner-icon="mdi-calendar-blank-outline" class="mr-0 custom_input" :value="date" clearable readonly v-on="on">
                    <template v-slot:prepend-inner>
                      <v-icon color="blue">mdi-calendar-blank-outline</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="date" @change="menu1 = false"></v-date-picker>
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">-</div>
              <v-menu v-model="menu2" :close-on-content-click="false" full-width max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field color="blue" prepend-inner-icon="mdi-calendar-blank-outline" class="custom_input" :value="date" clearable readonly v-on="on">
                    <template v-slot:prepend-inner>
                      <v-icon color="blue">mdi-calendar-blank-outline</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="date" @change="menu1 = false"></v-date-picker>
              </v-menu>
            </div>
            <v-expansion-panels flat v-model="panel1">
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">{{ $t('tag') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-checkbox v-model="selectedTags" value="#projectateam" color="blue" dense label="#projectateam"></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="#projectbteam" color="blue" dense label="#projectbteam"></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="#finance" color="blue" dense label="#finance"></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="#remote" color="blue" dense label="#remote"></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="#remoteteam" color="blue" dense label="#remoteteam"></v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="d-flex justify-end mt-5 ">
              <v-btn width="10rem" @click="cancel" color="gray-100" full-width class="mr-4 text-capitalize" elevation="0">{{ $t('clearAll') }}</v-btn>
              <v-btn width="10rem" color="blue" dark full-width elevation="0" class=" text-capitalize" @click="apply">{{ $t('apply') }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ProjectItemFilter",
  props: {
    selectedRoles: Array,
    selectedTags: Array
  },
  data() {
    return {
      panel: 0,
      panel1: 0,
      date: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      showDialog: false,
    }
  },
  methods: {
    apply() {
      this.$emit('selectedRoles', this.selectedRoles);
      this.$emit('selectedTags', this.selectedTags);
      console.log("dd", this.selectedRoles, this.selectedTags)
      this.showDialog = false;
    },
    cancel() {}
  }
}
</script>
