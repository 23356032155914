// Note: Folder API
export default function makeFoldersService(api) {
  return {
    getProjectFolders: async function (handle, projectID) {
      return api.get(`/${handle}/projects/${projectID}/folders`);
    },
    createFolder: async function (handle, projectKey, data) {
      return api.post(`/${handle}/projects/${projectKey}/folders`, data);
    },
    deleteFolder: async function (handle, projectKey, item) {
      return api.delete(`/${handle}/projects/${projectKey}/folders/${item}`);
    },
    updateFolder: async function (handle, projectKey, id, data) {
      return api.patch(`/${handle}/projects/${projectKey}/folders/${id}`, data);
    },
  };
}
