export default function runsService(api) {
    return {
      getExecutions: async function (handle, projectKey, runId) {
        return api.get(`/${handle}/projects/${projectKey}/runs/${runId}/executions`);
      },
      getTestRuns: async function (handle, projectKey, perPage, currentPage) {
        return api.get(`/${handle}/projects/${projectKey}/runs?per_page=${perPage}&current_page=${currentPage}`);
      },
      searchRuns: async function (handle, projectKey, text, perPage, currentPage) {
        return api.get(`/${handle}/projects/${projectKey}/runs/search?query=${text}&per_page=${perPage}&current_page=${currentPage}`);
      },
      createTestRun: async function (handle, projectKey, data) {
        return api.post(`/${handle}/projects/${projectKey}/runs`, data);
      },
      duplicateTestRun: async function(handle, projectKey,runId){
        return api.post(`/${handle}/projects/${projectKey}/run/${runId}/rerun`);
      },
      getTestRunExecutions: async function(handle, projectKey, runId){
        return api.get(`/${handle}/projects/${projectKey}/run/${runId}/executions`)
      },
      updateTestRun: async function (handle, projectKey, uid, data) {
        return api.put(`/${handle}/projects/${projectKey}/runs/${uid}`, data);
      },
      updateTestRuns: async function (handle, projectKey, data) {
        return api.put(`/${handle}/projects/${projectKey}/runs/`, data);
      },
      deleteTestRun: async function (handle, projectKey, uid) {
        return api.delete(`/${handle}/projects/${projectKey}/runs/${uid}`);
      },
      deleteTestRuns: async function (handle, projectKey, data) {
        return api.delete(`/${handle}/projects/${projectKey}/runs`, { data: data });
      },
      getProjectRuns: async function (handle, projectKey, page, limit) {
        return api.get(`/${handle}/projects/${projectKey}/runs?page=${page}&limit=${limit}`);
      },
      getTestRunById: async function (handle, projectKey, runId) {
        return api.get(`/${handle}/projects/${projectKey}/runs/${runId}`);
      },
      getTestRunDetail: async function (handle, projectKey, runId) {
        return api.get(`/${handle}/projects/${projectKey}/runs/${runId}/cases`);
      },
      getTestCaseRuns: async function (handle, projectKey, caseId) {
        return api.get(`/${handle}/projects/${projectKey}/cases/${caseId}/runs`);
      },
    };
}
