<template>
  <v-dialog v-model="showDialog" max-width="400" persistent fullscreen>
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ isEditMode ? $t('sharedStepPage.editSharedStep') : $t('sharedStepPage.createNewSharedStep') }}
          </h2>
          <v-btn icon @click="showDialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form ref="form" v-model="validForm" lazy-validation class="mt-10">
          <v-row>
            <v-col cols="12" class="pb-0">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('title') }}<strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-text-field v-model="sharedStep.name" type="text" dense filled placeholder="Title" :rules="requiredRules" />
            </v-col>
          </v-row>
        </v-form>
        <div class="child-steps-area d-flex flex-column mt-8">
          <step-item v-for="(item, index) in sharedStep.steps" :data="item" :index="index" :key="item.title + index" @delete-step="deleteStepItem" @input-step="onEditStepItem" />
        </div>
        <div class="d-flex flex-row justify-end">
          <v-btn class="text-capitalize primary" depressed @click="addNewStep">
            {{ $t('sharedStepPage.addNewStep') }}
            <v-icon size="16">mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="gray-100" width="100%" class="text-capitalize" elevation="0" @click="onCancel()">
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn color="blue" width="100%" elevation="0" class="white--text text-capitalize" :disabled="!validForm" @click="onCreate()">
              {{ isEditMode ? $t('save') : $t('create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { tagTypes } from '@/constants/tag.js';
import StepItem from '@/components/Admin/SharedStep/StepItem';

export default {
  name: 'CreateUpdateSharedStepDialog',

  components: {
    StepItem,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    showDialog: {
      get()
      {
        return this.value;
      },
      set(v)
      {
        this.$emit('input', v);
      },
    },

    isEditMode()
    {
      return !!this.sharedStep.uid;
    },
  },

  watch: {
    showDialog(value)
    {
      if (!value) {
        return;
      }

      this.sharedStep = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        steps: this.data.steps || '',
      };
    },
  },

  data()
  {
    return {
      requiredRules: [(value) => !!value || this.$t('error.requiredField')],
      requiredMultiSelectRules: [(value) => !!value || value.length > 0 || this.$t('error.requiredField')],
      validForm: false,
      tagTypes: tagTypes,
      sharedStep: {
        uid: '',
        name: '',
        steps: [],
      },
    };
  },

  methods: {
    onCreate()
    {
      const isValidForm = this.$refs.form.validate();

      if (!isValidForm) {
        return;
      }
      this.$emit(this.isEditMode ? 'update-shared-step' : 'create-shared-step', this.sharedStep);
    },

    addNewStep()
    {
      const step = {
        title: '',
        description: '',
        expectedResult: '',
      };
      this.sharedStep.steps.push(step);
    },

    deleteStepItem(itemIndex)
    {
      const steps = [...this.sharedStep.steps];
      steps.splice(itemIndex, 1);
      this.sharedStep.steps = steps;
    },

    onEditStepItem(itemIndex, stepItem)
    {
      this.sharedStep.steps[itemIndex] = stepItem;
    },

    onCancel()
    {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss">
.v-textarea {
  .v-text-field__slot {
    textarea {
      padding: 12px;
    }
  }
}
</style>
