<template>
    <div>
        <v-sheet @click="showDialog = true" color="gray-100" class="d-flex align-center justify-center pointer"
            height="3.125rem" rounded="lg">
            <span class="px-4">{{ $t('filters') }} {{ totalSelected > 0 ? `(${ totalSelected })` : '' }} <v-icon>mdi-filter-variant</v-icon></span>
        </v-sheet>

        <v-dialog v-model="showDialog" fullscreen max-width="400">
            <v-card>
                <v-card-text class="black--text">
                    <div class="d-flex align-center justify-space-between pt-6">
                        <h2 class="black--text">{{ $t('filters') }}</h2>
                        <v-btn icon @click="close()">
                            <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                    </div>

                    <v-expansion-panels flat v-model="projectPanel">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="mx-0 px-0">{{ $t('project') }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <SearchComponent class="mb-3" :search="projectSearch"
                                    @update:search="projectSearch = $event" :placeholder="$t('search')" />
                                <template v-if="isProjectFilteredExist">
                                    <div v-for="(item, index) in projectFiltered" :key="index">
                                        <v-checkbox v-model="projectSelected" :value="item" color="blue"
                                            dense :label="item" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center">{{ $t('noProjects') }}</div>
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels flat v-model="tagsPanel">
                        <v-expansion-panel>
                            <v-expansion-panel-header class="mx-0 px-0">{{ $t('tags') }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <SearchComponent class="mb-3" :search="tagSearch" @update:search="tagSearch = $event"
                                    :placeholder="$t('search')" />
                                <template v-if="tagsFiltered?.length > 0">
                                    <div v-for="(item, index) in tagsFiltered" :key="index">
                                        <v-checkbox v-model="tagSelected" :value="item" color="blue" dense
                                            :label="item" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-center">{{ $t('noTags') }}</div>
                                </template>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-card-text>

                <v-card-actions>
                    <v-row>
                        <v-col cols="6">
                            <v-btn color="gray-100" width="100%" class="text-capitalize" elevation="0"
                                @click="clearAll">{{
                                $t('clearAll') }}</v-btn>
                        </v-col>

                        <v-col cols="6">
                            <v-btn color="blue" width="100%" elevation="0" class="white--text text-capitalize"
                                @click="apply">{{
                                $t('apply') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SearchComponent from '@/components/Project/SearchComponent.vue';

export default {
    name: 'RoleMemberFilterDialog',
    components: {
        SearchComponent,
    },
    props: {
        projects: {
            type: Array,
            default: () => [],
        },
        tags: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            projectPanel: 0,
            tagsPanel: 0,
            projectSearch: '',
            tagSearch: '',
            showDialog: false,
            tagSelected: [],
            projectSelected: [],
        };
    },

    watch: {
        showDialog(value) {
            if (!value) {
                return
            }

        },
    },
    computed: {
        projectFiltered() {
            return this.projects.filter((project) => project.toLowerCase().includes(this.projectSearch.toLowerCase()));
        },
        isProjectFilteredExist() {
            return this.projectFiltered.length > 0;
        },
        tagsFiltered() {
            return this.tags.filter((tag) => tag.toLowerCase().includes(this.tagSearch.toLowerCase()));
        },
        totalSelected() {
            return (this.projectSelected?.length ?? 0) + (this.tagSelected?.length ?? 0);
        },
    },

    mounted() {

    },

    methods: {
        apply() {
            this.$emit('apply', {
                projectSelected: this.projectSelected,
                tagSelected: this.tagSelected,
            });
            this.showDialog = false;
        },

        clearAll() {
            this.projectSelected = [];
            this.tagSelected = [];
            this.projectSearch = '';
            this.tagSearch = '';
        },

        close() {
            this.showDialog = false;
        },

    }
};
</script>