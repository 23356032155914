<template>
  <v-sheet color="gray-100" class="d-flex align-center justify-center mr-2" width="90px" height="50px" rounded="lg">
    <v-btn icon @click="handleTable">
      <ViewListSelectedIcon v-if="table"></ViewListSelectedIcon>
      <ViewListIcon v-else></ViewListIcon>
    </v-btn>
    <v-btn icon @click="handleTiles">
      <ViewDashboardIcon v-if="table"></ViewDashboardIcon>
      <ViewDashboardSelectedIcon v-else></ViewDashboardSelectedIcon>
    </v-btn>
  </v-sheet>
</template>

<script>
import ViewListIcon from '@/assets/svg/view-list.svg';
import ViewListSelectedIcon from '@/assets/svg/view-list-selected.svg';
import ViewDashboardIcon from '@/assets/svg/view-dashboard.svg';
import ViewDashboardSelectedIcon from '@/assets/svg/view-dashboard-selected.svg';

export default {
  props: {
    table: Boolean
  },
  components: {
    ViewListIcon,
    ViewListSelectedIcon,
    ViewDashboardIcon,
    ViewDashboardSelectedIcon
  },
  methods: {
    handleTable() {
      this.$emit('toggle-table', true);
    },
    handleTiles() {
      this.$emit('toggle-table', false);
    },
  }
};
</script>
