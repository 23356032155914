import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
 */
export default function makeOrgService(api) {
  return {
    newOrg: function (data) {
      return api.post('/orgs', data);
    },
    newInvite: function (data) {
      return api.post(`/orgs/${data.handle}/invite`, data);
    },
    orgIndex: function () {
      return api.get('/orgs');
    },
    getRoles: function (handle) {
      return api.get(`/orgs/${handle}/roles`);
    },
    getUsers: function (handle, payload) {
      return api.get(`/orgs/${handle}/users`, { params: payload });
    },
    /**
     * update organization
     * @param {Object} payload
     * @
     */
    updateOrg: function (payload) {
      const data = {
        avatar_url: payload.avatarURL,
        name: payload.orgName,
        preferences: payload.preferences
      };
      return api.patch(`/orgs/${payload.handle}`, data);
    },
    /**
     * remove user from org
     * @param {Object} payload
     *
     */
    removeUser: function (payload) {
      return api.delete(`/orgs/${payload.org_id}/users/${payload.memberId}`);
    },
    removeUserOrg: function (handle, userId, payload) {
      return api.delete(`/orgs/${handle}/users/${userId}`, { data: payload });
    },
    /**
     * delete organization
     * @param {String} orgId
     */
    deleteOrg: function (handle, payload) {
      return api.delete(`/orgs/${handle}`, payload);
    },
    /**
     * get access tokens for an org
     * @param {String} orgId
     */
    getAccessTokens: function (handle) {
      return api.get(`/${handle}/tokens`);
    },
    createAccessToken: function (handle, orgUid, data) {
      const accessTokenData = {
        newAccessTokenName: data.newAccessTokenName,
        expirationDate: data.expirationDate,
      };
      return api.post(`orgs/${handle}/access_tokens/${orgUid}/new`, accessTokenData);
    },
    updateAccessToken: function (handle, orgUid, data) {
      const accessTokenData = {
        newAccessTokenName: data.newAccessTokenName,
        expirationDate: data.expirationDate,
      };
      return api.patch(`orgs/${handle}/access_tokens/${orgUid}/${data.uid}`, accessTokenData);
    },
    deleteAccessToken: function (handle, orgUid, data) {
      return api.delete(`orgs/${handle}/access_tokens/${orgUid}/${data.access_token_id}`);
    },
    updateRoleOfMember: function (payload) {
      return api.patch(`/orgs/${payload.handle}/users/${payload.memberId}`, { new_role_uid: payload.newRole });
    },

    validateInvite: function (data) {
      return api.get(`/orgs/${data.handle}/invite/${data.token}`);
    },
    acceptInvite: function (data) {
      return api.post(`/orgs/${data.handle}/invite/${data.token}/accept`, data);
    },
    declineInvite: function (data) {
      return api.post(`/orgs/${data.handle}/invite/${data.token}/decline`, data);
    },
    deleteInviteByEmail: function (handle, email) {
      return api.delete(`/orgs/${handle}/invite`, { data: { email } });
    },
    getCurrentSubscription: function (orgId) {
      return api.get(`/subscriptions`, { params: { model: 'org', uid: orgId } });
    },
    changeSubscription: function (payload) {
      return api.post(`/checkouts`, payload, {
        params: { model: 'org', uid: payload.orgId },
      });
    },
    getSubscriptionHistory: function (orgId) {
      return api.get(`subscriptions/history`, { params: { model: 'org', uid: orgId } });
    },
    addOrgSeats: function (payload) {
      return api.post(`/subscriptions/seats`, payload, {
        params: { model: 'org', uid: payload.orgId },
      });
    },
    getCurrentUsage: function (orgId) {
      return api.get(`/subscriptions/usage`, { params: { model: 'org', uid: orgId } });
    },
    getOrgCount: function (handle) {
      return api.get(`/orgs/${handle}/users/count`);
    },
    listPendingInvites: function (orgId) {
      return api.get(`/orgs/${orgId}/invites`);
    },
    leaveOrg: function (handle, orgHandle) {
      return api.delete(`/${handle}/orgs/${orgHandle}`);
    }
  };
}
