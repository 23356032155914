const state = {
    suite_test_cases: {},
};

const getters = {
    /**
     * @param {String} testSuitedId
     *
     */
    getSuiteTestCase: (state) => (testSuiteId) => {
        return state.suite_test_cases[testSuiteId];
    },
    /**
     * returns true if the current user is org admin
     * @param {String} testCaseId
     * @return {Boolean} isAdmin
     */
    getTestCaseSummary: (state) => (testCaseId) => {
        return state.test_cases_summary[testCaseId];
    },
};

const mutations = {
    /**
     * cache test case
     * @param {Object} payload
     * @param {String} payload.test_suite_id
     * @param {Object} payload.test_cases
     */
    addSuiteTestCase(state, payload) {
        state.suite_test_cases[payload.test_suite_id] = payload.test_cases;
    },
    /**
     * cache test case summary
     * @param {Object} payload
     * @param {String} payload.test_case_id
     * @param {Object} payload.summary
     */
    addTestCaseSummary(state, payload) {
        state.test_cases_summary[payload.test_case_id] = payload.summary;
    },
};
const actions = {
    /**
     * add new test case
     * @param {Object} payload
     */
    addSuiteTestCase({ commit }, payload) {
        commit('addSuiteTestCase', payload);
    },
    /**
     * add new test case summary
     * @param {Object} payload
     */
    addTestCaseSummary({ commit }, payload) {
        commit('addTestCaseSummary', payload);
    },
};

export default function makeTest() {
    return {
        namespaced: true,
        state,
        getters,
        mutations,
        actions,
    };
}
