<template>
  <div>
    <v-sheet
      @click="showDialog = true"
      color="gray-100"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
    >
      <span class="px-4">{{ $t('filters') }} <v-icon size="16px">mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
    class="test-cases-filter-drawer"
      v-model="showDialog"
      attach
      fullscreen
      max-width="400"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">{{ $t('filters') }}</h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-expansion-panels flat v-model="rolesPanel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('priority') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(priority, index) in priorities" :key="index">
                  <v-checkbox
                    v-model="priority.selected"
                    :value="priority.selected"
                    color="blue"
                    dense
                    :label="priority.text"
                  ></v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels flat v-model="tagsPanel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('tags') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field v-model="searchTerm" placeholder="Search" prepend-inner-icon="mdi-magnify" filled single-line ></v-text-field>
                <div v-for="(tag, index) in searchedTags" :key="index">
                  <v-checkbox
                    v-model="tag.selected"
                    :value="tag.selected"
                    color="blue"
                    dense
                    :label="`#${tag.text}`"
                  ></v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="actions-container d-flex justify-end mt-5">
            <v-btn
              width="10rem"
              color="gray-100"
              full-width
              class="mr-4 text-capitalize"
              elevation="0"
               @click="clearFilters"
            >{{ $t('clearAll') }}</v-btn>
            <v-btn
              width="10rem"
              color="blue"
              dark
              full-width
              elevation="0"
              class="text-capitalize"
              @click="apply"
            >{{ $t('apply') }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { priorities } from '@/constants/grid.js';
export default {
  name: 'TestCasesFilter',
  data() {
    return {
      rolesPanel: 0,
      projectsPanel: 0,
      tagsPanel: 0,
      rolesMenu: false,
      projectsMenu: false,
      tagsMenu: false,
      showDialog: false,
      priorities: Object.values(priorities),
      searchTerm: '',
      // projects: projects,
      tags: [
        { value: "test", text: 'test'},
        { value: "billing", text: 'billing'},
        { value: "performance", text: 'performance'},
        { value: "navigation", text: 'navigation'},
      ],
    };
  },
  computed: {
    searchedTags(){
      return this.searchTerm.length ? this.tags.filter(tag => tag.value.toLowerCase().includes(this.searchTerm.toLowerCase())) : this.tags
    }
  },

  mounted() {
  
  },
  methods: {
    apply() {
      const selectedPriorities = this.priorities.filter(priority => priority.selected);
      const selectedTags = this.tags.filter(tag => tag.selected);
      const filters = {
        priorities: selectedPriorities.map(item => item.value),
        tags: selectedTags.map(item => item.value)
      };
      this.$emit('filters', filters);
      this.showDialog = false;
    },

    clearFilters() {
    this.$emit('filters', { priorities: [], tags: [] });

    this.priorities = this.priorities.map(priority => ({ ...priority, selected: false }));
    this.tags = this.tags.map(tag => ({ ...tag, selected: false }));

    this.searchTerm = '';

    this.showDialog = false;
  },

  }
};
</script>
<style>
.test-cases-filter-drawer .v-sheet {
  display: flex
}

.test-cases-filter-drawer .v-card__text {
  display: flex;
  flex-direction: column;
}

.test-cases-filter-drawer .actions-container {
  margin-top: auto !important;
}
</style>