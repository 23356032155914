import dashboardService from '@/services/api/dashboard';
import dayjs from 'dayjs';

export default {
  state: {
    lastUpdate: '',
  },

  getters: {
    lastUpdate: (state) => {
      return state.lastUpdate;
    },
  },

  mutations: {
  /*  setAccessTokens(state, items) {
      state.accessTokens = items;
    },
    removeAccessToken(state, accessTokenId) {
      state.accessTokens = state.accessTokens.filter((e) => {
        return e.uid != accessTokenId;
      });
    },
    removeAllAccessTokens(state) {
      state.accessTokens = [];
    },*/
  },

  actions: {
    async addData(context, type, data) {
      await dashboardService.addData(type, data);
      context.state.lastUpdate[type] = dayjs();
    },    
  },
  
  namespaced: true,
};
