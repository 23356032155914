<template>
  <div>
    <base-navbar />
    <router-view />
  </div>
</template>

<script>
import makeUserService from '@/services/api/user';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('user');

export default {
  computed: {
    ...mapGetters(['userName', 'currentAccount', 'user']),
  },
  mounted() {
    const userService = makeUserService(this.$api);
    userService.getProfile().then(res => {
      this.$store.dispatch("user/setUser", res.data);
    })
    userService.getOrgs().then(({ data }) => {
      this.$store.commit("user/setAccounts", [this.currentAccount, ...data])
    }).catch(err => {
      this.$swal({
        title: err?.response ? err?.response?.data?.error ?? this.$t('problemProcessingRequest') : this.$t('error.connection'),
        icon: 'error',
        showConfirmButton: false,
        position: 'top-end',
        timer: 2000,
        toast: true,
      });
    })
  },
};
</script>

<style></style>
