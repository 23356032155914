<template>
<v-row align-content="start" justify="start" dense class="fill-height">
  <div :class="[!isTreeViewCollapsed ? 'col-3' : 'col-1']" class="col fill-height mt-2" >    
    <TreeView
      :items="folders"
      :executionFolders="executionFolders"
      :selectedFolderUid="selectedFolderUid"
      :collapsed="isTreeViewCollapsed ? 0 : 1"
      @folder-selected="selectFolder"
      @folder-delete="deleteFolder"
      :hideCreate="showCreate"
    />
    <div class="position-relative" v-if="showCollapse">
      <div class="collapse-btn" @click="toggleMenu">
        <v-icon>mdi-arrow-left-bottom</v-icon>
        <span v-if="!isTreeViewCollapsed" class="collapse-text">{{ $t('collapse') }}</span>
      </div>
    </div>
  </div>
  <v-col class="fill-height mt-2" :class="[!isTreeViewCollapsed ? 'v-col-9' : 'v-col-11']">
    <v-row align-content="start" justify="start" dense>
      <div :class="[isDetailViewCollapsed ? 'col-6' : 'col-12']" class="col fill-height mt-2">
        <execution-list
          id="cases-list"
          :case-items="filterCases"
          :bread-crumbs="breadCrumbs"
          :allow-action="allowAction"
          :is-repository="!quickCreate"
          :is-detail-collapsed="isDetailViewCollapsed"
          @expandDetail="openDetailView"
          @createCase="onCreateCase"
          @refresh="selectFolder"
          @updateSelectedExecution="updateSelectedExecution"
          @selectedCases="handleCases"
          :assignees="assignees"
        ></execution-list>
      </div>
      <div v-if="isDetailViewCollapsed && execution" class="col-6 fill-height mt-2">
        <detail-view
          @updateSelectedExecution="updateSelectedExecution"
          :execution="execution"
          @closeDetail="closeDetailView"
          :testResults="testResults"
        ></detail-view>
      </div>
    </v-row>
  </v-col>
</v-row>
</template>
<script>
import ExecutionList from './List.vue';
import TreeView from '@/views/Tests/Case/Tree/Index.vue';
import DetailView from './Index.vue';
import { showErrorToast } from '@/utils/toast';
import makeCasesService from '@/services/api/case';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('user');
export default {
  components: {
      ExecutionList,
      TreeView,
      DetailView
  },
  props:{
    /*
      showCreate: Enables the creation of new folders.
      showCollapse: Hides the collapse folder option.
      selectOption: Returns selected cases only when set to true.
      allowAction: Activates the action button for selected cases.
    */
    showCreate:{
      type: Boolean,
      default: true,
    },
    showCollapse:{
      type: Boolean,
      default: true
    },
    selectOption:{
      type: Boolean,
      default: false,
    },
    allowAction:{
      type: Boolean,
      default: true
    },
    quickCreate:{
      type: Boolean,
      default: false
    },
    executions:{
      type: Array,
    },
    testResults:{
      type: Array,
    },
    execution:{
      type: Object,
    },
    assignees: {
      type: Array
    },
  },
  data() {
    return {
      isTreeViewCollapsed: false,
      isDetailViewCollapsed: false,
      selectedItem : null,
      folders: [],
      breadCrumbs: [],
      selectedCase: {},
      selectedCases: [],
      selectedFolderUid: this.$route.params.folderUid? this.$route.params.folderUid: null,
    };
  },
  computed: {
  ...mapState(['currentAccount']),
  cases(){
    if(this.executions.length)
        return this.executions.filter(element => element.parentUid == this.selectedFolderUid)
    return []
  },
  executionFolders(){
    return this.executions.map(item => item.parentUid)
  },
  executionPayload(){
    return {
      assignedTo: this.execution?.assignedTo,
      status: this.execution?.status,
      priority: this.execution?.priority
    }
  },
  filterCases(){
    if(this.selectOption)
      return this.selectedCases

    return this.cases
    },
  },
  methods: {
    // For Testrun
    toggleMenu() {
      this.isTreeViewCollapsed = !this.isTreeViewCollapsed
    },
    changeExpansion(item) {
      this.selectedItem = item
      this.isDetailViewCollapsed = true;

    },
    closeDetailView() {
      this.isDetailViewCollapsed = false;
    },
    handleCases(selectedCases){
      this.selectedCases = selectedCases
      this.$emit('selectedCases', selectedCases)
      this.$emit('updateCaseCount', selectedCases.length, this.cases.length)
    },
    buildBreadCrumbs(searchUID, currentFolders, chain) {
      for (let idx = 0; idx < currentFolders.length; idx++) {
        let folder = currentFolders[idx];
        if (folder.uid === searchUID) {
          chain.push({ text: folder.name });
          this.breadCrumbs = chain;
          break;
        } else if (folder.children && folder.children.length > 0) {
          let newChain = [...chain, { text: folder.name }];
          this.buildBreadCrumbs(searchUID, folder.children, newChain);
        }
      }
    },
    async selectFolder(folderUID = null) {
      if(folderUID)
          this.selectedFolderUid = folderUID
      else
          folderUID = this.selectedFolderUid

      this.buildBreadCrumbs(folderUID, this.folders, []);
      this.$emit('folder-select', folderUID)
    },
    async onCreateCase(caseItem) {
      const caseService = makeCasesService(this.$api);
      try {
        const res = await caseService.createTestCase(
          this.$route.params.handle,
          this.$route.params.key,
          caseItem
        );
        if (res.status == 200) {
          let caseItem = res.data;
          this.cases.unshift(...caseItem);
        } else {
          showErrorToast(this.$swal, this.$t('error.caseAddFailed'));
        }
      } catch (error) {
        showErrorToast(this.$swal, this.$t('error.caseAddFailed'));
        console.log("Error: ", error);
      }

    },
      // Keep
    openDetailView(item) {
      this.selectedCase = item;
      this.isDetailViewCollapsed = true;
      this.$emit('getExecution', item.uid);
    },
    deleteFolder(){
      this.cases = []
    },
    updateSelectedExecution({property, value}) {
      this.execution[property] = value
      return this.$emit('updateExecution', this.executionPayload)
      
    },
  },
}
</script>
<style>

</style>
