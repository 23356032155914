<template>
  <v-app-bar app color="secondary" flat dark>
    <router-link to="/">
      <img
        style="max-width: 20%; display: block;"
        :src="require('@/assets/png/logo.png')"
      />
    </router-link>
    <v-text-field
      v-if="user"
      hide-details
      prepend-inner-icon="mdi-magnify"
      :placeholder="$t('search')"
      class="mx-12"
      outlined
      flat
      dense
      style="max-width: 20%"
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-btn icon v-if="user">
      <v-icon>mdi-bell</v-icon>
    </v-btn>
    <v-menu offset-y v-if="user" >
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" class="mr-2">
          <v-avatar class="bg-primary">
            <template v-if="!user.avatar_url">
              <v-icon>mdi-account</v-icon>
            </template>
            <template v-else>
              <img :src="user.avatar_url" alt="avatar" />
            </template>
          </v-avatar>
        </v-btn>
      </template>
      <v-list dense class="text-left">
        <v-list-item href="/orgs/new" :active="isActive('/orgs/new')">
          <v-list-item-title>{{ $t('newOrganization') }}</v-list-item-title>
        </v-list-item>
        <v-list-item href="/profile" :active="isActive('/profile')">
          <v-list-item-title>{{ $t('profile') }}</v-list-item-title>
        </v-list-item>
        <v-list-item href="/settings/profile" :active="isActive('/settings/profile')">
          <v-list-item-title>{{ $t('settings') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapGetters, mapMutations } = createNamespacedHelpers('user');
export default {
  data() {
    return {
      drawer: false,
      menuItems: [
        {
          title: 'Menu 1',
          url: '#',
        },
        {
          title: 'Menu 2',
          url: '#',
        },
        {
          title: 'Menu 3',
          url: '#',
        },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAuthenticated', 'isAdmin', 'userName']),
  },
  methods: {
    ...mapMutations(['setUser', 'setOrgs', 'setCurrentAccount']),
    isActive(route) {
      return this.$route.path === route;
    },
    logout() {
      this.setUser(null);
      this.setOrgs(null);
      this.setCurrentAccount(null);
      localStorage.clear();
      this.$router.push('/login').catch(e => {
        console.log(e);
      });
    },
  },
};
</script>

