
import makeCommentsService from "@/services/api/comment";
import { showErrorToast, showSuccessToast, showConfirmationDialog } from '@/utils/toast';
import i18n from '../../i18n';

const state = {
    comments: [],
};

const getters = {
    getAllComments: (state) => state.comments,
};

const makeActions = (api) => {
  const commentsService = makeCommentsService(api);
  return {
    async fetchComments({ commit }, {handle, entityType, entityUid }) {
      try {
        const response = await commentsService.getComments(handle, entityType, entityUid);
        commit('SET_COMMENTS', response.data);
      } catch (error) {
        console.error('Error fetching comments: ', error);
      }
    },
    async addComment({ commit }, { swal,payload, user }) {
      try {
        const response = await commentsService.createComment(user,payload);
        commit('ADD_COMMENT', { ...response.data, user });
        showSuccessToast(swal, 'Comment created successfully');
      } catch (error) {
        console.error('Error creating comment: ');
        showErrorToast(swal, i18n.t('error.createComment'));
      }
    },
    async deleteComment({ commit }, { swal,commentUid }) {
      try {
        await commentsService.deleteComment(commentUid);
        commit('DELETE_COMMENT', commentUid);
        showSuccessToast(swal, 'Comment deleted successfully');
      } catch (error) {
        console.error('Error deleting comment: ');
        showErrorToast(swal, i18n.t('error.deleteComment'));
      }
    },
    async updateComment({ commit }, { swal, oldComment, payload }) {
      try {
        const response = await commentsService.updateComment(oldComment.uid, payload);
        commit('UPDATE_COMMENT', { ...oldComment, ...payload });
        showSuccessToast(swal, i18n.t('success.updateComment'));
      } catch (error) {
        console.error('Error updating comment: ');
        showErrorToast(swal, i18n.t('error.updateComment'));
      }
    },
  };
};

const mutations = {
    SET_COMMENTS: (state, comments) => (state.comments = comments),
    ADD_COMMENT: (state, comment) => state.comments.unshift(comment),
    DELETE_COMMENT: (state, commentUid) => state.comments = state.comments.filter(comment => comment.uid !== commentUid),
    UPDATE_COMMENT: (state, updatedComment) => {
        const index = state.comments.findIndex(comment => comment.uid === updatedComment.uid);
        if (index !== -1) {
            state.comments.splice(index, 1, updatedComment);
        }
    },
};

export default function makeComment(api) {
  return {
    namespaced: true,
    getters,
    state,
    mutations,
    actions: makeActions(api),
  };
}