import ProjectLayout from '@/Layouts/ProjectLayout.vue';
import DefaultLayout from '@/Layouts/DefaultLayout.vue';

const routes = [
  {
    path: '/:handle/:key/plans',
    component: ProjectLayout,
    children: [
      {
        path: '',
        name: 'TestPlans',
        component: () => import('@/views/Tests/Plans/Index'),
      },
    ],
  },
  {
    path: '/:handle/:key/plans/create',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'TestPlanCreate',
        component: () => import('@/views/Tests/Plans/CreatePlanView'),
      },
      {
        path: 'runs',
        name: 'TestPlanAddRuns',
        component: () => import('@/views/Tests/Plans/AddTestRunView'),
      },
      {
        path: 'duplicate',
        name: 'TestPlanDuplicate',
        component: () => import('@/views/Tests/Plans/Duplicate/Index'),
      },
      {
        path: 'duplicate/runs',
        name: 'TestPlanDuplicateAddRuns',
        component: () => import('@/views/Tests/Plans/Duplicate/AddRuns'),
      },
    ],
  },
  {
    path: '/:handle/:key/plans/:planId',
    component: ProjectLayout,
    children: [
      {
        path: 'rerun',
        name: 'TestPlanRerun',
        component: () => import('@/views/Tests/Plans/RerunView'),
      },
      {
        path: '',
        name: 'TestPlanDetail',
        component: () => import('@/views/Tests/Plans/DetailView'),
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    requiresAuth: true,
  };
  return { ...route, meta };
});
