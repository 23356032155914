<template>
  <v-container fluid style="padding: 0">
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
      />
      <p class="text-body-1 mt-3">{{ loadingText }}...</p>
    </v-overlay>

    <v-row>
      <v-col :cols="isMenuCollapsed ? 1 : 2">
        <left-menu :menuItems="menuItems" />
      </v-col>

      <v-col :cols="isMenuCollapsed ? 11 : 10" style="padding-left: 0 !important">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import LeftMenu from '@/components/Admin/LeftMenu';

export default {
  name: 'SettingsLayout',

  components: {
    LeftMenu,
  },

  computed: {
    ...mapGetters(['isMenuCollapsed', 'isLoading', 'loadingText']),

    menuItems() {
      return [
        { title: 'Account', icon: 'mdi-account-outline', to: { name: 'Account' }, isActive: true },
        { title: 'Organizations', icon: 'mdi-domain', to: { name: 'Organizations' }, isActive: false },
        { title: 'API Keys', icon: 'mdi-key', to: { name: 'APIKeys' }, isActive: false },
        // TODO - save thse removed menu items for later
        //{ title: 'Notifications', icon: 'mdi-bell-outline', to: { name: 'Notifications' }, isActive: false },
        //{ title: 'Authentication', icon: 'mdi-shield-outline', to: { name: 'Authentication' }, isActive: false },
        //{ title: 'Billing', icon: 'mdi-note-outline', to: { name: 'Billing' }, isActive: false },
        { title: 'Data Colors', icon: 'mdi-eyedropper-variant', to: { name: 'DataColors' }, isActive: false },
        { title: 'About', icon: 'mdi-information-outline', to: { name: 'About' }, isActive: false },
      ]
    }
  },
}
</script>
