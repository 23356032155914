<template>
  <!-- header section -->
  <v-col cols="12" sm="12" class="fill-height card bg-white">
    <v-col cols="12" sm="12" class="flex justify-between align-center pl-1">
      <div>
        <v-btn @click="handleEdit()" class="text-capitalize bg-white f-color-blue px-0 toggle-btn" depressed>
          <v-icon size="16">mdi-square-edit-outline</v-icon>
          {{ $t('edit') }}
        </v-btn>
      </div>
      <div>
        <span><v-icon>mdi-arrow-left-thin</v-icon></span>
        <span class="mx-2"><v-icon>mdi-arrow-right-thin</v-icon></span>
        <span class="close-btn" @click="$emit('closeDetail')"><v-icon>mdi-close</v-icon></span>
      </div>
    </v-col>
    <h2 class="pl-3">{{ caseItem.name }}</h2>
      <v-chip
        v-for="(item, index) in caseItem.customFields.tags"
        :key="index"
      >
        {{ item }}
      </v-chip>
    <v-col cols="12" sm="12">
      <h4>{{ $t('defect.overview') }}</h4>
      <v-list>
        <v-list-item class="px-0">
          <template>
            <div class="flex justify-between">
              <!--<div class="block round-8 px-3 py-2 w-50 mr-1" style="background-color: #F9FAFB">
                <h5 class="align-left">{{ $t('template') }}</h5>
                <div class="align-left contents">TEMPLATE</div>
              </div>-->
              <div class="block round-8 px-3 py-2 w-50 ml-1" style="background-color: #f9fafb">
                <h5 class="align-left">{{ $t('priority') }}</h5>
                <div class="align-left contents" :style="'color: ' + getPriorityColor(caseItem.customFields.priority)">
                  {{ caseItem.customFields.priority }}
                </div>
              </div>
            </div>
          </template>
        </v-list-item>
        <!--<v-list-item class="px-0 mt-2" v-if="!overviewCollapsed">
          <template>
            <div class="flex justify-between">
              <div class="block round-8 px-3 py-2 w-50 mr-1" style="background-color: #F9FAFB">
                <h5 class="align-left">Creator</h5>
                <div class="align-left contents">Maria Watson</div>
              </div>
              <div class="block round-8 px-3 py-2 w-50 ml-1" style="background-color: #F9FAFB">
                <h5 class="align-left">ID</h5>
                <div class="align-left contents">HM-1</div>
              </div>
            </div>
          </template>
        </v-list-item>-->
        <v-list-item class="px-0 mt-2" v-if="!overviewCollapsed">
          <template>
            <div class="flex justify-between">
              <div class="block round-8 px-3 py-2 w-50 mr-1" style="background-color: #f9fafb">
                <h5 class="align-left">Last update</h5>
                <div class="align-left contents">
                  {{ lastUpdated }}
                </div>
              </div>
              <!--<div class="block round-8 px-3 py-2 w-50 ml-1" style="background-color: #F9FAFB">
                <h5 class="align-left">Folder</h5>
                <div class="align-left contents">Adroid - App</div>
              </div>-->
            </div>
          </template>
        </v-list-item>
        <v-list-item class="px-0 mt-2" v-if="!overviewCollapsed">
          <template>
            <div class="flex justify-between">
              <!--<div class="block round-8 px-3 py-2 w-50 ml-1" style="background-color: #F9FAFB">
                <h5 class="align-left">Assigned</h5>
                <div class="align-left contents">Alex O'brien</div>
              </div>-->
            </div>
          </template>
        </v-list-item>
      </v-list>
      <h4 @click="overviewCollapsed = !overviewCollapsed" class="f-color-blue toggle-btn" v-if="overviewCollapsed">
        {{ $t('defect.showMore') }}
      </h4>
      <h4 @click="overviewCollapsed = !overviewCollapsed" class="f-color-blue toggle-btn" v-else>
        {{ $t('defect.showLess') }}
      </h4>

      <v-expansion-panels flat v-model="descriptionPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 panel-title">
            {{ $t('description') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- description contents -->
            <v-timeline dense>
              <v-timeline-item v-for="step in caseItem.steps" :key="step.key" right>
                <step-item :step-item="step"></step-item>
              </v-timeline-item>
            </v-timeline>

            <div
              v-for="(item, index) in caseItem.customFields.templateFields"
              :key="index"
              class="align-center px-0 py-6"
            >
              <div class="block round-8 px-3 py-2 w-50 ml-1 mb-2" style="background-color: #f9fafb">
                <h5 class="align-left">Field Name</h5>
                <div class="align-left contents">
                  {{ item.name }}
                </div>
              </div>

              <div>
                <v-text-field
                  v-if="item.dataType == 'text'"
                  type="text"
                  dense
                  filled
                  hide-details
                  :value="item.defaultValue"
                  :placeholder="$t('name')"
                />
                <v-col :key="optionIndex" v-for="(option, optionIndex) in item.options">
                  <v-radio-group :value="option" v-if="item.dataType == 'radio'">
                    <v-radio :key="option" :value="option" :label="option" hide-details></v-radio>
                  </v-radio-group>

                  <v-text-field :value="option" dense filled hide-details v-else-if="item.dataType === 'dropdown'" />

                  <v-checkbox
                    v-else-if="item.dataType === 'checkbox'"
                    :input-value="option"
                    :label="option"
                    color="blue"
                    dense
                    class="pt-0 mt-0"
                    hide-details
                  />
                </v-col>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>


      <!-- <v-expansion-panels flat v-model="commentsPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0">
            {{ $t('comments') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->

      <!--<v-expansion-panels flat v-model="runsPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 panel-title">
            {{ $t('testRuns') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <TestRunItem
              v-for="run in runs"
              :key="run.uid"
              //:run-id="run.slug"
              //:run-status="run.status"
              :run-title="run.name"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>-->

      <!-- <v-expansion-panels flat v-model="defectsPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 panel-title">
            {{ $t('defects') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github" justify-start/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
            <defect-item runId="AT-1" runTitle="Alpha Test Run" iconName="mdi-github"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>-->
    </v-col>
  </v-col>
</template>
<script>
import StepItem from '@/views/Tests/Case/Components/StepItem.vue';
//import TestRunItem from '@/components/TestRunItem.vue';
//import DefectItem from '@/components/DefectItem.vue';
import { priorityColors } from '@/constants/colors.js';
import { formatDate } from '@/utils/util';

//import makeRunsService from '@/services/api/run';

export default {
  name: 'DetailView',
  components: {
    StepItem,
    //TestRunItem,
    //DefectItem,
  },
  props: {
    caseItem: Object,
  },
  data() {
    return {
      overviewCollapsed: true,
      descriptionPanel: null,
      runsPanel: null,
      runs: [],
    };
  },
  computed: {
    lastUpdated() {
      return formatDate(this.caseItem.updatedAt, 'MM/dd/yy');
    },
  },
  methods: {
    handleEdit() {
      this.$router.push({
        name: 'EditTestCases',
        params: { uid: this.caseItem.testCaseRef },
      });
    },
    getPriorityColor(priority) {
      const foundPriority = priorityColors.find((color) => color.testCase === priority);
      return foundPriority?.value || 'unset';
    },
  },
};
</script>
<style>
h2,
h3,
h4 {
  text-align: left;
}
h5 {
  color: #667085;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.collapse-btn {
  color: #0c2ff3;
}
p {
  text-align: left;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.bg-white {
  background-color: white !important;
}
.close-btn:hover {
  cursor: pointer;
}
.f-color-blue {
  color: #0c2ff3 !important;
}
.w-50 {
  width: 50%;
}
.align-left {
  text-align: left;
}
.align-left .contents {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0c111d;
}
.toggle-btn {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}

.panel-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
</style>
