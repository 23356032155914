<template>
  <div class="text-left">
    <p class="font-weight-bold text-body-1 mb-6">
      {{ $t("authentication.step", { currentStep, totalSteps }) }}
    </p>

    <p class="font-weight-bold text-body-1 mb-1">
      {{ $t("authentication.enterPhoneNumber") }}
    </p>

    <p class="mb-6 text-body-1">
      {{ $t("authentication.willSendConfirmationCodeToPhoneNumbmer") }}
    </p>

    <p class="font-weight-medium text-body-1 text-left mb-1">
      {{ $t('common.phoneNumber') }}
    </p>
    <v-text-field
      v-model="phoneNumber"
      type="text"
      dense
      filled
      placeholder="+1-234-567-8910"
      :rules="phoneNumberRules"
      persistent-hint
      :hint="$t('common.phoneNumberExample')"
    />
  </div>
</template>

<script>
import { phoneNumberValidationRules  } from "@/utils/validation";

export default {
  name: 'EnterPhoneNumber',

  props: {
    value: {
      type: String,
      default: '',
    },

    totalSteps: {
      type: Number,
      default: 1,
    },

    currentStep: {
      type: Number,
      default: 1,
    },
  },

  data () {
    return {
      phoneNumberRules: phoneNumberValidationRules(this),
    }
  },

  computed: {
    phoneNumber: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },
}
</script>