<template>
  <v-card class="white py-4 px-4 mt-3" rounded="lg" elevation="0" width="100%">
    <div class="d-flex align-center justify-space-between">
      <h2>{{ title }}</h2>
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
          <v-btn class="tray-btn-margin tray-btn-outline" depressed v-bind="attrs" v-on="on" >
            <v-icon size="18">mdi-tray-arrow-down</v-icon>
          </v-btn>
          </template>
          <v-card rounded="8" elevation="0">
            <v-list>
              <router-link :to="{ name: 'ImportCSV' }">
                <v-list-item :key="1" >
                  <v-list-item-title>{{ $t('importFromCSV') }}</v-list-item-title>
                </v-list-item>
              </router-link>
              <!--<router-link :to="{ name: 'ImportXML' }">
                <v-list-item :key="2" >
                  <v-list-item-title>{{ $t('importFromXML') }}</v-list-item-title>
                </v-list-item>
              </router-link>-->
            </v-list>
          </v-card>
        </v-menu>
        <!--<v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
          <v-btn class="horizontal-margin tray-btn-margin tray-btn-outline" depressed v-bind="attrs" v-on="on" >
            <v-icon size="18">mdi-tray-arrow-up</v-icon>
          </v-btn>
          </template>
          <v-card rounded="8" elevation="0">
            <v-list>
              <router-link :to="{ name: 'ExportCSV' }">
                <v-list-item :key="1" >
                  <v-list-item-title>{{ $t('exportToCSV') }}</v-list-item-title>
                </v-list-item>
              </router-link>
              <router-link :to="{ name: 'ExportSpreadsheet' }">
                <v-list-item :key="2" >
                  <v-list-item-title>{{ $t('exportToSpreadsheet') }}</v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list>
          </v-card>
        </v-menu> -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="hasAction" color="#0C2FF3" dark class="text-capitalize tray-btn-outline px-6" depressed v-bind="attrs" v-on="on" >
            {{ actionText }}
            <v-icon class="ml-1" size="xs">mdi-plus</v-icon>
          </v-btn>
          </template>
          <v-card rounded="8" elevation="0">
            <v-list>
              <router-link :to="{ name: 'CreateTestCases' }">
                <v-list-item
                  :key="1"
                  cursor-pointer
                >
                  <v-list-item-title>{{ $t('createTestCase') }}</v-list-item-title>
                </v-list-item>
              </router-link>
              <!--<router-link :to="`/tests/cases/repository`">
                <v-list-item
                  :key="2"
                  cursor-pointer
                >
                  <v-list-item-title>{{ $t('addTestCaseFromRopository') }}</v-list-item-title>
                </v-list-item>
              </router-link>-->
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>
    <slot name="additional-actions" />
  </v-card>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: String,
    actionText: String,
    hasAction: {
      type: Boolean,
      default: true,
    },
  },

};
</script>

<style scoped>
h2{
  font-weight: 700;
}
.v-list-item:hover{
  background-color: #F9FAFB;
}
.v-list a{
  text-decoration: none;
}
.v-menu__content{
  text-align: left !important;
}
.horizontal-margin {
  margin: 0px 10px;
}

.tray-btn-margin {
  min-width: 40px !important;
  width: 40px !important;
  padding: 10px 0px !important;
}

.tray-btn-outline {
  border-radius: 8px;
  height: 40px !important;
}
</style>
