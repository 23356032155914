<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    @click:row="handleOrgClick"
  >
    <template v-slot:[`item.name`]="{ item }">
      <img v-if="item.avatar_url" :src="item.avatar_url" />
      <v-icon v-else large>mdi-account-circle</v-icon>
      <span class="ml-4">{{ item.name }}</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <div class="d-flex justify-space-between">
        <v-btn
          icon
          color="primary"
          @click="$emit('edit', item)"
        >
          <EditIcon />
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate } from '@/utils/util';

import EditIcon from '@/assets/svg/edit.svg';

export default {
  name: 'OrganizationsTable',

  props: {
    headers: Array,
    itemKey: String,
    items: Array,
  },

  components: {
    EditIcon,
  },

  methods: {
    formatCreatedDate(createdAt) {
      return formatDate(createdAt, 'MM/dd/yy')
    },
    handleOrgClick(item){
      this.$emit('select-row', item);
    }
  },
}
</script>
