export const sideNavItems = [ 
  {
    title: 'Dashboard',
    icon: 'mdi-home-outline',
    routeName: 'Dashboard',
    url: '/',
  },
  {
    title: 'TESTS',
    children: [
      {
        icon: 'mdi-cube-outline',
        title: 'Cases',
        url: '/tests/cases',
        routeName: 'Cases',
      },
      {
        icon: 'mdi-play-box-outline',
        title: 'Executions',
        url: '/tests/executions',
        routeName: 'Executions',
      },
      {
        icon: 'mdi-clipboard-text-outline',
        title: 'Runs',
        url: '/tests/runs',
        routeName: 'Runs',
      },
      {
        icon: 'mdi-view-day-outline',
        title: 'Suites',
        url: '/tests/suites',
        routeName: 'Suites',
      },
      {
        icon: 'mdi-flag-outline',
        title: 'Milestones',
        url: '/tymon711/tests/milestones',
        routeName: 'Milestones',
      },
    ],
  },
];
