<template>
    <div class="root">
        <div class="container1">
            <ul class="progressbar">
                <li :class="i < step? 'active':i == step? 'el-bound':''" v-for=" i in length" :key="i">
                </li>
            </ul>

        </div>
        <div class="container2">
            <ul class="progressbar">
                <li :class=" i == step? 'current':'el-hide'" v-for=" i in length" :key="i">
                </li>
            </ul>

        </div>
        <div class="container3">
            <ul class="progressbar">
                <li :class="i < step? 'active':i == step? 'el-hide':'el-hide'" v-for=" i in length" :key="i">
                </li>
            </ul>

        </div>
    </div>
</template>
<script>

export default {
    props: ['length', 'step'],
    data() {
        return {
        }
    },
}
</script>

<style scoped>
.el-hide {
    visibility: hidden
}

.container1 {
    width: 50%;
    position: absolute;
    z-index: 1;
}

.container2 {
    width: 50%;
    position: absolute;
    z-index: 2;
}

.container3 {
    width: 50%;
    position: absolute;
    z-index: 3;
}

.progressbar {
    counter-reset: step;
}

.progressbar li.active {
    box-shadow: none !important;
}

.progressbar li {
    float: left;
    width: 14.28%;
    position: relative;
    text-align: center;
}

.container1 ul {
    list-style-type: none;
}

.container2 ul {
    list-style-type: none;
}

.container3 ul {
    list-style-type: none;
}

.progressbar li:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "";
    counter-increment: step;
    width: 30px;
    height: 30px;
    border: 2px solid #bebebe;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 27px;
    background: white;
    color: #bebebe;
    text-align: center;
    font-weight: bold;
}

.progressbar li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: #979797;
    top: 15px;
    left: -50%;
    z-index: -1;
    margin: -1px 0px 0px 0px;
}

.progressbar li.active:before {
    content: "";
    /* FontAwesome Unicode */
    display: inline-block;
    margin-left: -1.3em;
    border-color: #8B5CF6;
    background: #8B5CF6;
    color: white;
    background-image: url('/image/check.svg');
    background-repeat: no-repeat;
    background-position: center center;
}

.progressbar li.current:before {
    border-color: #8B5CF6;
    content: "";
    margin: 10px 0px 0px 0px;
    display: inline-block;
    width: 10px;
    z-index: 10;
    height: 10px;
    background: #8B5CF6;
}

.progressbar li.el-bound:before {
    border-color: #8B5CF6;
    content: "";
}

.progressbar li.el-bound:after {
    background: #8B5CF6;
    margin: -1px 0px 0px 0px;
}

.progressbar li.current:after {
    background: #8B5CF6;
    visibility: hidden;

}

.progressbar li.active:after {
    background: #8B5CF6;
    margin: -1px 0px 0px 0px;
}

.progressbar li.active-li:after {
    background: #8B5CF6;
    margin: -1px 0px 0px 0px;
}

.progressbar li.active-li:before {
    border-color: #8B5CF6;
    background: #8B5CF6;
    margin: -1px 0px 0px 0px;
    color: white
}

.progressbar li:first-child:after {
    content: none;
    margin: -1px 0px 0px 0px;
}
</style>
