<template>
  <ValidationProvider
    ref="provider"
    :vid="vid"
    :name="$attrs.name"
    :rules="rules"
    v-slot="{ valid, errors }"
  >
    <v-form-group class="text-left" v-bind="$attrs">
      <v-text-field
        v-model="innerValue"
        v-bind="$attrs"
        :error="errors[0]"
        :success="valid ? (customValidation && !!customValidationError) ? false : true : null"
      ></v-text-field>
      <v-alert
        type="error"
        :value="!customValidationError && customValidation"
        id="inputLiveFeedback"
      >{{ errors.length ? errors[0] : customValidationError }}</v-alert>
    </v-form-group>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
    },
    customValidation: {
      type: Boolean,
      default: false,
    },
    customValidationError: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
    customValidationError(newVal) {
      if (newVal) {
        this.$refs.provider.applyResult({
          errors: [newVal], // array of string errors
          valid: false, // boolean state
          failedRules: {
            required: true,
          },
        });
      }
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>