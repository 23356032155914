<template>
  <div class="d-flex align-center">
    <div
      class="rounded-circle"
      style="width: 36px; height: 36px;"
      :style="{'background-color': value }"
      @click="showPickColorDialog=true"
    />

    <v-chip
      class="text-body-2 ml-3 grey lighten-5"
      label
      @click="showPickColorDialog=true"
    >
      <span style="width: 80px;">{{ value }}</span>
    </v-chip>

    <v-btn
      text
      color="blue"
      :disabled="value === defaultColor"
      @click="$emit('input', defaultColor)"
    >
      {{ $t('dataColors.restoreDefault') }}
    </v-btn>

    <PickColorDialog
      v-model="showPickColorDialog"
      :original-color="value"
      @choose-color="$emit('input', $event)"
      @close-dialog="showPickColorDialog=false"
    />
  </div>
</template>

<script>
import PickColorDialog from './PickColorDialog.vue';

export default {
  name: 'ColorItem',

  components: {
    PickColorDialog,
  },

  props: {
    value: {
      type: String,
    },
    defaultColor: {
      type: String,
    }
  },

  data () {
    return {
      showPickColorDialog: false,
    }
  },
}
</script>