<template>
  <div class="mt-4">
    <v-data-table
      :headers="headers"
      :items="entries"
      :loading="isLoading"
      :loading-text="loadingText"
      class="elevation-1"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span class="success--text" v-if="!item.deleted_at">{{ $t('active') }}</span>
        <span class="error--text" v-else>{{ $t('archived') }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <router-link :to="`/admin/${model}/${item.uid}/edit`">
          <v-btn text x-small> <v-icon>mdi-pencil</v-icon> </v-btn>
        </router-link>
        <v-btn text x-small @click.stop="() => $emit('attemptDelete', item)"> <v-icon>mdi-delete</v-icon> </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import makeAdminService from '@/services/api/admin';
let service;

export default {
  name: 'UsersList',
  props: {
    model: String,
    entries: Array,
    isLoading: Boolean,
  },
  data() {
    return {
      loadingText: 'Loading... Please wait',
      headers: [],
    };
  },
  mounted() {
    service.getModelSpecs(this.model).then((res) => {
      const fields = [];

      const excluded = /(password)|(preferences)|(last_sign_in_ip)|(admin)|(deleted)|(token)|(url)/;
      const isTimeStamp = /_at$/;
      for (const prop of res.data.schema.required) {
        if (excluded.test(prop)) continue;
        fields.push({ text: prop.replaceAll('_', ' '), align: 'start', value: prop });
      }

      this.headers = [
        { text: this.$t('id'), value: 'uid' },
        ...fields,
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions' },
      ];
    });
  },
  created() {
    service = makeAdminService(this.$api);
  },
};
</script>