<template>
  <v-menu content-class="custom_ele elevation-1" nudge-bottom="50" :close-on-content-click="false" left>
    <template v-slot:activator="{ on }">
      <v-sheet color="gray-100" width="50px" class="d-flex align-center justify-center" height="50px" rounded="lg" v-on="on">
        <v-icon color="gray-ish">mdi-cog-outline</v-icon>
      </v-sheet>
    </template>
    <v-list dense>
      <h4 class="font-weight-400 text-start ml-10 mt-4">{{ $t('columns') }}</h4>
      <template v-for="(item, index) in filterItems">      
        <v-list-item :key="index"  dense v-if="item.text">
          <v-list-item-action start>
            <v-checkbox v-model="item.checked"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>{{ item.text }}</v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item @click="restore" class="blue--text ml-5 mt-2 font-weight-bold">
        {{ $t('restoreDefault') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    filterItems: Array,
    requiredItems: Array
  },
  methods: {
    restore() {
      this.filterItems.forEach(item => {
        if(!this.requiredItems || !this.requiredItems.length)
          return item.checked = true;

        item.checked = this.requiredItems.includes(item.text);
      });
    },
  }
};
</script>
