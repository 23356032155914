

const CounterChartDefaults = {
  data: {
    series: 25,
    options: {
    },
  },
  fields: {
    primary: {
      typeOptions: ['count', 'sum']
    },
    //filters,
    //relationship
  },
};

const NoAxisChartDefaults = {
  data: {
    series: [25, 50, 100],
    options: {
      chart: {
        id: 'new-chart',
      },
      labels: ['Thing 1', 'Thing 2', 'Thing 3'],
    },
  },
  fields: {
    primary: {
      typeOptions: ['plot', 'count', 'sum']
    },
    secondary: {
      typeOptions: ['category']
    },
    //  filters,
    //  relationship
  }
};

const XYChartDefaults = {
  data: {
    series: [
      {
        name: 'Sample Data',
        color: '#8841eb',
        ref: {},
        data: [
          {
            x: 'Jan',
            y: 30,
          },
          {
            x: 'Feb',
            y: 35,
          },
          {
            x: 'Mar',
            y: 45,
          },
          {
            x: 'Apr',
            y: 40,
          },
          {
            x: 'May',
            y: 60,
          },
          {
            x: 'June',
            y: 50,
          },
        ],
      },
    ],
    options: {
      chart: {
        id: 'new-chart',
      },
    },
  },
  fields: {
    primary: {
      typeOptions: ['plot', 'count', 'sum']
    },
    secondary: {
      typeOptions: ['time', 'number', 'category']
    },
    //  filters,
    //  relationship
  },
};

const XYRangeChartDefaults = {
  data: {
    series: [
      {
        data: [
          {
            x: 'Team 1',
            y: [30, 50],
          },
          {
            x: 'Team 2',
            y: [35, 55],
          },
          {
            x: 'Team 3',
            y: [45, 60],
          },
          {
            x: 'Team 4',
            y: [40, 65],
          },
          {
            x: 'Team 5',
            y: [60, 70],
          },
          {
            x: 'Team 6',
            y: [50, 85],
          },
        ],
      },
    ],
    options: {
      chart: {
        id: 'new-chart',
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
    },
  },
  fields: {
    primary: {
      typeOptions: ['plot', 'count', 'sum']
    },
    secondary: {
      typeOptions: ['time', 'number', 'category'] // CTODO - not all XY can have all of these
    },
    //  filters,
    //  relationship
  },
};

const XYZChartDefaults = {
  data: {
    series: [
      {
        name: 'Items',
        data: [
          {
            x: 5,
            y: 1,
            z: 4,
          },
          {
            x: 10,
            y: 2,
            z: 2,
          },
          {
            x: 15,
            y: 3,
            z: 11,
          },
          {
            x: 20,
            y: 2,
            z: 3,
          },
          {
            x: 25,
            y: 1,
            z: 6,
          },
          {
            x: 30,
            y: 3,
            z: 2,
          },
          {
            x: 35,
            y: 4,
            z: 5,
          },
          {
            x: 40,
            y: 5,
            z: 13,
          },
          {
            x: 45,
            y: 3,
            z: 9,
          },
          {
            x: 50,
            y: 5,
            z: 10,
          },
        ],
      },
    ],
    options: {
      chart: {
        id: 'new-chart',
      },
      dataLabels: {
        enabled: false,
      },
    },
  },
  fields: {
    primary: {
      typeOptions: ['plot', 'count', 'sum']
    },
    secondary: {
      typeOptions: ['time', 'number', 'category'] // CTODO - not all XY can have all of these
    },
    //  tertiary: {
    //    key,
    //    type: options => standalone
    //  },
    //  filters,
    //  relationship
  },
};

const candleStickChartDefaults = {
  data: {
    series: [
      {
        name: 'Items',
        data: [
          {
            x: 'Jan',
            y: [5, 40, 25, 40],
          },
          {
            x: 'Feb',
            y: [40, 35, 30, 30],
          },
          {
            x: 'Mar',
            y: [30, 50, 30, 35],
          },
          {
            x: 'Apr',
            y: [35, 55, 30, 45],
          },
          {
            x: 'May',
            y: [45, 60, 35, 40],
          },
          {
            x: 'Jun',
            y: [40, 65, 40, 60],
          },
        ],
      },
    ],
    options: {
      chart: {
        id: 'new-chart',
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
    },
  },
  fields: {
    primary: {
      typeOptions: ['plot']
    },
    secondary: {
      typeOptions: ['time', 'number', 'category'] // CTODO - not all XY can have all of these
    },
  },
};

const boxPlotChartDefaults = {
  data: {
    series: [
      {
        type: 'boxPlot',
        data: [
          {
            x: 'Jan',
            y: [5, 15, 25, 40, 45],
          },
          {
            x: 'Feb',
            y: [10, 25, 35, 45, 50],
          },
          {
            x: 'Mar',
            y: [15, 30, 45, 50, 55],
          },
          {
            x: 'Apr',
            y: [20, 45, 50, 55, 60],
          },
          {
            x: 'May',
            y: [25, 40, 45, 60, 65],
          },
          {
            x: 'Jun',
            y: [30, 35, 40, 55, 70],
          },
        ],
      },
    ],
    options: {
      chart: {
        id: 'new-chart',
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
    },
  },
  fields: {
    primary: {
      typeOptions: ['plot']
    },
    secondary: {
      typeOptions: ['time', 'number', 'category']
    },
  },
};

export const ChartTypes = {
  counter: {
    fields: CounterChartDefaults.fields,
    defaultData: CounterChartDefaults.data,
  },

  line: {
    fields: XYChartDefaults.fields,
    defaultData: XYChartDefaults.data,
    seriesOptions: {
      type: {
        location: 'type',
        options: ['line', 'column', 'area', 'scatter', 'candlestick'], // CTODO - does it make more sense to have a
      }, //         MultiChartConfig class?
    },
    options: {
      title: {
        default: 'New Line Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
      dataLabels: {
        default: false,
        fidelity: 'chart',
        location: 'dataLabels.enabled',
      },
      seriesName: {
        default: 'Series',
        fidelity: 'series',
        location: 'name',
      },
      hideElements: {
        default: 'false',
        fidelity: 'chart',
        location: 'chart.sparkline.enabled',
      },
      subType: {
        default: 'smooth',
        fidelity: 'series',
        location: 'stroke.curve',
        options: ['smooth', 'straight', 'stepline'],
      },
      strokeWidth: {
        default: 1,
        fidelity: 'series',
        location: 'stroke.width',
        subType: 'number',
      },
      strokeType: {
        default: 0,
        fidelity: 'series',
        location: 'stroke.dashArray',
        subType: 'number',
      },
      markers: {
        default: 2,
        fidelity: 'series',
        location: 'markers.size',
        subType: 'number',
      },
      hoverMarkers: {
        default: 2,
        fidelity: 'series',
        location: 'markers.hover.sizeOffset',
        subType: 'number',
        tooltip: 'Stack the bars',
      },
    },
    automatedOptions: {
      timeseries: {
        location: 'xaxis.type',
        value: 'datetime',
        reference: 'series', // CTODO: when X series is a date?
      },
    },
  },

  area: {
    fields: XYChartDefaults.fields,
    defaultData: XYChartDefaults.data,
    seriesOptions: {
      type: {
        location: 'type',
        options: ['line', 'column', 'area', 'scatter', 'candlestick'], // CTODO - does it make more sense to have a
      }, //                                                               MultiChartConfig class?
    },
    options: {
      title: {
        default: 'New Area Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  bar: {
    fields: XYChartDefaults.fields,
    defaultData: XYChartDefaults.data,
    options: {
      stacked: {
        location: 'chart.stacked',
        fidelity: 'chart',
        children: ['scaled'],
        default: false,
      },
      scaled: {
        location: 'chart.stackType',
        fidelity: 'chart',
        parents: ['stacked'],
        options: ['normal', '100%'],
        default: 'normal',
      },
      title: {
        default: 'New Bar Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  histogram: {
    fields: XYChartDefaults.fields,
    defaultData: XYChartDefaults.data,
    options: {
      title: {
        default: 'New Histogram',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  pie: {
    fields: NoAxisChartDefaults.fields,
    defaultData: NoAxisChartDefaults.data,
    options: {
      title: {
        default: 'New Pie Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  donut: {
    fields: NoAxisChartDefaults.fields,
    defaultData: NoAxisChartDefaults.data,
    options: {
      title: {
        default: 'New Donut Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  radialBar: {
    fields: NoAxisChartDefaults.fields,
    defaultData: NoAxisChartDefaults.data,
    options: {
      title: {
        default: 'New Radial Bar Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  scatter: {
    fields: XYChartDefaults.fields,
    defaultData: XYChartDefaults.data,
    options: {
      title: {
        default: 'New Scatter Plot',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  bubble: {
    fields: XYZChartDefaults.fields,
    defaultData: XYZChartDefaults.data,
    options: {
      title: {
        default: 'New Bubble Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  heatmap: {
    fields: XYChartDefaults.fields,
    defaultData: {
      ...XYChartDefaults.data,
      ...{
        options: {
          dataLabels: {
            enabled: false,
          },
        },
      },
    },
    options: {
      title: {
        default: 'New Heatmap',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  candlestick: {
    defaultData: candleStickChartDefaults.data,
    options: {
      title: {
        default: 'New Candlestick Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  boxPlot: {
    aliasedType: 'candlestick',
    defaultData: boxPlotChartDefaults.data,
    options: {
      title: {
        default: 'New Box Plot',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  radar: {
    fields: XYChartDefaults.fields,
    defaultData: XYChartDefaults.data,
    options: {
      title: {
        default: 'New Radar Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  polarArea: {
    fields: NoAxisChartDefaults.fields,
    defaultData: NoAxisChartDefaults.data,
    options: {
      title: {
        default: 'New Polar Area Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  rangeBar: {
    fields: XYRangeChartDefaults.fields,
    defaultData: XYRangeChartDefaults.data,
    options: {
      title: {
        default: 'New Range Bar Chart',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },

  treemap: {
    fields: XYChartDefaults.fields,
    defaultData: XYChartDefaults.data,
    options: {
      title: {
        default: 'New Treemap',
        fidelity: 'chart',
        location: 'title.text',
      },
    },
  },
};
