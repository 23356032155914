<template>
  <v-card class="white mt-3 left-menu-card pt-4" rounded="lg" elevation="0">
    <v-select :items="projects" item-text="name" item-value="key" v-model="selectedProject" label="Select a project"  solo append-icon="mdi-chevron-down" class="mx-4"></v-select>
    <v-list nav>
      <v-list-item-group v-model="selectedItem" color="primary">
        <template v-for="(item, index) in menuItems">
          <v-list-item v-if="!item.children" :key="item.title" :to="item.to" @click="setActive(index)">
            <v-list-item-icon v-if="!isProjectMenuCollapsed">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-icon v-if="isProjectMenuCollapsed">{{ item.icon }}</v-icon>
              <v-list-item-title class="text-left" v-if="!isProjectMenuCollapsed">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else :key="item.title + index" :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-if="!isProjectMenuCollapsed">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="(child, childIndex) in item.children" :key="child.title" :to="child.to" @click="setActive(index, childIndex)">
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list-item-group>
    </v-list>
    <div class="collapse-btn" @click="toggleProjectMenu">
      <v-icon class="collapse-icon">{{ isProjectMenuCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom'
        }}</v-icon>
      <span v-if="!isProjectMenuCollapsed" class="collapse-text">
        {{ $t('collapse') }}
      </span>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
const { mapState: mapProjectState, mapMutations: mapProjectMutations } = createNamespacedHelpers('project');
const { mapMutations: mapFolderMutations } = createNamespacedHelpers('folder');

export default {
  name: 'ProjectLeftMenuCard',

  props: {
    menuItems: {
      type: Array,
      default: () => [],
    }
  },

  data()
  {
    return {
      selectedItem: 0,
    };
  },
  computed: {
    ...mapGetters(['isProjectMenuCollapsed']),
    ...mapProjectState(['projects']),
    selectedProject: {
      get() {
        return this.$route.params.key;
      },
      set(key) {
        this.SET_SELECTED_PROJECT(
          this.projects.find((project) => key === project.key)
        );
        this.UPDATE_SELECTED({});
         this.reloadCurrentRoute(key);
      },
    },
  },
  methods: {
    ...mapMutations(['toggleProjectMenu']),
    ...mapProjectMutations(['SET_SELECTED_PROJECT']),
    ...mapFolderMutations(['UPDATE_SELECTED']),
    setActive(index, childIndex)
    {
      if (childIndex !== undefined) {
        this.selectedItem = `${index}.${childIndex}`;
      } else {
        this.selectedItem = index;
      }
    },
    reloadCurrentRoute(key)
    {
      const currentRoute = this.$route;
      const newParams = { ...currentRoute.params, key };
      this.$router.replace({
        name: currentRoute.name,
        params: newParams,
        query: currentRoute.query
      }).then(() =>
      {
        this.$router.go();
      })
    }
  },
};
</script>

<style>
.left-menu-card {
  position: relative;
  height: calc(100vh - 100px);
  transition: width 0.3s;
}

.text-left {
  text-align: left;
}

.left-menu-card.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.collapse-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
}

.collapse-btn .collapse-text {
  margin-left: 8px;
  font-weight: bold;
}

.left-menu-card.collapsed .collapse-btn .collapse-text {
  display: none;
}

.left-menu-card.collapsed .collapse-btn {
  justify-content: center;
}
</style>
