<template>
  <v-card class="py-6 px-6 mt-3 d-flex flex-column" rounded="lg" elevation="0">
    <div class="d-flex flex-row justify-space-between">
      <v-btn @click="handleEdit" class="text-capitalize bg-white f-color-blue px-0 toggle-btn" depressed>
        <v-icon size="16">mdi-square-edit-outline</v-icon>
        {{ $t('edit') }}
      </v-btn>
      <div class="d-flex align-center">
        <button @click="handlePrevious" variant="plain" :disabled="!hasPrevious" depressed class="nav-btn">
          <v-icon size="24">mdi-arrow-left-thin</v-icon>
        </button>
        <button @click="handleNext" variant="plain" :disabled="!hasNext" depressed class="nav-btn">
          <v-icon size="24">mdi-arrow-right-thin</v-icon>
        </button>
        <span class="close-btn" @click="closeDetail"><v-icon>mdi-close</v-icon></span>
      </div>
    </div>
    <h2>{{ detailItem.name }}</h2>
    <h4 class="mt-3">{{ $t('defect.overview') }}</h4>
    <div class="d-flex justify-space-between">
      <div class="block round-8 px-3 py-2 w-50 mr-1" style="background-color: #f9fafb">
        <h5 class="align-left">Creator</h5>
        <div class="align-left contents">{{ detailItem.creator.first_name }} {{ detailItem.creator.last_name }}</div>
      </div>
      <div class="block round-8 px-3 py-2 w-50 ml-1" style="background-color: #f9fafb">
        <h5 class="align-left">Date of creation</h5>
        <div class="align-left contents">
          {{ formatCreatedAt(detailItem.created_at) }}
        </div>
      </div>
    </div>
    <h4 class="mt-4">{{ $t('Steps') }}</h4>
    <div v-for="(step, index) in detailItem.steps" :key="index" class="my-3">
      <h5 class="font-weight-bold">{{ $t('Step') }} {{ index + 1 }}</h5>
      <div class="d-flex mt-2">
        <div class="grid-item w-full">
          <h5 class="font-weight-bold">{{ $t('Description') }}</h5>
          <p>{{ step.description }}</p>
        </div>
        <div class="grid-item w-full">
          <h5 class="font-weight-bold">{{ $t('Expected result') }}</h5>
          <p>{{ step.expectedResult }}</p>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { formatDate } from '@/utils/util';

export default {
  name: 'SharedStepDetail',
  props: {
    detailItem: {
      type: Object,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatCreatedAt(date) {
      return formatDate(date, 'MM/dd/yy');
    },
    handlePrevious() {
      this.$emit('previous', this.detailItem);
    },
    handleNext() {
      this.$emit('next', this.detailItem);
    },
    handleEdit() {
      this.$emit('edit', this.detailItem);
    },
    closeDetail() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.nav-btn {
  min-width: 36px;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0 4px;
  border-radius: 4px;
  background-color: #f9fafb;
}
.nav-btn:hover {
  background-color: #e5e7eb;
}
.nav-btn:disabled {
  opacity: 0.5;
  background-color: #f9fafb;
}
.close-btn {
  cursor: pointer;
  margin-left: 4px;
}
</style>
