<template>
  <v-container fluid style="padding: 0">
    <Appbar></Appbar>
  
    <v-row> 
      <v-col :cols="isProjectMenuCollapsed ? 1 : 2">
        <project-left-menu :menuItems="menuItems" />
      </v-col>

      <v-col :cols="isProjectMenuCollapsed ? 11 : 10" style="padding-left: 0 !important">
        <router-view></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { createNamespacedHelpers } from 'vuex';

import Appbar from "@/components/Layout/Appbar/index.vue"
import ProjectLeftMenu from '@/components/Project/ProjectLeftMenu';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'ProjectLayout',

  components: {
    Appbar,
    ProjectLeftMenu,
  },

  computed: {
    ...mapGetters(['isProjectMenuCollapsed']),
    ...mapState(['currentAccount']),

    menuItems()
    {
        const handle = this.$route.params.handle
      const projectKey = this.$route.params.key
      return [
        { title: 'Milestones', icon: 'mdi-flag-outline', to: { name: 'Milestones', params: { handle:handle, key: projectKey } }, isActive: false },
        { title: 'Test plans', icon: 'mdi-calendar-check-outline', to: { name: 'TestPlans', params: { handle:handle, key: projectKey } }, isActive: false },
        { title: 'Test runs', icon: 'mdi-play-circle-outline', to: { name: 'Runs', params: { handle:handle, key: projectKey } }, isActive: false },
        { title: 'Test cases', icon: 'mdi-note-check-outline', to: { name: 'Cases', params: { handle:handle, key: projectKey } }, isActive: false },
          {
          title: 'Settings',
          icon: 'mdi-cog-outline',
          children: [
            { title: 'Templates', icon: 'mdi-view-week-outline', to: { name: 'Templates', params: { handle:handle, key: projectKey } }, isActive: false },
            { title: 'Custom fields', icon: 'mdi-tab-plus', to: { name: 'CustomFields', params: { handle:handle, key: projectKey } }, isActive: false },
            { title: 'Shared steps', icon: 'mdi-share', to: { name: 'SharedSteps', params: { handle:handle, key: projectKey } }, isActive: false },
          ],
          isActive: false
        },
      ];
    }
  },
}
</script>

<style>
 
</style>
