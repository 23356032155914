<template>
    <v-expansion-panels :mandatory="mandatoryStatus" flat>
        <v-expansion-panel>
            <v-expansion-panel-header class="pa-0" @click="onHeaderClick">
                <div class="d-flex justify-start align-center">
                    <v-checkbox v-model="parentCheckbox" :indeterminate="isIndeterminate" @change="toggleAll()"
                    @click="onParentCheckboxClick($event)"
                        class="text-body-2 cw-custom">
                        <template v-slot:label>
                            <span class="text-body-2">{{ parentLabel }}</span>
                        </template>
                    </v-checkbox>
                    <p class="text-body-2 mb-0 grey--text ml-custom">{{ description }}</p>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-for="(item) in internalItems" :key="item.id">
                    <div class="d-flex justify-start align-center">
                        <v-checkbox v-model="item.selected" :indeterminate="isIndeterminateForActions(item)"
                            @change="toggleItemAndActions(item)" class="text-body-2 cw-custom ml-5">
                            <template v-slot:label>
                                <span class="text-body-2">{{ item.name }}</span>
                            </template>
                        </v-checkbox>
                        <p class="text-body-2 mb-0 grey--text ml-custom-child">{{ item.description }}</p>
                    </div>

                    <!-- Nested actions (child checkboxes) -->
                    <div v-if="item.actions">
                        <div class="d-flex justify-start align-center" v-for="(nestedItem) in item.actions"
                            :key="nestedItem.id">
                            <v-checkbox v-model="nestedItem.selected" @change="updateParentCheckbox"
                                class="text-body-2 cw-custom ml-10">
                                <template v-slot:label>
                                    <span class="text-body-2">{{ nestedItem.name }}</span>
                                </template>
                            </v-checkbox>
                            <p class="text-body-2 mb-0 grey--text ml-custom-grand-child">{{ nestedItem.description }}</p>
                        </div>
                    </div>
                </div>

            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: 'NestedCheckboxPanel',
    props: {
        items: {
            type: Array,
            required: true,
        },
        parentLabel: {
            type: String,
            default: 'Select All',
        },
        description: {
            type: String,
            default: '',
        },
        initial: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            internalItems: this.items.map(item => ({
                ...item,
                selected: this.initial.includes(item.value), // Set selected based on the initial array for parent items
                actions: item.actions
                    ? item.actions.map(action => ({
                        ...action,
                        selected: this.initial.includes(action.value), // Set selected based on the initial array for nested actions
                    }))
                    : [],
            })),
            parentCheckbox: false,
            mandatoryStatus: true,
        };
    },
    computed: {
        isIndeterminate() {
            const selectedCount = this.internalItems.filter(item => item.selected || this.isIndeterminateForActions(item)).length;
            return selectedCount > 0 && selectedCount < this.internalItems.length;
        },
        getValueOnSelected() {
            let data = [];
            const parent = this.internalItems
                .filter(item => item.selected && item.value !== undefined && item.value !== null)
                .map(item => item.value);
            const child = this.internalItems
                .filter(item => item.actions)
                .map(item => item.actions
                    .filter(action => action.selected && action.value !== undefined && action.value !== null)
                    .map(action => action.value))
                .flat();
            data = [...parent, ...child];
            return data;
        }
    },
    watch: {
        internalItems: {
            handler() {
                this.$emit('update-items', this.getValueOnSelected);
            },
            deep: true,
        },
        initial: {
            handler() {
                this.internalItems = this.items.map(item => ({
                    ...item,
                    selected: this.initial.includes(item.value), // Set selected based on the initial array for parent items
                    actions: item.actions
                        ? item.actions.map(action => ({
                            ...action,
                            selected: this.initial.includes(action.value), // Set selected based on the initial array for nested actions
                        }))
                        : [],
                }));
            },
            deep: true,
        },
    },
    methods: {
        toggleAll() {
            const allSelected = this.parentCheckbox;
            this.internalItems.forEach(item => {
                item.selected = allSelected;
                if (item.actions) {
                    item.actions.forEach(action => (action.selected = allSelected));
                }
            });
        },
        toggleItemAndActions(item) {
            const allSelected = item.selected;
            if (item.actions) {
                item.actions.forEach(action => (action.selected = allSelected));
            }
            this.updateParentCheckbox();
        },
        updateParentCheckbox() {
            const selectedCount = this.internalItems.filter(item => item.selected || this.isIndeterminateForActions(item)).length;
            this.parentCheckbox = selectedCount === this.internalItems.length;
        },
        isIndeterminateForActions(item) {
            if (!item.actions || item.actions.length === 0) return false;
            const selectedCount = item.actions.filter(action => action.selected).length;
            return selectedCount > 0 && selectedCount < item.actions.length;
        },
        onHeaderClick() {
            this.mandatoryStatus = false;
        },
        onParentCheckboxClick(event) {
            event.stopPropagation();
        }
    },
};
</script>

<style scoped>
.ml-custom {
    margin-left: 20rem;
}
.ml-custom-child {
    margin-left: 300px;
}
.ml-custom-grand-child {
    margin-left: 280px;
}
.cw-custom {
    width: 100%;
    max-width: 160px;
}
</style>
