class SettingsService {
  getCurrentAccount() {
    const currentAccount = localStorage.getItem("currentAccount");
    return currentAccount ? JSON.parse(currentAccount) : null;
  }
  setCurrentAccount(currentAccount) {
    localStorage.setItem("currentAccount", JSON.stringify(currentAccount));
  }
  // get projects related to the current account
  getProjects() {
    const projects = localStorage.getItem("projects");
    return projects ? JSON.parse(projects) : null;
  }
  // set current projects
  setProjects(projects) {
    localStorage.setItem("projects", JSON.stringify(projects));
  }
  // get selected project
  getSelectedProject() {
    const project = localStorage.getItem("project");
    return project ? JSON.parse(project) : null;
  }
  // set selected project
  setProject(project) {
    localStorage.setItem("project", JSON.stringify(project));
  }
  getCurrentSubscription() {
    return localStorage.getItem('currentSubscription') || null;
  }
  setCurrentSubscription(currentSubscription) {
    localStorage.setItem('currentSubscription', JSON.stringify(currentSubscription));
  }
}

export default new SettingsService();
