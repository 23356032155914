<template>
  <div class="test-cases-footer">
    <div class="input-container">
			<v-icon class="input-icon">mdi-folder-plus-outline</v-icon>
      <input
        v-model="caseTitle"
        type="text"
        :placeholder="$t('createQuickTestCase')"
        @keyup.enter="createQuickTestCase"
      />
    </div>
    <button
      class="create-button"
      :disabled="!caseTitle"
      @click="createQuickTestCase"
    >
      Create
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      caseTitle: ''
    };
  },
  methods: {
    createQuickTestCase() {
      if (this.caseTitle.trim() === '') {
        return;
      }
      this.$emit('create-quick-test-case', this.caseTitle.trim());
      this.caseTitle = '';
    }
  }
};
</script>

<style scoped>
.test-cases-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid #e0e0e0;
}

.input-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 16px;
}

.input-container .input-icon {
  color: #9e9e9e;
	position: absolute;
	left: 30px;
}

.input-container input {
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
	padding-left: 34px;
	background-color: #F9F9FB;
}

.create-button {
  background-color: #0823D0;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}

.create-button:disabled {
  background-color: #CED8FE;
  color: #FFFFFF;
  cursor: not-allowed;
}
</style>
