<template>
  <v-card max-width="400" class="py-4 px-2 mx-auto mb-4 custom-card" elevation="4">
    <v-card-title class="text-h4 font-weight-medium justify-center">
      {{ plan.title }}
    </v-card-title>

    <v-card-subtitle class="justify-center black--text body-2 text--lighten-4">{{ plan.subtitle }}</v-card-subtitle>

    <v-divider></v-divider>

    <v-card-title class="my-3 text-h2 font-weight-bold black--text justify-center">
      {{ plan.price }}
    </v-card-title>

    <v-card-subtitle class="justify-center black--text body-2 text--lighten-4 pa-0">{{ measurement }}</v-card-subtitle>

    <v-list disabled>
      <v-subheader>{{ $t('benefitsInclude') }}:</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item v-for="(item, i) in plan.benefits" :key="i">
          <v-list-item-icon class="mr-1">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2 text-left">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider />

    <v-card-actions>
      <v-btn v-if="isCurrent" class="mt-2 btn" width="100%" disabled>{{ $t('currentPlan') }}</v-btn>
      <v-btn v-else class="mt-2 btn" width="100%" @click="select" outlined>
        {{ currentSubscription.rank > plan.rank ? $t('downgrade') : $t('upgrade') }}
        <v-progress-circular v-if="showLoader" indeterminate model-value="10" :size="20"></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    plan: { type: Object, required: true },
    currentSubscription: { type: Object, required: true },
    isLoading: Boolean,
  },
  data() {
    return { showLoader: false };
  },
  emits: ['select'],
  computed: {
    measurement() {
      return this.plan.type === 'org' ? 'per user/month' : 'per month';
    },
    isCurrent() {
      return this.currentSubscription.stripe_price_id === this.plan.stripe_id;
    },
  },
  methods: {
    select() {
      if (this.isLoading) return;
      else return this.$emit('select', this.plan);
    },
  },
  watch: {
    'plan.isProcessing': {
      handler: function (newValue, oldValue) {
        this.showLoader = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style>
.btn {
  background: #111827;
  color: #fff !important;
  transition: ease-in 0.3s;
}

.btn:hover {
  transform: scale(105%);
}
</style>
