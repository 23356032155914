<template>
  <v-navigation-drawer
    v-model="drawer"
    temporary
    right
    app
    width="485"
    class="position-relative pa-8 rounded-lg rounded-r-0"
  >
    <div class="d-flex flex-column justify-space-between w-full mb-6">
      <div class="d-flex align-center justify-space-between">
        <h2 class="black--text">
          {{ $t("authentication.twoFactorAuthentication") }}
        </h2>
        <v-btn icon @click="drawer = false">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>

    <v-form
      ref="form"
      v-model="validForm"
      lazy-validation
      class="mt-10"
    >
      <ChooseAuthenticationMethod
        v-if="step === 0"
        v-model="authenticationMethod"
      />

      <template v-if="isSMSVerification">
        <EnterPhoneNumber
          v-if="step === 1"
          v-model="phoneNumber"
          :total-steps="3"
          :current-step="step"
        />

        <EnterVerificationCode
          v-if="step === 2"
          v-model="verificationCode"
          :total-steps="3"
          :current-step="step"
        />

        <RecoveryCodes
          v-if="step === 3"
          :total-steps="3"
          :current-step="step"
        />
      </template>

      <template v-else>
        <ScanQRCode
          v-if="step === 1"
          :total-steps="2"
          :current-step="step"
          @change-otp="otpCode = $event"
        />

        <RecoveryCodes
          v-if="step === 2"
          :recovery-codes="recoveryCodes"
          :total-steps="2"
          :current-step="step"
        />
      </template>
    </v-form>

    <div class="text-subtitle-1 text-md-h6 font-weight-regular mt-8 w-full">
      <v-row>
        <v-col cols="12" md="6">
          <v-btn
            color="gray-100"
            width="100%"
            class="text-capitalize"
            elevation="0"
            @click="drawer = false"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-col>

        <v-col cols="12" md="6">
          <v-btn
            color="blue"
            width="100%"
            elevation="0"
            class="white--text text-capitalize"
            :disabled="isContinueBtnDisabled"
            @click="onContinue()"
          >
            {{ $t('common.continue') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import makeUserService from '@/services/api/user';

import ChooseAuthenticationMethod from './ChooseAuthenticationMethod.vue';
import EnterPhoneNumber from './EnterPhoneNumber.vue';
import EnterVerificationCode from './EnterVerificationCode.vue';
import RecoveryCodes from './RecoveryCodes.vue';
import ScanQRCode from './ScanQRCode.vue';

import { showErrorToast } from '@/utils/toast';

export default {
  name: 'SetupAuthenticationNavigation',

  components: {
    ChooseAuthenticationMethod,
    EnterPhoneNumber,
    EnterVerificationCode,
    RecoveryCodes,
    ScanQRCode,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({ currentUser: 'user/user' }),

    drawer: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    isSMSVerification() {
      return this.authenticationMethod === 'sms'
    },

    isContinueBtnDisabled() {
      if (!this.validForm) {
        return true
      }

      if (this.isSMSVerification) {
        if (this.step === 2 && this.verificationCode.length !== 6) {
          return true
        }
      } else {
        return false
      }

      return false
    }
  },

  data () {
    return {
      step: 0,
      authenticationMethod: 'sms',
      phoneNumber: '',
      verificationCode: '',
      otpCode: '',
      recoveryCodes: [],
      validForm: false,
    }
  },

  watch: {
    drawer(value) {
      if (!value) {
        return
      }

      this.step = 0
    },
  },

  methods: {
    ...mapActions({
      setUser: 'user/setUser',
      setLoading: 'setLoading',
    }),

    async onContinue() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      if (this.isSMSVerification) {
        if (this.step === 3) {
          this.drawer = false
        }
      } else {
        if (this.step === 1) {
          const verified = await this.validateAuthentication()

          if (!verified) {
            return
          }
        }

        if (this.step === 2) {
          this.drawer = false
        }
      }

      this.step++
    },

    async validateAuthentication() {
      this.setLoading({
        loading: true,
        loadingText: this.$t('authentication.verifyingCode')
      })

      try {
        const userService = makeUserService(this.$api);

        const response = await userService.validateAuthentication({
          type: 'otp',
          code: this.otpCode,
          isNewSetup: true,
        });

        this.setUser({
          ...this.currentUser,
          preferences: {
            ...this.currentUser.preferences,
            enableTwoStepAuthentication: response.data.verified,
          },
        })

        if (response.data.verified) {
          this.recoveryCodes = response.data.recoveryCodes || []
          return true
        }

        showErrorToast(this.$swal, this.$t('authentication.invalidCode'))
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Internal server error')
      } finally {
        this.setLoading({
          loading: false,
        })
      }

      return false
    }
  },
}
</script>