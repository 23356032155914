<template>
  <div>
    <v-sheet
      @click="showDialog = true"
      color="gray-100"
      class="d-flex align-center justify-center pointer"
      height="3.125rem"
      rounded="lg"
    >
      <span class="px-4">{{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      fullscreen
      max-width="400"
      persistent
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">{{ $t('filters') }}</h2>
            <v-btn
              icon
              @click="close()"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>

          <v-expansion-panels flat v-model="tagsPanel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('tagPage.type') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(tagType, index) in tagTypes" :key="index">
                  <v-checkbox
                    v-model="tagType.selected"
                    :value="tagType.selected"
                    color="blue"
                    dense
                    :label="tagType.name"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                color="gray-100"
                width="100%"
                class="text-capitalize"
                elevation="0"
                @click="clearAll"
              >{{ $t('clearAll') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                color="blue"
                width="100%"
                elevation="0"
                class="white--text text-capitalize"
                @click="apply"
              >{{ $t('apply') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { tagTypes } from '@/constants/tag.js';

export default {
  name: 'TagFilterDialog',

  data() {
    return {
      tagsPanel: 0,
      showDialog: false,
      tagTypes: tagTypes,
      oldSelectedTagTypeIds: [],
    };
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.tagTypes.forEach(tagType => {
        const alreadyChecked = this.oldSelectedTagTypeIds.includes(tagType.id)
        this.$set(tagType, 'selected', alreadyChecked ? true : false);
      });
    },
  },

  mounted() {
    this.clearAll();
  },

  methods: {
    apply() {
      const selectedTagTypes = this.tagTypes.filter(tagType => tagType.selected);
      this.oldSelectedTagTypeIds = selectedTagTypes.map(tagType => tagType.id);

      this.$emit('update-filter-types', selectedTagTypes);
      this.showDialog = false;
    },

    clearAll() {
      this.tagTypes.forEach(tagType => {
        this.$set(tagType, 'selected', false);
      });
    },

    close() {
      this.showDialog = false;
    }
  }
};
</script>
