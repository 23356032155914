<template>
  <div>
    <v-sheet @click="showDialog = true" color="gray-100" class="d-flex align-center justify-center" height="50px"
      rounded="lg">
      <span class="px-4"> {{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog v-model="showDialog" fullscreen max-width="400">
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">{{ $t('filters') }}</h2>
            <v-btn icon @click="showDialog = false">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
          <div>
            <div class="mb-1 text-start mt-4">{{ $t('numberOfTestRuns') }}</div>
            <v-range-slider style="height: 50px;" color="blue" v-model="testrun"></v-range-slider>
            <div class="d-flex align-center">
              <v-text-field value="0" color="blue" class="mr-0 custom_input"></v-text-field>
              <div class="mx-4 font-weight-bold text-h6">-</div>
              <v-text-field value="10" color="blue" class="mr-0 custom_input"></v-text-field>
            </div>
          </div>
          <div>
            <div class="mb-1 text-start mt-4">{{ $t('numberOfTestCases') }}</div>
            <v-range-slider style="height: 50px;" color="blue" v-model="testcases"></v-range-slider>
            <div class="d-flex align-center">
              <v-text-field value="2" color="blue" class="mr-0 custom_input"></v-text-field>
              <div class="mx-4 font-weight-bold text-h6">-</div>
              <v-text-field value="120" color="blue" class="mr-0 custom_input"></v-text-field>
            </div>
          </div>
          <div>
            <div class="mb-1 text-start mt-4">{{ $t('numberOfDefects') }}</div>
            <v-range-slider style="height: 50px;" color="blue" v-model="defects"></v-range-slider>
            <div class="d-flex align-center">
              <v-text-field value="3" color="blue" class="mr-0 custom_input"></v-text-field>
              <div class="mx-4 font-weight-bold text-h6">-</div>
              <v-text-field value="25" color="blue" class="mr-0 custom_input"></v-text-field>
            </div>
          </div>
          <v-expansion-panels flat v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('projectAdmin') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field color="blue" prepend-inner-icon="mdi-magnify" :placeholder="$t('search')"
                  class="custom_input mx-0"></v-text-field>
                <v-checkbox v-model="selectedTags" value="Nate" color="blue" dense label="Nate"></v-checkbox>
                <v-checkbox v-model="selectedTags" value="Alex" color="blue" dense label="Alex"></v-checkbox>
                <v-checkbox v-model="selectedTags" value="Mandy N." color="blue" dense label="Mandy N."></v-checkbox>
                <v-checkbox v-model="selectedTags" value="Maria A." color="blue" dense label="Maria A."></v-checkbox>
                <v-checkbox v-model="selectedTags" value="Boris N." color="blue" dense label="Boris N."></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-start pt-6">
            <div>{{ $t('lastChanges') }}</div>
            <div class="d-flex align-center">
              <v-menu v-model="lastChangesStart" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date.lastChanges.start" prepend-icon="mdi-calendar-blank-outline" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date.lastChanges.start" @input="lastChangesStart = false"></v-date-picker>
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">-</div>
              <v-menu v-model="lastChangesEnd" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date.lastChanges.end" prepend-icon="mdi-calendar-blank-outline" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date.lastChanges.end" :min="date.lastChanges.start"
                  @input="lastChangesEnd = false"></v-date-picker>
              </v-menu>
            </div>
            <div class="mt-4">{{ $t('creationDate') }}</div>
            <div class="d-flex align-center">
              <v-menu v-model="creationDateStart" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date.creationDate.start" prepend-icon="mdi-calendar-blank-outline" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date.creationDate.start" @input="creationDateStart = false"></v-date-picker>
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">-</div>
              <v-menu v-model="creationDateEnd" :close-on-content-click="false" transition="scale-transition" offset-y
                min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="date.creationDate.end" prepend-icon="mdi-calendar-blank-outline" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date.creationDate.end" :min="date.creationDate.start"
                  @input="creationDateEnd = false"></v-date-picker>
              </v-menu>
            </div>
            <div>
              <div class="mb-1 text-start mt-4">{{ $t('numberOfUsers') }}</div>
              <v-range-slider style="height: 50px;" color="blue" v-model="users"></v-range-slider>
              <div class="d-flex align-center">
                <v-text-field value="3" color="blue" class="mr-0 custom_input"></v-text-field>
                <div class="mx-4 font-weight-bold text-h6">-</div>
                <v-text-field value="25" color="blue" class="mr-0 custom_input"></v-text-field>
              </div>
            </div>
            <v-expansion-panels flat v-model="panel1">
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">{{ $t('users') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field color="blue" prepend-inner-icon="mdi-magnify" :placeholder="$t('search')"
                    class="custom_input mx-0"></v-text-field>
                  <v-checkbox v-model="selectedTags" value="Nate" color="blue" dense label="Nate"></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="Alex" color="blue" dense label="Alex"></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="Mandy N." color="blue" dense label="Mandy N."></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="Maria A." color="blue" dense label="Maria A."></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="Boris N." color="blue" dense label="Boris N."></v-checkbox>
                  <v-checkbox v-model="selectedTags" value="Project." color="blue" dense
                    :label="$t('show_only_project')"></v-checkbox>
                  <div class="mt-4">No Activity</div>
                  <div class="d-flex align-center">
                    <v-menu v-model="activeOnlyStart" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date.activeOnly.start" prepend-icon="mdi-calendar-blank-outline" readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date.activeOnly.start" @input="activeOnlyStart = false"></v-date-picker>
                    </v-menu>
                    <div class="mx-4 font-weight-bold text-h6">-</div>
                    <v-menu v-model="activeOnlyEnd" :close-on-content-click="false" transition="scale-transition" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date.activeOnly.end" prepend-icon="mdi-calendar-blank-outline" readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date.activeOnly.end" :min="date.activeOnly.start"
                        @input="activeOnlyEnd = false"></v-date-picker>
                    </v-menu>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="d-flex justify-end mt-5">
              <v-btn width="10rem" @click="cancel" color="gray-100" full-width class="mr-4 text-capitalize"
                elevation="0">{{
                  $t('clearAll') }}</v-btn>
              <v-btn width="10rem" color="blue" dark full-width elevation="0" class="text-capitalize" @click="apply">{{
                $t('apply')
              }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ProjectFilter",
  props: {
    selectedRoles: Array,
    selectedTags: Array,
  },
  data() {
    return {
      panel: 0,
      testrun: [0, 100],
      testcases: [0, 100],
      defects: [0, 100],
      users: [0, 100],
      panel1: 0,
      date: {
        lastChanges: {
          start: new Date().toISOString().substr(0, 10),
          end: new Date().toISOString().substr(0, 10),
        },
        creationDate: {
          start: new Date().toISOString().substr(0, 10),
          end: new Date().toISOString().substr(0, 10),
        },
        activeOnly: {
          start: new Date().toISOString().substr(0, 10),
          end: new Date().toISOString().substr(0, 10),
        },
      },
      lastChangesStart: false,
      lastChangesEnd: false,
      creationDateStart: false,
      creationDateEnd: false,
      activeOnlyStart: false,
      activeOnlyEnd: false,
      showDialog: false,
    };
  },
  methods: {
    apply() {
      this.$emit('selectedRoles', this.selectedRoles);
      this.$emit('selectedTags', this.selectedTags);
      console.log("dd", this.selectedRoles, this.selectedTags);
      this.showDialog = false;
    },
    cancel() {

    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 400px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
