import Vue from "vue";
import Vuetify from 'vuetify'
import colors from "vuetify/lib/util/colors";
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const getSavedThemeMod = () =>
  localStorage.getItem("isDarkMode") === "true" ? true : false;

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        default: "#6B7280",
        primary: "#0C2FF3",
        secondary: "#FFFFFF",
        black: "#000000",
        white: "#FFFFFF",
        danger: colors.red.accent3,
        accent: colors.amber.accent2,
        warning: colors.orange,
        background: "#1F2937",
        "purple-700": "#6d28d9",
        "gray-900": "#111827",
        "gray-800": "#1f2937",
        "gray-700": "#374151",
        "gray-500": "#6b7280",
        "gray-300": "#d1d5db",
        "gray-100": "#f3f4f6",
        "gray-light": "#f9fafb",
        "blue": "#0C2FF3",
        "blue-light": "#e6ecff",
        "green-500": "#3fb950",
        "red-100": "#f85149",
        "red-300": "#f44336",
        "yellow-100": "#ffad50",
        "star": "#ebc517"
        // accenttext: colors.amber.lighten4,
        // darkerblue: "#1d262b",
        // componentmain: colors.blueGrey.darken4,
      },
      light: {
        default: "#f3f4f6",
        primary: "#0C2FF3",
        secondary: "#111827",
        black: "#000000",
        white: "#FFFFFF",
        danger: colors.red.accent3,
        accent: colors.indigo.accent2,
        warning: colors.orange.accent3,
        background: "#FFFFFF",
        "purple-700": "#6d28d9",
        "gray-ish": "#667085",
        "gray-900": "#111827",
        "gray-800": "#1f2937",
        "gray-700": "#374151",
        "gray-500": "#6b7280",
        "gray-300": "#d1d5db",
        "gray-100": "#f3f4f6",
        "gray-light": "#f9fafb",
        "blue": "#0C2FF3",
        "blue-light": "#e6ecff",
        "blue-500": "#0969da",
        "green-500": "#3fb950",
        "red-100": "#f85149",
        "red-300": "#f44336",
        "yellow-100": "#ffad50",
        "star": "#ebc517"
        // accenttext: colors.indigo.darken2,
        // componentmain: colors.blueGrey.lighten5,
      },
    },
  },
});
