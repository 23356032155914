<template>
  <v-card class="white py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
    <div class="d-flex align-center justify-space-between">
      <h2>{{ $t('tags') }}</h2>
      <v-btn
        color="blue"
        dark
        class="text-capitalize"
        @click="$emit('create-new-tag')"
      >
        {{ $t('createTag') }}
        <v-icon class="ml-1" size="xs">mdi-plus</v-icon>
      </v-btn>
    </div>
    <div class="mt-4 d-flex">
      <v-chip
        label
        width="200px"
        :class="{ 'blue--text': status === 'active' }"
        :color="status === 'active' ? 'blue-light' : 'gray-light'"
        @click="$emit('update-status', 'active')"
      >
        <div class="font-weight-bold px-2">
          {{ $t('active') }}
          <span class="ml-2">{{ activeCount }}</span>
        </div>
      </v-chip>

      <v-chip
        class="ml-2"
        label
        width="200px"
        :class="{ 'blue--text': status === 'archived' }"
        :color="status === 'archived' ? 'blue-light' : 'gray-light'"
        @click="$emit('update-status', 'archived')"
      >
        <div class="font-weight-bold px-2">
          {{ $t('archived') }}
          <span class="ml-2">{{ archivedCount }}</span>
        </div>
      </v-chip>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'TagHeader',

  props: {
    status: String,
    activeCount: Number,
    archivedCount: Number,
  },

  methods: {
    updateStatus(value) {
      this.$emit('update-status', value);
    },
  }
}
</script>
