import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import AdminLayout from '@/Layouts/AdminLayout.vue';

const routes = [
  {
    path: '/:handle/admin',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: AdminLayout,
        children: [
          {
            path: '',
            redirect: 'users',
          },
          {
            path: 'users',
            name: 'UsersView',
            component: () => import('@/views/Admin/users/UsersView'),
          },
          {
            path: 'roles',
            name: 'Roles',
            component: () => import('@/views/Admin/Roles/Index'),
          },
          {
            path: 'tags',
            name: 'Tags',
            component: () => import('@/views/Admin/Tag/Index'),
          },
        
          {
            path: 'roles/create',
            name: 'AdminRoleCreate',
            component: () => import('@/views/Admin/Roles/RolesCreateView'),
          },
          {
            path: 'roles/:id/edit',
            name: 'AdminRoleEdit',
            component: () => import('@/views/Admin/Roles/RolesEditView'),
            props: true,
          },
          {
            path: 'roles/:id/member',
            name: 'AdminRolesMember',
            component: () => import('@/views/Admin/Roles/RolesMemberView'),
            props: true,
          },
        ],
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    requiresAuth: true,
  };
  return { ...route, meta };
});
