import ProjectLayout from '@/Layouts/ProjectLayout.vue';

const routes = [
  {
    path: '/:handle/:key/runs',
    component: ProjectLayout,
    children: [
      {
        path: '',
        name: 'Runs',
        component: () => import('@/views/Tests/Runs/RunView'),
        props: true,
      },
      {
        path: 'create',
        name: 'TestRunCreate',
        component: () => import('@/views/Tests/Runs/Create/TestRunCreateView'),
      },
      {
        path: ':id/edit',
        name: 'TestRunEdit',
        component: () => import('@/views/Tests/Runs/RunEditView'),
        props: true,
      },
      {
        path: ':id',
        name: 'TestRunCaseEdit',
        component: () => import('@/views/Tests/Runs/RunCaseEditView'),
        props: true,
      },
      {
        path: 'create/cases',
        name: 'RunAddCase',
        component: () => import('@/views/Tests/Runs/Create/RunAddCaseView'),
        props: true,
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    requiresAuth: true,
  };
  return { ...route, meta };
});
