<template>
  <div>
    <v-sheet
      @click="showDialog = true"
      color="gray-100"
      class="d-flex align-center justify-center pointer"
      height="40"
      rounded="lg"
    >
      <span class="px-4">{{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog v-model="showDialog" fullscreen max-width="400" persistent>
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">{{ $t('filters') }}</h2>
            <v-btn icon @click="close()">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="mt-6 text-start">
            <div>{{ $t('sharedStepPage.numberOfSteps') }}</div>
            <v-range-slider class="mt-4" style="height: 50px" color="blue" v-model="stepsNum" />
            <div class="d-flex align-center">
              <v-text-field
                class="mr-0 custom_input"
                v-model="stepsNum[0]"
                color="blue"
                hide-details="auto"
                filled
                dense
                height="40"
              />
              <v-icon class="mx-4">mdi-minus</v-icon>
              <v-text-field
                class="mr-0 custom_input"
                v-model="stepsNum[1]"
                color="blue"
                hide-details="auto"
                filled
                dense
                height="40"
              />
            </div>
          </div>

          <div class="mt-6 text-start">
            <div>{{ $t('sharedStepPage.referencedBy') }}</div>
            <v-range-slider class="mt-4" style="height: 50px" color="blue" v-model="referencesNum" />
            <div class="d-flex align-center">
              <v-text-field
                class="mr-0 custom_input"
                v-model="referencesNum[0]"
                color="blue"
                hide-details="auto"
                filled
                dense
                height="40"
              />
              <v-icon class="mx-4">mdi-minus</v-icon>
              <v-text-field
                class="mr-0 custom_input"
                v-model="referencesNum[1]"
                color="blue"
                hide-details="auto"
                filled
                dense
                height="40"
              />
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn color="gray-100" width="100%" class="text-capitalize" elevation="0" @click="clearAll">{{
                $t('clearAll')
              }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn color="blue" width="100%" elevation="0" class="white--text text-capitalize" @click="apply">{{
                $t('apply')
              }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'SharedStepFilterDialog',

  data() {
    return {
      tagsPanel: 0,
      showDialog: false,
      defaultStepRange: [2, 6],
      defaultReferenceRanage: [0, 553],
      stepsNum: [2, 6],
      referencesNum: [0, 553],
    };
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return;
      }
    },
  },

  mounted() {
    this.clearAll();
  },

  methods: {
    apply() {
      this.$emit('update-filter', false, this.stepsNum, this.referencesNum);
      this.showDialog = false;
    },

    clearAll() {
      this.$emit('update-filter', true, this.stepsNum, this.referencesNum);
      this.showDialog = false;
    },

    close() {
      this.showDialog = false;
    },
  },
};
</script>
