<template>
  <v-row justify="space-between">
    <v-col cols="12" sm="6" md="4" offset-md="4" offset-sm="3" class="d-flex flex-column align-center">
      <EmptyOrganizationImg />

      <div class="text-h5 mt-5">{{ $t('organization.readyCreateFirstOrganization') }}</div>
      <div class="text-body mt-2">
        {{ $t('organization.organizationAreWorkspace') }}
      </div>

      <v-btn color="blue" dark class="text-capitalize mt-5" @click="$emit('create')">
        {{ $t('organization.createOrganization') }} <v-icon class="ml-1" size="xs">mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import EmptyOrganizationImg from '@/assets/svg/empty-organization.svg';

export default {
  name: 'EmptyOrganization',

  components: {
    EmptyOrganizationImg,
  }
}
</script>