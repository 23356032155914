<template>
  <v-card class="px-2">
    <v-card-title class="mb-3">{{$t("edit profile")}}</v-card-title>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <v-form role="updateProfileForm" @submit.prevent="handleSubmit(updateProfile)">
        <v-text-field
          class="mb-3"
          name="first_name"
          :label="$t('firstName')"
          :rules="
           [
            v => !!v || 'First name is required',
            v => (v && v.length <= 10 && v.length >=2) || 'First name must be geater than 2 and  less than 10 characters',
           ]
          "
          v-model="user.first_name"
        ></v-text-field>

        <v-text-field
          class="mb-3"
          name="last_name"
          :rules="
           [
            v => !!v || 'Last name is required',
            v => (v && v.length <= 10 && v.length >=2) || 'Last name must be geater than 2 and  less than 10 characters',
           ]
          "
          dense
          outlined
          v-model="user.last_name"
        ></v-text-field>

        <v-text-field
          class="mb-3"
          name="email"
          type="email"
          :label="$t('email')"
          :rules="[
            v => !!v || 'E-mail is required',
            v => /.+@.+/.test(v) || 'E-mail must be valid'
          ]"
          dense
          outlined
          v-model="user.email"
        ></v-text-field>

        <v-row class="justify-center mt-4">
          <profile-password-dialog />
          <v-btn class="mx-1" color="primary" type="submit">{{$t('updateProfile')}}</v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-card>
</template>


<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapMutations } = createNamespacedHelpers('user');
import makeUserService from '@/services/api/user';
import jwt_decode from 'jwt-decode';
export default {
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email: '',
      },
    };
  },
  computed: {
    ...mapState({ currentUser: 'user' }),
  },
  mounted() {
    this.setForm();
  },
  methods: {
    ...mapMutations(['setUser']),
    setForm() {
      const { first_name, last_name, email } = this.currentUser;
      this.user = { first_name, last_name, email };
    },
    updateProfile() {
      const userService = makeUserService(this.$api);
      userService.updateProfile(this.user).then(response => {
        const user = jwt_decode(response.data.token);
        this.setUser(user);
        //localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem('token', response.data.token);
        this.$swal({
          title: this.$t('userProfileChanged'),
          icon: 'success',
          showConfirmButton: false,
          position: 'center',
          timer: 2000,
          toast: true,
        });
      });
    },
  },
};
</script>