export function emailValidationRules(vueInstance) {
  return [
    (v) => !!v || vueInstance.$t("emailRequired"),
    (v) =>
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(v) ||
      vueInstance.$t("validEmail"),
  ];
}

export function passwordValidationRules(vueInstance) {
  return [
    (v) => !!v || vueInstance.$t("passwordRequired"),
    (v) => v.length >= 6 || vueInstance.$t("validPassword"),
    (v) => /[A-Z]/.test(v) || vueInstance.$t("passwordUppercase"),
    (v) => /[0-9]/.test(v) || vueInstance.$t("passwordNumber"),
    (v) => /[\W_]/.test(v) || vueInstance.$t("passwordSpecial"),
  ];
}

export function phoneNumberValidationRules(vueInstance) {
  return [
    (v) => !!v || vueInstance.$t('error.requiredField'),
    (v) => /^(\+1[-\s.]?)?(\(?\d{3}\)?[-\s.]?)?\d{3}[-\s.]?\d{4}$/.test(v) || vueInstance.$t("error.invalidPhoneNumber"),
  ]
}
