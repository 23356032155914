<template>
  <v-dialog v-model="showDialog" max-width="400" persistent fullscreen>
    <v-card class="d-flex flex-column justify-space-between overflow-hidden">
      <v-card-text class="black--text mt-8">
        <div class="d-flex align-center justify-space-between">
          <h2 class="black--text">
            {{ isEditMode ? $t('templatesPage.editTemplate') : $t('templatesPage.createTemplate') }}
          </h2>
          <v-btn icon @click="showDialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form ref="form" v-model="validForm" lazy-validation class="d-flex flex-column mt-10">
          <v-row>
            <v-col cols="12" class="pb-0">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('templatesPage.template_name') }}
              </p>
              <v-text-field
                v-model="customTemplate.name"
                type="text"
                dense
                filled
                :placeholder="$t('name')"
                :rules="requiredRules"
              />
            </v-col>
          </v-row>

          <v-row class="px-3 mb-4">
            <v-row class="px-0" v-for="(item, index) in fields" :key="index">
              <CustomFieldForm
                @add-option="onAddOption"
                :custom-fields="customFields"
                :fieldIndex="index"
                :fieldItem="item"
                @remove-field="removeItem"
              />
            </v-row>
          </v-row>

          <v-row>
            <v-col class="d-flex flex-row justify-end">
              <v-menu offset-y left :nudge-bottom="4">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="#061AAE"
                    class="text-capitalize font-weight-bold white--text rounded-lg"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                  >
                    {{ $t('templatesPage.add_field') }}
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <!-- <v-list-item :key="1" @click="handleAddExistingFieldClick">
                      {{ $t('templatesPage.add_exist_fields') }}
                  </v-list-item> -->
                  <v-list-item :key="2" @click="handleAddCustomFieldClick">
                    {{ $t('templatesPage.add_new_custom_field') }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-8">
        <v-row>
          <v-col cols="6">
            <v-btn color="gray-100" width="100%" class="text-capitalize" elevation="0" @click="onCancel()">
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="blue"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              :disabled="!validForm"
              @click="onCreate()"
            >
              {{ isEditMode ? $t('save') : $t('create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomFieldForm from '@/components/Admin/CustomTemplates/CustomFieldForm.vue';
import { uuid } from 'vue-uuid';
// import makeCustomFieldService from '@/services/api/customField';

export default {
  name: 'CreateUpdateCustomField',

  components: {
    CustomFieldForm,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      requiredRules: [(value) => !!value || this.$t('error.requiredField')],
      validForm: false,
      fields: [],
      customTemplate: {
        uid: '',
        name: '',
        customFields: [],
      },
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },

    isEditMode() {
      return !!this.customTemplate.uid;
    },
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return;
      }

      this.customTemplate = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        customFields: this.data.customFields || [],
      };

      this.fields = this.data.customFields || [];

    },
  },
  methods: {
     getCustomFields(handle) {
      // const customFieldService = makeCustomFieldService(this.$api);
      // await customFieldService.getCustomFields(handle).then((response) => {
      //   this.customFields = response.data;
      // });
    },
    handleAddCustomFieldClick() {
      const newItem = {
        id: uuid.v4(),
        name: '',
        dataType: 'text',
        defaultValue: '',
        options: [],
        default_date: new Date().toISOString().substr(0, 10),
      };

      this.fields.push(newItem);
    },
    handleAddExistingFieldClick() {
      const newItem = {
        name: '',
        dataType: 'existing_field',
        defaultValue: [],
        default_date: new Date().toISOString().substr(0, 10),
      };

      this.fields.push(newItem);
    },
    onAddOption(field) {
      const index = this.fields.indexOf(field);

      const options = [...field.options, ''];

      const newField = {
        id: field.id,
        name: field.name,
        dataType: field.dataType,
        defaultValue: field.defaultValue,
        options,
      };

      this.fields.splice(index, 1, newField);
    },

    removeItem(selectedItem) {
      console.log(selectedItem);
      this.fields = this.fields.filter((item) => item != selectedItem);
      console.log(this.fields);
    },

    onCreate() {
      const isValidForm = this.$refs.form.validate();

      if (!isValidForm) {
        return;
      }

      this.customTemplate.customFields = this.fields;

      this.$emit(this.isEditMode ? 'update-template' : 'create-template', this.customTemplate);
    },

    onCancel() {
      this.$emit('close-dialog');
    },
  },
  created() {
    // await this.getCustomFields(this.$route.params.handle)
  },
};
</script>

<style lang="scss" scoped>
.font-16 {
  font-size: 16px;
}

.bg-color-grey {
  background-color: #f9fafb;
}
.custom-switch {
  ::v-deep .v-input--switch__thumb {
    width: 24px;
    height: 24px;
    top: 0;
    right: 2px;
  }

  ::v-deep .primary--text {
    background-color: #ffffff !important; /* Custom track color when switch is on */
  }

  ::v-deep .primary--text.v-input--switch__track {
    background-color: #0000ff !important; /* Custom thumb color */
    opacity: 1;
  }
}

.bg-f9f9fb {
  background-color: #f9fafb;
}

.swal-back {
  width: 440px;
}
</style>
