<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    @click:row="$emit('view', $event)"
  >
    <template v-slot:[`item.priority`]="{ item }">
      <span :style="{ color: getPriorityItem(item.priority)?.color || '#000000de' }">{{ getPriorityItem(item.priority)?.label || '' }}</span>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <span :style="{ color: getStatusItem(item.status)?.color || '#000000de' }">{{ getStatusItem(item.status)?.label || '' }}</span>
    </template>

    <template v-slot:[`item.integration`]="{ item }">
      <span class="text-capitalize">{{ item.integration }}</span>
    </template>

    <template v-slot:[`item.updated_at`]="{ item }">
      <span>{{ formatUpdateddAt(item.updated_at) }}</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <div class="d-flex justify-space-between">
        <v-btn
          icon
          color="primary"
          @click="$emit('edit', item)"
        >
          <EditIcon />
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { formatDate } from '@/utils/util';

import EditIcon from '@/assets/svg/edit.svg';

import { priorityList, statusList } from '@/constants/defect.js';

export default {
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
  },

  components: {
    EditIcon,
  },

  methods: {
    getPriorityItem(priority) {
      return priorityList.find(item => item.value === priority)
    },

    getStatusItem(status) {
      return statusList.find(item => item.value === status)
    },

    formatUpdateddAt(updatedAt) {
      return formatDate(updatedAt, 'MM/dd/yy')
    }
  }
}
</script>
