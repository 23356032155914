<template>
  <v-card class="elevation-0 my-1 bg-transparent" outlined>
    <v-card-text>
      <v-row align="center">
        <v-col cols="6" align="left">
          <v-list-item text>
            <v-list-item-avatar>
              <img v-if="org.avatar_url" :src="org.avatar_url" />
              <v-icon v-else large>mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-blue-500 font-weight-bold">
                <a :href="'/settings/orgs/' + org.handle">{{org.name}}</a>
              </v-list-item-title>
              <v-list-item-subtitle>{{org.role_name.toLowerCase()}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6" align="right">
          <v-btn @click="$emit('leave', org)" color="red-100 " small outlined>
            <small class="font-weight-bold px-6">{{$t('leave')}}</small>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text> 
  </v-card>
</template>

<script>
export default {
  props: {
    /**
     * the user name of member
     */
    org: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>