class CredentialService {
  getUser() {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  }
  getOrgs() {
    const orgs = localStorage.getItem('orgs');
    return orgs ? JSON.parse(orgs) : [];
  }
  /**
   * store current orgs
   */
  setOrgs(orgs) {
    localStorage.setItem('orgs', JSON.stringify(orgs));
  }
  /**
   * store current user
   * @param {Object} user
   */
  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  /**
   * store current accounts
   * @param {Object} accounts
   */
}

export default new CredentialService();
