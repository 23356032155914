<template>
  <v-container>
    <!-- Member Info -->
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="6" align="left">
        <v-list-item class="d-flex align-self-center border-0 px-0 mx-0">
          <v-checkbox
            class="mx-1 my-0"
            :value="isSelected"
            :hide-details="true"
            @input="$emit('member-selected', $event)"
          />
          <v-avatar size="3rem" class="mr-10 bg-secondary">
            <v-icon v-if="!avatar">mdi-account</v-icon>
            <img v-else size="3rem" :src="avatar" />
          </v-avatar>

          <v-list-item-content class="text-body font-weight-bold text-capitalize">
            {{ username }}
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <!-- Member Actions -->
      <v-col cols="6">
        <v-row justify="end" align="center">
          <v-spacer></v-spacer>
          <!-- Role Display -->
          <v-col sm="4" class="px-0">
            <span class="d-block text-body text-right font-weight-bold grey--text text--darken-1 text-capitalize">
              {{ role }}
            </span>
          </v-col>

          <!-- Settings Menu (only available to admins) -->
          <v-col sm="2" v-if="isAdmin">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on"> mdi-cog</v-icon>
              </template>
              <v-list>
                <v-list-item @click="$emit('remove-member', { username, memberId })">
                  <v-list-item-title>{{ $t('removeMember') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Represents a table item for a member with actions like permissions and settings.
export default {
  props: {
    username: { type: String, required: true },
    isAdmin: { type: Boolean, default: false },
    userId: { type: String, required: true },
    avatar: {
      type: String,
    },
    role: { type: String, required: true },
    permissions: {
      type: Array,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    unselectedRoles() {
      return this.permissions.filter((perm) => perm !== this.role);
    },
  },
};
</script>

