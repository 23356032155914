import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import ProjectLayout from '@/Layouts/ProjectLayout.vue';

const routes = [
  {
    path: '/:handle/projects',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'ProjectsView',
        component: () => import('@/views/Projects/ProjectsView'),
      },
      {
        path: 'create',
        name: 'ProjectCreateView',
        component: () => import('@/views/Projects/ProjectCreateView'),
      },
      {
        path: ':key/edit',
        name: 'ProjectDetailView',
        component: () => import('@/views/Projects/ProjectDetailView'),
      },
    ],
  },
];

export default routes.map(route => {
  const meta = {
    requiresAuth: true,
  }
  return { ...route, meta }
});
