<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey">
    <template v-slot:[`item.entitiy_types`]="{ item }">
      <span>{{ item.entitiy_types.join(', ') }}</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <td class="d-flex justify-space-between align-center">
        <v-menu
          v-if="!item.deleted_at"
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="grey darken-2">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-tooltip top :disabled="isAbleToManage">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-list-item
                    v-if="!item.archived_at"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!isAbleToManage"
                    @click="$emit('edit-tag', item)"
                  >
                    <v-list-item-icon class="mr-4" >
                      <EditIcon />
                    </v-list-item-icon>
                    <v-list-item-content class="text-left">
                      <v-list-item-title>
                        {{ $t('edit') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <span>{{ $t('tagPage.noPermissionToDo', { action: $t('edit').toLowerCase() }) }}</span>
            </v-tooltip>

            <v-tooltip top :disabled="isAbleToManage">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-list-item
                    :disabled="!isAbleToManage"
                    @click="$emit('archive-tag', item)"
                  >
                    <v-list-item-icon class="mr-4" >
                      <ArchiveIcon />
                    </v-list-item-icon>
                    <v-list-item-content class="text-left">
                      <v-list-item-title>
                        {{ item.archived_at ? $t('unarchive') : $t('archive') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <span>{{ $t('tagPage.noPermissionToDo', { action: item.archived_at ? $t('unarchive').toLowerCase() : $t('archive').toLowerCase() }) }}</span>
            </v-tooltip>

            <v-tooltip top :disabled="isAbleToManage">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-list-item
                    :disabled="!isAbleToManage"
                    @click="$emit('delete-tag', item)"
                  >
                    <v-list-item-icon class="mr-4" >
                      <DeleteIcon />
                    </v-list-item-icon>
                    <v-list-item-content class="text-left">
                      <v-list-item-title class="red--text">
                        {{ $t('delete') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <span>{{ $t('tagPage.noPermissionToDo', { action: $t('delete').toLowerCase() }) }}</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { createNamespacedHelpers  } from 'vuex';

import { tagTypes } from '@/constants/tag.js';
import EditIcon from '@/assets/svg/edit.svg';
import ArchiveIcon from '@/assets/svg/archived.svg';
import DeleteIcon from '@/assets/svg/delete.svg';

const { mapState } = createNamespacedHelpers('user');

export default {
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
  },

  data() {
    return {
      tags: this.items,
    }
  },

  components: {
    EditIcon,
    ArchiveIcon,
    DeleteIcon,
  },

  computed: {
    ...mapState(['currentAccount']),

    isAbleToManage() {
      return this.currentAccount.role_name === "owner" || this.currentAccount.role_name === "admin"
    }
  },
};
</script>
