<template>
  <v-container>
    <v-row justify="end" class="mt-12">
      <v-col cols="12" md="3">
        <v-row>
          <v-col cols="12" class="elevation-2 pa-0">
            <v-list dense class="text-left pa-0">
              <v-list-item>{{ $t('orgPermission') }}</v-list-item>
              <v-divider></v-divider>

              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  @click="routeTo(i)"
                  v-for="(item, i) in navItems"
                  :key="i"
                  class="text-decoration-none white"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-badge :content="counts[item.key]">
                        <span class="mx-3">{{ item.text }}</span>
                      </v-badge>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" class="pa-0 mt-2">
            <v-card class="pa-0 elevation-2">
              <v-card-title class="text-body-1 pb-0">
                <p>{{ $t('seats') }}</p>
                <v-spacer></v-spacer>
                <p class="text-body-2">
                  {{ $t('seatsUsed', { occupied: occupiedSeats, total: totalSeats }) }}
                </p>
              </v-card-title>
              <v-sheet class="px-4 mb-3">
                <v-progress-linear rounded :value="percentageUsed"></v-progress-linear>
              </v-sheet>
              <v-divider></v-divider>
              <v-list-item :to="{ name: 'SeatManager' }" class="primary--text"> {{ $t('manageSeats') }} </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="8" v-if="membersState.isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col> -->
      <v-col cols="12" sm="12" md="8">
        <router-view @reload-sub="async () => loadSubscription()"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import makeOrgService from '@/services/api/org';
let orgService;
export default {
  data() {
    return {
      org: {},
      counts: { members: null, pending: null },
      navItems: [
        {
          route: 'MembersList',
          key: 'members',
          text: 'Members',
        },
        {
          route: 'PendingMembersList',
          key: 'pending',
          text: 'Pending Invitations',
        },
      ],
      selectedItem: 1,
      totalSeats: 0,
      occupiedSeats: 0,
    };
  },
  computed: {
    ...mapGetters({
      isOrgAdmin: 'user/isOrgAdmin',
      user: 'user/user',
      orgs: 'user/orgs',
      getOrg: 'user/getOrg',
      currentAccount: 'user/currentAccount',
    }),
    percentageUsed() {
      return Math.round((this.occupiedSeats / this.totalSeats) * 100);
    },
  },

  methods: {
    ...mapActions({
      setCurrentAccount: 'user/setCurrentAccount',
      setOrgs: 'user/setOrgs',
      initSettings: 'user/initSettings',
    }),
    routeTo(i) {
      if (this.$route.name === this.navItems[i].route) return;

      this.selectedItem = i;
      this.$router.push({ name: this.navItems[i].route });
    },
    async loadSubscription() {
      makeOrgService(this.$api)
        .getCurrentSubscription(this.org.uid)
        .then((res) => {
          const sub = res.data.subscription;
          this.totalSeats = sub.qty;
          this.occupiedSeats = sub.occupied_seats;
        })
        .catch((err) => {
          this.$swal({
            icon: 'error',
            title: this.$t('error'),
            text: err.response.data.error,
          });
        });
    },
    async getCount() {
      const res = await orgService.getOrgCount(this.org.handle);
      this.counts.members = res.data.members.toString();
      this.counts.pending = res.data.pending.toString();
    },
  },
  async mounted() {
    await Promise.all([this.loadSubscription(), this.getCount()]);
  },
  /**
   * Load members data using @org_name before the page is rendered
   */
  async created() {
    orgService = makeOrgService(this.$api);
    this.org = this.getOrg(this.$route.params.handle);
    this.selectedItem = this.navItems.findIndex((nav) => nav.route === this.$route.name);
  },
};
</script>

<style>
</style>