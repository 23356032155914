import makeHandleService from '@/services/api/handle';
import { debounce } from 'debounce';

/**
 * mix in for validating handle
 */
export const handleDuplicateMixin = {
  data: function () {
    return {
      handleError: '',
      handleAvailable: false,
      handleRequestState: {
        isLoading: false,
      },
    };
  },
  methods: {
    /**
     * check if username is being used
     * @param {String}
     */
    usernameInUse: debounce(function (username) {
      if (username.length > 2 && !this.handleRequestState.isLoading) {
        this.checkUsername(username);
      }
    }, 1000),
    /**
     * check if username is being used
     * @param {String}
     */
    async checkUsername(username) {
      try {
        this.handleError = '';
        this.handleRequestState.isLoading = true;
        const handleService = new makeHandleService(this.$api);
        const response = await handleService.handleInUse(username);
        if (response.data.handleInUse) {
          this.handleAvailable = false;
          this.handleError = this.$t('nameInUse', { username: username });
        } else {
          this.handleAvailable = true;
          this.handleError = '';
        }
      } catch (err) {
        this.handleError = this.$t('problemProcessingRequest');
      } finally {
        this.handleRequestState.isLoading = false;
      }
    },
  },
};
