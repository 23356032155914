<template>
  <v-card class="white pt-4 px-6 pb-0 mt-2 mb-2" rounded="lg" elevation="0" width="100%">
    <div class="d-flex flex-row align-center justify-space-between">
      <h2>{{ $t(title) }}</h2>
      <v-btn color="blue" dark class="text-none py-2 px-6" :to="{ name: 'MilestoneCreate'}">
        {{ $t(actionText) }}
        <v-icon class="pl-2" size="medium">mdi-plus</v-icon>
      </v-btn>
    </div>
    <div class="d-flex align-center justify-start ml-0 py-4">
      <v-chip
        @click="updateFilter('open')"
        :class="{ 'blue--text': filter === 'open' }"
        width="115px"
        :color="filter === 'open' ? '#e6ecff' : '#f9fafb'"
        label>
        <div class="font-weight-bold px-2"> {{ $t('Open') }} <span class="ml-2">{{ ongoingItemCount }}</span></div>
      </v-chip>
      <div class="ml-2">
        <v-chip
          @click="updateFilter('closed')"
          :class="{ 'blue--text': filter === 'closed' }"
          width="115px"
          :color="filter === 'closed' ? '#e6ecff' : '#f9fafb'"
          label>
          <div class="font-weight-bold px-2"> {{ $t('Closed') }} <span class="ml-2">{{ archivedItemCount }}</span></div>
        </v-chip>
      </div>
    </div>
    <slot name="additional-actions" />
  </v-card>
</template>

<script>
export default {
  name: 'MilestoneHeader',

  props: {
    title: String,
    actionText: String,
    filter: String,
    ongoingItemCount: Number,
    archivedItemCount: Number,
  },
  methods: {
    updateFilter(filter) {
      this.$emit('update-filter', filter);
    },
  }
};
</script>

<style scoped>
h2{
  font-weight: 900;
}

.horizontal-margin {
  margin: 0px 10px;
}

.tray-btn-margin {
  min-width: 40px !important;
  width: 40px !important;
  padding: 10px 0px !important;
}

.tray-btn-outline {
  border-radius: 8px;
  box-shadow: none;
}
</style>
