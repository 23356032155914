<template>
  <v-card class="white mt-3 test-left-menu-card" rounded="lg" elevation="0">
    <v-toolbar flat dense>
      <v-toolbar-title>
        {{ $t('testSuites') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="addTestSuite">
        <v-icon>mdi-folder-plus-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-treeview
      v-model="tree"
      :items="items"
      activatable
      item-key="id"
      open-on-click
      hoverable
      transition
      :open.sync="open"
      rounded
      selectable
      return-object
      expand-icon="mdi-chevron-down"
      on-icon="mdi-folder-outline"
      off-icon="mdi-folder-outline"
      indeterminate-icon="mdi-folder-outline"
      @update:active="setActive"
      @update:open="handleOpen"
    >
      <template v-slot:label="{ item }">
        <div v-if="item === editingSuite">
          <input
            v-model="editingSuite.name"
            type="text"
            class="new-folder-input"
            @keyup.enter="saveNewSuite"
            @blur="cancelNewSuite"
          />
        </div>
        <div v-else>
          {{ item.name }} ({{ item.count }})
        </div>
      </template>
    </v-treeview>
    <div v-if="!loading && isEmpty() && !isEditing()" class="items-center">
      <v-btn color="#F2F4F7" elevation="0" class="text-capitalize" @click="addTestSuite">
        <v-icon class="mr-1" size="xs">mdi-folder-plus-outline</v-icon> {{ $t('createTestSuite') }}
      </v-btn>
    </div>
    <div class="collapse-btn" @click="toggleTestMenu">
      <v-icon>{{ isTestMenuCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-right-bottom' }}</v-icon>
      <span v-if="!isTestMenuCollapsed" class="collapse-text">{{ $t('collapse') }}</span>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'TestLeftMenuCard',

  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
    createTestSuite: {
      type: Function,
      required: true,
    },
    handleRowClicked: {
      type: Function,
      required: true,
    }
  },

  data() {
    return {
      items: [],
      selectedItem: null,
      tree: [],
      open: [],
      editingSuite: null,
      loading: true,
    };
  },

  computed: {
    ...mapGetters(['isTestMenuCollapsed']),
  },

  watch: {
    menuItems: {
      immediate: true,
      handler(newItems) {
        this.updateItems(newItems);
      }
    },
    editingSuite: {
      immediate: true,
      handler(newVal) {
        this.open.push(this.selectedItem);
      }
    },
    selectedItem: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.handleRowClicked(this.selectedItem);
        }
      }
    }
  },

  methods: {
    ...mapMutations(['toggleTestMenu']),
    setActive(activeItems) {
      let currentItem = activeItems.length ? activeItems[0] : null;

      if (currentItem == this.editingSuite)
        return;

      this.selectedItem = currentItem;
    },
    handleOpen(openItems) {
      this.open = openItems;
      this.selectedItem = openItems.length ? openItems[0] : null;
    },
    addTestSuite() {
      this.editingSuite = {
        name: 'New Suite',
        count: 0,
        children: [],
        parent: this.selectedItem,
      };

      if (this.selectedItem) {
        const parentItem = this.selectedItem;
        if (parentItem) {
          parentItem.children.push(this.editingSuite);
        } else {
          this.items.push(this.editingSuite);
        }
      } else {
        this.items.push(this.editingSuite);
      }
    },
    async saveNewSuite() {
      let newSuite = {
        parentId: this.editingSuite.parent?.id || null,
        externalId: this.$route.params.id,
        customFields: {},
        source: this.$route.params.id,
        name: this.editingSuite.name,
      };
      await this.createTestSuite(newSuite);
      this.editingSuite = null;
    },
    cancelNewSuite() {
      if (this.editingSuite && this.editingSuite.parent) {
        const parentItem = this.editingSuite.parent;
        if (parentItem) {
          parentItem.children.pop();
        }
      } else {
        this.items.pop();
      }
      this.editingSuite = null;
    },
    async formatSuites(menuItems, parentSuite = null) {
      return await Promise.all(menuItems.map(async (suite) => ({
        id: suite.uid,
        name: suite.name,
        count: suite.children ? suite.children.length : 0,
        children: suite.children
          ? await this.formatSuites(suite.children, suite)
          : [],
        parent: parentSuite,
      })));
    },
    async updateItems(newItems) {
      this.loading = true;
      this.items = await this.formatSuites(newItems);
      this.loading = false;
    },
    isEmpty() {
      return this.items.length === 0;
    },
    isEditing() {
      return this.editingSuite !== null;
    },
  },
};
</script>

<style>
.test-left-menu-card {
  position: relative;
  height: calc(100vh - 100px);
  transition: width 0.3s;
}
.text-left {
  text-align: left;
}
.collapse-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
}
.collapse-btn .collapse-text {
  margin-left: 8px;
}
.test-left-menu-card.collapsed .collapse-btn .collapse-text {
  display: none;
}
.test-left-menu-card.collapsed .collapse-btn {
  justify-content: center;
}
</style>
