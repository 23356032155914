<template>
  <v-sheet class="project-item" @click="cardClick" rounded="lg" p="4" color="#f9fafb">
    <div class="d-flex align-center justify-space-between py-4 px-2">
      <div class="d-flex gap-4">
        <div class="d-flex">
          <v-btn icon class="mr-2" @click="toggleStar(item)">
            <v-icon v-if="item.customFields.star" color="star">mdi-star</v-icon>
            <v-icon v-else color="gray-ish">mdi-star-outline</v-icon>
          </v-btn>
          <v-avatar class="mr-2" size="40">
            <img :src="item.logo || require('@/assets/png/project.png')" width="100%" alt="logo">
          </v-avatar>
        </div>
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.name }}
          </div>
          <div>
            {{ item.customFields.description }}
          </div>
        </div>
      </div>
      <div class="d-flex align-center">
        <div class="mr-2 text-start" v-if="filterItems.length > 0 && filterItems[filterItems.length - 2].checked">
          <div class="custom_color text-body-2">
            {{ $t('lastChanges') }}:
          </div>
          <div class="font-weight-bold text-end">
            {{ formattedDate(item.updatedAt) }}
          </div>
        </div>
        <v-menu content-class="custom_ele elevation-0" nudge-bottom="35" left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon color="gray-ish">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="onEdit(item)" class="pointer">
              <EditIcon />
              <v-list-item-content class="ml-2">{{ $t('edit') }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="item.customFields.status==='active'" @click="onArchive(item)" class="pointer">
              <ArchieveIcon />
              <v-list-item-content class="ml-2">{{ $t('archive') }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="item.customFields.status==='archived'" @click="onUnArchive(item)" class="pointer">
              <ArchieveIcon />
              <v-list-item-content class="ml-2">{{ $t('unarchive') }}</v-list-item-content>
            </v-list-item>
            <v-list-item @click="onDelete(item)" class="pointer">
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">{{ $t('delete') }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-sheet color="#f3f4f7" rounded="lg" class="py-2 px-5 mx-4 text-start align-center d-flex justify-space-between">
      <div>
        <span class="font-weight-bold">{{ item.testCount }}</span> <span class="custom_color">{{ $t('testRuns') }}</span>
      </div>
      <div v-if="filterItems.length > 0 && filterItems[filterItems.length - 6].checked">
        <span class="font-weight-bold">{{ item.casesCount }}</span> <span class="custom_color">{{ $t('testCases') }}</span>
      </div>
      <div v-if="filterItems.length > 0 && filterItems[filterItems.length - 5].checked">
        <span class="font-weight-bold">{{ item.defectCount }}</span> <span class="custom_color">{{ $t('defects') }}</span>
      </div>
    </v-sheet>
    <div class="d-flex align-center justify-space-between py-2 px-5 w-full py-10">
      <div>
        <v-row class="pl-3">
          <div v-for="(pic, imgIndex) in item.images" :key="imgIndex">
            <v-avatar class="ml-n2 custom_border" size="30">
              <img :src="pic" />
            </v-avatar>
          </div>
          <v-avatar class="font-weight-bold custom_color ml-n2" color="#ebecf0" size="30" v-if="item.showCount">
            +{{ item.count }}
          </v-avatar>
        </v-row>
      </div>
      <div v-if="filterItems.length > 0 && filterItems[filterItems.length - 4].checked">
        <div class="custom_color text-body-2">{{ $t('projectAdmin') }}:</div>
        <div class="font-weight-bold">{{ item.projadmin }}</div>
      </div>
      <div v-if="filterItems.length > 0 && filterItems[filterItems.length - 3].checked">
        <div class="custom_color text-body-2">{{ $t('creationDate') }}:</div>
        <div class="font-weight-bold">{{ formattedDate(item.created_at) }}</div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import ArchieveIcon from '@/assets/svg/archived.svg';
import { formattedDate } from '@/utils/util';

export default {
  components: {
    DeleteIcon,
    EditIcon,
    ArchieveIcon
  },
  props: {
    item: Object,
    filterItems: Array
  },
  methods: {
    formattedDate,
    cardClick() {
      this.$emit('select-item', this.item);
    },
    toggleStar(item) {
      this.$emit('toggle-star', item);
    },
    onEdit(item) {
      this.$emit('edit-item', item);
    },
    onArchive(item) {
      this.$emit('archive-item', item);
    },
    onUnArchive(item) {
      this.$emit('unarchive-item', item);
    },
    onDelete(item) {
      this.$emit('delete-item', item);
    },
  },
};
</script>

<style>
  .project-item:hover {
    cursor: pointer;
  }
  .project-item:hover {
    border: 1px solid #d1e1ff !important;
  }
</style>
