<template>
    <v-data-table hide-default-footer :header-props="{ 'sort-icon': 'mdi-chevron-down' }" class="custom-table mt-6"
        :headers="filteredHeaders" :items="itemsPerView" :item-key="itemKey">
        <template v-slot:[`item.name`]="{ item }">
            <td class="d-flex align-center">
                <div class="mb-2">
                    <v-checkbox v-model="item.toBeSelected" :value="item.toBeSelected" color="blue" hide-details dense
                        @change="toBeSelectedHandler"></v-checkbox>
                </div>
                <div class="text-start">
                    <div class="text-subtitle-2 font-weight-bold">{{ item.name }}</div>
                </div>
            </td>
        </template>
        <template>
        </template>
        <template v-slot:[`item.testcases`]="{ item }">
            <td class="d-flex align-center">
                <div class="text-start">
                    <div class="text-subtitle-3">{{ item.testcases }} test cases</div>
                </div>
            </td>
        </template>
        <template v-slot:[`item.testruns`]="{ item }">
            <td class="d-flex align-center">
                <div class="text-start">
                    <div class="text-subtitle-2 font-weight-bold">{{ item.testruns }} test runs</div>
                </div>
            </td>
        </template>
        <template v-slot:[`item.progress`]="{ item }">
            <div class="d-flex flex-row align-center justify-space-between">
                <v-progress-linear rounded :color="item.progress > 70 ? '#F2284E' : '#0c2ff3'" background-color="#F2F4F7"
                    :height="8" :value="item.progress" />
                <span class="font-weight-regular ml-3">{{ item.progress }}%</span>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                <v-btn icon>
                    <v-icon color="gray-500" @click="$emit('updateUser', item)">{{ tableFilter === 'all' ?
                        'mdi-plus-box-outline' : 'mdi-minus-box-outline' }}</v-icon>
                </v-btn>
            </div>

        </template>
    </v-data-table>
</template>
  
<script>
export default {
    props: {
        tableFilter: {
            type: String,
            default: 'all',
        },
        usersData: {
            type: Array,
        },
        filteredHeaders: {
            type: Array,
        },
        filteredItems: {
            type:Array,
        },
        rowClass: {
            type:Function,
        }
    },
    data() {
        return {
            users: this.usersData,
            itemKey: 'name',
            isAllUserChecked: false,
            isSelectedUserChecked: false,
        };
    },
    computed: {
        itemsPerView() {
            if (this.tableFilter === 'all') {
                return this.filteredItems.filter((item) => !item.selected);
            } else {
                return this.filteredItems.filter((item) => item.selected);
            }
        },
    },
    watch: {
        tableFilter(newValue) {
            this.toggleUsers();
        },
    },
    methods: {
        toggleUsers() {
            const isSelected = this.tableFilter === 'all' ? this.isAllUserChecked : this.isSelectedUserChecked;
            this.users.forEach((user) => {
                const condition = this.tableFilter === 'all' ? !user.selected : user.selected;
                if (condition) {
                    this.$set(user, 'toBeSelected', isSelected);
                }
            });
        },
        toBeSelectedHandler() {
            const filteredUsers =
                this.tableFilter === 'all'
                    ? this.users.filter((user) => !user.selected)
                    : this.users.filter((user) => user.selected);
            const allTrue = filteredUsers.every((user) => user.toBeSelected);

            if (this.tableFilter === 'all') {
                this.isAllUserChecked = allTrue;
            } else {
                this.isSelectedUserChecked = allTrue;
            }
        },
    },
};
</script>
  