export default function makeAccessTokenService(api) {
  return {
    newUserAccessToken: function (data) {
      const accessTokenData = {
        newAccessTokenName: data.newAccessTokenName,
        expirationDate: data.expirationDate,
        orgUid: data.orgUid
      };
      return api.post(`/access_tokens/new`, accessTokenData);
    },
    getUserAccessTokens: function () {
      return api.get(`/access_tokens`);
    },
    updateAccessToken: function (data) {
      const accessTokenData = {
        newAccessTokenName: data.newAccessTokenName,
        expirationDate: data.expirationDate,
        orgUid: data.orgUid,
      };
      return api.patch(`/access_tokens/${data.uid}`, accessTokenData);
    },
    deleteUserAccessToken: function (data) {
      return api.delete(`/access_tokens/${data.access_token_id}`);
    },
    deleteUserAllAccessTokens: function () {
      return api.delete(`/access_tokens`);
    },
  };
}
