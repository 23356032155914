<template>
  <v-row justify="space-between">
    <v-col cols="12" sm="8" md="6" offset-md="3" offset-sm="2" class="d-flex flex-column align-center">
      <EmptyTagImg />

      <div class="text-h5 mt-5">{{ $t('tagPage.readyCreateFirstTag') }}</div>
      <div class="text-body mt-2">
        {{ $t('tagPage.createFirstTagNotice') }}
      </div>

      <v-btn color="blue" dark class="text-capitalize mt-3" @click="$emit('create-new-tag')">
        {{ $t('createTag') }} <v-icon class="ml-1" size="xs">mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import EmptyTagImg from '@/assets/svg/empty-tag.svg';

export default {
  name: 'EmptyTag',

  components: {
    EmptyTagImg,
  }
}
</script>