<template>
  <v-app-bar color="white" class="px-4" height="90px" elevation="0" rounded="lg">
    <router-link to="/">
      <img src="@/assets/png/logo.png" alt="logo" class="mr-6" />
    </router-link>
    <v-btn
      v-for="link in links"
      :key="link.text"
      :to="link.to"
      text
      class="text-capitalize mr-2 topbar"
      :class="{ 'active-link': link.isActive }" 
      @click="setActive(link.text)"
    >
      {{ $t(link.text) }}
    </v-btn>
    <v-spacer></v-spacer>
    <!--
    <v-btn icon class="mr-2">
      <BellIcon />
    </v-btn>
    -->
    <div>
      <v-avatar v-if="displayUserAccount.avatar_url" size="40px">
        <img :src="displayUserAccount.avatar_url" :alt="displayUserAccount.name" />
      </v-avatar>
      <v-avatar v-else color="blue" size="40px">
        <span class="white--text">
          {{ displayUserAccount.initials }}
        </span>
      </v-avatar>
      <v-avatar
        v-if="currentAccount.name != displayUserAccount.name && currentAccount.avatar_url"
        size="20px"
        class="org-avatar"
      >
        <img :src="currentAccount.avatar_url" :alt="currentAccount.name" />
      </v-avatar>
      <v-avatar v-else-if="currentAccount.name != displayUserAccount.name" color="blue" size="20px" class="org-avatar">
        <span class="white--text">
          {{ initials }}
        </span>
      </v-avatar>
    </div>

    <v-menu offset-y v-model="menuOpen" min-width="200">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center pl-4" v-bind="attrs" v-on="on">
          <div class="d-flex flex-column align-start ml-2">
            <span class="black--text font-weight-medium mr-2">{{ displayUserAccount.name }}</span>
            <span v-if="currentAccount.name != displayUserAccount.name" class="caption grey--text lighten-1">
              {{ currentAccount.name }}</span
            >
          </div>
          <v-icon>{{ menuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </div>
      </template>
      <v-list>
        <v-subheader>{{ $t('account_menu.personal_account') }}</v-subheader>

        <v-list-item @click="selectHandle(displayUserAccount)">
          <v-list-item-title class="d-flex align-center">
            <v-avatar v-if="displayUserAccount.avatar_url" size="32px">
              <img :src="displayUserAccount.avatar_url" :alt="displayUserAccount.name" />
            </v-avatar>
            <v-avatar color="blue" v-else size="32px">
              <span class="white--text">
                {{ displayUserAccount.initials }}
              </span>
            </v-avatar>
            <div class="d-flex flex-column align-start ml-2">
              <span>{{ displayUserAccount.name }}</span>
            </div>
          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>
        <v-subheader v-if="displayableAccounts.length > 0">{{ $t('account_menu.switch_workspace') }}</v-subheader>
        <v-list v-if="displayableAccounts.length > 0" max-height="200px" class="overflow-auto custom-scrollbar">
          <v-list-item @click="selectHandle(account)" v-for="account in displayableAccounts" :key="account.handle">
            <v-list-item-title class="d-flex align-center">
              <v-avatar v-if="account.avatar_url" size="32px">
                <img :src="account.avatar_url" :alt="account.name" />
              </v-avatar>
              <v-avatar color="blue" v-else size="32px">
                <span class="white--text">
                  {{ account.initials }}
                </span>
              </v-avatar>
              <div class="d-flex flex-column align-start ml-2">
                <span>{{ account.name }}</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>

        <v-list-item :to="{ name: 'Organizations' }">
          <v-list-item-title class="d-flex align-center">
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span>{{ $t('account_menu.add_workspace') }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item :to="{ name: 'Account' }">
          <v-list-item-title class="d-flex align-center">
            <v-icon class="mr-2">mdi-cog-outline</v-icon>
            <span>{{ $t('settings') }}</span>
          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="logout">
          <v-list-item-title class="d-flex align-center">
            <v-icon class="mr-2" color="red">mdi-logout</v-icon>
            <span style="color: red">{{ $t('logout') }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SettingsService from '@/services/local/settings';
import makeAuthService from '@/services/api/auth';
import makeUserService from '@/services/api/user';
//import BellIcon from '@/assets/svg/bell.svg';

const { mapActions, mapGetters, mapState, mapMutations } = createNamespacedHelpers('user');
import { DB_NAME } from '@/constants/dashboardConstants';

let authService;
let userService;

export default {
  name: 'Appbar',
  components: {
    //BellIcon
  },
  computed: {
    ...mapGetters(['orgs', 'isAuthenticated', 'userName']),
    ...mapState(['user', 'currentAccount']),
    handle() {
      if (this.$route.params?.handle) {
        return this.$route.params.handle;
      } else if (this.currentAccount.handle) {
        return this.currentAccount.handle;
      }
      return this.$store.state.user.user.handle;
    },
    initials() {
      if (this.currentAccount.handle === this.user.handle) {
        return `${this.user.firstName[0]}${this.user.lastName[0]}`;
      }
      return `${this.currentAccount.name[0]}${this.currentAccount.name.at(-1)}`;
    },
    displayableAccounts() {
      const accounts = [];

      if (!this.currentAccount) {
        return accounts;
      }

      const orgsToDisplay = this.orgs
        .filter((org) => org.handle !== this.currentAccount.handle)
        .map((org) => ({
          name: org.name,
          handle: org.handle,
          avatar_url: org.avatar_url,
          type: org.type,
          role_name: org.role,
          initials: `${org.name[0]}${org.name.at(-1)}`,
        }));

      accounts.push(...orgsToDisplay);

      return accounts;
    },

    displayUserAccount() {
      const userAccount = {
        name: `${this.user?.firstName} ${this.user?.lastName}`,
        handle: this.user.handle,
        avatar_url: this.user.avatar_url,
        type: 'user',
        role_name: 'owner',
        initials: `${this.user.firstName[0]}${this.user.lastName[0]}`,
      };
      return userAccount;
    },
  },
  data() {
    return {
      links: [],
      menuOpen: false,
    };
  },
  mounted() {
    this.updateLinks(this.handle);
  },
  created() {
    authService = makeAuthService(this.$api);
    userService = makeUserService(this.$api);
  },
  methods: {
    ...mapActions(['initSession']),
    ...mapMutations(['setUser', 'setOrgs', 'setCurrentAccount']),
    updateLinks(handle, key) {
      let handleName = handle || this.handle;

      const currentPage = this.$router.history.current;
      if (key) {
        currentPage.params.key = key;
      }
      this.links = [
        {
          text: 'project',
          to: { name: 'ProjectsView', params: { handle: handleName } },
          isActive: true,
        },
        // {
        //   text: 'dashboard',
        //   to: { name: 'DashboardMain', params: { ...currentPage.params, handle: handle } },
        //   isActive: false,
        // },
        //{
        //  text: 'reports',
        //  to: { name: 'Reports', params: { ...currentPage.params, handle: handle } },
        //  isActive: false
        //},
        {
          text: 'administration',
          to: { name: 'UsersView', params: { ...currentPage.params, handle: handleName } },
          isActive: false,
        },
        //{
        //  text: 'integration',
        //  to: { name: 'Integrations', params: { ...currentPage.params, handle: handle } },
        //  isActive: false
        //},
      ];
    },
    setActive(selectedLink) {
      this.links.forEach((link) => {
        link.isActive = link.text === selectedLink;
      });
    },
    goToSettings() {
      this.$router.push({ name: 'Settings' });
    },
    selectHandle(account) {
      let selectedHandle = {
        handle: account.handle,
        name: account.name,
        type: account.type,
        role_name: account.role_name,
        avatar_url: account.avatar_url,
      };

      const currentPage = this.$router.history.current;
      // Handle settings pages differently
      if (
        currentPage.path.indexOf('settings') >= 0 &&
        (!currentPage.params.handle || selectedHandle.handle === this.$store.state.user.user.handle)
      ) {
        // If we're going to the user settings page or the org page
        if (selectedHandle.handle === this.$store.state.user.user.handle) {
          this.$router.replace({
            name: 'Account',
          });
        } else {
          this.$router.replace({
            name: 'OrgAccount',
            params: { handle: selectedHandle.handle },
          });
        }
      } else {
        if (currentPage.params?.handle === this.currentAccount.handle) {
          this.$router.replace({
            name: currentPage.name,
            params: { ...currentPage.params, handle: selectedHandle.handle },
          });
        }
      }
      this.setCurrentAccount(selectedHandle);
      SettingsService.setCurrentAccount(selectedHandle);
      
    },
    logout() {
      this.setCurrentAccount(null);
      this.setUser(null);
      this.setOrgs(null);
      localStorage.clear();
      this.$router.push('/login').catch((e) => {
        console.log(e);
      });
      indexedDB.deleteDatabase(DB_NAME);
    },

    getOrgInitials(org) {
      return `${org.name.at(0)}${org.name.at(-1)}`;
    },
  },
  watch: {
    $route(to, from) {
      this.setActive(to.name);
      this.updateLinks(to.params.handle, to.params.key);
    },
  },
};
</script>

<style>
.org-avatar {
  height: 1.2rem;
  min-width: 1.2rem;
  position: absolute !important;
  transform: translate(-15px, 21px) !important;
  font-size: 11px;
}

/* Custom scrollbar styles for the specific container */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f9f9fb;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #eaecf0;
}

.topbar.v-btn--active {
  color: blue !important; 

}


</style>
