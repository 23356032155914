<template>
  <v-row justify="space-between">
    <v-col cols="12" sm="8" md="6" offset-md="3" offset-sm="2" class="d-flex flex-column align-center ga-3">
      <EmptyTagImg />

      <div class="text-h5 mt-6 font-weight-bold">{{ title }}</div>
    

      <v-btn color="blue" dark class="text-capitalize mt-5" @click="$emit('action')">
        {{ btnText }} <v-icon class="ml-1" size="xs">mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import EmptyTagImg from '@/assets/svg/empty-step.svg';
import Placeholder from '@/components/base/Placeholder.vue';

export default {
  name: 'Placeholder',

  components: {
    EmptyTagImg,
  },

  props: {
    title: String,
    btnText: String
  }
};
</script>
