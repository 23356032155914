<template>
  <!-- header section -->
  <v-col cols="12" sm="12" class="fill-height card bg-white">
    <v-dialog
      v-model="testDialog"
      max-width="594"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex justify-between">
          <h4>{{selectedResultUid ? $t('editResult') : $t('addResult') }}</h4>
          <v-btn 
            text 
            small 
            depressed 
            @click="closeTestDialog"
          >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form class="text-left">
            <label class="text-weight-black result-form-label">Status</label>
            <v-select 
              v-model="executionState" 
              placeholder="Select status" 
              class="rounded-lg mb-4" 
              :items="executionsStatus" 
              :hide-details="true" 
              dense 
              filled  
              :menu-props="{'offset-y': true}"
            >
            </v-select>
            <label class="text-weight-black result-form-label">{{$t('addComment')}}</label>
            <VueEditor 
              v-model="executionComment" 
              :editorToolbar="customToolbar" 
              class="mb-4" 
            />
            <div class="result-attachments" v-if="!selectedResultUid">
              <label class="text-weight-black result-form-label">{{$t('addAttachment')}}</label>
              <label for="input-file" id="input-file-browser" class="mb-4">
                <div>
                  <p>{{$t('browseFile')}}</p>
                </div>
              </label>
              <v-file-input
                class="d-none"
                id="input-file"
                multiple
                loading
                v-model="files"
                accept="image/png, image/jpeg, image/bmp"
                counter
              ></v-file-input>
              <div class="images-preview" v-if="imagesPreview.length">
                <div class="image-preview" v-for="(item, index) in imagesPreview" :key="index">
                  <img :src="item"  alt="">
                  <v-btn @click="deleteImg(index)" icon x-small depressed><v-icon small>mdi-close</v-icon></v-btn>
                </div>
              </div>
            </div>
            <div class="test-result-actions d-flex justify-between">
              <v-btn
                dark
                large
                color="gray-100"
                class="text-capitalize font-weight-bold black--text mr-4 mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                @click="closeTestDialog"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                dark
                large
                color="blue"
                class="text-capitalize font-weight-bold mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                @click="selectedResultUid ? updateResult() : addResult() "
              >
                {{selectedResultUid ? $t('editResult') : $t('addResult') }}
              </v-btn>
            </div>
          </v-form>
        
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="flex justify-space-between align-center pl-1">
      <div>
        <v-btn
          @click="testDialog = true"
          class="text-capitalize px-1 btn-custom bg-white f-color-blue px-0 toggle-btn mx-1"
          depressed
        >
          <v-icon size="16">mdi-plus</v-icon>
          {{ $t('addResult') }}
        </v-btn>
        <v-btn
          @click="handleEdit()"
          class="text-capitalize px-1 btn-custom bg-white f-color-blue px-0 toggle-btn mx-1"
          depressed
        >
          <v-icon size="16">mdi-refresh</v-icon>
          {{ $t('rerun') }}
        </v-btn>
      </div>
      <div>
        <v-btn 
          class="text-capitalize px-1 btn-custom bg-white f-color-blue px-0 toggle-btn mx-1" 
          depressed
          @click="$parent.$emit('moveItem', 'previous')"
        >
          <v-icon>mdi-arrow-left-thin</v-icon>
        </v-btn>
        <v-btn 
          class="text-capitalize px-1 btn-custom bg-white f-color-blue px-0 toggle-btn mx-1" 
          depressed
          @click="$parent.$emit('moveItem', 'next')"
        >
          <v-icon>mdi-arrow-right-thin</v-icon>
        </v-btn>
        <span class="close-btn" @click="$emit('closeDetail')"><v-icon>mdi-close</v-icon></span>
      </div>
    </div>
    <h2 class="my-5">{{ execution.name }}</h2>
    <div class="tags my-5 d-flex" v-if="execution.caseFields.tags">
      <v-chip v-for="(item, index) in execution.caseFields.tags"
        :key="index" 
        label
        small 
        class="grey lighten-3 blue-grey--text"
      >
        {{item}}
      </v-chip>
    </div>
    <h4>{{ $t('defect.overview') }}</h4>
    <div class="execution-detail my-4" id="execution-detail" >
      <v-row>
        <v-col cols="6">
          <v-select
            id="execution_status"
            :menu-props="{'offset-y': true}"
            @change="$emit('updateSelectedExecution', {property: 'status', value: execution.status})"
            :items="status"
            v-model="execution.status"
            label="Status"
            dense
            filled
            flat
            item-color="grey"
            :hide-details="true"
          >
          <template slot="selection" slot-scope="data">
            <span               
              :class="{
                'light-blue--text': data.item.toLowerCase().includes('new'),
                'amber--text text--darken-4': data.item.toLowerCase().includes('incomplete'),
                'green--text': data.item.toLowerCase().includes('passed'),
                'red--text': data.item.toLowerCase().includes('failed'),
              }"
            >
              {{data.item}}
            </span>
          </template>
          <template slot="item" slot-scope="data">
            <span 
              :class="{
                'light-blue--text': data.item.toLowerCase().includes('new'),
                'amber--text text--darken-4': data.item.toLowerCase().includes('incomplete'),
                'green--text': data.item.toLowerCase().includes('passed'),
                'red--text': data.item.toLowerCase().includes('failed'),
              }"
            >
            {{data.item}}
            </span>
          </template>
        </v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            filled
            :menu-props="{'offset-y': true}"
            @change="$emit('updateSelectedExecution', {property: 'priority', value: execution.priority})"
            label="Priority"
            :items="priorities"
            v-model="execution.priority"
            dense
            :hide-details="true"
          >
            <template slot="selection" slot-scope="data">
              <span               
                :class="{
                  'amber--text text--darken-4': data.item.toLowerCase().includes('medium'),
                  'green--text': data.item.toLowerCase().includes('low'),
                  'red--text': data.item.toLowerCase().includes('high'),
                }"
              >
                {{data.item}}
              </span>
            </template>
            <template slot="item" slot-scope="data">
              <span 
                :class="{
                  'amber--text text--darken-4': data.item.toLowerCase().includes('medium'),
                  'green--text': data.item.toLowerCase().includes('low'),
                  'red--text': data.item.toLowerCase().includes('high'),
                }"
              >
                {{data.item}}
              </span>
            </template>
        </v-select>
        </v-col>
      </v-row>
      
      <v-expansion-panels flat v-model="overviewCollapsed">
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0">
            <h4 @click="overviewCollapsed = overviewCollapsed" class="f-color-blue toggle-btn"> {{overviewCollapsed == 0 ? $t('defect.showLess') : $t('defect.showMore')}}</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <!-- <v-col cols="6">
                <div class="grey lighten-3 pa-2">
                  <h5 class="align-left">{{ $t('template') }}</h5>
                  <div class="align-left contents" v-if="execution.case_fields">
                    {{ execution.case_fields.template }}
                  </div>
                </div>
              </v-col> -->
              <v-col cols="6">
                <div class="grey lighten-3 pa-2">
                  <h5 class="align-left">{{ $t('id') }}</h5>
                  <div class="align-left contents">
                    {{ execution.externalId }}
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="grey lighten-3 pa-2">
                  <h5 class="align-left">{{ $t('lastUpdate') }}</h5>
                  <div class="align-left contents">
                    {{ execution.updatedAt }}
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="grey lighten-3 pa-2">
                  <h5 class="align-left">{{ $t('defect.assignedTo') }}</h5>
                  <div class="align-left contents">
                    {{ execution.email}}
                  </div>
                </div>
              </v-col>
            </v-row> 
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
 
    </div>
  <v-expansion-panels flat v-model="descriptionPanel" class="panel-expansion" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header class="mx-0 px-0 panel-title">
        {{ $t('description') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <!-- description contents -->
          <v-timeline dense>
            <v-expansion-panels multiple flat v-model="collapseStep">
              <v-expansion-panel 
                v-for="step in execution.steps"
                  :key="step.key">
                <v-timeline-item
                  right
                  small
                  class="timeline-item"
                >
            
                <v-expansion-panel-header>
                  <h4>{{step.title}}</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="step-item d-flex">
                    <div class="step-item-description">
                      <h5>{{ $t('descriptionLabel') }}</h5>
                      <p>{{step.description}}</p>
                      <!-- {{step.description}} -->
                    </div>
                    <div class="step-item-expected-reult">
                      <h5>{{ $t('expectedResult') }}</h5>
                      <p>{{step.expectedResult}}</p>
                    </div>
                  </div>
                  <!-- <step-item  :step-item="step"></step-item> -->
                </v-expansion-panel-content>
                
                
              </v-timeline-item>
            </v-expansion-panel>
          </v-expansion-panels>
          </v-timeline>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <v-expansion-panels flat v-model="resultHistoryPanel" class="panel-expansion result-expansion">
    <v-expansion-panel>
      <v-expansion-panel-header class="mx-0 px-0 panel-title">
        {{ $t('resultHistory') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content  v-if="filterResults && filterResults.length">
        <!-- description contents -->
          <v-timeline dense>
            <v-expansion-panels multiple flat v-model="collapseResult">
              <v-expansion-panel 
                v-for="(result, index) in filterResults"
                  :key="index">
                <v-timeline-item
                  right
                  medium
                  class="timeline-item"
                  color="grey lighten-2"
                >
                <template v-slot:icon>
                  <span class="grey--text text--darken-1 caption font-weight-medium">{{index + 1}}</span>
                </template>
            
                <v-expansion-panel-header>
                  <div class="expansion-header d-flex justify-space-between">
                    <h4 class="result-name">{{result.first_name}} {{result.last_name}}</h4>
                    <h4 class="result-date">{{result.resultCreatedAt}}</h4>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="step-item">
                    <div class="step-item-status">
                      <h5
                        :class="{
                          'light-blue--text': result.status.toLowerCase().includes('new'),
                          'amber--text text--darken-4': result.status.toLowerCase().includes('incomplete'),
                          'green--text': result.status.toLowerCase().includes('passed'),
                          'red--text': result.status.toLowerCase().includes('failed'),
                        }"
                      >{{result.status.toUpperCase()}}</h5>
                    </div>
                    <div class="step-item-comment">
                      <div v-html="result.comment"></div>
                    </div>
                    <div class="step-item-attachments" v-if="result.attachments.length">
                      <div class="images">
                        <viewer :images="result.attachments"
                          @inited="inited"
                          class="viewer d-flex flex-wrap" ref="viewer">
                          <template #default="scope">
                            <img v-for="src in scope.images" :src="src" :key="src">
                          </template>
                        </viewer>
                      </div>
                    </div>
                    <div class="step-item-action">
                    <v-btn
                      @click="resultDialog(result.resultUid, result.status, result.comment)"
                      class="text-capitalize px-1 btn-custom  f-color-blue px-2 toggle-btn mx-1"
                      depressed
                    >
                      <v-icon size="16">mdi-pencil</v-icon>
                      {{ $t('edit') }}
                    </v-btn>
                    <v-btn
                      @click="deleteResult(result.resultUid)"
                      class="text-capitalize px-1 btn-custom f-color-red px-2 toggle-btn mx-1"
                      depressed
                    >
                      <v-icon size="16">mdi-delete</v-icon>
                      {{ $t('delete') }}
                    </v-btn>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-timeline-item>
            </v-expansion-panel>
          </v-expansion-panels>
          </v-timeline>
      </v-expansion-panel-content>
      <v-expansion-panel-content v-else>
        {{$t('noResultFound')}}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  </v-col>

</template>
<script>
import { priorityColors } from '@/constants/colors.js';
import { formatDate } from '@/utils/util';
import { VueEditor } from "vue2-editor";
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "viewer-vue"
export default {
  name: 'DetailView',
  components: {
    VueEditor ,
    Viewer
  },
  props: {
    execution: Object,
    testResults: Array,
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ['link']
      ],
      files: [],
      executionsStatus: ['Passed', 'Failed', 'Pending', 'Inconclusive', 'Skipped'],
      executionState: null,
      executionComment: null,
      overviewCollapsed: false,
      descriptionPanel: null,
      resultHistoryPanel: [],
      runsPanel: null,
      runs: [],
      status: ['New', 'Incomplete', 'Passed', 'Failed'],
      priorities: ['Low','Medium','High'],
      collapseStep: [],
      collapseResult: [],
      testDialog: false,
      selectedResultUid: null
    }
  },
  computed: {
    imagesPreview(){
      const list = this.files.map(element => {
        return URL.createObjectURL(element)
      });
      return list
    },
    lastUpdated() {
      return formatDate(this.caseItem.updated_at, 'MM/dd/yy');
    },
    filterResults(){
      return this.testResults.map(item => {
        const createdFormat = new Date(item.resultCreatedAt);
        const created_at = `${createdFormat.getFullYear()}/${String(createdFormat.getMonth()+1).padStart(2,'0')}/${new String(createdFormat.getDate()).padStart(2,'0')} ${createdFormat.getHours()}:${createdFormat.getMinutes()}`

        return {
          ...item,
          resultCreatedAt:created_at
        }
      }
     )
    }
  },
  methods: {
  inited (viewer) {
      this.$viewer = viewer
    },
    show () {
      this.$viewer.show()
    },
    handleEdit() {
      this.$router.push({
        name: 'EditTestCases',
        params: { uid: this.caseItem.test_case_ref },
      });
    },
    deleteImg(index){
      this.files.splice(index,1);
    },
    addResult(){
      const payload = {
        files: this.files,
        status: this.executionState.toLowerCase(),
        comment: this.executionComment
      }

      this.$parent.$emit('addResult', payload)
      this.closeTestDialog();
    },
    updateResult(){
      const payload = {
        status: this.executionState.toLowerCase(),
        comment: this.executionComment
      }
    
      this.$parent.$emit('updateResult', this.selectedResultUid, payload)
      this.closeTestDialog();
      
    },
    resultDialog(resultUid, status, comment){
      this.executionComment = comment;
      this.executionState = status[0].toUpperCase() + status.slice(1)
      this.selectedResultUid = resultUid;
      this.testDialog = true;
    },
    deleteResult(resultUid){
      this.$parent.$emit('deleteResult', resultUid)
    },
    getPriorityColor(priority) {
      const foundPriority = priorityColors.find((color) => color.testCase === priority);
      return foundPriority?.value || 'unset';
    },
    closeTestDialog(){
      this.files = [];
      this.executionComment = null;
      this.executionState = null;
      this.selectedResultUid = null;
      this.testDialog = false;
    }
  },
}
</script>
<style scoped>
.result-form-label{
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 500 !important;
  margin-bottom: 6px !important;
  display: block !important;
}
.tags{
  gap: 8px;
}
#input-file-browser{
  display: block;
  height: 120px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #D0D5DD;
  background-color: #F9FAFB;
  color: #0C2FF3;
  font-weight: bold;
  cursor: pointer;
}
#input-file-browser:hover{
  background-color: #eeeeee;
  transition: all 0.5s;
}

</style>
<style>
/* Customize Editor */
.quillWrapper{
  background-color: #f0f0f0;
  border-radius: 16px;
}
.quillWrapper .ql-toolbar{
  border: 0px !important;
}
.quillWrapper #quill-container{
  border: 0px !important;
  height: 120px;
}
.quillWrapper .ql-toolbar .ql-formats svg{
  width: 16px !important;
}
.quillWrapper .ql-toolbar .ql-formats button{
  width: 20px;
}
.ql-editor{
  min-height: auto !important;
}
/* Result buttons */
.test-result-actions{
  gap: 16px;
}
.test-result-actions button{
  width: calc(50% - 16px) !important;
  border-radius: 6px !important;
}
.images-preview{
  margin: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.image-preview img{
  width: 60px;
  object-fit: cover;
  height: 60px;
  border: 1px dashed blue;
}
.image-preview button{
  position: absolute;
}
.execution-detail .v-text-field--filled.v-input--dense .v-label--active, .v-text-field--full-width.v-input--dense .v-label--active{
  color: #667085 !important
}
.v-text-field.v-input--is-focused>.v-input__control>.v-input__slot:after{
  display: none;
}
h2, h3, h4{
  text-align: left;
}
h5{
  color:#667085;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.collapse-btn{
  color:#0C2FF3;
}
p{
  text-align: left;
}
.flex{
  display: flex;
}
.justify-between{
  justify-content: space-between;
}
.justify-start{
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.bg-white{
  background-color: white !important;
}
.close-btn:hover{
  cursor: pointer;
}
.f-color-blue{
  color: #0C2FF3 !important;
}
.w-50{
  width: 50%;
}
.align-left{
  text-align: left;
}
.align-left .contents{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0C111D;
}
.toggle-btn{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}

.panel-title{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
/* Customize Expansion */
/* .panel-expansion{
  overflow: hidden;
} */
.panel-expansion .v-timeline-item__divider{
  min-width: auto !important;
}
.panel-expansion .v-timeline--dense:not(.v-timeline--reverse):before, .v-application--is-rtl .v-timeline--reverse.v-timeline--dense:before{
  left: 11px !important;
}
.panel-expansion .v-timeline--dense .v-timeline-item{
  justify-content: flex-end;
  gap: 8px;
}
.timeline-item h4{
  color: #0C111D;
  font-size: 14px;
}
.panel-expansion .v-timeline--dense .v-timeline-item__body{
  max-width: max-content;
  min-width: calc(100% - 24px) !important;
  background-color: #F9FAFB;
  border-radius: 8px;
  padding: 16px;
}
.panel-expansion .v-timeline--dense .v-timeline-item__body button{
  padding: 0px !important;
}
.step-item{
  gap: 8px
}
.step-item > div{
  width: calc(50% - 4px);
  flex-shrink: 0;
}
.step-item > div h5{
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.step-item > div p{
  font-size: 14px;
  color: #0C111D;
}
.result-expansion .step-item > div{
  width: 100%;
}
.btn-custom:hover::before{
  display: none;
}
/* Test Results */
.result-expansion .v-timeline-item__dot{
  box-shadow: none !important;
}
.result-expansion .expansion-header h4.result-name{
  font-weight: 500 !important;
  color: #0C111D;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
}
.result-expansion .expansion-header h4.result-date{
  color: #667085;
  font-weight: 400;
  font-size: 13px;
}
.result-expansion .step-item-attachments .viewer img{
  width: 150px;
  height: 100px;
  margin: 8px;
  border: 3px solid #888;
  cursor: pointer;
}
.result-expansion .step-item-action{
  gap: 8px;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}
</style>
