<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="filteredHeaders"
    :items="itemsPerView"
    :item-key="itemKey"
    show-select
  >

    <template v-slot:[`header.data-table-select`]="{}">
      <div class="d-flex flex-row align-center">
        <div class="mb-2">
          <v-checkbox
            v-if="tableFilter === 'all' && itemsPerView.length"
            v-model="isAllCasesChecked"
            :value="isAllCasesChecked"
            color="blue"
            hide-details
            dense
            @change="toggleCases"
          ></v-checkbox>
          <v-checkbox
            v-else-if="itemsPerView.length"
            v-model="isSelectedCaseChecked.g"
            :value="isSelectedCaseChecked.g"
            color="blue"
            hide-details
            dense
            @change="toggleCases"
          ></v-checkbox>
        </div>
      </div>
    </template>
    <template v-slot:[`item.data-table-select`]="{ item }">
      <td class="d-flex align-center">
        <div class="mb-2">
          <v-checkbox
            v-model="item.selected"
            :value="item.selected"
            color="blue"
            hide-details
            dense
            @change="onSelect($event, item)"
          ></v-checkbox>
        </div>
      </td>
    </template>

    <template v-slot:[`header.actions`]="{header}" >
      <div class="d-none">{{ header.text }}</div>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span :class=getColor(item.priority)>{{ item.priority }}</span>
    </template>
    <template v-slot:[`item.tag`]="{item}">
      <span class="custom-attribute font-weight-bold">{{ item.tag }}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <td class="d-flex justify-space-between align-center">
        <span>{{ item.email }}</span>
        <v-btn icon>
          <v-icon color="gray-500" @click="$emit('addCases', [item])">{{ tableFilter === 'all' ? 'mdi-plus-box-outline' : 'mdi-minus-box-outline' }}</v-icon>
        </v-btn>
      </td>
    </template>
  </v-data-table>
</template>

<script>

export default {
  props: {
    tableFilter: {
      type: String,
      default: 'all',
    },
    cases: {
      type: Array,
      default: () => [],
    },
    filteredHeaders: Array,
  },
  data() {
    return {
      itemKey: 'name',
      isAllCasesChecked: false,
      isSelectedCaseChecked: false,
      selectedCases: {},
    };
  },
  computed: {
    itemsPerView() {
      //if (this.tableFilter === 'all') {
      //  return this.cases.filter((item) => !item.selected);
      //} else {
      //  return this.cases.filter((item) => item.selected);
      //}
      return this.cases;
    },
  },
  watch: {
    tableFilter(newValue) {
      this.toggleCases();
    },
  },
  methods: {
    toggleCases() {
      // TODO - what does this actually do?
      const isSelected = this.tableFilter === 'all' ? this.isAllCasesChecked : this.isSelectedCaseChecked.g;
      this.cases.forEach((cse) => {
        const condition = this.tableFilter === 'all' ? !cse.selected : cse.selected;
        if (condition) {
          this.$set(cse, 'selected', isSelected);
        }
      });
    },
    getColor(priority) {
      if (priority == "High") {
        return "text-red"
      } else if (priority == "Medium") {
        return "text-yellow"
      } else {
        return "text-green"
      }
    },
    onSelect(value, selectItem) {
      if (value && !this.selectedCases[selectItem.uid]) { 
        this.selectedCases[selectItem.uid] = selectItem;
      } else if (!value && this.selectedCases[selectItem.uid]) {
        delete this.selectedCases[selectItem.uid];
      }

      const filteredCases =
        this.tableFilter === 'all' // TODO - fix filters
          ? this.cases.filter((cse) => !cse.selected)
          : this.cases.filter((cse) => cse.selected);
      const allTrue = filteredCases.every((cse) => cse.selected);

      if (this.tableFilter === 'all') {
        this.isAllCasesChecked = allTrue;
      } else {
        this.isSelectedCaseChecked.g = allTrue;
      }
    },
  },
};
</script>

<style scope>

.text-red{
  color: #EF5350;
}
.text-green{
  color: #66BB6A;
}
.text-yellow{
  color: #FFA726;
}

</style>
