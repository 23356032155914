<template>
  <div class="card flex justify-between h-40 bg-gray align-center px-3 py-1 my-1 rounded">
    <div class="flex justify-start">
      <div class="run-id">
        <v-icon size="20px">{{ iconName }}</v-icon>
        {{ runId }}
      </div>
      <div class="run-title">{{ runTitle }}</div>
    </div>
    <div class="run-status">
      <icon-link></icon-link>
    </div>
  </div>
</template>
<script>
import iconLink from '@/assets/svg/link-outline-20x20.svg'

export default {
  name: 'DefectItem',
  components: {
    iconLink,
  },
  props: {
    runId: String,
    runTitle: String,
    runStatus: String,
    iconName: String,
  },
}
</script>
<style>
.h-40{
  height: 40px;
}
.bg-gray{
  background-color: #F9FAFB;
}
.align-center{
  align-items: center;
}
.run-id{
  width: 70px !important;
  text-align: left;
  color:#667085;
}
.run-title{
  color: #0C111D;
}

</style>
