<template>
  <div>
    <v-btn @click="showModal = true" class="mx-1">{{$t('changePassword')}}</v-btn>

    <v-dialog v-model="showModal" :title="$t('changePassword')">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <v-form role="changePasswordForm" @submit.prevent="handleSubmit(changePassword)">
          <ValidationProvider rules="required|min:6" name="currentPassword">
            <v-text-field
              type="password"
              v-model="passwordForm.currentPassword"
              :rules="[
                (value) => value === '' || value === null || value.length >= 6 || 'Password must be at least 6 characters'
              ]"
              :label="$t('currentPassword')"
              outlined
              dense
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider rules="required|min:6" name="Password" vid="password">
            <v-text-field
              type="password"
              v-model="passwordForm.newPassword"
              :rules="[
                (value) => value === '' || value === null || value.length >= 6 || 'Password must be at least 6 characters'
              ]"
              :label="$t('newPassword')"
            ></v-text-field>
          </ValidationProvider>
          <ValidationProvider rules="required|confirmed:password" name="Password confirmation">
            <v-text-field
              type="password"
              v-model="passwordForm.repeatPassword"
              :rules="[
                (value) => value === '' || value === null || value.length >= 6 || 'Password must be at least 6 characters'
              ]"
              :label="$t('confirmPassword')"
            ></v-text-field>
          </ValidationProvider>
          <v-row class="justify-center border-top pt-3">
            <v-btn class="mx-1" @click="hideModal">{{$t('cancel')}}</v-btn>
            <v-btn class="mx-1" color="primary" type="submit">{{$t('change')}}</v-btn>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-dialog>
  </div>
</template>

<script>
import makeUserService from '@/services/api/user';
export default {
  data() {
    return {
      showModal: false,
      passwordForm: {
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
      },
    };
  },
  methods: {
    changePassword() {
      const userService = makeUserService(this.$api);
      userService
        .changePassword(this.passwordForm)
        .then(response => {
          if (response.status === 200) {
            this.$swal({
              title: this.$t('passwordChanged'),
              icon: 'success',
              showConfirmButton: false,
              position: 'center',
              timer: 2000,
              toast: true,
            });
            this.hideModal();
          }
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: `Error`,
            text: error.response.data.error,
            position: 'center',
          });
        });
    },
    hideModal() {
      this.showModal = false;
      this.passwordForm = {
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
      };
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
  },
};
</script>