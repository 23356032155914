<template>
  <div>
    <AccountCard :accounts="accounts" :currentAccount="currentAccount" />
    <v-list class="text-left bg-secondary">
      <template v-for="sidebar in sidebars">
        <v-list-item :key="sidebar.id" v-if="!sidebar.children" :to="nav.url">
          <v-list-item-icon>
            <v-icon>{{ sidebar.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ sidebar.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :key="sidebar.id + 'title'" v-else>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 text-gray-500">{{ sidebar.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="child in sidebar.children" :key="child.id + '-' + sidebar.id" :to="child.link" :id="child.description" :class="{ active: isActive(child.link) }" class="bg-white">
          <v-list-item-icon>
            <HomeIcon v-if="child.id === 0" :class="{ activeImg: isActive(child.link) }" />
            <AccountIcon v-else-if="child.id === 1" :class="{ activeImg: isActive(child.link) }" />
            <FolderIcon v-else-if="child.id === 2" :class="{ activeImg: isActive(child.link) }" />
            <CalendarIcon v-else :class="{ activeImg: isActive(child.link) }" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-gray-900 font-weight-medium fs-14 font-inter">{{ child.subTitle }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import HomeIcon from '@/assets/svg/home.svg';
import AccountIcon from '@/assets/svg/account.svg';
import FolderIcon from '@/assets/svg/folder.svg';
import CalendarIcon from '@/assets/svg/calendar.svg';
import AccountCard from '@/components/User/AccountCard.vue';

export default {
  props: {
    sidebars: Array,
    /**
     * account that can be accessed by the current user
     */
    accounts: {
      type: Array,
      required: false,
      default: () => [],
    },
    /**
     * current account
     */
    currentAccount: {
      type: Object,
      required: true,
    },
  },

  components: {
    HomeIcon,
    AccountIcon,
    FolderIcon,
    CalendarIcon,
    AccountCard,
  },
  methods: {
    isActive: function (link)
    {
      if (this.$route.name === 'new-token') {
        if (link.substring(10) === 'personal-access-token') {
          return true;
        } else {
          return false;
        }
      }
      return this.$route.name === link.substring(10);
    },
  },
};
</script>

<style lang="scss" scoped>
.activetext {
  color: #6d28d9;
}

.activeImg {
  path {
    stroke: #6d28d9;
  }
}

.accounts-menu>ul {
  width: 100% !important;
}
</style>