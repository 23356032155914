<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
  >
    <template v-slot:[`item.id`]="{ item, index }">
      <ColorItem
        :value="item.value"
        :default-color="defaultItems[index].value"
        @input="$emit('change-color', type, index, $event)"
      />
    </template>
  </v-data-table>
</template>

<script>
import ColorItem from './ColorItem.vue';

export default {
  name: 'ColorsTable',

  components: {
    ColorItem,
  },

  props: {
    headers: Array,
    itemKey: String,
    defaultItems: Array,
    items: Array,
    type: String,
  },
}
</script>
