import i18n from '../../i18n';
import makeTemplateService from '@/services/api/template';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

const state = {
  templates: [],
  totalTemplates: 0
};

const mutations = {
  SET_TEMPLATES: (state, templates) => (state.templates = templates),
  SET_TOTAL_TEMPLATES: (state, total) => (state.totalTemplates = total),
};

const makeActions = (api) => {
  const templatesService = makeTemplateService(api);
  return {
    get: async function ({ commit, rootState }, { handle, projectKey, params }) {
      const response = await templatesService.getTemplates(handle, projectKey, params);
      commit('SET_TEMPLATES', response.data.templates);
      commit('SET_TOTAL_TEMPLATES', Number(response.data.total ? response.data.total : 0));

      if (response.data.meta) {
        commit('SET_TOTAL_ARCHIVED', response.data.meta?.totalArchived || 0);
        commit('SET_TOTAL_ACTIVE', response.data.meta?.totalActive || 0);
      }
    },
    add: async function ({ rootState }, { swal, handle, projectKey, payload }) {
      await templatesService.createTemplate(handle, projectKey, payload);
      showSuccessToast(swal, i18n.t('success.templateCreated'));
    },
    delete: async function ({ rootState }, { swal, handle, projectKey, templateId }) {
      await templatesService.deleteTemplate(handle, projectKey, templateId);
      showSuccessToast(swal, i18n.t('success.templateDeleted'));
    },
    update: async function ({ rootState }, { swal, handle, projectKey, templateId, payload }) {
      try {
        await templatesService.updateTemplate(handle, projectKey, templateId, payload);
        showSuccessToast(swal, i18n.t('success.templateUpdated'));
      } catch (err) {
        if (err.response.status === 422) {
          showErrorToast(swal, err.response.data.message);
        }
      }
    },
  };
};

const getters = {
  templates(state) {
    return state.templates;
  },
};

export default function makeTemplate(api) {
  return {
    namespaced: true,
    state,
    mutations,
    getters,
    actions: makeActions(api),
  };
}
