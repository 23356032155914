<template>
  <div class="text-left">
    <p class="font-weight-bold text-body-1 mb-6">
      {{ $t("authentication.step", { currentStep, totalSteps }) }}
    </p>

    <p class="font-weight-bold text-body-1 mb-1">
      {{ $t("authentication.enterVerificationCode") }}
    </p>

    <p class="mb-6 text-body-1">
      {{ $t("authentication.sentVerificationCodeToPhoneNumber", { phoneNumber: '+1-***-***-8910'}) }}
    </p>

    <p class="font-weight-medium text-body-1 text-left mb-1">
      {{ $t('authentication.verificationCode') }}
    </p>
    <v-otp-input v-model="verificationCode" />
  </div>
</template>

<script>
export default {
  name: 'EnterVerificationCode',

  props: {
    value: {
      type: String,
      default: '',
    },

    totalSteps: {
      type: Number,
      default: 1,
    },

    currentStep: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    verificationCode: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },
}
</script>