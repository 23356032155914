<template>
  <v-card color="primary" dark class="elevation-0 pa-12">
    <v-img :src="require(`@/assets/png/${imgName}`)" alt="card icon" />

    <v-card-text class="pa-60">
      <p class="title">{{ headline }}</p>
      <p class="subtitle-1">{{ text }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Card',
  props: {
    headline: String,
    imgName: String,
    text: String,
  },
};
</script>


