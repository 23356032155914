<template>
  <v-container class="elevation-1 pa-1 white card height-parent position-relative">
    <v-row align-content="start">
      <v-col cols="12" align-self="start" class="v-padding-0">
        <v-toolbar flat class="toolbar-bg" v-if="coallsed">
          <v-toolbar-title ><b>{{ $t('testSuites') }}</b></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="folderFormDialog = !folderFormDialog" :disabled="!selectedProject" v-bind="attrs" v-on="on" >
                <v-icon color="#0C2FF3 !important">mdi-folder-plus-outline</v-icon>
              </v-btn>
            </template>
            <span v-if="selectedProject">{{ $t('info.addFolder') }}</span>
            <span v-else>{{ $t('info.selectProject') }}</span>
          </v-tooltip>
        </v-toolbar>
        <v-toolbar v-else flat class="toolbar-bg item-center">
          <v-btn icon @click="folderFormDialog = !folderFormDialog" :disabled="!selectedProject" v-bind="attrs" v-on="on" >
            <v-icon color="#0C2FF3 !important">mdi-folder-plus-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col cols="12" align-self="start" class="v-padding-0" v-if="coallsed">
        <v-treeview v-model="tree" :open.sync="open" :items="folders" :active.sync="active" open-on-click
          activatable rounded class="text-left" hoverable expand-icon="mdi-chevron-up" density="compact" color="rgb(24,49,230)">
          <template v-slot:prepend="{ item, open }">
            <div style="border-left: 2px red;">
              <v-icon color="yellow-100" v-if="hasChildren(item)">
                {{ open ? 'mdi-folder-open-outline' : 'mdi-folder-outline' }}
              </v-icon>
              <v-icon color="yellow-100" v-else> mdi-folder-outline </v-icon>
              {{ item.title }}
              <span v-if="hasChildren(item)">({{ item.children.length }})</span>
            </div>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  props: ['coallsed'],
  data() {
    return {
      saveError: null,
      folderFormDialog: false,
      savingInProgress: false,
      tree: [],
      open: [],
      active: [],
      folderDetail: {},
      folders: [
      {
          id: 1,
          title: 'Applications',
          children: [
            { id: 2, title: 'Calendar app' },
            { id: 3, title: 'Chrome app' },
            { id: 4, title: 'Webstorm app' },
          ],
        },
        {
          id: 5,
          title: 'Documents',
          children: [
            {
              id: 6,
              title: 'vuetify',
              children: [
                {
                  id: 7,
                  title: 'src',
                  children: [
                    { id: 8, title: 'index ts' },
                    { id: 9, title: 'bootstrap ts' },
                  ],
                },
              ],
            },
            {
              id: 10,
              title: 'material2',
              children: [
                {
                  id: 11,
                  title: 'src',
                  children: [
                    { id: 12, title: 'v-btn ts' },
                    { id: 13, title: 'v-card ts' },
                    { id: 14, title: 'v-window ts' },
                  ],
                },
              ],
            },
          ],
        },
      ]
    };
  },

  watch: {
    selectedProject() {
      this.getProjectFolders();
    },
    active() {
      this.$emit('folder-selected', this.active[0]);
    },
  },
  computed: {
    selectedProject() {
      return this.$route.params.key;
    },
    selectedOrganization() {
      return this.$store.state.user.currentAccount;
    },
  },
  methods: {
    hasChildren(item) {
      return item.children && item.children.length > 0;
    },
    updateFolder(payload) {
      this.$store
        .dispatch('folder/add', {
          swal: this.$swal,
          handle: this.$store.state.user.currentAccount.handle,
          projectKey: this.$route.params.key,
          payload: {
            ...payload,
            org: this.$store.state.user.currentAccount.uid,
            externalId: 'testfiesta',
            source: 'testfiesta',
          },
        })
        .then(() => {
          this.folderFormDialog = false;
        })
        .catch((errr) => {
          this.saveError = errr;
        });
    },
    getProjectFolders() {
      this.$store.dispatch('folder/getProjectFolders', {
        projectKey: this.$route.params.key,
        handle: this.$store.state.user.currentAccount.handle,
      });
    },
  },
  mounted() {
    if (this.selectedProject) {
      this.getProjectFolders();
    }
  },
};
</script>

<style>
  .item-center{
    display: flex;
    justify-content: center;
  }
  .toolbar-bg{
    background-color: transparent !important;
  }
  .height-parent{
    height: 100%;
  }
  .float-bottom{
    position: absolute;
    bottom: 0;
  }
  .position-relative{
    position: relative;
  }
  .card{
    border-radius: 5px;
  }
  .v-treeview-node__root {
    position: relative;
  }

  .v-treeview-node__children {
    margin-left: 10px;
  }

  .v-treeview-node {
    margin-left: 10px;
  }

  .v-padding-0{
    padding-top: 0;
    padding-bottom: 0;
  }

  /* .v-treeview-node__children:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -10px;
    width: 1px;
    background-color: #000000;
    opacity: 0.2;
  } */
  .v-treeview-node__children{
    border-left: 1px solid rgb(247,248,249);
    /* border-left: 1px solid red; */
  }
  .v-treeview-node__root .v-treeview-node__level{
    width: 5px !important;
    height: 1px;
    background-color: rgb(247, 248, 249);
    /* background-color: red; */
  }
  .v-treeview-node__root{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }
</style>
