<template>
  <div>
    <v-sheet
      @click="showDialog = true"
      color="gray-100"
      class="d-flex align-center justify-center pointer"
      height="3.125rem"
      rounded="lg"
    >
      <span class="px-4">{{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      fullscreen
      max-width="485"
      persistent
      content-class="custom-field-filter-dialog"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">{{ $t('filters') }}</h2>
            <v-btn
              icon
              @click="close()"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>

          <v-expansion-panels flat v-model="typesPanel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('customFieldPage.dataType') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(item, index) in dataTypes" :key="index">
                  <v-checkbox
                    v-model="selectedTypes"
                    :value="item.type"
                    color="blue"
                    dense
                    :label="item.name"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels flat v-model="datePanel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('creationDate') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="showStartDateCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          dense
                          filled
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          :placeholder="$t('customFieldPage.startDate')"
                        />
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :max="today"
                        @input="showStartDateCalendar = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col cols="6">
                    <v-menu
                      v-model="showEndDateCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          dense
                          filled
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          :placeholder="$t('customFieldPage.endDate')"
                        />
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        :max="today"
                        @input="showEndDateCalendar = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-if="dataSources.length > 0"
            flat v-model="sourcesPanel"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('customFieldPage.dataSource') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(item, index) in dataSources" :key="index">
                  <v-checkbox
                    v-model="selectedSources"
                    :value="item"
                    color="blue"
                    dense
                    :label="item"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                color="gray-100"
                width="100%"
                class="text-capitalize"
                elevation="0"
                @click="clearAll"
              >{{ $t('clearAll') }}</v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                color="blue"
                width="100%"
                elevation="0"
                class="white--text text-capitalize"
                @click="apply"
              >{{ $t('apply') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/util';

import { dataTypes } from '@/constants/customField.js';

export default {
  name: 'CustomFieldFilterDialog',

  props: {
    data: {
      type: Object,
      default: () => ({
        types: [],
        sources: [],
        startDate: null,
        endDate: null,
      })
    },
    dataSources: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      showDialog: false,
      typesPanel: 0,
      dataTypes: dataTypes,
      selectedTypes: [],
      sourcesPanel: 0,
      selectedSources: [],
      datePanel: 0,
      showStartDateCalendar: false,
      startDate: null,
      showEndDateCalendar: false,
      endDate: null,
    };
  },

  computed: {
    today() {
      return formatDate(new Date(), 'yyyy-MM-dd')
    }
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.selectedTypes = this.data.types || []
      this.selectedSources = this.data.sources || []
      this.startDate = this.data.startDate
      this.endDate = this.data.endDate
    },
  },

  methods: {
    apply() {
      this.$emit('update-filter-condition', {
        types: this.selectedTypes,
        sources: this.selectedSources,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.showDialog = false;
    },

    clearAll() {
      this.selectedTypes = []
      this.selectedSources = []
      this.startDate = null
      this.endDate = null
    },

    close() {
      this.showDialog = false;
    }
  }
}
</script>

<style lang="scss">
.custom-field-filter-dialog {
  .v-input.v-text-field {
    .v-input__slot {
      padding-right: 4px !important;
    }
  }
}
</style>