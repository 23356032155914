// Note: Comment API
export default function makeCommentsService(api) {
  return {
    // comments/:entity_type/:entity_uid
    getComments: async function (handle, entityType, entityUid) {
      return api.get(`${handle}/comments/${entityType}/${entityUid}`);
    },
    createComment: async function (handle, data) {
      return api.post(`${handle}/comments`, data);
    },
    deleteComment: async function (item) {
      return api.delete(`comments/${item}`);
    },
    updateComment: async function (item, payload) {
      return api.put(`comments/${item}`, payload);
    },
  };
}
