<template>
  <div>
    <v-dialog
      class="test-cases-filter-drawer"
      v-model="isOpen"
      attach
      fullscreen
      max-width="400"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6 mb-4">
            <h2 class="black--text">Export test cases</h2>
            <v-btn
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
          </div>
          <div>
            <h3 class="select-title mt-4">{{ $t('exportTo') }}</h3>
            <v-radio-group v-model="selectedItem">
              <v-radio v-for="(item, index) in exportTypes" :label="item.title" :value="item.value" :key="index"></v-radio>
            </v-radio-group>
            <h3 class="select-title mt-4">{{ $t('fields') }}</h3>
            <v-select placeholder="Choose fields" v-model="selectedFields" :items="fields" item-text="title" item-value="value" multiple filled class="round-8">
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggle"
                >
                  <v-list-item-action>
                    <v-icon :color="selectedFields.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('selectAll') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </div>
          <div class="actions-container d-flex justify-end mt-5">
            <v-btn
              width="10rem"
              color="gray-100"
              full-width
              class="mr-4 text-capitalize"
              elevation="0"
              @click="$emit('closeDialog')"
            >{{ $t('cancel') }}</v-btn>
            <v-btn
              width="10rem"
              color="blue"
              dark
              full-width
              elevation="0"
              class="text-capitalize"
            >{{ $t('export') }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ExportDialog',
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      selectedFields: [],
      selectedItem: 'csv',
      exportTypes: [
        { title: "CSV", value: "csv" },
        { title: "Excel", value: "excel" }
      ],
      fields: [
        { title: "ID", value: "id", isSelected: false },
        { title: "Name", value: "name", isSelected: false },
        { title: "Priority", value: "priority", isSelected: false },
        { title: "Description", value: "description", isSelected: false },
        { title: "Steps", value: "steps", isSelected: false },
        { title: "Expected result", value: "result", isSelected: false },
      ]
    };
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.selectAllFields) {
          this.selectedFields = []
        } else {
          this.selectedFields = this.fields.slice()
        }
      })
    },
  },
  computed: {
      selectAllFields () {
        return this.selectedFields.length === this.fields.length
      },
      selectSomeFields () {
        return this.selectedFields.length > 0 && !this.selectAllFields
      },
      icon () {
        if (this.selectAllFields) return 'mdi-close-box'
        if (this.selectSomeFields) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
};
</script>
<style>
.test-cases-filter-drawer .v-sheet {
  display: flex
}

.test-cases-filter-drawer .v-card__text {
  display: flex;
  flex-direction: column;
}

.test-cases-filter-drawer .actions-container {
  margin-top: auto !important;
}

.select-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
</style>