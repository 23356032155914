<template>
  <v-dialog
    v-model="showDialog"
    max-width="500"
    persistent
  >
    <v-card class="pa-2">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('settingsPage.newApiKey') }}
          </h2>
          <v-btn icon @click="showDialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>

        <p class="text-body-1 text-left font-weight-light mt-3">
          {{ $t('settingsPage.copyApiKeyNotice') }}
        </p>

        <p class="font-weight-medium body-2 text-left mb-1">
          {{ $t('settingsPage.apiKey') }}
        </p>

        <v-text-field
          :value="newApiKey"
          type="text"
          dense
          filled
          readonly
        >
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="copyKey"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              {{ $t('common.copy') }}
            </v-tooltip>
          </template>
        </v-text-field>

        <v-checkbox
          v-model="isSavedKey"
          :label="$t('settingsPage.savedApiKeyQuestion')"
          hide-details
          class="mt-0"
        />
      </v-card-text>

      <v-card-actions class="pb-3">
        <v-row>
          <v-col cols="6" offset="6">
            <v-btn
              color="blue"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              :disabled="!isSavedKey"
              @click="$emit('close-dialog')"
            >
              {{ $t('common.gotIt') }}
          </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { showSuccessToast, showErrorToast } from '@/utils/toast';

export default {
  name: 'NewApiKeyCopyDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    newApiKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isSavedKey: false,
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  methods: {
    copyKey() {
      navigator.clipboard
        .writeText(this.newApiKey)
        .then(() => {
          showSuccessToast(this.$swal, this.$t("settingsPage.savedApiKey"));
        }, (err) => {
          showErrorToast(this.$swal, err);
        })
    }
  }
}
</script>