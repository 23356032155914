<template>
  <v-card class="elevation-1 white">
    <v-row align="start" class="mx-1 py-10">
      <v-col cols="2" class="text-left">
        <v-avatar class="bg-secondary">
          <v-icon v-if="!currentAccount.avatar_url" large>mdi-account-circle</v-icon>
          <img v-else :src="currentAccount.avatar_url" />
        </v-avatar>
      </v-col>
      <v-col cols="10" class="text-left">
        <span>{{currentAccount.handle}}</span>
        <div v-if="currentAccount.isOrg">
          <span>{{$t("orgAccount")}}</span>
        </div>
        <div v-else>
          <span>{{$t("personalAccount")}}</span>
        </div>
      </v-col>
      <v-col cols="12">
        <v-menu id="accounts" offset-y transition="scale-transition">
          <template #activator="{ on }">
            <v-list-item v-on="on">
              <v-icon variant="primary">mdi-arrow-left-right</v-icon>
              <v-list-item-content>
                <v-list-item-title>{{$t('switchAccount')}}</v-list-item-title>
              </v-list-item-content>
              <v-icon variant="primary">mdi-menu-down</v-icon>
            </v-list-item>
          </template>
          <v-list dense>
            <v-list-item
              v-for="account in accounts"
              :key="account.uid"
              :href="account.isOrg ? '/settings/orgs/' + account.handle: '/settings/profile'"
              class="text-left text-decoration-none"
            >
              <template v-if="account && account.handle">
                <v-avatar :src="account.avatar_url">
                  <v-icon v-if="!account.avatar_url" large>mdi-account-circle</v-icon>
                  <img v-else :src="account.avatar_url" />
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{account.handle}}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    /**
     * accounts that can be accessed by the current user inlcuing orgs
     * */

    accounts: {
      type: Array,
      required: true,
    },
    /**
     * currently selected account
     */
    currentAccount: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>