import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
*/

export default function makeTagService(api) {
  return {
    getTags: function (handle) {
      return api.get(`/${handle}/tags`);
    },

    createTag: function (handle, data) {
      return api.post(`/${handle}/tags`, data);
    },

    updateTag: function (handle, { uid, ...data }) {
      return api.patch(`/${handle}/tags/${uid}`, data);
    },

    deleteTag: async function (handle, uid) {
      return api.delete(`/${handle}/tags/${uid}`);
    },
  }
}
