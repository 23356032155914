<template>
  <v-dialog
    v-model="showDialog"
    max-width="450"
    persistent
  >
    <v-card class="pa-2">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text text-left">
            {{ $t('account.deleteAccountConfirm') }}
          </h2>
          <v-btn icon @click="showDialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>

        <p class="text-body-1 text-left font-weight-light mt-3">
          {{ $t('account.willDeleteAccount') }}
          {{ $t('account.willLostData') }}
          <br>
          {{ $t('account.mustConfirmOperation') }}
        </p>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <p class="font-weight-medium body-2 text-left mb-1">
            {{ $t('password') }}
          </p>
          <v-text-field
            v-model="password"
            dense
            filled
            :type="visiblePassword ? 'text' : 'password'"
            :placeholder="$t('password')"
            :rules="requiredRules"
            :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            @click:append="visiblePassword = !visiblePassword"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-3">
        <v-row>
          <v-col cols="6">
            <v-btn
              color="gray-100"
              width="100%"
              class="text-capitalize"
              elevation="0"
              @click="showDialog = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="error"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              @click="onDeleteAccount()"
            >
              {{ $t('delete') }}
          </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteAccountConfirmDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: false,
      password: '',
      visiblePassword: false,
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  methods: {
    onDeleteAccount() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit('delete-account', this.password)
    }
  }
}
</script>