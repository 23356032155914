<template>
  <v-container class="pa-6 elevation-1 white fill-height align-start card" fluid>
    <v-row justify="start" align="start" v-if="!isBlankTable">
      <v-col cols="7" sm="7" class="search-bar-style">
        <v-text-field v-model="searchTerm" class="text-field search-field mr-3 round-8 " placeholder="Search by name, id, tags, etc." height="40" dense filled single-line >
          <template v-slot:prepend-inner>
            <iconSearch></iconSearch>
          </template>
        </v-text-field>
        <TestCasesFilter @filters="applyFilters"></TestCasesFilter>
      </v-col>
      <v-col cols="5" sm="5" class="search-bar-style setting-btn-style">
        <div class="btn-selector">
          <template>
            <div class="text-center">
              <SettingsMenu :filterItems="filteredMenuHeaders" />
            </div>
          </template>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="breadcrumb-container mt-4">
        <v-breadcrumbs :items="breadCrumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item v-if="isLastItem(item)"><b>{{ item.text }}</b></v-breadcrumbs-item>
            <v-breadcrumbs-item v-else><span style="color: #667085">{{ item.text }}</span></v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="12" sm="12">
        <v-data-table :headers="filteredHeaders" :items="isSelectedData ? selectedData : displayTableData" item-key="uid" show-select class="data-table-style" v-model="selectedRows" @input="handleSelectTestCases" :header-props="{ 'sort-icon': 'mdi-chevron-down' }" @click:row="handleClick">
          <template v-slot:[`header.actions`]="{ header }">
            <div class="d-none">{{ header.text }}</div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <span class="custom-attribute font-weight-bold">{{ item.external_id }}</span>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span class="custom-attribute font-weight-bold">{{ item.name }}</span>
          </template>
          <template v-slot:[`item.assignees`]="{ item }">
            
            <div class="d-flex align-center">
              <v-menu offset-y v-if="isDetailCollapsed">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-account</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="text-left">
                  <v-list-item 
                    v-for="(assigned,index) in assignees" 
                    :key="index" @click="$emit('updateSelectedExecution', {property: 'assignedTo', value: assigned.uid});
                    item.email=assigned.email" 
                    >
                    <v-list-item-icon>
                      <v-icon color="blue">mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{assigned.email}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <div>
                {{item.email}}
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row justify-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense class="text-left">
                  <v-list-item @click="deleteTestCase(item.uid)">
                    <v-list-item-icon>
                      <v-icon color="danger">mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleRowEdit(item.test_case_ref)">
                    <v-list-item-icon>
                      <v-icon color="info">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-col>
    

    </v-row>
    <EditDialog :isOpen="isOpenEditDialog" :priorities="priorities" :tags="tags" @closeDialog="handleCloseEditDialog" @clickSave="updateCases" />
    <ExportDialog :isOpen="isOpenExportDialog" @closeDialog="handleCloseExportDialog" />
    <ConfirmDialog :isOpen="isOpenConfirmDialog" @closeDialog="handleCloseConfirmDialog" @confirm="handleConfirm" />
        <ConfirmBulkDeleteDialog
        :title="$t('testruns.test_case.bulk_remove.title', { count: selectedRows.length })"
        :isOpen="isOpenConfirmBulkDeleteDialog" @closeDialog="handleCloseConfirmBulkDeleteDialog" @confirm="confirmBulkRemove" />

  </v-container>
</template>

<script>
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import { executionTableHeader } from '@/constants/grid';
import TestCasesFilter from '@/views/Tests/Case/Components/Filter.vue';
import EditDialog from '@/views/Tests/Case/Components/EditDialog.vue';
import ExportDialog from '@/views/Tests/Case/Components/ExportDialog.vue';
import ConfirmDialog from '@/views/Tests/Case/Components/ConfirmDialog.vue';
import ConfirmBulkDeleteDialog from '@/views/Tests/Case/Components/ConfirmBulkDeleteDialog.vue';
import iconSearch from '@/assets/svg/search-outline-16x16-gray.svg';
import makeCasesService from '@/services/api/case';
import { showSuccessToast, showAlertToast, showErrorToast } from '@/utils/toast';
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('user');
let caseService;

export default {
  components: {
    TestCasesFilter,
    EditDialog,
    ExportDialog,
    ConfirmDialog,
    iconSearch,

    SettingsMenu,
    ConfirmBulkDeleteDialog,
  },
  props: {
    isRepository: Boolean,
    caseItems: Array,
    breadCrumbs: [],
    allowAction: {
      type: Boolean,
      default: true
    },
    assignees:{
      type: Array
    },
    execution: {
      type: Object
    },
    isDetailCollapsed:{
      type: Boolean,
      default: false
    }
  },
  data()
  {
    return {
      // test state for blank page
      isBlankTable: false,
      // test state for blank page
      isOpenEditDialog: false,
      isOpenExportDialog: false,
      isOpenConfirmDialog: false,
      isOpenConfirmBulkDeleteDialog: false,
      isSelectedData: false,
      isAddedTable: false,
      openFilterDrawer: true,
      toggleSelect: false,
      testName: '',
      testTemplate: '',
      selectedRows: [],
      headers: executionTableHeader,
      searchTerm: '',
      filters: [],
      enableRestoreDefault: false,
      actionBtnShow: false,
      selectedData: [],
      states: [{ text: 'High' }, { text: 'Medium' }, { text: 'Low' }],
      priorities: [
        { value: 'High', text: 'High' },
        { value: 'Medium', text: 'Medium' },
        { value: 'Low', text: 'Low' },
      ],
      tags: [
        { value: 'test', text: '#test' },
        { value: 'billing', text: '#billing' },
        { value: 'performance', text: '#performance' },
        { value: 'navigation', text: '#navigation' },
      ],
      testTemplates: [
        { text: 'App', value: 1, externalId: 'HM-1', source: 'Test create data for App' },
        { text: 'Web', value: 2, externalId: 'HM-2', source: 'Test create data for Web' },
      ],
    };
  },
  watch: {
    selectedRows(newVal) {
      this.$emit('updateSelectedExecution', newVal);
    },
  },
  created()
  {
    caseService = makeCasesService(this.$api);
  },
  computed: {
    ...mapState(['currentAccount']),
    folderSelected()
    {
      return this.$store.state.folder.selectedFolderUID ? 1 : 0;
    },
    selectedItemsTitles()
    {
      return this.selectedItems.map((i) => i.text).join(', ');
    },
    filteredMenuHeaders()
    {
      const filtered = this.headers.filter((header) => header.value != 'actions');
      return filtered;
    },
    filteredHeaders()
    {
      return this.headers.filter((header) =>
      {
        const filterItem = this.filteredMenuHeaders.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },

    displayTableData() {
      return this.caseItems;
    },
  },
  methods: {


    handleCloseConfirmBulkDeleteDialog()
    {
      this.isOpenConfirmBulkDeleteDialog = false;
    },
    handleCloseConfirmDialog()
    {
      this.isOpenConfirmDialog = false;
    },
    handleConfirm()
    {
      this.isOpenConfirmDialog = false;
    },
    hanldeEditClick()
    {
      this.isOpenEditDialog = true;
    },
    handleCloseEditDialog()
    {
      this.isOpenEditDialog = false;
    },
    handleExportClick()
    {
      this.isOpenExportDialog = true;
    },
    handleCloseExportDialog()
    {
      this.isOpenExportDialog = false;
    },
    isLastItem(item)
    {
      return item.text == this.breadCrumbs[this.breadCrumbs.length - 1].text;
    },
    getColor(priority)
    {
      if (priority == 'High') {
        return 'text-red';
      } else if (priority == 'Medium') {
        return 'text-yellow';
      } else {
        return 'text-green';
      }
    },
    applyFilters(filters){
      this.filters = filters
    },
    handleSelectTestCases()
    {
      if (this.selectedRows.length > 0) {
        this.actionBtnShow = true;
      } else {
        this.actionBtnShow = false;
      }
      this.$emit('selectedCases', this.selectedRows)
    },
    handleClick(row)
    {
      this.$emit('expandDetail', row);
    },
    restoreDefault()
    {
      this.enableRestoreDefault = false;
      this.handleColumnReset();
    },
    handleColumnReset()
    {
      this.headers.map((header) =>
      {
        header.isSelected = true;
      });
    },
    handleClickColumnFilter()
    {
      const filters = this.filteredMenuHeaders;
      for (let item in filters) {
        if (filters[item].isSelected !== true) {
          this.enableRestoreDefault = true;
          return;
        }
      }
      this.enableRestoreDefault = false;
    },

    addSelectedCases()
    {
      this.selectedData = this.selectedRows;
      this.isSelectedData = true;
      this.isAddedTable = true;
    },
    removeSelectedCases()
    {
      this.isAddedTable = false;
    },
    // quick create test case function
    async quickCreate()
    {
      const item = this.testTemplates[parseInt(this.testTemplate) - 1];
      const payload = {
        name: this.testName,
        externalId: '',
        source: item.source,
        parentId: this.$store.state.folder.selectedFolderUID || null,
        customFields: {
          tags: [],
          priority: this.priorities[this.priorities.length - 1].value,
        },
      };
      this.$emit('createCase', payload);
      this.testName = '';
      this.testTemplate = '';
    },
    // get Test case history function
    async getCaseHistoryData()
    {
      let handle = this.currentAccount.handle;
      this.tableData = [];
      try {
        const res = await caseService.getCases(
          handle,
          this.$route.params.key,
          10,
          0
        );
        if (res.status == 200) {
          const cases = res.data.cases;
          if (cases.length == 0) {
            this.isBlankTable = true;
            return;
          }
          for (let item in cases) {
            let caseItem = {
              "id": parseInt(item) + 1,
              "name": cases[item].name,
              "priority": cases[item].custom_fields.priority,
              "tags": cases[item].custom_fields.tags,
              "externalId": cases[item].external_id,
              "source": cases[item].source,
              "uid": cases[item].uid,
              "steps": cases[item].steps,
              "sharedSteps": cases[item].custom_fields.sharedSteps
            };
            this.tableData.push(caseItem);
          }
        } else {
          console.log('Error occured');
        }
      } catch (err) {
        console.error('Error:', err);
      }
    },
    // delete Test case from uid
    async deleteTestCase(uid)
    {
      this.$parent.$emit('caseRemove', uid)
    },
    async handleBulkRemove()
    {
      if (this.selectedRows.length > 0) {
        this.isOpenConfirmBulkDeleteDialog = true;
      }
    },

    async confirmBulkRemove()
    {
      const itemUIDs = this.selectedRows.map((row) => row.uid);
      const payload = {
        ids: itemUIDs,
      };
      this.confirmDialogOpen = false;
      this.$parent.$emit('bulkRemove', payload);
      this.isOpenConfirmBulkDeleteDialog = false;
    },

    async updateCases(selectedPriority, selectedTag)
    {
      const handle = this.currentAccount.handle;
      try {
        const payload = {
          name: this.selectedRows[0].name,
          externalId: this.selectedRows[0].externalId,
          source: this.selectedRows[0].source,
          custom_fields: {
            tags: selectedTag,
            priority: selectedPriority,
          },
        };
        const response = await caseService.updateTestCase(
          handle,
          this.$route.params.key,
          this.selectedRows[0].uid,
          payload
        );
        if (response.status == 200) {
          console.log('Case item updated successfully!');
        } else {
          console.error('Error occured in backend!');
        }
      } catch (err) {
        console.log('Error: ', err);
      }
    },
    handleRowEdit(uid)
    {
      this.$router.push({ name: 'EditTestCases', params: { uid: uid } });
      // TODO - pass this item via state to avoid extra API calls
      //let caseItem;
      //for (let item in this.caseItems) {
      //  if (this.caseItems[item].uid == uid) {
      //    caseItem = this.caseItems[item]
      //  }
      //}
      //this.$router.push({ name: 'EditTestCases', params: { uid: caseItem } })
    },
  },
  mounted()
  {
    //this.getCaseHistoryData()
  },
};
</script>
<style>
.color-red {
  color: #f2284e !important;
}

.f-color-red {
  color: #f2284e !important;
}

.round-8 {
  border-radius: 8px;
}

.round-6 {
  border-radius: 6px;
}

.h-40 {
  height: 40px !important;
}

.btn-selector {
  position: relative;
}

.modal-main-area {
  height: 100%;
  padding: 32px 32px 32px 32px;
}

.dialog-title {
  font-weight: 900 !important;
}

.filter-dialog {
  padding-top: 15px;
}

.dialog-action {
  width: 90%;
  display: flex;
  position: absolute;
  bottom: 25px;
}

.btn-selector .selector-wrapper {
  position: relative;
}

.selector-style {
  position: absolute;
  right: 0;
  left: unset;
  top: 30px;
  min-width: 240px;
}

.modal-btn {
  width: 45%;
}

.f-color-white {
  color: white !important;
}

.text-red {
  color: #ef5350;
}

.text-green {
  color: #66bb6a;
}

.text-yellow {
  color: #ffa726;
}

.align-start {
  align-items: baseline !important;
  font-family: Inter !important;
}

.search-box-style {
  padding-top: 0;
  border-radius: 8px;
}

.search-bar-style {
  display: flex;
  padding-bottom: 0;
  justify-content: space-between;
}

.setting-btn-style {
  display: flex;
  justify-content: flex-end;
}

.setting-btn {
  position: absolute;
  right: 10px;
  width: 40px !important;
  min-width: 40px !important;
}

.breadcrumb-container {
  padding: 0;
}

.breadcrumb-container ul {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
}

.create-btn {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
}

.create-btn button {
  color: white !important;
  margin-top: 10px;
}

.bottom-input-style {
  margin-top: 0;
  border-radius: 5px;
  margin-right: 10px;
}

.bottom-input-style .v-text-field__slot {
  padding-left: 10px;
}

.bottom-input-style .v-select__selections {
  padding-left: 10px;
}

.data-table-style {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.data-table-style tbody tr {
  height: 50px !important;
  line-height: 0% !important;
  min-height: 50px;
  cursor: pointer;
}

.data-table-style tbody tr:hover,
.data-table-style tbody tr:hover:not(.v-data-table__expanded__content) {
  background-color: #d0d5dd !important;
}

.v-input__prepend-inner {
  padding-left: 10px;
}

.v-list-item__content {
  text-align: start;
}

.v-breadcrumbs__item .normal-font-color {
  color: rgb(93, 101, 121) !important;
  color: red;
}

.search-field .v-input__slot {
  display: flex;
  align-items: center !important;
}

.search-field .v-input__prepend-inner {
  align-self: center;
  margin-top: 0 !important;
  padding-left: 0px;
  padding-right: 8px !important;
}

.text-field .v-input__slot {
  background-color: #f9f9fb !important;
}

.btn-restore {
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  cursor: pointer;
}

.menu-header {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.absolute {
  position: absolute;
}

.bottom-right {
  display: flex;
  justify-content: right;
  right: 24px;
  bottom: 16px;
}

.f-color-blue {
  color: #0c2ff3;
}

.action-btn .v-list-item__title {
  display: flex;
  justify-content: flex-start;
}

.h-100 {
  height: 100%;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  display: block;
}

h2.blank-title {
  text-align: center;
}

p.blank-description {
  max-width: 500px;
  text-align: center;
}

.none {
  display: none;
}

.custom-attribute {
  white-space: nowrap;
}</style>
