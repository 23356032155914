export const chartColors = [
  { id: 1, value: '#0C2FF3' },
  { id: 2, value: '#FFA726' },
]

export const statusColors = [
  { id: 1, value: '#42A5F5', testCase: 'Active', testRun: '', defect: 'New' },
  { id: 2, value: '#66BB6A', testCase: 'Passed', testRun: 'Active', defect: 'Active' },
  { id: 3, value: '#FFA726', testCase: 'Incomplete', testRun: 'Rerun', defect: 'Blocked' },
  { id: 4, value: '#EF5350', testCase: 'Failed', testRun: 'Failed', defect: 'Incomplete' },
  { id: 5, value: '#7E57C2', testCase: '', testRun: 'In progress', defect: 'Testing' },
  { id: 6, value: '#667085', testCase: '', testRun: 'Passed', defect: 'Done' },
]

export const priorityColors = [
  { id: 1, value: '#EF5350', testCase: 'High', testRun: 'High', defect: 'High' },
  { id: 2, value: '#FFA726', testCase: 'Medium', testRun: 'Medium', defect: 'Medium' },
  { id: 3, value: '#66BB6A', testCase: 'Low', testRun: 'Low', defect: 'Low' },
]

export const typeColors = [
  { id: 1, value: '#42A5F5', testCase: 'Created', testRun: '', defect: '' },
  { id: 2, value: '#66BB6A', testCase: 'Assigned', testRun: '', defect: '' },
  { id: 3, value: '#FFA726', testCase: 'Executed', testRun: '', defect: '' },
  { id: 4, value: '#7E57C2', testCase: '', testRun: '', defect: 'Linked' },
  { id: 5, value: '#4642F5', testCase: '', testRun: 'Created', defect: '' },
  { id: 6, value: '#42F5EA', testCase: '', testRun: 'Assigned', defect: '' },
  { id: 7, value: '#EF5350', testCase: '', testRun: 'Executed', defect: '' },
]