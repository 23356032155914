<template>
  <v-data-table
    hide-default-footer
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="filteredHeaders"
    :items="filteredItems"
    :item-key="itemKey"
    :item-class="rowClass"
    @click:row="onRowClick"
  >
    <template v-slot:[`header.name`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.test`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.cases`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.lastchanges`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.creationdate`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.defects`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.projadmin`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.users`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`item.test`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item.test }}
      </span>
    </template>
    <template v-slot:[`item.cases`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item.cases }}
      </span>
    </template>
    <template v-slot:[`item.lastchanges`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ formattedDate(item.updated_at) }}
      </span>
    </template>
    <template v-slot:[`item.defects`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item.defects }}
      </span>
    </template>
    <template v-slot:[`item.creationdate`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ formattedDate(item.created_at) }}
      </span>
    </template>
    <template v-slot:[`item.projadmin`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item.projadmin }}
      </span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <td class="d-flex align-center">
        <v-btn icon class="mr-2" @click="toggleStar(item)">
          <v-icon v-if="item.customFields.star" color="star">mdi-star</v-icon>
          <v-icon v-else color="gray-ish">mdi-star-outline</v-icon>
        </v-btn>
        <v-avatar class="mr-2" size="40">
          <img :src="item.logo || require('@/assets/png/project.png')" width="100%" alt="logo" />
        </v-avatar>
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.name }}
          </div>
          <div>
            {{ item.subtitle }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.users`]="{ item }">
      <td class="d-flex align-center">
        <v-row>
          <div v-for="(pic, imgIndex) in item.images" :key="imgIndex">
            <v-avatar class="ml-n2 custom_border" size="30">
              <img :src="pic" />
            </v-avatar>
          </div>
          <v-avatar class="font-weight-bold gray-ish--text ml-n2" color="#ebecf0" size="30" v-if="item.showCount">
            +{{ item.count }}
          </v-avatar>
        </v-row>
        <div>
          <v-menu content-class="custom_ele elevation-0" nudge-bottom="35" left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon color="gray-ish">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-if="!item.archivedAt" @click="onEdit(item)" class="pointer">
                <EditIcon />
                <v-list-item-content class="ml-2">{{ $t('edit') }}</v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!item.archivedAt" @click="onArchive(item)" class="pointer">
                <ArchieveIcon />
                <v-list-item-content class="ml-2">{{ $t('archive') }}</v-list-item-content>
              </v-list-item>
              <v-list-item v-else-if="item.archivedAt" @click="onUnArchive(item)" class="pointer">
                <ArchieveIcon />
                <v-list-item-content class="ml-2">{{ $t('unarchive') }}</v-list-item-content>
              </v-list-item>
              <v-list-item @click="onDelete(item)" class="pointer">
                <DeleteIcon />
                <v-list-item-content class="ml-2 error--text">{{ $t('delete') }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import ArchieveIcon from '@/assets/svg/archived.svg';
import { formattedDate } from '@/utils/util';

export default {
  props: {
    filteredHeaders: Array,
    filteredItems: Array,
    itemKey: String,
    rowClass: Function,
  },
  data() {
    return {
      debounce: false,
    };
  },
  components: {
    DeleteIcon,
    EditIcon,
    ArchieveIcon,
  },
  methods: {
    formattedDate,
    onRowClick(item) {
      if (!this.debounce) {
        this.$emit('select-item', item);
      } else {
        this.debounce = false;
      }
    },
    onEdit(item) {
      this.$emit('edit-item', item);
    },
    onArchive(item) {
      this.$emit('archive-item', item);
    },
    onUnArchive(item) {
      this.$emit('unarchive-item', item);
    },
    onDelete(item) {
      this.$emit('delete-item', item);
    },
    toggleStar(item) {
      this.debounce = true;
      this.$emit('toggle-star', item);
    },
  },
};
</script>

<style>
.v-data-table .v-data-table__wrapper tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table .v-data-table__wrapper tbody tr:nth-child(even) {
  background-color: #f9fafb;
}

.pointer {
  cursor: pointer;
}

.header_text {
  color: #475467;
  font-weight: 700;
}

.custom_color {
  color: #667085;
}

.custom_border {
  border: 2px solid #ffffff;
}

.v-data-table table {
  border-collapse: collapse;
}

.v-data-table th {
  border: none !important;
}

.v-data-table td {
  border: none !important;
}

.v-data-table .v-data-table__wrapper tbody tr {
  height: 80px;
}

.v-data-table .v-data-table__wrapper tbody tr td {
  height: 80px !important;
  padding-top: 0;
}

.v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
  background-color: transparent !important;
}
</style>
