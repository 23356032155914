<template>
  <div>
    <v-btn @click="showDialog = true" elevation="0" class="text-capitalize ml-2 px-3 rounded-lg" height="40" width="112">
      <span class="px-4"> {{ $t('filters') }} <v-icon class="ml-1" size="16px">mdi-filter-variant</v-icon></span>
    </v-btn>
    <v-dialog v-model="showDialog" fullscreen max-width="400">
      <v-card>
        <v-card-text class="black--text">
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('testruns.create_testrun.priority') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox v-for="(item,index) in priorities" v-model="panel_priority" off-icon="mdi-checkbox-blank" :value="item" dense :label="item" :key="index"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('testruns.create_testrun.status') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox v-for="(item,index) in statuses" v-model="panel_status" off-icon="mdi-checkbox-blank" :value="item" dense :label="item" :key="index"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div>
            <div class="mb-1 text-start mt-4">{{ $t('numberOfTestCases') }}</div>
            <v-range-slider style="height: 50px;" color="blue" v-model="testcases"></v-range-slider>
            <div class="d-flex align-center">
              <v-text-field v-model="testcases[0]" dense single-line filled  :value="testcases[0]" color="blue" class="mr-0 custom_input rounded-lg"></v-text-field>
              <div class="mx-4 font-weight-bold text-h6">-</div>
              <v-text-field v-model="testcases[1]" dense single-line filled :value="testcases[1]" color="blue" class="mr-0 custom_input rounded-lg"></v-text-field>
            </div>
          </div>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">{{ $t('testruns.create_testrun.milestone') }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field dense single-line filled color="blue" prepend-inner-icon="mdi-magnify" placeholder="Search" class="custom_input mx-0 rounded-lg"></v-text-field>
                <v-checkbox v-for="(item,index) in milestones" v-model="panel_milestone" off-icon="mdi-checkbox-blank" :value="item" dense :label="item" :key="index"></v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-start pt-6">
            <div class="mt-4">{{ $t('creationDate') }}</div>
            <div class="d-flex align-center">
              <v-menu v-model="menu1" :close-on-content-click="false" full-width max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field dense single-line filled color="blue" prepend-inner-icon="mdi-calendar-blank-outline" class="mr-0 custom_input rounded-lg" :value="date_start" clearable readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date_start" @change="menu1 = false"></v-date-picker>
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">{{ $t('-') }}</div>
              <v-menu v-model="menu2" :close-on-content-click="false" full-width max-width="290">
                <template v-slot:activator="{ on }">
                  <v-text-field  dense single-line filled color="blue" prepend-inner-icon="mdi-calendar-blank-outline" class="custom_input rounded-lg" :value="date_end" clearable readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="date_end" @change="menu1 = false"></v-date-picker>
              </v-menu>
            </div>
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">{{ $t('testruns.create_testrun.tag') }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field dense single-line filled color="blue" v-model="searchTag" prepend-inner-icon="mdi-magnify" placeholder="Search" class="custom_input mx-0 rounded-lg"></v-text-field>
                  <v-checkbox v-for="(item,index) in filterTags" v-model="panel_tag" off-icon="mdi-checkbox-blank" :value="item" dense :label="item" :key="index"></v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div>
              <div class="mb-1 text-start mt-4">{{ $t('testruns.progress') }}</div>
              <v-range-slider style="height: 50px;" color="blue" v-model="progress"></v-range-slider>
              <div class="d-flex align-center">
                <v-text-field v-model="progress[0]" dense single-line filled :value="progress[0]" color="blue" class="mr-0 custom_input rounded-lg"></v-text-field>
                <div class="mx-4 font-weight-bold text-h6">-</div>
                <v-text-field v-model="progress[1]" dense single-line filled :value="progress[1]" color="blue" class="mr-0 custom_input rounded-lg"></v-text-field>
              </div>
            </div>
            <div class="d-flex justify-end mt-5">
              <v-btn width="10rem" @click="cancel" color="gray-100" full-width class="mr-4 text-capitalize" elevation="0">{{ $t('clearAll') }}</v-btn>
              <v-btn width="10rem" color="blue" dark full-width elevation="0" class="text-capitalize" @click="apply">{{ $t('apply') }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ProjectFilter",
  data() {
    return {
      priorities: ["High", "Medium", "Low"],
      statuses:["Active","In progress", "Passed", "Failed", "Rerun", "New"],
      milestones: ["None", "Release1.1", "Release2.2", "Release3.3"],
      tags: ["none", "#smoke", "#web", "#databaseintegrity", "#dev_team","#regression","#feature","#integration","#security"],
      searchTag: null,
      panel_priority: ["High", "Medium", "Low"],
      panel_status: ["Active","In progress", "Passed", "Failed", "Rerun", "New"],
      panel_milestone: ["None", "Release1.1", "Release2.2", "Release3.3"],
      panel_tag: ["none", "#smoke", "#web", "#databaseintegrity", "#dev_team","#regression","#feature","#integration","#security"],
      testrun: [0, 100],
      testcases: [0, 100],
      progress: [0, 100],
      date_start: new Date().toISOString().substr(0, 10),
      date_end: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      showDialog: false,
    };
  },
  computed:{
    filterTags(){
      if(!this.searchTag)
        return this.tags
      
      return this.tags.filter(tag => tag.startsWith(this.searchTag))
    }
  },
  methods: {
    apply() {
      this.$emit("applyFilters", {
        panel_priority: this.panel_priority,
        panel_status: this.panel_status,
        panel_milestone: this.panel_milestone,
        panel_tag: this.panel_tag,
        testcases_range: this.testcases,
        progress_range: this.progress,
        dateRange: {
          start: this.date_start,
          end: this.date_end,
        },
      });
      this.showDialog = false;
    },
    cancel() {
      this.panel_priority = ["High", "Medium", "Low"];
      this.panel_status = ["Active","In progress", "Passed", "Failed", "Rerun"];
      this.panel_milestone = ["None", "Release1.1", "Release2.2", "Release3.3"];
      this.panel_tag = ["none", "#smoke", "#web", "#databaseintegrity", "#dev_team","#regression","#feature","#integration","#security"];
      this.testrun = [0, 100];
      this.testcases = [0, 100];
      this.progress = [0, 100];
      this.date_start = new Date().toISOString().substr(0, 10);
      this.date_end = new Date().toISOString().substr(0, 10);
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 400px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
