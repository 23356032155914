export default function makeAdminService(api) {
  return {
    listModels() {
      return api.get(`/admin/models`);
    },
    getModelSpecs(model) {
      return api.get(`/admin/models/${model}`);
    },
    listResourceEntries(model) {
      return api.get(`/admin/${model}`);
    },
    getResourceEntry(model, id) {
      return api.get(`/admin/${model}/${id}`);
    },
    updateResourceEntry(model, id, payload) {
      return api.patch(`/admin/${model}/${id}`, payload);
    },
    archiveResourceEntry(model, id) {
      return api.delete(`/admin/${model}/${id}`);
    },
  };
}
