<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row justify-space-between mt-4">
      <div class="d-flex flex-row justify-start">
        <iconDots />
        <h3 class="ml-2">Step {{ index + 1 }}</h3>
      </div>
      <button @click="deleteStep"><v-icon color="danger">mdi-trash-can-outline</v-icon></button>
    </div>
    <div class="d-flex flex-column mt-4">
      <p class="font-weight-medium body-2 text-left mb-1">
        {{ $t('name') }}<strong class="red--text text--lighten-1">*</strong>
      </p>
      <v-text-field v-model="step.title" type="text" dense filled placeholder="Name" @input="editStepItem" />
      <p class="font-weight-medium body-2 text-left mb-1">
        {{ $t('sharedStepPage.stepDesctiption') }}<strong class="red--text text--lighten-1">*</strong>
      </p>
      <v-textarea v-model="step.description" type="text" dense background-color="#F9F9FB" auto-grow placeholder="Description" @input="editStepItem" />
      <p class="font-weight-medium body-2 text-left mb-1">
        {{ $t('sharedStepPage.expectedResult') }}<strong class="red--text text--lighten-1">*</strong>
      </p>
      <v-textarea v-model="step.expectedResult" type="text" dense background-color="#F9F9FB" auto-grow placeholder="Expected result" @input="editStepItem" />
    </div>
  </div>
</template>

<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';

export default {
  name: 'StepItem',
  components: {
    iconDots,
  },
  props: ['data', 'index'],
  data()
  {
    return {
      step: {
        title: '',
        description: '',
        expectedResult: '',
      },
      id: '',
    };
  },
  created()
  {
    this.step = {
      ...this.data
    }
  },
  methods: {
    deleteStep()
    {
      this.$emit('delete-step', this.index);
    },
    editStepItem()
    {
      this.$emit('input-step', this.index, this.step);
    },
  },
};
</script>
