<template>
  <v-data-table
    v-model="selectedItems"
    hide-default-footer
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    show-select
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    @click:row="handleClick"
    @input="handleSeleted"
  >
    <template v-slot:[`headers.name`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <span class="custom-attribute font-weight-bold">{{ item.name }}</span>
    </template>
    <template v-slot:[`item.steps_number`]="{ item }">
      <span class="custom-attribute">{{ item.steps.length }} &nbsp; steps</span>
    </template>
    <template v-slot:[`item.referenced_by`]="{ item }">
      <span class="custom-attribute"> {{ item.referenced_by }} test cases</span>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-center">
        <v-menu left offset-y class="font-inter">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense class="text-left font-inter">
            <v-list-item class="pointer d-flex align-center" v-if="item.archived_at == null">
              <EditIcon />
              <v-list-item-content @click="$emit('edit', item)" class="ml-2">{{ $t('edit') }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="pointer" @click="$emit(item.archived_at == null ? 'archive' : 'unarchive', item)">
              <ArchiveIcon v-if="item.archived_at == null" />
              <UnArchiveIcon v-else />
              <v-list-item-content class="ml-2">{{
                item.archived_at == null ? $t('archive') : $t('unarchive')
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item @click="$emit('delete', item)" class="pointer">
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">{{ $t('delete') }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import EditIcon from '@/assets/svg/edit.svg';
import ArchiveIcon from '@/assets/svg/archived.svg';
import UnArchiveIcon from '@/assets/svg/unarchive.svg';
import DeleteIcon from '@/assets/svg/delete.svg';

const { mapState } = createNamespacedHelpers('user');

export default {
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
  },

  components: {
    EditIcon,
    ArchiveIcon,
    DeleteIcon,
    UnArchiveIcon,
  },

  data() {
    return {
      tags: this.items,
      selectedItems: [],
    };
  },

  methods: {
    handleClick(item, index) {
      this.$emit('row-click', item, index?.index);
    },
    handleSeleted() {
      this.$emit('input', this.selectedItems);
    },
  },

  computed: {
    ...mapState(['currentAccount']),

    isAbleToManage() {
      return this.currentAccount.role_name === 'owner' || this.currentAccount.role_name === 'admin';
    },
  },
};
</script>
