<template>
  <v-container class="d-flex flex-column justify-space-between overflow-hidden px-0">
    <v-card-text class="black--text mt-0 pt-0">
      <div class="d-flex align-center justify-space-between">
        <v-col class="d-flex flex-row justify-start align-center">
          <iconDots/>
          <span class="font-16 font-inter font-weight-bold">{{ $t('templatesPage.custom_field', { index : fieldIndex + 1 }) }}</span>
        </v-col>
        <v-btn icon @click="$emit('remove-field',fieldItem)">
          <trashIcon class="pointer"/>
        </v-btn>
      </div>
      <v-form
        ref="form"
        v-model="validForm"
        lazy-validation
        class="d-flex flex-column mt-10"
      >
        <v-row class="px-3 mb-4">
          <v-row v-if="fieldItem.dataType !== 'existing_field'">
            <v-col cols="12" class="pb-0">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('name') }}
              </p>
              <v-text-field
                
                v-model="fieldItem.name"
                type="text"
                dense
                filled
                :placeholder="$t('name')"
                :rules="requiredRules"
              />
            </v-col>
          </v-row>
          <v-row v-if="fieldItem.dataType == 'existing_field'">
            <v-col cols="12" class="pb-0">
              <v-autocomplete :items="customFields" item-text="name" item-value="uid" label="Search by name" class="bg-color-grey round-8 px-2"  dense height="38px" placeholder="Text" hide-details />
            </v-col>
          </v-row >
          <v-row v-else>
            <v-col cols="12" class="pb-0">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('templatesPage.data_type') }}
              </p>
              <v-select v-model="fieldItem.dataType" class="bg-color-grey round-8 px-2" :items="dataTypes" item-text="text"
              item-value="value" dense height="38px" placeholder="Text" hide-details />
            </v-col>
            <v-col cols="12" class="pb-0" v-if="isMultiType()">
              <v-col v-for="(item,index) in fieldItem.options" :key="index" class="d-flex align-center px-0 py-6 h-40">
                <v-radio-group :value="1" v-if="fieldItem.dataType == 'radio'">
                  <v-radio
                    :key="1"
                    :value="1"
                    hide-details
                  ></v-radio>
                </v-radio-group>
                <v-col cols="1" v-else-if="fieldItem.dataType == 'dropdown'">
                </v-col>
                <v-checkbox
                  v-else-if="fieldItem.dataType == 'checkbox'"
                  input-value="true"
                  color="blue"
                  dense
                  class="pt-0 mt-0"
                  hide-details
                />
                <v-text-field
                  v-model="fieldItem.options[index]"
                  type="text"
                  dense
                  filled
                  hide-details
                  :placeholder="$t('name')"
                  :rules="requiredRules"
                />
              </v-col>
              <v-col class="d-flex flex-row justify-end px-0">
                <v-btn
                  color="#061AAE"
                  class="text-capitalize font-weight-bold white--text rounded-lg"
                  :width="$vuetify.breakpoint.smAndDown ? '100%' : '180px'"
                  @click="$emit('add-option', fieldItem)"
                >
                  {{ $t('templatesPage.add_custom_field') }}
                  <v-icon color="white">mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-col>

            <v-col cols="12" class="pb-0" v-else>
              <div class="d-flex align-center">
                <p class="font-weight-medium body-2 text-left mb-0">
                  {{ $t('templatesPage.provide_default_value') }}
                </p>
                <v-spacer />
                <v-switch
                  v-model="isDefaultValue"
                  inset
                  hide-details
                  class="custom-switch mt-0"
                />
              </div>
              <v-col class="d-flex flex-column align-start px-0" v-if="isDefaultValue">
                <p class="font-weight-medium body-2 text-left mb-0">
                  {{ $t('templatesPage.default_value') }}
                </p>
                <v-textarea v-if="fieldItem.dataType == 'text' || fieldItem.dataType == 'step'" auto-grow v-model="fieldItem.defaultValue" :placeholder="$t('templatesPage.enter_default_value')" class="bg-f9f9fb round-8 mt-1 mb-2 pt-3 px-3 pb-0 h-100 w-full" hide-details/>
                <v-text-field
                  v-else-if="fieldItem.dataType == 'link'"
                  v-model="fieldItem.defaultValue"
                  type="text"
                  dense
                  filled
                  hide-details
                  class="w-full"
                  :placeholder="$t('name')"
                  :rules="requiredRules"
                />
                <v-menu v-else-if="fieldItem.dataType == 'date'" v-model="menu1" :close-on-content-click="false" full-width max-width="290">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense single-line filled color="blue" prepend-inner-icon="mdi-calendar-blank-outline" class="mr-0 custom_input rounded-lg w-full" :value="fieldItem.default_date" clearable readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="fieldItem.default_date" @change="menu1 = false"></v-date-picker>
                </v-menu>
                <v-col v-else-if="fieldItem.dataType == 'attachment'"  class="item-area position-relative px-0">
                  <v-file-input class="bg-color-grey round-8 w-full" dense height="38px" hide-details/>
                </v-col>
              </v-col>
            </v-col>
          </v-row>
        </v-row>
      </v-form>
    </v-card-text>
  </v-container>
</template>

<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';
import trashIcon from '@/assets/svg/trash-outline.svg';

export default {
  name: 'CustomFieldForm',

  components: {
    iconDots,
    trashIcon
  },

  props: [
    'fieldItem',
    'fieldIndex',
    'customFields'
  ],
  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: false,
      customField: {
        uid: '',
        name: '',
        type: '',
        source: '',
        options: [],
      },
      dataTypes: [
        { type: "Dropdown", text: 'Dropdown', value: 'dropdown' },
        { type: "Radio", text: 'Radio button', value: 'radio' },
        { type: "Link", text: 'Link', value: 'link' },
        { type: "Text", text: 'Text', value: "text" },
        { type: "Checkbox", text: 'Check-box', value: 'checkbox' },
        { type: "Date", text: 'Date', value: 'date' },
        { type: "Step", text: 'Step', value: 'step' },
        { type: "Attachment", text: 'Attachment', value: 'attachment' },
      ],
      caseTemplate: null,
      isDefaultValue: false,
      menu1: false,
      field_date: new Date().toISOString().substr(0, 10),
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    isEditMode() {
      return !!this.customField.uid
    },
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.customField = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        type: this.data.type || '',
        source: this.data.source || '',
        options: this.data.options || [],
      }
    },

    'customField.type': {
      handler: function () {
        if (this.isEditMode && this.customField.type === this.data.type) {
          this.customField.options = this.data.options
        } else {
          this.customField.options = []
        }
      }
    }
  },
  methods: {
    onAddItem() {
      this.customField.options.push('')
    },

    removeOptionItem(index) {
      this.customField.options.splice(index, 1)
    },

    handleAddSubField() {
      const newItem = 'New'
      this.fieldItem.defaultValue.push(newItem)
    },

    isMultiType() {
      if (this.fieldItem.dataType == 'radio' || this.fieldItem.dataType == 'dropdown' || this.fieldItem.dataType == 'checkbox')
        return true
      return false
    },

    onCreate() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit(this.isEditMode ? 'update-custom-field' : 'create-custom-field', this.customField)
    },

    onCancel() {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style lang="scss" scoped>

.font-16 {
  font-size: 16px;
}

.bg-color-grey {
  background-color: #F9FAFB;
}
.custom-switch {
  ::v-deep .v-input--switch__thumb {
    width: 24px;
    height: 24px;
    top: 0;
    right: 2px;
  }

  ::v-deep .primary--text{
    background-color: #ffffff !important; /* Custom track color when switch is on */
  }

  ::v-deep .primary--text.v-input--switch__track {
    background-color: #0000ff !important; /* Custom thumb color */
    opacity: 1;
  }
}

.bg-f9f9fb{
  background-color: #F9FAFB;
}

.h-40 {
  height: 40px;
}

.swal-back {
  width: 440px;
}
</style>