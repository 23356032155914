<template>
  <v-container class="d-row flex-column justify-start pa-0">
    <v-container class="bg-f9faf8 rounded-lg">
      <h3>{{ stepItem.title }}</h3>
      <v-col class="d-flex flex-row justify-space-between">
        <v-col class="px-0">
          <h4 class="subtitle">{{ $t('description') }}</h4>
          <p>{{ stepItem.description }}</p>
        </v-col>
        <v-col class="px-0">
          <h4 class="subtitle">{{ $t('expectedResult') }}</h4>
          <p>{{ stepItem.expectedResult }}</p>
        </v-col>
      </v-col>
    </v-container>
    <v-container class="d-flex flex-column justify-start align-end px-0">
      <v-container v-for="(item,index) in stepItem.children" :key="index" class="d-flex flex-row justify-space-between px-0 pt-0">
        <v-container class="px-0 width-48"></v-container>
        <v-container class="bg-f9faf8 rounded-lg w-full">
          <h3>{{ item.name }}</h3>
          <div class="flex justify-between">
            <v-col class="px-0">
              <h4 class="subtitle">{{ $t('description') }}</h4>
              <p>{{ item.description }}</p>
            </v-col>
            <v-col class="px-0">
              <h4 class="subtitle">{{ $t('expectedResult') }}</h4>
              <p>{{ item.expectedResult }}</p>
            </v-col>
          </div>
        </v-container>
      </v-container>
    </v-container>
  </v-container>
</template>
<script>
export default {
  props: {
    stepItem: Object,
  },
}
</script>
<style>
.subtitle{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.bg-f9faf8 {
  background-color: #F9FAFB;
}

.width-48{
  width: 48px;
  padding: 0px;
  margin: 0px;
}
</style>
