
/**
 * bind testfiesta handle api endpoints to the frotend components
 * @param {Object} api 
 * @return {Object} 
*/
export default function makeHandleService(api) {
  return {

    /**
     * create handle row{document} for an org or user
     * @param {String} data.name
     * @param {String} data.owner_uid
     * @param {String} data.owner_type
     * @param {Boolean} data.current
     * */
    createHandle: function (data) {
      // refomrat incoming data
      let payload = {
        name: data.name,
        owner_uid: data.owner_uid,
        owner_type: data.owner_type,
        current: data.current
      }
      return api.post('handle', payload)
    },
    /**
   * update handle row{document} for an org or user
   * @param {String} data.name
   * @param {String} data.owner_uid
   * @param {String} data.owner_type
   * @param {Boolean} data.current
   * */
    updateHandle: function (data) {
      // refomrat incoming data
      let payload = {
        name: data.name,
        owner_uid: data.owner_uid,
        owner_type: data.owner_type,
        current: data.current
      }
      return api.patch(`/handle/${data.oldHandle}`, payload);
    },
    /**
     * check if handle with the same handle name exists
    */
    handleInUse: function (name) {
      return api.get(`/handle/${name}`);
    }
  };
}
