<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="filteredHeaders"
    :items="filteredItems"
    :item-key="itemKey"
    :item-class="rowClass"
    :value="selectedItems"
    @input="onRowClick"
    @click:row="handleRowClick"
    height="100%"
    show-select
  >
    <template v-slot:[`header.actions`]="{header}" >
        <div class="d-none">{{ header.text }}</div>
    </template>
    <template v-slot:[`item.name`]="{item}">
      <span class="custom-attribute font-weight-bold">{{ item.name }}</span>
    </template>
    <template v-slot:[`item.priority`]="{item}">
      <span :class=getColor(item.customFields.priority)>{{ item.customFields.priority }}</span>
    </template>
    <template v-slot:[`item.status`]="{item}">
      <span :class=getColor(item.customFields.status)>{{ item.customFields.status }}</span>
    </template>
    <template v-slot:[`item.milestone`]="{item}">
      <span :class=getColor(item.customFields.milestone)>{{ item.customFields.milestone }}</span>
    </template>
    <template v-slot:[`item.tags`]="{item}">
      <span :class=getColor(item.customFields.tags)>{{ item.customFields.tags }}</span>
    </template>
    <template v-slot:[`item.testcases`]="{item}">
      <span class="">{{ item.customFields.caseCount }} test cases</span>
    </template>
    <template v-slot:[`item.creationdate`]="{item}">
      <span class="">{{ item.createdAt }}</span>
    </template>
    <template v-slot:[`item.progress`]="{item}">
      <div class="d-flex flex-row align-center justify-space-between">
        <v-progress-linear
          rounded
          color="#0c2ff3"
          background-color="#F2F4F7"
          :height="8"
          :value="item.customFields.progress"
        />
        <span class="font-weight-regular ml-3">{{ item.customFields.progress }}%</span>
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-center">
        <v-menu left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense class="text-left">
            <v-list-item v-if="item.customFields.archived === false" @click="onEdit(item)" class="pointer">
                <EditIcon />
                <v-list-item-content class="ml-2">{{ $t('edit') }}</v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.customFields.archived === false" @click="onArchive(item)" class="pointer">
                <ArchieveIcon />
                <v-list-item-content class="ml-2">{{ $t('archive') }}</v-list-item-content>
              </v-list-item>
              <v-list-item v-else-if="item.customFields.archived === true" @click="onUnArchive(item)" class="pointer">
                <ArchieveIcon />
                <v-list-item-content class="ml-2">{{ $t('unarchive') }}</v-list-item-content>
              </v-list-item>
              <v-list-item @click="onDelete(item)" class="pointer">
                <DeleteIcon />
                <v-list-item-content class="ml-2 error--text">{{ $t('delete') }}</v-list-item-content>
              </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:[`item.users`]="{ item }">
      <td class="d-flex align-center">
        <v-row>
          <div v-for="(pic, imgIndex) in item.images" :key="imgIndex">
            <v-avatar class="ml-n2 custom_border" size="30">
              <img :src="pic" />
            </v-avatar>
          </div>
          <v-avatar class="font-weight-bold gray-ish--text ml-n2" color="#ebecf0" size="30" v-if="item.showCount">
            +{{ item.count }}
          </v-avatar>
        </v-row>
        <div>
          <v-menu content-class="custom_ele elevation-0" nudge-bottom="35" left>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon color="gray-ish">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="onEdit(item)" class="pointer">
                <EditIcon />
                <v-list-item-content class="ml-2">{{ $t('edit') }}</v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.customFields.status==='active'" @click="onArchive(item)" class="pointer">
                <ArchieveIcon />
                <v-list-item-content class="ml-2">{{ $t('archive') }}</v-list-item-content>
              </v-list-item>
              <v-list-item v-else-if="item.customFields.status==='archived'" @click="onUnArchive(item)" class="pointer">
                <ArchieveIcon />
                <v-list-item-content class="ml-2">{{ $t('unarchive') }}</v-list-item-content>
              </v-list-item>
              <v-list-item @click="onDelete(item)" class="pointer">
                <DeleteIcon />
                <v-list-item-content class="ml-2 error--text">{{ $t('delete') }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>

import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import ArchieveIcon from '@/assets/svg/archived.svg';
import { formattedDate } from '@/utils/util';

export default {
  props: {
    filteredHeaders: Array,
    filteredItems: Array,
    itemKey: String,
    rowClass: Function,
    clearSelection: Boolean,
  },
  data() {
    return {
      debounce: false,
      selectedItems: [],
    };
  },
  components: {
    DeleteIcon,
    EditIcon,
    ArchieveIcon
  },
  watch: {
    clearSelection(newVal) {
      if (newVal) {
        this.selectedItems = [];
        this.$emit('select-item', this.selectedItems);
      }
    },
  },
  methods: {
    formattedDate,
    onRowClick(item) {
      this.selectedItems = item;
      this.$emit('select-item', this.selectedItems);
    },
    handleRowClick(item) {
      this.$emit('select-row', item);
    },
    onEdit(item) {
      this.$emit('edit-item', item);
    },
    onArchive(item) {
      this.$emit('archive-item', item);
    },
    onUnArchive(item) {
      this.$emit('unarchive-item', item);
    },
    onDelete(item) {
      this.$emit('delete-item', item);
    },
    toggleStar(item) {
      this.debounce = true;
      this.$emit('toggle-star', item);
    },
    getColor(priority) {
      switch(priority){
        case "High":
        case "Failed":
          return "font-weight-bold red--text text--lighten-1"
        case "Medium":
        case "Rerun":
          return "font-weight-bold orange--text text--lighten-1"
        case "Low":
        case "Active":
          return "font-weight-bold green--text text--lighten-1"
        case "Passed":
          return "font-weight-bold deep-purple--text text--lighten-1"
        case "In progress":
          return "font-weight-bold grey--text text--lighten-1"
      }
    },
  },
};
</script>

<style>
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(odd) {
    background-color: #ffffff;
  }
  .v-data-table-header__icon {
    opacity: 1 !important;
  }
  .v-data-table .v-data-table__wrapper tbody tr:nth-child(even) {
    background-color: #F9FAFB;
  }
  .pointer {
    cursor: pointer;
  }
  .header_text {
    color: #475467;
    font-weight: 700;
  }
  .custom_color {
    color: #667085;
  }
  .custom_border {
    border: 2px solid #ffffff;
  }
  .v-data-table table {
    border-collapse: collapse;
  }
  .v-data-table th {
    border: none !important;
  }
  .v-data-table td {
    border: none !important;
    cursor: pointer;
  }
  .v-data-table tr.project-item:hover {
    border: 1px solid #d1e1ff !important;
  }
  .v-data-table .v-data-table__wrapper tbody tr {
    height: 80px;
  }
  .v-data-table .v-data-table__wrapper tbody tr td {
    height: 80px !important;
    padding-top: 0;
  }
  .v-data-table tbody tr:hover:not(.v-data-table__expanded__content) {
    background-color: transparent !important;
  }
</style>
