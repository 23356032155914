<template>
  <v-dialog v-model="dialog" :max-width="maxWidth">
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: '500px',
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
  },
};
</script>
