<template>
  <v-card class="white mt-3 left-menu-card" rounded="lg" elevation="0">
    <v-list nav>
      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="item.title"
          :to="item.to"
          @click="setActive(index)"
        >
          <v-list-item-icon v-if="!isMenuCollapsed">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-icon v-if="isMenuCollapsed">{{ item.icon }}</v-icon>
            <v-list-item-title class="text-left" v-if="!isMenuCollapsed">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div class="collapse-btn" @click="toggleMenu">
      <v-icon>{{ isMenuCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom'  }}</v-icon>
      <span v-if="!isMenuCollapsed" class="collapse-text">{{ $t('collapse') }}</span>
    </div>
  </v-card> 
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LeftMenuCard',

  props: {
    menuItems: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      selectedItem: 0,
    };
  },

  computed: {
    ...mapGetters(['isMenuCollapsed']),
  },

  methods: {
    ...mapMutations(['toggleMenu']),
    setActive(index) {
      this.selectedItem = index;
    }
  }
};
</script>

<style>
.left-menu-card {
  position: relative;
  height: calc(100vh - 100px);
  transition: width 0.3s;
}
.text-left {
  text-align: left;
}
.collapse-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: blue;
}
.collapse-btn .collapse-text {
  margin-left: 8px;
}
.left-menu-card.collapsed .collapse-btn .collapse-text {
  display: none;
}
.left-menu-card.collapsed .collapse-btn {
  justify-content: center;
}

</style>
