<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
    fullscreen
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between py-6">
          <h2 class="black--text">
            {{ data.name }}
          </h2>
          <v-btn icon @click="showDialog = false">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row class="text-left">
          <v-col cols="12" class="text-h6 pb-1">
            {{ $t('defect.overview') }}
          </v-col>

          <v-col cols="6" class="pr-2 py-2">
            <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
              <p class="mb-1">{{ $t('defect.creator') }}</p>
              <p class="mb-0 font-weight-bold">
                {{ data.creator || '&nbsp;' }}
              </p>
            </div>
          </v-col>

          <v-col cols="6" class="pl-2 py-2">
            <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
              <p class="mb-1">{{ $t('id') }}</p>
              <p class="mb-0 font-weight-bold">
                {{ data.id || '&nbsp;' }}
              </p>
            </div>
          </v-col>

          <template v-if="expandOverviewPanel">
            <v-col cols="6" class="pr-2 py-2">
              <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
                <p class="mb-1">{{ $t('testRun') }}</p>
                <p class="mb-0 font-weight-bold">
                  {{ data.testRun || '&nbsp;' }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" class="pl-2 py-2">
              <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
                <p class="mb-1">{{ $t('testCase') }}</p>
                <p class="mb-0 font-weight-bold">
                  {{ data.testCase || '&nbsp;' }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" class="pr-2 py-2">
              <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
                <p class="mb-1">{{ $t('lastUpdate') }}</p>
                <p class="mb-0 font-weight-bold">
                  {{ formatDate(data.updated_at) }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" class="pl-2 py-2">
              <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
                <p class="mb-1">{{ $t('defect.assignedTo') }}</p>
                <p class="mb-0 font-weight-bold">
                  {{ data.assignedTo || '&nbsp;' }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" class="pr-2 py-2">
              <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
                <p class="mb-1">{{ $t('status') }}</p>
                <p
                  class="mb-0 font-weight-bold"
                  :style="{ color: getStatusItem(data.status)?.color || '#000000de' }"
                >
                  {{ getStatusItem(data.status)?.label || '' }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" class="pl-2 py-2">
              <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
                <p class="mb-1">{{ $t('integration') }}</p>
                <p class="mb-0 font-weight-bold text-capitalize">
                  {{ data.integration || '&nbsp;' }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" class="pr-2 py-2">
              <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
                <p class="mb-1">{{ $t('priority') }}</p>
                <p
                  :style="{ color: getPriorityItem(data.priority)?.color || '#000000de' }"
                  class="mb-0 font-weight-bold"
                >
                  {{ getPriorityItem(data.priority)?.label || '' }}
                </p>
              </div>
            </v-col>

            <v-col cols="6" class="pl-2 py-2">
              <div class="text-body-1 grey lighten-5 rounded-lg px-4 py-2">
                <p class="mb-1">{{ $t('typeLabel') }}</p>
                <p class="mb-0 font-weight-bold">
                  {{ data.type || '&nbsp;' }}
                </p>
              </div>
            </v-col>
          </template>

          <v-col cols="12">
            <v-responsive
              class="pointer blue--text text-body-1 font-weight-bold"
              width="100px"
              @click="expandOverviewPanel = !expandOverviewPanel"
            >
              {{ expandOverviewPanel ? $t('defect.showLess') : $t('defect.showMore') }}
            </v-responsive>
          </v-col>
        </v-row>

        <v-expansion-panels flat v-model="commentsPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="mx-0 px-0 text-h6 ">
              {{ $t('comments') }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-tiptap
                v-model="newComment"
                outlined
                :dense="false"
                :placeholder="$t('addComment')">
                <template #bottom>
                  <v-card>
                    <v-col cols="12" class="text-right pa-1">
                      <v-btn color="primary" icon @click="$emit('add-comment', newComment)" :disabled="!newComment">
                        <v-icon>mdi-send</v-icon>
                      </v-btn>
                    </v-col>
                  </v-card>
                </template>
              </v-tiptap>

              <v-list three-line class="mt-4 py-0 text-left">
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, index) in data.comments"
                    :key="index"
                    class="mb-0 px-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-1 d-flex align-start">
                        <v-icon  size="40">mdi-account-circle</v-icon>
                        <div class="ml-2">
                          <p class="mb-1">{{ item.author }}</p>
                          <p class="text-caption grey--text mb-0">{{ formatDate(item.created_at) }}</p>
                        </div>
                      </v-list-item-title>
                      <p class="body-1 mt-2 mb-0" v-html="item.content" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate } from '@/utils/util';

import { priorityList, statusList } from '@/constants/defect.js';

export default {
  name: 'ViewDefectDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  data () {
    return {
      expandOverviewPanel: true,
      commentsPanel: 0,
      newComment: '',
      showTiptap: false,
    }
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.expandOverviewPanel = true
      this.newComment = ''
    },
  },

  methods: {
    getPriorityItem(priority) {
      return priorityList.find(item => item.value === priority)
    },

    getStatusItem(status) {
      return statusList.find(item => item.value === status)
    },

    formatDate(date) {
      if (!date) {
        return ''
      }

      return formatDate(date, 'MM/dd/yy')
    },
  }
}

</script>