export default function makeTemplatesService(api) {
  return {
    getTemplates: async function (handle, projectKey, params) {
      return api.get(`/${handle}/projects/${projectKey}/templates?${params}`);
    },
    createTemplate: async function (handle, projectKey, { ...data }) {
      return api.post(`/${handle}/projects/${projectKey}/templates`, data);
    },
    deleteTemplate: async function (handle, projectKey, item) {
      return api.delete(`/${handle}/projects/${projectKey}/templates/${item}`);
    },
    updateTemplate: async function (handle, projectKey, item, payload) {
      return api.patch(`/${handle}/projects/${projectKey}/templates/${item}`, payload);
    },
  };
}

