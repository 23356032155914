<template>
  <v-container class="pa-6 primary">
    <Card
      :headline="currentElement.headline"
      :text="currentElement.text"
      :imgName="currentElement.imgName"
    />
    <Indicators
      :elements="this.cards"
      :currentElementIndex="this.currentElementIndex"
      :showElement="this.showElement"
    />
  </v-container>
</template>

<script>
import Card from './Card.vue';
import Indicators from './Indicators.vue';

export default {
  name: 'Carousel',
  props: { cards: Array },
  components: { Card, Indicators },

  data() {
    return {
      currentElementIndex: 0,
      intervalid1: '',
    };
  },
  computed: {
    currentElement() {
      return this.cards[this.currentElementIndex];
    },
    reachedMaxLeft() {
      return this.currentElementIndex === 0;
    },
    reachedMaxRight() {
      return this.currentElementIndex === this.cards.length - 1;
    },
  },
  methods: {
    showNextElement() {
      this.currentElementIndex++;
    },
    showPrevElement() {
      this.currentElementIndex--;
    },
    showElement(elementIndex) {
      this.currentElementIndex = elementIndex;
    },
    todo: function() {
      this.intervalid1 = setInterval(
        function() {
          if (this.reachedMaxRight) {
            this.showElement(0);
          } else {
            this.showNextElement();
          }
        }.bind(this),
        5050
      );
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalid1);
  },
  mounted() {
    clearInterval(this.intervalid1);
    this.todo();
  },
};
</script>

<style lang="scss" scoped>
.card-carousel {
  display: flex;
  margin-bottom: 30px;
}

.icon {
  height: 90px;
  width: auto;
}
</style>