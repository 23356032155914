 // Color should be dynamic from user preferences but it will be implemented after user personal setting page is done.
export const priorityList = [
  { id: 1, value: 'high', label: 'High', color: '#EF5350'},
  { id: 2, value: 'medium', label: 'Medium', color: '#FFA726'},
  { id: 3, value: 'low', label: 'Low', color: '#66BB6A'},
]

export const statusList = [
  { id: 1, value: 'new', label: 'New', color: '#42A5F5' },
  { id: 2, value: 'active', label: 'Active', color: '#66BB6A' },
  { id: 3, value: 'blocked', label: 'Blocked', color: '#FFA726' },
  { id: 4, value: 'testing', label: 'Testing', color: '#7E57C2' },
  { id: 5, value: 'done', label: 'Done', color: '#667085' },
  { id: 6, value: 'incomplete', label: 'Incomplete', color: '#EF5350' },
]

export const integrationList = [
  { id: 1, value: 'github', label: 'Github' },
  { id: 2, value: 'gitlab', label: 'Gitlab' },
  { id: 3, value: 'bitbucket', label: 'Bitbucket' },
]