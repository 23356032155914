var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"my-12",attrs:{"align":"start","justify":"center"}},[_c('v-col',{staticClass:"mx-2",attrs:{"cols":"12","sm":"12","md":"9"}},[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xsOnly),expression:"$vuetify.breakpoint.xsOnly"}],attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-responsive',[(_vm.user)?_c('AccountCard',{attrs:{"accounts":_vm.accounts,"currentAccount":{
              isOrg: false,
              name: _vm.user.first_name + ' ' + _vm.user.last_name,
              handle: _vm.user.username,
              ..._vm.user,
            }}}):_vm._e()],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xsOnly),expression:"$vuetify.breakpoint.xsOnly"}],staticClass:"mx-2",attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-responsive',[_c('ProfileDropDown',{attrs:{"menus":_vm.sidebars}})],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.xsOnly),expression:"!$vuetify.breakpoint.xsOnly"}],attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ProfileSideBar',{attrs:{"sidebars":_vm.sidebars,"currentAccount":{
            isOrg: false,
            name: _vm.user.first_name + ' ' + _vm.user.last_name,
            handle: _vm.user.username,
            ..._vm.user,
          },"accounts":_vm.accounts}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"9"}},[_c('router-view')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }