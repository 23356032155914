<template>
  <v-container id="invite-member-container">
    <v-row id="invite-member-row1">
      <v-col cols="12" class="mt-10" id="invite-member-col1">
        <v-icon size="48" color="primary" id="invite-member-icon">mdi-email</v-icon>
      </v-col>
      <v-col cols="12" id="invite-member-col2">
        <h4 id="invite-member-heading">{{ $t('inviteMemberTo') }} {{ current_org.name }}</h4>
      </v-col>
    </v-row>
    <v-row id="invite-member-row2">
      <v-col cols="12" id="invite-member-col3">
        <v-form id="invite-member-form">
          <v-row class="ma-6" id="invite-member-row3">
            <v-col cols="12" md="8" id="invite-member-col4">
              <v-combobox
                v-model="selectedUser"
                :items="suggestions"
                :search-input.sync="query"
                :label="$t('searchNameEmail')"
                :loading="loadingSuggestions"
                item-text="value"
                item-value="value"
                prepend-inner-icon="mdi-database-search"
                auto-select-first
                hide-no-data
                return-object
                clearable
                autofocus
                :error-messages="inputError"
                dense
                outlined
                :filter="isNonMember"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar id="invite-member-avatar">
                      <img v-if="data.item.avatar_url" :src="data.item.avatar_url" />
                      <v-icon v-else>mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-left" id="invite-member-content">
                      <v-list-item-title class="" id="invite-member-title">{{ data.item.title }}</v-list-item-title>
                      <v-list-item-subtitle id="invite-member-subtitle">
                        <span v-if="data.item.username">@{{ data.item.username }}</span>
                        <span v-else> {{ data.item.email }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="role"
                :items="roles"
                :label="$t('role')"
                flat
                outlined
                dense
                class="text-left"
                :error-messages="roleError"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2" id="invite-member-col6">
              <v-btn id="invite-member-btn" color="primary" outlined @click="inviteUser">
                <span v-if="!sendingInvite" id="invite-member-btn-span">{{ $t('invite') }}</span>
                <v-progress-circular v-else value="40" size="24" color="primary" indeterminate></v-progress-circular>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import makeOrgService from '@/services/api/org';
import makeUserService from '@/services/api/user';
import { debounce } from 'debounce';
import validator from 'validator';

let userService;
let orgService;

export default {
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    current_org: {
      type: Object,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  computed: {
    validEmail() {
      return this.selectedUser && validator.isEmail(this.selectedUser.email);
    },
  },
  created() {
    userService = makeUserService(this.$api);
    orgService = makeOrgService(this.$api);
  },
  data() {
    return {
      query: '',
      loadingSuggestions: false,
      suggestions: [],
      selectedUser: null,
      sendingInvite: false,
      inputError: [],
      roleError: [],
      role: null,
    };
  },
  watch: {
    query(value) {
      if (!value || value.length < 3) return;

      this.initSearch(value);
    },
  },
  methods: {
    /**
     * initalize search function if query length is > 3 chars and there is no pending search
     * @param {String} query
     */
    initSearch: debounce(function (query) {
      this.searchUsers(query);
    }, 300),
    /**
     * Search for user suggestions based on input
     * @param {String} query
     */
    async searchUsers(query) {
      try {
        this.loadingSuggestions = true;
        const response = await userService.searchUsers(query);
        this.suggestions = response.data.map((u) => {
          return { ...u, title: `${u.first_name} ${u.last_name}`, value: u.username || u.email };
        });

        if (this.suggestions.length === 0) {
          this.suggestions = [{ title: `Invite "${query}" ?`, email: '', value: query }];
        }
      } finally {
        this.loadingSuggestions = false;
      }
    },

    async inviteUser() {
      if (this.sendingInvite) return;
      let hasError = false;

      const user = this.selectedUser;
      const role = this.role;

      if (!user) {
        hasError = true;
        this.inputError = [''];
      } else if (!user.uid && !validator.isEmail(user.value)) {
        hasError = true;
        this.inputError = ['A valid email address must be provided'];
      } else {
        this.inputError = [];
      }

      if (!role) {
        hasError = true;
        this.roleError = [''];
      } else {
        this.roleError = [];
      }

      if (hasError) return;

      const payload = {
        role,
        org_id: this.current_org.uid,
      };

      if (user.uid) {
        payload.user_uid = user.uid;
      } else {
        payload.user_email = user.value;
      }

      this.sendingInvite = true;
      try {
        await orgService.newInvite(payload);
        this.$emit('completed');

        // reset all states
      } catch (error) {
        if (!error.response) return this.showError();

        this.showError(error.response?.data.error);
      }
      this.sendingInvite = false;
    },

    isNonMember(user) {
      return !user.uid || this.members.filter((member) => member.uid == user.uid).length === 0;
    },

    showError(message) {
      return this.$swal({
        title: message ?? this.$t('problemProcessingRequest'),
        icon: 'error',
        showConfirmButton: false,
        position: 'top-end',
        timer: 2000,
        toast: true,
      });
    },
  },
};
</script>

<style scoped>
.v-autocomplete__content {
  max-height: 300px;
  overflow-y: auto;
}

#error {
  border: 0px !important;
}
</style>
