<template>
  <div>
    <v-text-field
      v-show="!localShowTiptap"
      :label="placeholder"
      v-model="localNewComment"
      @click="localShowTiptap = true"
      clearable
      :solo="isSolo"
    ></v-text-field>

    <!-- Tiptap Editor -->
    <div v-show="localShowTiptap" class="elevation-0 rounded">
      <v-tiptap v-model="localNewComment" :outlined="true" :dense="isDense" :placeholder="placeholder">
        <template v-if="showBottom" #bottom>
          <v-card>
            <v-col cols="12" class="text-right pa-1">
              <v-btn color="primary" small class="mr-2" depressed @click="emitNewComment">{{ $t('save') }}</v-btn>
              <v-btn color="grey-5" small class="mx-2" depressed @click="onCancel">{{ $t('cancel') }}</v-btn>
            </v-col>
          </v-card>
        </template>
      </v-tiptap>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newComment: {
      type: String,
      default: '',
    },
    showTiptap: {
      type: Boolean,
      default: false,
    },
    isSolo: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showBottom: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localShowTiptap: this.showTiptap, 
      localNewComment: this.newComment, 
    };
  },
  watch: {
    showTiptap(newVal) {
      this.localShowTiptap = newVal;
    },
    newComment(newVal) {
      this.localNewComment = newVal;
    },
    localNewComment(newVal) {
      this.$emit('update:newComment', newVal);
    },
  },
  methods: {
    onCancel() {
      this.localShowTiptap = false;
      this.localNewComment = ""
    },
    emitNewComment() {
      this.$emit('add:newComment', this.localNewComment);
      this.localShowTiptap = false;
    },
  },
};
</script>
