<template>
  <div class="text-left">
    <p class="font-weight-bold text-body-1 mb-6">
      {{ $t("authentication.step", { currentStep, totalSteps }) }}
    </p>

    <p class="font-weight-bold text-body-1 mb-1">
      1. {{ $t("authentication.scanQRCode") }}
    </p>

    <p class="mb-0 text-body-1">
      {{ $t("authentication.scanQRCodeNotice") }}
    </p>

    <v-img
      v-if="qrCodeUrl"
      width="300"
      :src="qrCodeUrl"
      class="mx-auto"
    />

    <p class="font-weight-bold text-body-1 mb-1">
      2. {{ $t("authentication.enterSixDigitCode") }}
    </p>

    <v-text-field
      v-model="verificationCode"
      type="text"
      dense
      filled
      placeholder="123456"
      :rules="requiredRules"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import makeUserService from '@/services/api/user';

export default {
  name: 'ScanQRCode',

  props: {
    totalSteps: {
      type: Number,
      default: 1,
    },

    currentStep: {
      type: Number,
      default: 1,
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      qrCodeUrl: '',
    }
  },

  computed: {
    ...mapGetters({ currentUser: 'user/user' }),

    verificationCode: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('change-otp', v)
      }
    },
  },

  mounted() {
    this.createTwoStepVerification()
  },

  methods: {
    ...mapActions({ setUser: 'user/setUser' }),

    async createTwoStepVerification() {
      const userService = makeUserService(this.$api)

      try {
        const response = await userService.createAuthentication({
          type: 'otp'
        });

        this.qrCodeUrl = response.data.url

        this.setUser({
          ...this.currentUser,
          preferences: {
            ...this.currentUser.preferences,
            authenticationType: 'otp',
            enableTwoStepAuthentication: response.data.enableTwoStepAuthentication,
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>