<template>
  <ul>
    <li
      v-for="(_, index) in elements"
      class="dot"
      :key="index"
      :class="{ 'dot-selected': currentElementIndex === index }"
    >
      <button
        class="indicator-btn"
        @click="showElement(index)"
        :disabled="currentElementIndex === index"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Indicators',
  props: { elements: Array, currentElementIndex: Number, showElement: Function },
};
</script>
<style lang="scss" scoped>
.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: white;
  opacity: 0.45;
  cursor: pointer;
  position: relative;
}

.dot-selected {
  opacity: 1;
  cursor: default;
}

.indicator-btn {
  position: absolute;
  left: 0px;
  padding: 0px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: transparent;
  border: none;
}
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 5px;
}
</style>
